import * as React from 'react';

export function useVisibility<T extends Element, U extends Element>(options: {
    element: T | undefined,
    parentElement?: U,
    updateWhenHidden?: boolean,
    resetOnChange?: ReadonlyArray<any>,
}): boolean {
  const { resetOnChange, element, parentElement, updateWhenHidden = true } = options;
  const [observer, setObserver] = React.useState<IntersectionObserver | undefined>(undefined);
  const [seen, setSeen] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const intersectionCallback = React.useCallback((entries: IntersectionObserverEntry[]) => {
    const invisible = entries[0].intersectionRatio <= 0;

    if (invisible) {
      setVisible(false);
    } else {
      setSeen(true);
      setVisible(true);
    }
  }, []);

  React.useEffect(() => setSeen(visible), resetOnChange);

  React.useEffect(() => {
    const observer = new IntersectionObserver(intersectionCallback, {
      root: parentElement || null,
      rootMargin: "0px",
      threshold: [0.0, 0.75],
    });

    setObserver(observer);

    return () => {
      observer.disconnect();
      setObserver(undefined);
    };
  }, [parentElement, intersectionCallback]);

  React.useEffect(() => {
    if (observer == null || element == null) return;
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [
    observer,
    element,
  ]);

  return React.useMemo(() => {
    return updateWhenHidden ? visible : seen;
  }, [seen, visible, updateWhenHidden]);
}
