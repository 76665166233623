import * as React from 'react';
import { Spoiler } from '@akst.io/web-resume-dom/ui/base/spoiler/spoiler';
import { Small } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { Rows } from '../../../ui/layout/layout';
import {
  Heading,
  List,
  ListItem,
  Normal,
  SubHeading,
  SubSubHeading,
  SubHeadingWithTimeAndTagline,
} from '../../../ui/type/type';

type OpenSketchProps = { children: any };

export const Interests = React.memo(({
  OpenSketch,
}: {
  OpenSketch: React.ComponentType<OpenSketchProps>,
}) => {
  return (
      <Rows gridTemplateColumns="unset">
        <Heading subHeading={undefined}>
          Interests
        </Heading>
        <SubSubHeading key="1">Recreation</SubSubHeading>
        <List>
          <ListItem>I've been learning Mandarin since 2018, but only seriously since 2020</ListItem>
          <ListItem>I thought cyrillic looked cool so I started learning Russian in 2021</ListItem>
          <ListItem>Generally <i>speaking</i>, I find phoentics and lingistics interesting</ListItem>
          <ListItem>I enjoy playing playing piano and a bit of guitar</ListItem>
          <ListItem>I like to do <OpenSketch>sketching</OpenSketch></ListItem>
        </List>
        <SubSubHeading key="1">Sports</SubSubHeading>
        <Normal>I'm not much a for a sports person, but I do like:</Normal>
        <List>
          <ListItem>Karate</ListItem>
          <ListItem>Bouldering</ListItem>
          <ListItem>Running</ListItem>
        </List>
        <Normal>Don't be fooled, I'm aweful at all of these</Normal>
        <SubSubHeading key="1">Programming</SubSubHeading>
        <Normal>Note these are areas of interest not specialisation</Normal>
        <List>
          <ListItem>Automation, Developer Tooling</ListItem>
          <ListItem>Compilers, Parsers</ListItem>
          <ListItem>Distributed Systems</ListItem>
          <ListItem>Graphics Programming</ListItem>
        </List>
      </Rows>
  );
});
