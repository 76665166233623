import { ApplicationService } from '@akst.io/web-resume-dom/services/application/application_service';
import {
  LocalApplicationStarter,
  LocalApplicationController,
} from '@akst.io/web-resume-dom/services/application/types';
import { getAssetPath } from '@akst.io/web-resume-dom/services/asset/asset_service';
import {
  LiveFileSystemNode,
  isLiveFile,
} from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import { FileHelperService } from '@akst.io/web-resume-dom/services/file_helper/file_helper_service';
import { SpriteService } from '@akst.io/web-resume-dom/services/sprite/sprite_service';
import { unsafeSpriteCast } from '@akst.io/web-resume-dom/services/sprite/types';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import sprite from '@akst.io/web-resume-dom/ui/sprites/note_pad_sprite.json';
import { createNotePad } from './note_pad';
import { FILE_EXTENSION, NotePadPresenter, NotePadStore } from './note_pad_presenter';

export type Options = {
  applicationService: ApplicationService;
  fileHelperService: FileHelperService;
  spriteService: SpriteService;
  Panel: React.ComponentType<PanelProps>;
};

export function installNotePad({
  applicationService,
  fileHelperService,
  spriteService,
  Panel,
}: Options) {
  const presenter = new NotePadPresenter(applicationService, fileHelperService);

  const filePath = ['programs', 'Note Pad.exe'];

  class NotePadController implements LocalApplicationController {
    constructor(private readonly store: NotePadStore) {
    }

    get title(): string {
      return this.store.title;
    }

    onQuit() {
    }
  }

  const withFile: LocalApplicationStarter = (file: LiveFileSystemNode | undefined, procId: number) => {
    if (file && !isLiveFile(file)) {
      return { ok: false, error: { type: 'incorrect-file-type', fileType: 'directory' } };
    }

    const store = presenter.createStore(file, procId);
    const controller = new NotePadController(store);
    const Component = createNotePad({ store, presenter, Panel, filePath });
    return { ok: true, value: { controller, Component } };
  };

  spriteService.register(
      FILE_EXTENSION,
      'text.sprite',
      unsafeSpriteCast(sprite),
  );
  applicationService.register(
      FILE_EXTENSION,
      { initializer: { type: 'local', withFile } },
      filePath,
  );
}
