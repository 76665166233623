import { UnreachableError } from '@akst.io/lib/base/types';
import * as Result from '@akst.io/lib/base/result';
import * as geo from '@akst.io/web-resume-dom/base/geometry';
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { RenderFrame } from '../configs/types';

export class AnimatorCanvasStore {
  canvas: HTMLCanvasElement | undefined;
  context: CanvasRenderingContext2D | undefined;
  nextFrameOn: number = -1;
  queuedFrames: RenderFrame[] = [];

  constructor(
      canvas: HTMLCanvasElement,
      context: CanvasRenderingContext2D,
      readonly bounds: Readonly<geo.Vector>,
      readonly sheet: HTMLImageElement,
  ) {
    this.canvas = canvas;
    this.context = context;
  }
}

export class AnimatorCanvasPresenter {
  constructor(
      private readonly logging: LoggingService,
      private readonly now: () => number,
      private readonly dpr: number,
  ) {
  }

  render(store: AnimatorCanvasStore, t: number) {
    const ctx = store.context;
    if (ctx == null) return;
    if (t < store.nextFrameOn) return;

    const srcDim = store.bounds;
    const dst = geo.scale(srcDim, this.dpr);
    const frame = store.queuedFrames.shift();

    ctx.clearRect(0, 0, dst.x, dst.y);

    if (frame == null) {
      ctx.drawImage(
          store.sheet,
          0, 0, srcDim.x, srcDim.y,
          0, 0, dst.x, dst.y,
      );
    } else {
      switch (frame.kind) {
        case 'wait':
          store.nextFrameOn = t + frame.duration;
          return;

        case 'queue-frame':
          store.nextFrameOn = t + frame.frame.duration;
          break;

        default:
          throw new UnreachableError(frame);
      }

      for (const src of frame.frame.source) {
        ctx.drawImage(
            store.sheet,
            src.x, src.y, srcDim.x, srcDim.y,
            0, 0, dst.x, dst.y,
        );
      }
    }
  }

  createStore(
      canvas: HTMLCanvasElement,
      sheet: HTMLImageElement,
      bounds: geo.Vector,
  ): Result.T<AnimatorCanvasStore, undefined> {
    const result = configCanvas(canvas, bounds, this.dpr);
    if (!result.ok) return result;

    const store = new AnimatorCanvasStore(
        canvas,
        result.value[1],
        bounds,
        sheet,
    );
    this.logging.debug('created AnimatorCanvasStore', canvas, result.value[1]);
    return Result.Ok(store);
  }

  queueFrames(store: AnimatorCanvasStore, frames: RenderFrame[]) {
    store.queuedFrames = store.queuedFrames.concat(frames);
  }

  resume(store: AnimatorCanvasStore, canvas: HTMLCanvasElement) {
    const result = configCanvas(canvas, store.bounds, this.dpr);
    if (!result.ok) return;

    store.canvas = result.value[0];
    store.context = result.value[1];
  }

  pause(store: AnimatorCanvasStore) {
    store.canvas = undefined;
    store.context = undefined;
  }
}

function configCanvas(
    canvas: HTMLCanvasElement,
    bounds: Readonly<geo.Vector>,
    dpr: number,
): Result.T<[HTMLCanvasElement, CanvasRenderingContext2D], undefined> {

  const scaled = geo.scale(bounds, dpr);
  canvas.width = scaled.x;
  canvas.height = scaled.y;
  canvas.style.width = `${bounds.x}px`;
  canvas.style.height = `${bounds.y}px`;

  const context = canvas.getContext('2d');
  if (context == null) return Result.Err(undefined);
  return Result.Ok([canvas, context]);
}
