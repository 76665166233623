import styled from 'styled-components';
import { baseUnit, smallestUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { navy, white } from '@akst.io/web-resume-dom/ui/base/color/colors';

const toolBarItemHeight = baseUnit * 2.5;
const leftPadding = baseUnit / 2;

export const Toolbar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${smallestUnit}px ${smallestUnit}px ${smallestUnit}px ${leftPadding}px;
    margin-bottom: ${smallestUnit}px;
    background: ${navy};
    color: ${white};
    line-height: 1;
    font-weight: 700;
`;

export const ToolbarLeft = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 0;
  flex-grow: 1;
`;

export const ToolbarSprite = styled.div`
  max-height: ${toolBarItemHeight}px;
  max-width: ${toolBarItemHeight}px;
  margin-right: ${baseUnit}px;
`;

export const ToolbarText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: hidden;
`;

export const ToolbarControls = styled.div`
  display: grid;
  grid-template-rows: ${toolBarItemHeight}px;
  grid-template-columns: ${toolBarItemHeight}px ${toolBarItemHeight}px;

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ToolbarButtonText = styled.span`
  font-weight: 700;
  line-height: 0;
`;

