import { action, observable, makeObservable } from 'mobx';

export class ColorStore {
  public r: number = 0;
  public g: number = 0;
  public b: number = 0;
  public pallet: ReadonlyArray<string> = [];

  constructor() {
    makeObservable(this, {
      r: observable.ref,
      g: observable.ref,
      b: observable.ref,
      pallet: observable.ref
    });
  }
}

export class ColorPresenter {
  constructor() {
    makeObservable(this, {
      onRGBChange: action,
      onPalletChange: action
    });
  }

  public onRGBChange(store: ColorStore, r: number, g: number, b: number) {
    store.r = r;
    store.g = g;
    store.b = b;
  }

  public onPalletChange(store: ColorStore, pallet: ReadonlyArray<string>) {
    store.pallet = pallet;
  }
}
