import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import { wait } from '@akst.io/web-resume-dom/base/time/time';
import { ApplicationService } from '@akst.io/web-resume-dom/services/application/application_service';
import { Clock } from '@akst.io/web-resume-dom/base/time/time';
import { AssetService } from '@akst.io/web-resume-dom/services/asset/asset_service';
import { AudioService } from '@akst.io/web-resume-dom/services/audio/audio_service';
import { DesktopService } from '@akst.io/web-resume-dom/services/desktop/desktop_service';
import { FileSystemService } from '@akst.io/web-resume-dom/services/file_system/file_system_service';
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { SpeechService } from '@akst.io/web-resume-dom/services/speech/speech_service';
import { AssistantController } from '@akst.io/web-resume-dom/ui/application/assistant/type';
import type { DragContainerProps } from '@akst.io/web-resume-dom/ui/system/draggable/create';
import { FileGridProps } from '@akst.io/web-resume-dom/ui/system/file_grid/file_grid';
import { createStartupStep } from './steps/startup_step/create';
import { createBluescreenStep } from './steps/bluescreen_step/create';
import { createDesktopStep } from './steps/desktop_step/create';
import {
  TransitionProgress,
} from './steps/types';
import { Flow } from './flow';
import { FlowController } from './flow_controller';
import { FlowPresenter } from './flow_presenter';

type FlowNode = {
  Component: React.ComponentType;
  controller: FlowController;
};

const setCssFullHeight = () => {
  const height = `${window.innerHeight}px`;
  document.body.style.setProperty('--window-height', height);
};

export function createFlow({
  applicationService,
  assistantController,
  audioService,
  assetService,
  clock,
  DragContainer,
  desktopService,
  fileSystemService,
  FileGrid,
  loggingService,
  speechService,
  username,
}: {
  audioService: AudioService,
  assistantController: AssistantController,
  assetService: AssetService,
  applicationService: ApplicationService,
  clock: Clock,
  desktopService: DesktopService,
  DragContainer: React.ComponentType<DragContainerProps>,
  fileSystemService: FileSystemService,
  FileGrid: React.ComponentType<FileGridProps>,
  loggingService: LoggingService,
  speechService: SpeechService,
  username: string,
}): FlowNode {
  const presenter = new FlowPresenter(wait);
  const store = presenter.createStore([
    createStartupStep({
      assetService,
      audioService,
      speechService,
    }),
    createDesktopStep({
      applicationService,
      assistantController,
      audioService,
      clock,
      DragContainer,
      desktopService,
      fileSystemService,
      username,
      loggingService: loggingService.createChild('Desktop'),
      FileGrid,
    }),
    createBluescreenStep(),
  ]);

  // TODO move the CSS variable from being global
  // to becoming a observable value.
  setCssFullHeight();

  document.addEventListener('orientationchange', () => {
    setCssFullHeight();
  }, { passive: true });

  document.addEventListener('resize', () => {
    setCssFullHeight();
  }, { passive: true });

  const transitionProgress = computed((): TransitionProgress => {
    return {
      length: store.transitionLength || 0,
      percent: store.transitionPercent || 0,
    };
  })

  const onStepTransition = (id: string, ms?: number) => {
    presenter.onStepTransition(store, id, ms);
  };

  return {
    controller: new FlowController(store, presenter),
    Component: observer(() => (
        <Flow
          view={store.view}
          transitionProgress={transitionProgress.get()}
          stepId={store.stepId}
          onStepTransition={onStepTransition}
          transitioningTo={store.transitioningTo}
        />
    )),
  };
}
