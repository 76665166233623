import { unindent } from '@akst.io/lib/base/strings';
import { Bootstrap } from '@akst.io/web-resume-dom/page/index/bootstrap';
import { getAssetPath } from '@akst.io/web-resume-dom/services/asset/asset_service';
import { FileSystemService } from '@akst.io/web-resume-dom/services/file_system/file_system_service';
import { FileSystemServiceImpl } from '@akst.io/web-resume-dom/services/file_system/file_system_service_impl';
import {
  Directory,
  File,
  FileSystemNode,
  Shortcut,
} from '@akst.io/web-resume-dom/services/file_system/types';
import folderSprite from '@akst.io/web-resume-dom/ui/sprites/folder_sprite.json';

type FileAssetId =
  | 'image/meme_1'
  | 'image/meme_2'
  | 'image/meme_3'
  | 'image/meme_4'
  | 'image/meme_5'
  | 'image/meme_6'
  | 'image/meme_7'
  | 'image/meme_8'
  | 'image/meme_9'
  | 'image/meme_10'
  | 'image/meme_11'
  | 'image/meme_12'
  | 'image/meme_13'
  | 'image/meme_14'
  | 'image/meme_15'
  | 'video/meme_1'
  | 'video/meme_2'
  | 'video/meme_3'
  | 'video/meme_4'
  | 'video/meme_5'
  | 'video/meme_6';

export function getFilePath(id: FileAssetId): string {
  switch (id) {
    case 'image/meme_1':
    case 'image/meme_2':
    case 'image/meme_3':
    case 'image/meme_4':
    case 'image/meme_5':
    case 'image/meme_6':
    case 'image/meme_7':
    case 'image/meme_8':
    case 'image/meme_9':
    case 'image/meme_10':
    case 'image/meme_11':
    case 'image/meme_12':
    case 'image/meme_13':
    case 'image/meme_14':
    case 'image/meme_15':
    case 'video/meme_1':
    case 'video/meme_2':
    case 'video/meme_3':
    case 'video/meme_4':
    case 'video/meme_5':
    case 'video/meme_6':
      return getAssetPath(id);
  }
}

type FSEntry = [string, FileSystemNode];

export function createFileSystemService({
  bootstrap,
  userName,
  password,
}: {
  bootstrap: Bootstrap;
  userName: string;
  password: string;
}): FileSystemService {
  const computerOwner = bootstrap.isForYiJing ? 'YiJing\'s' : 'My';
  const myComputer: FSEntry =
    [
      `${computerOwner} Computer`,
      Shortcut.create([]),
    ];

  const angusExe: FSEntry =
    [
      `Angus.EXE`,
      Shortcut.create(['programs', 'Angus.exe']),
    ];

  const memes: FSEntry[] =
    [
      ['Funny Vids', Directory.create([
        ['funny 1.vid', File.create(getFilePath('video/meme_1'))],
        ['funny 2.vid', File.create(getFilePath('video/meme_2'))],
        ['funny 3.vid', File.create(getFilePath('video/meme_3'))],
        ['funny 4.vid', File.create(getFilePath('video/meme_4'))],
        ['funny 5.vid', File.create(getFilePath('video/meme_5'))],
        ['funny 6.vid', File.create(getFilePath('video/meme_6'))],
      ])],
      ['Funny Pics', Directory.create([
        ['funny 1.img', File.create(getFilePath('image/meme_1'))],
        ['funny 2.img', File.create(getFilePath('image/meme_2'))],
        ['funny 3.img', File.create(getFilePath('image/meme_3'))],
        ['funny 4.img', File.create(getFilePath('image/meme_4'))],
        ['funny 5.img', File.create(getFilePath('image/meme_5'))],
        ['funny 6.img', File.create(getFilePath('image/meme_6'))],
        ['funny 7.img', File.create(getFilePath('image/meme_7'))],
        ['funny 8.img', File.create(getFilePath('image/meme_8'))],
        ['funny 9.img', File.create(getFilePath('image/meme_9'))],
        ['funny 10.img', File.create(getFilePath('image/meme_10'))],
        ['funny 11.img', File.create(getFilePath('image/meme_11'))],
        ['funny 12.img', File.create(getFilePath('image/meme_12'))],
        ['funny 13.img', File.create(getFilePath('image/meme_13'))],
        ['funny 14.img', File.create(getFilePath('image/meme_14'))],
        ['funny 15.img', File.create(getFilePath('image/meme_15'))],
      ])]
    ];

  return new FileSystemServiceImpl(
    Directory.create([
      ['desktop', Directory.create([
        angusExe,
        myComputer,
      ])],
      ['programs', Directory.create([])],
      ['documents', Directory.create([
        ['top-secret.txt', File.create(unindent`
            username: ${userName}
            password: ${password}
        `)],
      ])],
      ['sprites', Directory.create([
        ['folder.sprite', File.create(JSON.stringify(folderSprite))],
        ['alphabet', Directory.create([])],
      ])],
      ['assistants', Directory.create([
        ['clippy.assist', File.create('clippy')],
        ['bonzi.assist', File.create('bonzi')],
      ])],
      ['media', Directory.create([
        ...memes,
      ])],
    ]),
  );
}
