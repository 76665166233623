import * as Iterable from './iterable';

export function join(iterableA: Iterable<string>, infix: string): string {
  const [init, iterableB] = Iterable.consume(iterableA);
  if (init == null) return '';
  return Iterable.reduce(
      iterableB,
      init,
      (acc: string, s: string) => `${acc}${infix}${s}`,
  );
}

export function nthIndexOf(
    str: string,
    nth: number,
    match: string,
    from: number = 0,
) {
  let index = from;
  for (let i = 0; i < nth; i++) {
    if (index === -1) return -1;
    index = index + 1 + str.slice(index + 1).indexOf(match);
  }
  return index;
}

export function rowColOfOffset(
    input: string,
    offset: number,
): {
  row: number,
  col: number,
} {
  const lastNewline = input.slice(0, offset).lastIndexOf('\n');
  const col = offset - lastNewline;
  const row = input.slice(0, lastNewline).split('\n').length;
  return { col, row };
}

export function capitialise(word: string) {
  return `${word[0].toUpperCase()}${word.slice(1)}`;
}


export function findIndexOf(text: string, p: (ch: string) => boolean): number | undefined {
  for (let i = 0; i < text.length; i++) {
    if (p(text[i])) {
      return i;
    }
  }
  return undefined;
}

function templateStringJoin<A>(
    chunks: TemplateStringsArray,
    args: A[],
    toString: (arg: A) => string,
): string {
  let joined = chunks[0];

  for (let i = 1; i < chunks.length; i++) {
    joined += toString(args[i - 1]);
    joined += chunks[i];
  }

  return joined;
}

export function unindent(chunks: TemplateStringsArray, ...args: any): string {
  const joined = templateStringJoin(chunks, args, a => `${a}`);
  const lines = joined.split('\n').slice(1);
  const indentDepth = findIndexOf(lines[0], ch => ch !== ' ')
  if (indentDepth === undefined) {
    throw new Error('need to fix this');
  }

  const dedented = lines.map(line => line.slice(indentDepth));
  return dedented.join('\n');
}
