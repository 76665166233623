import * as React from 'react';
import {
  LocalApplicationStarter,
  LocalApplicationController,
} from '@akst.io/web-resume-dom/services/application/types';
import { ApplicationService } from "@akst.io/web-resume-dom/services/application/application_service";
import { AudioService } from '@akst.io/web-resume-dom/services/audio/audio_service';
import { FileSystemService } from "@akst.io/web-resume-dom/services/file_system/file_system_service";
import {
  isLiveFile,
  LiveFileSystemNode,
} from "@akst.io/web-resume-dom/services/file_system/live_files_system_node";
import { SpriteService } from '@akst.io/web-resume-dom/services/sprite/sprite_service';
import { unsafeSpriteCast } from '@akst.io/web-resume-dom/services/sprite/types';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import sprite from '@akst.io/web-resume-dom/ui/sprites/image_sprite.json';
import { ImageViewerPresenter, ImageViewerStore } from './image_viewer_presenter';
import { createImageViewer } from './image_viewer';

type CreateOptions = {
  applicationService: ApplicationService;
  audioService: AudioService;
  fileSystemService: FileSystemService;
  spriteService: SpriteService;
  Panel: React.ComponentType<PanelProps>;
};

const FILE_EXTENSION = 'img';

export function installImageViewer({
  applicationService,
  audioService,
  fileSystemService,
  spriteService,
  Panel,
}: CreateOptions) {
  const presenter = new ImageViewerPresenter(applicationService, audioService);

  class ImageViewerController implements LocalApplicationController {
    constructor(private readonly store: ImageViewerStore) {
    }

    get title(): string {
      return presenter.getTitle(this.store);
    }

    onQuit() {
    }
  }

  const withFile: LocalApplicationStarter = (file: LiveFileSystemNode | undefined, procId: number) => {
    if (!file || !isLiveFile(file)) {
      return { ok: false, error: { type: 'incorrect-file-type', fileType: 'file' } };
    }

    const store = presenter.createStore(procId, file);
    const controller = new ImageViewerController(store);
    const Component = createImageViewer({ store, presenter, Panel });
    return { ok: true, value: { controller, Component } };
  };

  spriteService.register(
      FILE_EXTENSION,
      'ImageViewer.sprite',
      unsafeSpriteCast(sprite),
  );
  applicationService.register(
      FILE_EXTENSION,
      { initializer: { type: 'local', withFile } },
      ['programs', 'ImageViewer.exe'],
  );
}
