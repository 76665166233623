import { checkExists } from '@akst.io/lib/base/types';
import { Dequeue } from '@akst.io/web-resume-dom/base/dequeue/dequeue';
import { boundaryTrace, Point } from '@akst.io/web-resume-dom/base/contour_tracing/contour_tracing';
import { PathCommand, Commands } from '@akst.io/web-resume-dom/ui/base/svg/path';
import { Sprite } from '@akst.io/web-resume-dom/services/sprite/types';

export type ColorArray = ReadonlyArray<number | undefined>;
export type Path = { d: ReadonlyArray<PathCommand>, fill: number };

export function spriteColors(sprite: Sprite): ColorArray {
  return sprite.fills.map(value => value == null ? undefined : value);
}

function pointsToPathCommands(points: ReadonlyArray<Point>): ReadonlyArray<PathCommand> {
  type WithPoints = (a: number, b: number) => PathCommand;

  const commands = [];
  let withPoints: WithPoints = Commands.M;
  let nextWithPoints: WithPoints = Commands.L;

  for (const point of points) {
    commands.push(withPoints(point[0], point[1]));
    withPoints = nextWithPoints;
  }

  commands.push(Commands.Z());
  return commands;
}

  // TODO implement a different path fill algorithm that can head
  // diagonally like the boundary tracing algorithm instead of re-
  // running the algorithm on each boundary node.
function claimOwnershipOf({
  width,
  height,
  color,
  parent,
  toClaim,
  colors,
  owners,
}: {
  width: number,
  height: number,
  color: number,
  parent: number,
  toClaim: ReadonlyArray<Point>,
  colors: ColorArray,
  owners: number[],
}) {
  const asCoord = (index: number) => [index % width, Math.floor(index / width)];
  const asIndex = (x: number, y: number) => (y * width) + x;
  const shouldQueue = (i: number) => owners[i] !== parent && colors[i] === color;

  for (const point of toClaim) {
    const initialIndex = asIndex(point[0], point[1]);
    if (!shouldQueue(initialIndex)) continue;

    const queue = Dequeue.create<number>();
    queue.pushRight(initialIndex);
    owners[initialIndex] = parent;

    while (queue.length) {
      const index = checkExists(queue.popLeft(), 'queue');
      const [x, y] = asCoord(index);

      if (x > 0) {
        const westIndex = asIndex(x - 1, y);
        if (shouldQueue(westIndex)) {
          owners[westIndex] = parent;
          queue.pushRight(westIndex);
        }
      }

      if (x < (width - 1)) {
        const eastIndex = asIndex(x + 1, y);
        if (shouldQueue(eastIndex)) {
          owners[eastIndex] = parent;
          queue.pushRight(eastIndex);
        }
      }

      if (y > 0) {
        const northIndex = asIndex(x, y - 1);
        if (shouldQueue(northIndex)) {
          owners[northIndex] = parent;
          queue.pushRight(northIndex);
        }
      }

      if (y < (height - 1)) {
        const southIndex = asIndex(x, y + 1);
        if (shouldQueue(southIndex)) {
          owners[southIndex] = parent;
          queue.pushRight(southIndex);
        }
      }
    }
  }
}

export function spritePaths(colors: ColorArray, width: number, height: number) {
  const owners = colors.map((_, index) => index);
  const paths: Path[] = [];

  for (let y = 0; y < width; y++) {
    const yOffset = y * width;
    for (let x = 0; x < width; x++) {
      const index = x + yOffset;

      // this is the background no need to claim it.
      if (colors[index] === undefined) continue;

      // this means it has already been claimed by a path
      if (owners[index] !== index) continue;

      const color = checkExists(colors[index], 'colours');
      const [borderPath, toClaim] = boundaryTrace(x, y, width, height, colors);
      const d = pointsToPathCommands(borderPath);

      claimOwnershipOf({
        width,
        height,
        color,
        parent: index,
        toClaim,
        colors,
        owners,
      });

      paths.push({ d, fill: color });
    }
  }

  return paths;
}

export type UsableBounds = { width: number, height: number };

export function calculateUsableBounds(
    sourceWidth: number,
    sourceHeight: number,
    availableWidth: number,
    availableHeight: number,
): UsableBounds {
  const widthRatio = availableWidth / sourceWidth;
  const heightRatio = availableHeight / sourceHeight;
  const idealRatio = Math.min(widthRatio, heightRatio);
  return { width: sourceWidth * idealRatio, height: sourceHeight * idealRatio };
}
