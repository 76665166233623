import * as React from 'react';
import { hexAsString } from '@akst.io/web-resume-dom/ui/base/color/color';

export type PathCommand =
  | { type: 'move-to', x: number, y: number }
  | { type: 'line-to', x: number, y: number }
  | { type: 'move-h', x: number }
  | { type: 'move-v', y: number }
  | { type: 'move-h-by', x: number }
  | { type: 'move-v-by', y: number }
  | { type: 'end' };

export const Commands = {
  M(x: number, y: number): PathCommand {
    return { type: 'move-to', x, y };
  },
  H(x: number): PathCommand {
    return { type: 'move-h', x };
  },
  V(y: number): PathCommand {
    return { type: 'move-v', y };
  },
  h(x: number): PathCommand {
    return { type: 'move-h-by', x };
  },
  v(y: number): PathCommand {
    return { type: 'move-v-by', y };
  },
  L(x: number, y: number): PathCommand {
    return { type: 'line-to', x, y };
  },
  Z(): PathCommand {
    return { type: 'end' };
  },
};

export function serializePathCommands(commands: ReadonlyArray<PathCommand>): string {
  let output = '';
  let prefix = '';

  for (const command of commands) {
    output += prefix;

    switch (command.type) {
      case 'move-to':
        output += `M${command.x} ${command.y}`;
        break;

      case 'line-to':
        output += `L${command.x} ${command.y}`;
        break;

      case 'move-h':
        output += `H ${command.x}`;
        break;

      case 'move-v':
        output += `V ${command.y}`;
        break;

      case 'move-h-by':
        output += `h ${command.x}`;
        break;

      case 'move-v-by':
        output += `v ${command.y}`;
        break;

      case 'end':
        output += 'Z';
        break;
    }

    prefix = ' ';
  }

  return output;
}

export function optimizeCommands(input: ReadonlyArray<PathCommand>): ReadonlyArray<PathCommand> {
  const output = [];
  let cursor = 0;
  let lastX = 0;
  let lastY = 0;

  while (cursor < input.length) {
    let commandLast: undefined | PathCommand = output[output.length - 1];
    let command = input[cursor];

    inner: while (commandLast) {
      switch (command.type) {
        case 'line-to':
          if (command.y === lastY) {
            command = Commands.H(command.x);
          } else if (command.x === lastX) {
            command = Commands.V(command.y);
          } else {
            commandLast = undefined;
          }
          break;

        case 'move-h':
          if (commandLast.type === 'move-h') {
            output.pop();
          }
          commandLast = undefined;
          break;

        case 'move-v':
          if (commandLast.type === 'move-v') {
            output.pop();
          }
          commandLast = undefined;
          break;

        default:
          break inner;
      }

      if (commandLast && commandLast.type === 'move-to') break;
    }

    if ('y' in command) {
      lastY = command.type !== 'move-v-by' ? command.y : command.y + lastY;
    }

    if ('x' in command) {
      lastX = command.type !== 'move-h-by' ? command.x : command.x + lastX;
    }

    output.push(command);
    cursor += 1;
  }

  return output;
}

export type PathProps = {
  d: ReadonlyArray<PathCommand>;
  fill?: number;
};

export const Path = React.memo(function Path(props: PathProps) {
  const { fill, d } = props;

  const optimized = React.useMemo(() => optimizeCommands(d), [d]);
  const dString = React.useMemo(() => serializePathCommands(optimized), [optimized]);
  const fillString = React.useMemo(() => (
      fill != null ? hexAsString(fill) : undefined
  ), [fill]);

  return (
      <path d={dString} fill={fillString}/>
  );
});
