import * as React from 'react';
import styled from 'styled-components';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { Rows } from '../../../ui/layout/layout';
import { Heading } from '../../../ui/type/type';

export const Loading = React.memo(({}: {}) => {
  return (
      <LoadingWrapper>
        <Heading subHeading="Hold your horses">
          Loading Resume...
        </Heading>
      </LoadingWrapper>
  );
});

const LoadingWrapper = styled.div`
  text-align: center;
  padding-bottom: ${baseUnit * 4}px;
  padding-top: ${baseUnit * 4}px;
`;
