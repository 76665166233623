export const turquoise = '#008080';
export const navy = '#000080';
export const red = 'red';
export const intenseBlue = '#0100ad';
export const cutePeach = '#f8ad9b';
export const cuteCream = '#ffedbc';
export const retroRed = '#E7618E';
export const retroPurple = '#6a82fb';

export const bgGrey = '#c0c0c0';
export const bgGreyDark = '#a0a0a0';

export const black = '#000000';
export const blackA25 = 'rgba(0, 0, 0, 0.25)';
export const blackA50 = 'rgba(0, 0, 0, 0.5)';
export const white = '#FFFFFF';

export const borderGrey = 'white #808080 #808080 white';
export const borderGreyInverted = '#808080 white white #808080';
