import {
  DesktopStepPresenter,
  DesktopStepStore,
} from './desktop_step_presenter';
import { StepController } from '../types';

export class DesktopStepController implements StepController {
  constructor(
    private readonly store: DesktopStepStore,
    private readonly presenter: DesktopStepPresenter,
  ) {
  }

  async start() {
    this.presenter.start(this.store);
  }
}
