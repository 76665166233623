import styled from 'styled-components';
import { bgGrey, borderGrey, borderGreyInverted } from '../../color/colors';
import { baseUnit, smallestUnit } from '../../units/units';

const inputHeight = (baseUnit * 2);

export const Root = styled.input`
  box-sizing: border-box;
  border-radius: 0;
  border-style: solid;
  border-color: ${borderGreyInverted};
  border-width: ${smallestUnit}px;
  background-color: ${bgGrey};
  display: block;
  width: 100%;
  height: ${inputHeight}px;
  margin: 0;
  appearance: none;

  &:focus {
      outline: 1px dotted black;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${inputHeight}px;
    border-style: solid;
    border-color: transparent;
    border-width: ${smallestUnit}px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  &::-moz-range-track {
    width: 100%;
    height: ${inputHeight}px;
  }

  &::-ms-track {
    width: 100%;
    height: ${inputHeight}px;
  }

  &::-ms-thumb {
    border-radius: 0;
    border-style: solid;
    border-color: ${borderGrey};
    border-width: ${smallestUnit}px;
    width: ${baseUnit}px;
    height: ${baseUnit}px;
    background-color: transparent;
    appearance: none;
  }

  &::-webkit-slider-thumb {
    box-sizing: content-box;
    border-radius: 0;
    border-style: solid;
    border-color: ${borderGrey};
    border-width: ${smallestUnit}px;
    width: ${baseUnit}px;
    height: ${baseUnit}px;
    background-color: ${bgGrey};
    appearance: none;
    margin: 0;
  }

  &::-moz-range-thumb {
    border-radius: 0;
    border-style: solid;
    border-color: ${borderGrey};
    border-width: ${smallestUnit}px;
    width: ${baseUnit}px;
    height: ${baseUnit}px;
    background-color: ${bgGrey};
    appearance: none;
  }
`;
