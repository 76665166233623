import * as React from 'react';
import { useMeasureWithElement } from '@akst.io/web-resume-dom/ui/base/responsive/measure';
import {
  CrossPlatformEvent,
  usePointerEventsOnElement,
  useWindowPointerEvents,
} from '@akst.io/web-resume-dom/ui/base/cross_platform/pointer_events';

export type CanvasProps = {
  onResize(canvas?: HTMLCanvasElement, bounds?: { width: number, height: number }): void;
  onCanvasPointerStart(event: CrossPlatformEvent<HTMLCanvasElement>): void;
  onCanvasPointerMove(event: CrossPlatformEvent<HTMLCanvasElement>): void;
  onCanvasPointerEnd(): void;
  useAnimation(canvas?: HTMLCanvasElement): void;
};

export const Canvas = React.memo(function Canvas(props: CanvasProps) {
  const {
    onCanvasPointerStart,
    onCanvasPointerMove,
    onCanvasPointerEnd,
    onResize,
    useAnimation,
  } = props;

  const [canvas, setCanvas] = React.useState<HTMLCanvasElement | null>(null);

  const {
    width: canvasWidth,
    height: canvasHeight,
  } = useMeasureWithElement(canvas, {
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    const bounds = canvasWidth && canvasHeight && { width: canvasWidth, height: canvasHeight };
    onResize(canvas || undefined, bounds ? bounds : undefined);
  }, [
    canvas,
    onResize,
    canvasWidth,
    canvasHeight,
  ]);

  /*
   * This powers the canvas.
   */
  useAnimation(canvas || undefined);

  usePointerEventsOnElement<HTMLCanvasElement>(
    canvas,
    {
      onPointerStart: onCanvasPointerStart,
      onPointerMove: onCanvasPointerMove,
    },
  );

  useWindowPointerEvents({
    onPointerEnd: onCanvasPointerEnd,
  });

  return (
      <canvas
          width={canvasWidth}
          height={canvasHeight}
          ref={setCanvas}
          style={{
            width: 250,
            height: 250,
            background: 'transparent',
            display: 'block',
            cursor: 'crosshair',
            position: 'relative',
          }}
      />
  );
});
