import { observable, makeObservable } from 'mobx';

export type FilePathDescription = ReadonlyArray<string>;
export type FileSystemNode = File | Directory | Shortcut;

export class File {
  public readonly type = 'file';
  public data: string;

  private constructor(data: string) {
    this.data = data;

    makeObservable(this, {
      data: observable.ref
    });
  }

  static create(data: string) {
    return new File(data);
  }
}

export class Directory {
  public readonly type = 'directory';
  public readonly children: Map<string, FileSystemNode>;

  private constructor(children: Map<string, FileSystemNode>) {
    this.children = children;
  }

  static create(fileList: ReadonlyArray<[string, FileSystemNode]>) {
    const children = observable.map();
    fileList.forEach(([k, v]) => children.set(k, v));
    return new Directory(children);
  }
}

export class Shortcut {
  public readonly type = 'shortcut';
  public readonly path: ReadonlyArray<string>;

  private constructor(path: ReadonlyArray<string>) {
    this.path = path;
  }

  static create(path: ReadonlyArray<string>) {
    return new Shortcut(path);
  }
}
