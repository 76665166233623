import { action, computed, observable, makeObservable } from 'mobx';
import { UnreachableError, checkExists } from '@akst.io/lib/base/types';
import * as Result from '@akst.io/lib/base/result';
import { FileSystemService } from '@akst.io/web-resume-dom/services/file_system/file_system_service';
import { FilePathDescription } from '@akst.io/web-resume-dom/services/file_system/types';
import {
  LiveFile,
  LiveDirectory,
  LiveFileSystemNode,
  isLiveDirectory,
  isLiveFile,
  isLiveShortcut,
  liveFileId,
} from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import {
  ApplicationService,
  FindPidsRequest,
} from './application_service';
import {
  ApplicationConfig,
  ApplicationInitialiser,
  ApplicationInitResult,
  ApplicationInstance,
  RemoteApplicationStarter,
} from './types';

type InternalApplicationInstance = ApplicationInstance & {
  file: LiveFileSystemNode | undefined,
  applicationId: string;
  pid: number;
};

export class ApplicationServiceImpl implements ApplicationService {
  private procCounter = 1;
  private readonly minimized: Set<number> = observable.set();
  private readonly _running: Map<number, InternalApplicationInstance> = observable.map();
  private readonly configs: Map<string, ApplicationConfig> = new Map();

  constructor(
      private readonly fileExplorerApplicationId: string,
      private readonly windowAnimationTime: number,
      private readonly startRemoteApplication: RemoteApplicationStarter,
      private readonly fileSystemService: FileSystemService,
  ) {
    makeObservable<ApplicationServiceImpl, "startProc">(this, {
      visible: computed,
      toggleMinization: action,
      minimize: action,
      maximize: action,
      stopProc: action,
      startProc: action
    });
  }

  get running(): ReadonlyMap<number, ApplicationInstance> {
    return this._running;
  }

  get visible(): ReadonlyMap<number, ApplicationInstance> {
    const visible = new Map<number, ApplicationInstance>();
    for (const [id, instance] of this._running) {
      if (this.minimized.has(id)) continue;
      visible.set(id, instance);
    }
    return visible;
  }

  register(extension: string, config: ApplicationConfig, exeLocation: FilePathDescription) {
    this.configs.set(extension, config);
    this.fileSystemService.write(exeLocation, extension);
  }

  /**
   * This method has the complexity of `O(n)` due to the lack
   * of an index to search processes by the type of application
   * that booted the process.
   */
  findPidOf(extension: string): number | undefined {
    for (const [pid, instance] of this._running) {
      if (instance.applicationId === extension) {
        return pid;
      }
    }
  }

  findPids(req: FindPidsRequest): number[] {
    switch (req.kind) {
      case 'noop':
        return [];
      case 'file-extension':
        break;
      default:
        throw new UnreachableError(req);
    }

    const pids: number[] = [];
    const { ext: extension } = req;

    for (const [pid, instance] of this._running) {
      if (instance.applicationId === extension) {
        pids.push(pid);
      }
    }

    return pids;
  }

  toggleMinization(procId: number) {
    const instance = this._running.get(procId);
    if (instance == null) {
      throw new Error('not implemented');
    }

    const { controller } = instance;
    const isMinimized = this.minimized.has(procId);
    if (isMinimized) {
      controller.onMaximize && controller.onMaximize(this.windowAnimationTime);
      this.minimized.delete(procId);
    } else {
      controller.onMinimize && controller.onMinimize(this.windowAnimationTime);
      this.minimized.add(procId);
    }
  }

  minimize(procId: number) {
    const instance = this._running.get(procId);
    if (instance == null) {
      throw new Error('not implemented');
    }

    if (!this.minimized.has(procId)) {
      const callback = instance.controller.onMinimize;
      callback && callback(this.windowAnimationTime);
      this.minimized.add(procId);
    }
  }

  maximize(procId: number) {
    const instance = this._running.get(procId);
    if (instance == null) {
      throw new Error('not implemented');
    }

    if (this.minimized.has(procId)) {
      const callback = instance.controller.onMaximize;
      callback && callback(this.windowAnimationTime);
      this.minimized.delete(procId);
    }
  }

  open(fileNode: LiveFileSystemNode) {
    if (isLiveFile(fileNode)) {
      this.openFile(fileNode);
    } else if (isLiveShortcut(fileNode)) {
      const lookedupNode = this.fileSystemService.lookupShortcut(fileNode);
      this.open(checkExists(lookedupNode, 'unknown file'));
    } else if (isLiveDirectory(fileNode)) {
      this.openDirectory(fileNode);
    }
  }

  openFile(file: LiveFile): Result.T<number, undefined> {
    const fileId = liveFileId(file);
    const appId = this.fileSystemService.getApplicationIdentifier(file);
    const noFile = appId === file.data;
    return this.startProc(
        noFile ? undefined : fileId,
        appId,
        noFile ? undefined : file,
    );
  }

  openDirectory(file: LiveDirectory) {
    const fileId = liveFileId(file);
    this.startProc(fileId, this.fileExplorerApplicationId, file);
  }

  stopProc(processId: number) {
    const proc = this._running.get(processId);
    if (proc != null) {
      proc.controller.onQuit();
      this._running.delete(processId);
    }
  }

  private startProc(
    fileId: string | undefined,
    applicationId: string,
    file: LiveFileSystemNode | undefined
  ): Result.T<number, undefined> {
    const config = this.configs.get(applicationId);
    if (config == null) {
      console.error(`missing configuration for '${applicationId}'`);
      return Result.Err(undefined);
    }

    const procId = this.procCounter++;
    const result = this.initializeProc(fileId, procId, config.initializer, file);

    if (result.ok) {
      this._running.set(procId, {
        controller: result.value.controller,
        Component: result.value.Component,
        file,
        pid: procId,
        applicationId,
      });
      return Result.Ok(procId);
    } else {
      const { error } = result;
      console.error(error);
      switch (error.type) {
        case 'already-running':
          this.maximize(error.pid);
          break;

        default:
          break;
      }
      return Result.Err(undefined);
    }
  }

  private initializeProc(
      fileId: string | undefined,
      procId: number,
      config: ApplicationInitialiser,
      file: LiveFileSystemNode | undefined,
  ): ApplicationInitResult {
    switch (config.type) {
      case 'local':
        return config.withFile(file, procId);
      case 'remote': {
        const data = file && isLiveFile(file) ? file.unsafeData : undefined;
        return this.startRemoteApplication(config.url, fileId, data);
      }
      default:
        throw new UnreachableError(config);
    }
  }
}
