import { FilePathDescription } from '@akst.io/web-resume-dom/services/file_system/types';
import { FileSystemService } from '@akst.io/web-resume-dom/services/file_system/file_system_service';
import { FileHelperService } from '@akst.io/web-resume-dom/services/file_helper/file_helper_service';
import { FileNavigatorProps } from '@akst.io/web-resume-dom/ui/system/file_navigator/file_navigator';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import { createFileWindow } from './file_window/file_window';
import { FileHelperServiceImpl } from './file_helper_service_impl';

type CreateOptions = {
  fileSystemService: FileSystemService;
  FileNavigator: React.ComponentType<FileNavigatorProps>;
  Panel: React.ComponentType<PanelProps>;
};

export function installFileAccessManager({
  fileSystemService,
  FileNavigator,
  Panel,
}: CreateOptions): FileHelperService {
  const FileWindow = createFileWindow({ FileNavigator, Panel });


  return new FileHelperServiceImpl(fileSystemService);
}
