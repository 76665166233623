import styled, { css, keyframes } from 'styled-components';
import { navy, white } from '@akst.io/web-resume-dom/ui/base/color/colors';
import { fontFamily } from '@akst.io/web-resume-dom/ui/base/typography/fonts';

export const DragSlotsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const FileRoot = styled.div<{ $clicked: boolean }>`
  border: 1px solid transparent;
  position: absolute;
  width: var(--fileWidth);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  ${({ $clicked }) => $clicked && css`
    background: ${navy};
    border: 1px dotted ${white};
    z-index: 2;
  `}
`;

export const FileTitle = styled.div<{ $clicked: boolean }>`
  overflow-y: hidden;
  max-width: 100%;
  max-height: var(--textMaxHeight);
  text-align: center;
  font-family: Menlo, ${fontFamily};
  font-weight: 600;
  font-size: var(--textFontSize);
  line-height: var(--textLineHeight);
  letter-spacing: -0.6px;

  ${({ $clicked }) => $clicked && css`
    max-height: initial;
    color: ${white};
  `}
`;

export const FileSpriteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: var(--iconSize);
  height: var(--iconSize);
  max-width: var(--iconSize);
  max-height: var(--iconSize);
`;

export const FileSpacer = styled.div`
  width: var(--iconSize);
  height: var(--textMarginTop);
`;

export const FileInnerButtonRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const seesaw = keyframes`
  0% { transform: rotate(-5deg); }
  50% { transform: rotate(5deg); }
  100% { transform: rotate(-5deg); }
`;

export const SeeSaw = styled.div<{
  animationDelay: number;
}>`
  animation-name: ${seesaw};
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: ${p => p.animationDelay}s;
  animation-duration: 4s;
`;

