import { action, observable, runInAction, makeObservable } from 'mobx';
import {
  AssetService,
  getAssetPath,
} from '@akst.io/web-resume-dom/services/asset/asset_service';
import { AudioService } from '@akst.io/web-resume-dom/services/audio/audio_service';
import { SpeechService } from '@akst.io/web-resume-dom/services/speech/speech_service';
import { TransitionFn } from '../types';

export class StartupStepStore {
  backgroundImage?: string = undefined;
  loadedGlobalAssets = false;
  loadedMicrosoftLogo = false;
  transitioningAway = false;
  backgroundVisible = false;

  constructor() {
    makeObservable(this, {
      backgroundImage: observable.ref,
      loadedGlobalAssets: observable.ref,
      loadedMicrosoftLogo: observable.ref,
      transitioningAway: observable.ref,
      backgroundVisible: observable.ref
    });
  }

  get microsoftLogo(): string {
    return getAssetPath('image/windows_logo');
  }

  get canSubmit(): boolean {
    return this.backgroundVisible && this.loadedGlobalAssets;
  }
}

export class StartupStepPresenter {
  constructor(
      private readonly assetService: AssetService,
      private readonly audioService: AudioService,
      private readonly speechService: SpeechService,
  ) {
    makeObservable(this, {
      onLoadedImage: action
    });
  }

  createStore(): StartupStepStore {
    return new StartupStepStore();
  }

  async start(store: StartupStepStore) {
    // require a least 5 seconds of load time
    await Promise.all([
      this.assetService.loadAllAssets(),
      this.wait(4000),
    ]);
    runInAction(() => store.loadedGlobalAssets = true);
  }

  async onLoadedImage(store: StartupStepStore, animationTime: number) {
    store.loadedMicrosoftLogo = true;
    await this.wait(animationTime);
    runInAction(() => store.backgroundVisible = true);
  }

  async onContinue(
      store: StartupStepStore,
      onStepTransition: TransitionFn,
      transitionTime: number,
  ) {
    const voice = this.speechService.getVoices({}).voices[0];
    this.speechService.say({
      message: 'sh',
      volume: 0.01,
      voiceURI: voice?.voiceURI,
    });

    if (store.canSubmit) {
      runInAction(() => store.transitioningAway = true);
      onStepTransition('desktop', transitionTime);
    }
  }

  private wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
