import { checkExists } from '@akst.io/lib/base/types';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { StepNode } from './steps/types';

type Waiter = (length: number) => Promise<void>;

type StepMap = ReadonlyMap<string, StepNode>;
type View = StepNode['Component'];
export type CurrentView =
  | { type: 'show', View: View }
  | { type: 'transition', From: View, To: View }

export class FlowStore {
  readonly steps: StepMap;
  transitionPercent?: number = undefined;
  transitionLength?: number = undefined;

  stepId: string;
  transitioningTo?: string = undefined;

  constructor(stepId: string, steps: StepMap) {
    this.stepId = stepId;
    this.steps = steps;

    makeObservable(this, {
      transitionPercent: observable.ref,
      transitionLength: observable.ref,
      stepId: observable.ref,
      transitioningTo: observable.ref,
      currentStep: computed,
      nextStep: computed,
      view: computed
    });
  }

  get currentStep(): StepNode {
    return checkExists(this.steps.get(this.stepId), 'failed to find step');
  }

  get nextStep(): StepNode | undefined {
    const { transitioningTo } = this;
    return transitioningTo != null
        ? checkExists(this.steps.get(transitioningTo), 'failed to find transition')
        : undefined;
  }

  get view(): CurrentView {
    const { currentStep, nextStep } = this;
    if (nextStep) {
      return {
        type: 'transition',
        From: currentStep.Component,
        To: nextStep.Component,
      };
    }
    return {
      type: 'show',
      View: currentStep.Component
    };
  }
}

export class FlowPresenter {
  constructor(private readonly wait: Waiter) {
    makeObservable(this, {
      onStepTransition: action
    });
  }

  createStore(steps: ReadonlyArray<StepNode>): FlowStore {
    return new FlowStore(
        steps[0].id,
        new Map(steps.map(s => [s.id, s] as [string, StepNode])),
    );
  }

  start(store: FlowStore): Promise<void> {
    return this.startStep(store, store.stepId);
  }

  private async startStep(store: FlowStore, id: string): Promise<void> {
    const step = checkExists(store.steps.get(id), 'failed to find step');
    return step.controller.start();
  }

  async onStepTransition(store: FlowStore, id: string, ms?: number) {
    const startPromise = this.startStep(store, id);
    if (ms == null) {
      store.stepId = id;
    } else {
      store.transitioningTo = id;
      store.transitionLength = ms;
      await this.wait(ms);
      runInAction(() => {
        store.stepId = id;
        store.transitionPercent = undefined;
        store.transitioningTo = undefined;
      });
    }
    return startPromise;
  }
}
