import {
  JsonFieldReader,
  Json,
  parseUrl,
} from '@akst.io/web-resume-dom/base/config/config';

export class Bootstrap {
  public readonly isForYiJing: boolean;
  public readonly developerMode: boolean;

  constructor(config: {
    isForYiJing: boolean;
    developerMode: boolean;
  }) {
    this.isForYiJing = config.isForYiJing;
    this.developerMode = config.developerMode;
  }

  static parseFromUrl(url: string) {
    return parseUrl(url, Bootstrap.parseFromJsonReader);
  }

  static parseFromJsonReader(reader: JsonFieldReader) {
    return new Bootstrap({
      isForYiJing: reader('boolean', 'happy-bday-yijing', true, false),
      developerMode: reader('boolean', 'dev', true, false),
    });
  }
}
