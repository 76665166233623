import * as React from 'react';
import * as Animations from './animated_typography_styles';

export type TextAnimationProps = {
  elements: JSX.Element[],
  elementStyles: React.CSSProperties,
};

export function useChildrenAsTextAnimationProps(children: string | JSX.Element | JSX.Element[]) {
  return React.useMemo(() => {
    if (typeof children === 'string') return children;

    return {
      elementStyles: {},
      elements: (
          Array.isArray(children)
              ? children
              : [children]
      ),
    } as const;
  }, [children]);
}

const useAnimationHook = (
    {
      children,
      animationDurationMs,
      animationDistance,
      Animation,
      createEnvironmentStyles,
    }: {
      children: string | TextAnimationProps
      animationDurationMs: number,
      animationDistance: number,
      Animation: React.ComponentType<JSX.IntrinsicElements['span']>,
      createEnvironmentStyles: () => (React.CSSProperties & Record<string, string>),
    },
    dependencies: any[],
): TextAnimationProps => {
  const elements = React.useMemo(() => {
    const multiplicant = animationDurationMs * animationDistance;
    const base = -1 * multiplicant;
    const letters: (string | JSX.Element)[] = typeof children === 'string'
        ? Array.from(children)
        : children.elements;
    const animationDuration = `${animationDurationMs}ms`;
    const { length } = letters;
    return letters.map((c, index) => {
      const offset = multiplicant * (index / length);
      const animationDelay = `${base + offset}ms`
      return (
          <Animation
            style={{ animationDelay }}
            key={index}
          >
            {c}
          </Animation>
      );
    });
  }, [children, Animation]);

  const elementStyles = React.useMemo<any>(() => {
    const styles = createEnvironmentStyles();

    if (typeof children !== 'string') {
      Object.assign(styles, children.elementStyles);
    }

    return styles;
  }, [children, ...dependencies]);

  return { elements, elementStyles };
};

export const useRainbowAnimation = ({
  animationDistance = 1,
  animationDuration: animationDurationMs,
  brightness = 0.75,
  children,
}: {
  animationDistance?: number,
  animationDuration: number,
  brightness?: number,
  children: string | TextAnimationProps,
}): TextAnimationProps => {
  return useAnimationHook({
    Animation: Animations.LetterWithColorAnimation,
    animationDistance,
    animationDurationMs,
    children,
    createEnvironmentStyles: () => ({
      '--colorAnimationDuration': `${animationDurationMs}ms`,
      '--brightness': `${100 * brightness}%`,
      '--saturation': `100%`,
    }),
  }, [animationDurationMs, brightness]);
};

export const useWaveAnimation = ({
  displacement,
  animationDistance = 1,
  animationDuration: animationDurationMs,
  children,
}: {
  displacement: number,
  animationDistance?: number,
  animationDuration: number,
  children: string | TextAnimationProps,
}): TextAnimationProps => {
  return useAnimationHook({
    Animation: Animations.LetterWithWaveAnimation,
    animationDistance,
    animationDurationMs,
    children,
    createEnvironmentStyles: () => ({
      '--displacementMax': `${displacement}px`,
      '--displacementMin': `-${displacement}px`,
      '--waveAnimationDuration': `${animationDurationMs}ms`,
    }),
  }, [animationDurationMs, displacement]);
};

export type AnimationConfig = {
  initialValue?: number,
  animationForce: number,
  animationDelay: number,
  animationDuration: number,
};

const useNestedAnimation = ({
  Animation,
  children,
  configs,
  maxPropertyName,
  minPropertyName,
  units,
}: {
  Animation: React.ComponentType<JSX.IntrinsicElements['div']>,
  children: any,
  configs: readonly AnimationConfig[],
  maxPropertyName: string,
  minPropertyName: string,
  units: string,
}) => {
  return React.useMemo(() => {
    let output = children;
    for (const {
      initialValue = 0,
      animationForce,
      animationDelay,
      animationDuration,
    } of configs) {
      output = (
          <Animation style={{
            animationDelay: `${animationDelay - animationDuration}ms`,
            animationDuration: `${animationDuration}ms`,
            [minPropertyName]: `${initialValue - animationForce}${units}`,
            [maxPropertyName]: `${initialValue + animationForce}${units}`,
          } as any}>{output}</Animation>
      );
    }
    return output;
  }, [
    children,
    configs,
    Animation,
    maxPropertyName,
    minPropertyName,
  ]);
};

export const BitchImLeviating = React.memo(({
  rotations,
  yTranslations,
  children,
}: {
  rotations: readonly AnimationConfig[],
  yTranslations: readonly AnimationConfig[],
  children: any,
}) => {
  return useNestedAnimation({
    children: useNestedAnimation({
      Animation: Animations.BitchImRotating,
      children,
      configs: rotations,
      maxPropertyName: '--rotationMax',
      minPropertyName: '--rotationMin',
      units: 'turn',
    }),
    configs: yTranslations,
    Animation: Animations.BitchImTranslatingOnMyYAxis,
    maxPropertyName: '--translationMax',
    minPropertyName: '--translationMin',
    units: 'px',
  });
});
