import * as React from 'react';
import { TreeMenu as TreeMenuImpl, TreeNodeMenu } from '@akst.io/web-resume-dom/ui/system/tree_menu/tree_menu';

type ExternallyInjectedProps = {
  onSave(): void;
  onSaveAs(): void;
  onOpen(): void;
  onUndo(): void;
  onRedo(): void;
  onClear(): void;
};

type SpriteMakerTreeMenuProps = ExternallyInjectedProps;

export const TreeMenu = React.memo((props: SpriteMakerTreeMenuProps) => {
  const {
    onSave,
    onSaveAs,
    onOpen,
    onUndo,
    onRedo,
    onClear,
  } = props;

  const treeMenuOptions: ReadonlyArray<TreeNodeMenu> = React.useMemo(() => [
    {
      type: 'menu',
      label: 'File',
      options: [
        {
          type: 'action',
          label: 'Save',
          onClick: onSave,
        },
        {
          type: 'action',
          label: 'Save As',
          onClick: onSaveAs,
        },
        {
          type: 'action',
          label: 'Open',
          onClick: onOpen,
        },
      ],
    },
    {
      type: 'menu',
      label: 'Edit',
      options: [
        {
          type: 'action',
          label: 'Undo',
          onClick: onUndo,
        },
        {
          type: 'action',
          label: 'Redo',
          onClick: onRedo,
        },
        {
          type: 'action',
          label: 'Clear',
          onClick: onClear,
        },
      ],
    }
  ], [
    onSave,
    onSaveAs,
    onOpen,
    onUndo,
    onRedo,
    onClear,
  ]);

  return <TreeMenuImpl tree={treeMenuOptions}/>
});

export function createTreeMenu(options: ExternallyInjectedProps): React.ComponentType {
  return React.memo(() => (
      <TreeMenu {...options}/>
  ));
}
