import styled from 'styled-components';
import { baseUnit } from '../units/units';
import { bgGrey, bgGreyDark, borderGrey } from '../color/colors';

const scrollBarWidth = baseUnit * 2;

export const ScrollableContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;

  position: relative;
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
`;

export const Scrollable = styled.div`
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */

  overflow-y: scroll;
  min-height: 0;
  max-height: 100%;
  padding-right: ${scrollBarWidth}px;

  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
`;

export const ScrollBar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${scrollBarWidth}px;
  height: 100%;
  background-color: ${bgGreyDark};

  border-width: ${baseUnit/ 8}px;
  border-style: solid;
  border-color: ${borderGrey};
`;

export const ScrollHandlePosition = styled.div`
  display: grid;
  background-color: ${bgGrey};
  width: ${scrollBarWidth}px;
  height: ${scrollBarWidth}px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  border-width: ${baseUnit / 8}px;
  border-style: solid;
  border-color: ${borderGrey};
`;
