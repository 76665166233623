import * as React from 'react';
import { NumberInput } from '@akst.io/web-resume-dom/ui/base/form/input/input';

export type GridSizeInputProps = {
  onGridSizeChange(width: number, height: number): void;
  gridWidth: number;
  gridHeight: number;
  inputSpacing?: number;
};

export const GridSizeInput = React.memo(function GridSizeInput(props: GridSizeInputProps) {
  const { gridHeight, gridWidth, onGridSizeChange, inputSpacing } = props;

  const bottomInputStyle = React.useMemo(() => (
      inputSpacing ? { marginTop: inputSpacing } : undefined
  ), [inputSpacing]);

  const onWidthChange = React.useCallback((value: number) => {
    onGridSizeChange(value, gridHeight);
  }, [onGridSizeChange, gridHeight]);

  const onHeightChange = React.useCallback((value: number) => {
    onGridSizeChange(gridWidth, value);
  }, [onGridSizeChange, gridWidth]);

  return (
      <>
        <NumberInput
            label="Width"
            value={gridWidth}
            onChange={onWidthChange}
        />
        <div style={bottomInputStyle}>
          <NumberInput
              label="Height"
              value={gridHeight}
              onChange={onHeightChange}
          />
        </div>
      </>
  );
});
