import {
  AudioAssetId,
  AssetService,
} from '@akst.io/web-resume-dom/services/asset/asset_service';
import { AudioService } from './audio_service';

export class AudioServiceImpl implements AudioService {
  private resumePromise?: Promise<void>;

  constructor(
      private readonly assetService: AssetService,
      private readonly audioContext: AudioContext,
  ) {
  }

  setResumePromise(promise: Promise<void>) {
    this.resumePromise = promise.catch(error => {
      console.error(error);
    });
  }

  async playSound(id: AudioAssetId, offset: number = 0): Promise<AudioBufferSourceNode | undefined> {
    await this.resumePromise;

    const trackSource = this.audioContext.createBufferSource();
    const audioAsset = this.assetService.getAudioAsset(id);

    if (audioAsset) {
      trackSource.buffer = audioAsset.buffer;
      trackSource.connect(this.audioContext.destination);
      trackSource.start();
      return trackSource;
    } else {
      // currently known issue with safari version
      console.error('failed to play audio');
    }
  }
}
