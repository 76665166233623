import * as React from 'react';
import styled from 'styled-components';
import * as Resume from '@akst.io/lib/resume/types';
import * as Typography from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { funnyFontFamily } from '@akst.io/web-resume-dom/ui/base/typography/fonts';
import {
  SubSubHeading,
  Tenure,
} from '../../../ui/type/type';

export function createIdBreadCrumbId(e: Resume.ExperinceData): string {
  return `resume-experincePage-item-${e.id}`;
}

export const ExperienceToc = React.memo(({
  items,
}: {
  items: readonly Resume.ExperinceData[],
}) => {
  const onClick = React.useCallback((id: string) => {
    document.getElementById(id)?.scrollIntoView();
  }, []);

  return (
    <>
      <SubSubHeading>Table of Contents</SubSubHeading>
      <List>
        {items.map(it => (
          <TocItem onClick={onClick} key={it.id} item={it}/>
        ))}
      </List>
    </>
  );
});

const TocItem = React.memo(({
  onClick,
  item,
}: {
  onClick: (id: string) => void,
  item: Resume.ExperinceData,
}) => {
  const onClickImpl = (e: React.MouseEvent<unknown>) => {
    e.preventDefault();
    onClick(createIdBreadCrumbId(item));
  };

  return (
    <li>
      <Typography.Normal>
        <Link onClick={onClickImpl} href="#">
          <Tenure tenure={item.tenure}/>
          {" — "}
          {item.name}
        </Link>
      </Typography.Normal>
    </li>
  );
});

const List = styled.ol`
  margin: 0;
  display: grid;
  grid-gap: .5rem;
  grid-auto-flow: row;
  font-family: ${funnyFontFamily};
`;

const Link = styled.a`
  font-weight: 900;
  text-decoration: none;
`;

