import * as React from 'react';
import { ApplicationService } from "@akst.io/web-resume-dom/services/application/application_service";
import { LocalApplicationController, LocalApplicationStarter } from '@akst.io/web-resume-dom/services/application/types';
import { FileSystemService } from "@akst.io/web-resume-dom/services/file_system/file_system_service";
import {
  isLiveDirectory,
  LiveFileSystemNode,
} from "@akst.io/web-resume-dom/services/file_system/live_files_system_node";
import { SpriteService } from "@akst.io/web-resume-dom/services/sprite/sprite_service";
import { unsafeSpriteCast } from '@akst.io/web-resume-dom/services/sprite/types';
import { FileNavigatorProps } from '@akst.io/web-resume-dom/ui/system/file_navigator/file_navigator';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import sprite from '@akst.io/web-resume-dom/ui/sprites/file_explorer_sprite.json';
import { FileExplorerPresenter, FileExplorerStore } from './file_explorer_presenter';
import { createFileExplorer } from './file_explorer';

type CreateOptions = {
  applicationId: string;
  applicationService: ApplicationService;
  fileSystemService: FileSystemService;
  FileNavigator: React.ComponentType<FileNavigatorProps>;
  spriteService: SpriteService;
  Panel: React.ComponentType<PanelProps>;
};

export function installFileExplorer({
  applicationId,
  applicationService,
  fileSystemService,
  FileNavigator,
  spriteService,
  Panel,
}: CreateOptions) {
  const presenter = new FileExplorerPresenter(
      applicationService,
      fileSystemService,
  );

  class Controller implements LocalApplicationController {
    constructor(private readonly store: FileExplorerStore) {
    }

    get title() {
      return this.store.title;
    }

    onQuit() {
    }
  }

  const withFile: LocalApplicationStarter = (file: LiveFileSystemNode | undefined, procId: number) => {
    if (file && !isLiveDirectory(file)) {
      return { ok: false, error: { type: 'incorrect-file-type', fileType: 'file' } };
    }

    const directory = file || fileSystemService.getLiveDirectory([]);

    const store = presenter.createStore(procId, directory);
    const controller = new Controller(store);
    const Component = createFileExplorer({
      store,
      presenter,
      FileNavigator,
      Panel,
    });

    return { ok: true, value: { controller, Component } };
  };

  spriteService.register(
      applicationId,
      'file_explorer.sprite',
      unsafeSpriteCast(sprite),
  );

  applicationService.register(
      applicationId,
      { initializer: { type: 'local', withFile } },
      ['programs', 'Explorer.exe'],
  );
}
