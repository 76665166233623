import * as React from 'react';
import { UnreachableError } from '@akst.io/lib/base/types';

export const baseUnit = 8;
export const smallestUnit = 2;

export type Unit =
    | { kind: 'auto' }
    | { kind: '%', value: number }
    | { kind: 'px', value: number };

export function useBaseUnit(value: number): Unit {
  return React.useMemo(() => ({ value: value * baseUnit, kind: 'px' }), [value]);
}

export function usePx(value: number): Unit {
  return React.useMemo(() => ({ value, kind: 'px' }), [value]);
}

export function usePercent(value: number): Unit {
  return React.useMemo(() => ({ value, kind: '%' }), [value]);
}

export function useAuto(): Unit {
  return React.useMemo(() => ({ kind: 'auto' }), []);
}

export function toString(value: Unit): string {
  switch (value.kind) {
    case 'px':
      return `${value.value}${value.kind}`;

    case 'auto':
      return 'auto';

    case '%':
      return `${100 * value.value}%`;

    default:
      throw new UnreachableError(value);
  }
}

