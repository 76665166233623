import { action, reaction, observable, makeObservable } from 'mobx';
import { disposeOnUnmount, observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';
import * as colors from '@akst.io/web-resume-dom/ui/base/color/colors';
import { Normal } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { baseUnit, smallestUnit } from '@akst.io/web-resume-dom/ui/base/units/units';

export type Dimensions = { width?: string | number, height?: string | number };

export type MultilineInputProps = {
  value: string;
  label?: string;
  disabled?: boolean;
  resizeable?: boolean;
  placeholder?: string;
  onChange(value: string): void;
  initialSize?: Dimensions;
};

function withLabel(
    label: string | undefined,
    content: JSX.Element,
) {
  return (
      <Root hasLabel={label != null}>
        {label && (
          <Label>
            <Normal weight="bold">{label}</Normal>
          </Label>
        )}
        {content}
      </Root>
  );
}

const Root = styled.div<{ hasLabel: boolean }>`
  ${({ hasLabel }) => hasLabel && css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  `}
`;

const Label = styled.div`
  margin-bottom: ${baseUnit / 2}px;
`;

export const MultilineInput = observer(class MultilineInput extends React.Component<MultilineInputProps> {
  multilineInput: HTMLTextAreaElement | null = null;

  constructor(props: MultilineInputProps) {
    super(props);

    makeObservable(this, {
      multilineInput: observable.ref
    });
  }

  componentDidMount() {
    const asUnit = (value: number | string | undefined): string =>
        typeof value === 'string' ? value :
        typeof value === 'number' ? `${value}px` :
        '';

    disposeOnUnmount(this, reaction(
        () => (
            this.multilineInput && this.props.initialSize
        ),
        () => {
          const { props: { initialSize }, multilineInput } = this;
          if (initialSize && multilineInput) {
            multilineInput.style.width = asUnit(initialSize.width);
            multilineInput.style.height = asUnit(initialSize.height);
          }
        },
        { fireImmediately: true },
    ));
  }

  render() {
    return withLabel(
        this.props.label,
        this.renderInput(),
    );
  }

  private renderInput(): JSX.Element {
    const { disabled, resizeable = true, placeholder, value } = this.props;
    return (
        <TextareaStyle
            ref={this.setMultilineInput}
            value={value}
            placeholder={placeholder}
            onChange={this.onTextAreaChange}
            disabled={disabled}
            resizeable={resizeable}
        />
    );
  }

  private readonly setMultilineInput = action((element: HTMLTextAreaElement | null) => {
    this.multilineInput = element;
  });

  private readonly onTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    this.props.onChange(event.target.value);
  };
});

const TextareaStyle = styled.textarea<{ resizeable: boolean }>`
  display: block;
  border-color: ${colors.borderGreyInverted};
  border-width: ${smallestUnit}px;
  border-style: solid;
  border-radius: 0;
  padding: ${baseUnit / 4}px ${baseUnit / 2}px;
  width: 100%;
  margin: 0;
  outline: none;

  ${({ resizeable }) => !resizeable && css`
    resize: none;
  `}
`;


type CommonInputProps = {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}

type Width = '100%' | 'unset' | undefined;

type BaseInputProps =
  & { type: string, max?: number, min?: number, width: Width }
  & CommonInputProps
  & Pick<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>;

class BaseInput extends React.PureComponent<BaseInputProps> {
  render() {
    return withLabel(this.props.label, this.renderInput());
  }

  private renderInput(): JSX.Element {
    const {
      type,
      placeholder,
      value,
      onChange,
      max,
      min,
      disabled,
      width = '100%',
    } = this.props;
    return (
        <BaseInputStyle
            style={{ width }}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            max={max}
            min={min}
        />
    );
  }
}

const BaseInputStyle = styled.input`
  display: block;
  border-color: ${colors.borderGreyInverted};
  border-width: ${smallestUnit}px;
  border-style: solid;
  border-radius: 0;
  padding: ${baseUnit / 4}px ${baseUnit / 2}px;
  outline: none;
  max-width: 100%;
`;

export type NumberInputProps = CommonInputProps & {
  min?: number;
  max?: number;
  value: number;
  width?: Width;
  onChange(value: number): void;
};

export const NumberInput = React.memo(function NumberInput(props: NumberInputProps) {
  const {
    disabled,
    value,
    label,
    placeholder,
    onChange,
    min,
    max,
    width,
  } = props;

  const onInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onChange(parseFloat(event.currentTarget.value));
  }, [onChange]);

  return (
      <BaseInput
          type="number"
          max={max}
          min={min}
          width={width}
          onChange={onInputChange}
          value={value.toString()}
          label={label}
          placeholder={placeholder}
          disabled={disabled}
      />
  );
});
