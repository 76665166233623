import { UnreachableError } from '@akst.io/lib/base/types';
import { capitialise } from '@akst.io/lib/base/strings';
import {
  EducationDataCourse,
  ExperinceData,
  ReferenceData,
  SkillDataData,
} from './types';

export function formatReferenceRelation(
    { kind }: ReferenceData,
    { name } : ExperinceData,
) {
  switch (kind) {
    case 'former-boss':
      return `${name} (Former Boss)`;
    case 'current-coworker':
      return `${name} (Coworker)`;
    default:
      throw new UnreachableError(kind);
  }
}

export function formatEducationCert({
  cert,
  area,
}: {
  cert: EducationDataCourse['cert'],
  area?: string,
}): string {
  switch (cert) {
    case 'diploma':
      return area ? `Diploma of ${area}` : 'Diploma';
    case 'bachelor':
      return area ? `Bachelor of ${area}` : 'Bachelor';
    case 'honours-only':
      return area ? `Bachelor of ${area} (Honours)` : 'Bachelor (Honours)';
    case 'other':
      return area ? `Course in ${area}` : 'Other';
    default:
      throw new UnreachableError(cert);
  }
}

export function formatSkillDataDataLabel({
  id,
  label,
}: SkillDataData): string {
  if (label) return label;
  return id.split('-').map(capitialise).join(' ');
}
