import * as Result from '@akst.io/lib/base/result';

export async function loadFont(url: string, name: string): Promise<void> {
  const styleSheet = document.createElement('style');
  styleSheet.textContent = [
    '@font-face {',
    `font-family: '${name}';`,
    `src: url('${url}');`,
    '}',
  ].join('');
  document.head.appendChild(styleSheet);

  console.log(url, name);
  await (document as any).fonts.load(name, 'BESbswy');
}

type ImageResult = Result.T<HTMLImageElement, Error>;
export function loadImage(url: string): Promise<ImageResult> {
  let onResolve: (image: Result.T<HTMLImageElement, Error>) => void;

  const image = new Image();
  const promise = new Promise<ImageResult>((resolve) => onResolve = resolve);

  const onLoad = () => onResolve(Result.Ok(image));
  const onError = (error: any) => onResolve(Result.Err(error));

  image.onload = onLoad;
  image.onerror = onError;
  image.src = url;

  return promise.finally(() => {
    image.removeEventListener('load', onLoad);
    image.removeEventListener('error', onError);
  });
}

export function decodeAudio(
    audioContext: AudioContext,
    arrayBuffer: ArrayBuffer,
): Promise<Result.T<AudioBuffer, Error>> {
  return new Promise((resolve) => {
    const onResolve = (buffer: AudioBuffer) => resolve(Result.Ok(buffer));
    const onReject = (error: Error) => resolve(Result.Err(error));

    // turns out this is the only way to get this to work with safari :(
    audioContext.decodeAudioData(arrayBuffer, onResolve, onReject);
  });
}
