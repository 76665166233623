import * as React from 'react';
import { LocalApplicationStarter } from '@akst.io/web-resume-dom/services/application/types';
import { ApplicationService } from "@akst.io/web-resume-dom/services/application/application_service";
import { AudioService } from '@akst.io/web-resume-dom/services/audio/audio_service';
import { AssetService } from '@akst.io/web-resume-dom/services/asset/asset_service';
import { DesktopService } from '@akst.io/web-resume-dom/services/desktop/desktop_service';
import { FileSystemService } from "@akst.io/web-resume-dom/services/file_system/file_system_service";
import { LiveFileSystemNode } from "@akst.io/web-resume-dom/services/file_system/live_files_system_node";
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { SpriteService } from '@akst.io/web-resume-dom/services/sprite/sprite_service';
import { unsafeSpriteCast } from '@akst.io/web-resume-dom/services/sprite/types';
import { UseDragSlot } from '@akst.io/web-resume-dom/ui/system/draggable/create';
import { DragPointProps } from '@akst.io/web-resume-dom/ui/system/draggable/draggable';
import sprite from '@akst.io/web-resume-dom/ui/sprites/image_sprite.json';
import { createAssistant, Services } from './create'
import { AssistantController } from './type'

const FILE_EXTENSION = 'assist';

export function installAssistant({
  DragPoint,
  services,
  useDragSlot,
}: {
  DragPoint: React.ComponentType<DragPointProps>,
  services: Services & {
    audioService: AudioService,
    spriteService: SpriteService,
  },
  useDragSlot: UseDragSlot,
}): {
  assistantController: AssistantController,
} {
  const {
    controller,
    factory,
  } = createAssistant({
    DragPoint,
    services,
    useDragSlot,
  });

  const withFile: LocalApplicationStarter = (file: LiveFileSystemNode | undefined, procId: number) => {
    return factory.createAssistant({ file, procId })
  };

  services.spriteService.register(
      FILE_EXTENSION,
      'Assistant.sprite',
      unsafeSpriteCast(sprite),
  );
  services.applicationService.register(
      FILE_EXTENSION,
      { initializer: { type: 'local', withFile } },
      ['programs', 'Assistant.exe'],
  );

  return {
    assistantController: controller,
  };
}
