import * as React from 'react';
import styled, { css } from 'styled-components';
import * as colors from '@akst.io/web-resume-dom/ui/base/color/colors';
import {
  bodyFontFamily,
  fontFamily,
  funnyFontFamily,
  monoFontFamily,
} from './fonts';

type Color = 'white' | 'black' | 'retroRed' | 'retroPurple';
type Decoration = 'unset' | 'underline';
type LetterSpacing = 'normal' | 'medium' | 'wide';
type LineHeight = 'initial' | '1' | '1.8';
type Weight = 'normal' | 'bold';
type Display = 'inline-block' | 'block';
type Style = 'normal' | 'body' | 'monospace' | 'funny';
type Size = 'h1' | 'h2' | 'h3' | 'large' | 'medium' | 'small';

const StyleContext = React.createContext<Style>('normal');

export const StyleProvider = StyleContext.Provider;

export type TypographyProps = {
  children: React.ReactNode,
  color?: Color,
  decoration?: Decoration,
  display?: Display,
  ellipsis?: boolean,
  letterSpacing?: LetterSpacing,
  margins?: 'none',
  lineHeight?: LineHeight;
  style?: Style,
  size?: Size,
  weight?: Weight,
};

const SIZES = {
  h1: '1.75rem',
  h2: '1.25rem',
  h3: '1rem',
  large: '0.9rem',
  medium: '0.75rem',
  small: '0.6rem',
} as const;

const COLORS = {
  white: 'white',
  black: 'white',
  retroRed: colors.retroRed,
  retroPurple: colors.retroPurple,
} as const;

const WEIGHTS = {
  normal: 400,
  bold: 700,
} as const;

const LINE_HEIGHTS = {
  initial: 'initial',
  1: '1',
  '1.8': '1.8em',
} as const;

const STYLES = {
  normal: fontFamily,
  body: bodyFontFamily,
  monospace: monoFontFamily,
  funny: funnyFontFamily,
} as const;

const LETTER_SPACING = {
  normal: '0.02em',
  medium: '0.04em',
  wide: '0.1em',
} as const;

const { memo, useContext, useMemo } = React;

const createComponent = ({
  Tag,
}: {
  Tag: React.ComponentType<{
    children: any,
    style?: React.CSSProperties,
    ['data-margin']?: 'none',
    ['data-typography']: 'true',
  }>,
}): React.ComponentType<TypographyProps> => (
    memo(({
      children,
      color,
      decoration = 'none',
      display = 'block',
      ellipsis,
      letterSpacing = 'normal',
      margins,
      lineHeight = 'initial',
      size,
      style: propStyle,
      weight,
    }) => {
      const style = useContext<Style>(StyleContext);

      const ellipsisStyle = useMemo<React.CSSProperties>(() => {
        if (!ellipsis) return {};

        return {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        };
      }, [ellipsis]);

      return (
          <Tag
            data-typography="true"
            data-margin={margins}
            style={{
              color: color && COLORS[color],
              fontFamily: STYLES[propStyle || style],
              fontSize: size && SIZES[size],
              fontWeight: WEIGHTS[weight ?? 'normal'],
              display,
              letterSpacing: LETTER_SPACING[letterSpacing],
              lineHeight: LINE_HEIGHTS[lineHeight],
              textDecoration: decoration,
              ...ellipsisStyle,
            }}
          >
            {children}
          </Tag>
      );
    })
);

const rootStyles = css`
  margin: 0;

  [data-typography]:not([data-margin="none"]) + & {
    margin-top: 1rem;
  }
`;

export const Heading = createComponent({
  Tag: styled.h1`
    font-size: ${SIZES.h1};
    ${rootStyles}
  `,
});

export const SubHeading = createComponent({
  Tag: styled.h2`
    font-size: ${SIZES.h2};
    ${rootStyles}
  `,
});

export const SubSubHeading = createComponent({
  Tag: styled.h3`
    font-size: ${SIZES.h3};
    ${rootStyles}
  `,
});

export const Large = createComponent({
  Tag: styled.p`
    font-size: ${SIZES.large};
    ${rootStyles}
  `,
});

export const Normal = createComponent({
  Tag: styled.p`
    font-size: ${SIZES.medium};
    ${rootStyles}
  `,
});

export const Small = createComponent({
  Tag: styled.p`
    font-size: ${SIZES.small};
    ${rootStyles}
  `,
});

export const Span = createComponent({
  Tag: styled.p`
    ${rootStyles}
  `,
});
