import { action, observable, makeObservable } from 'mobx';
import { LiveFile } from "@akst.io/web-resume-dom/services/file_system/live_files_system_node";
import { ApplicationService } from '@akst.io/web-resume-dom/services/application/application_service';
import { getAssetPath } from '@akst.io/web-resume-dom/services/asset/asset_service';
import { AudioService } from '@akst.io/web-resume-dom/services/audio/audio_service';

export class ImageViewerStore {
  file: LiveFile;
  loaded: boolean = false;
  width?: number = undefined;
  height?: number = undefined;

  constructor(
      public readonly procId: number,
      file: LiveFile,
  ) {
    this.file = file;

    makeObservable(this, {
      file: observable.ref,
      loaded: observable.ref,
      width: observable.ref,
      height: observable.ref
    });
  }
}

export class ImageViewerPresenter {
  constructor(
      public readonly applicationService: ApplicationService,
      public readonly audioService: AudioService,
  ) {
    makeObservable(this, {
      onLoad: action
    });
  }

  createStore(procId: number, file: LiveFile): ImageViewerStore {
    return new ImageViewerStore(procId, file);
  }

  getTitle(store: ImageViewerStore) {
    return `Img Viewer - ${store.file.name}`;
  }

  onClose(store: ImageViewerStore) {
    this.applicationService.stopProc(store.procId);
  }

  onLoad(store: ImageViewerStore, image: HTMLImageElement) {
    store.loaded = true;
    this.audioService.playSound('audio/windows_ding');
  }
}
