import { action, computed, reaction, runInAction, observable, makeObservable } from 'mobx';
import { Dequeue } from '@akst.io/web-resume-dom/base/dequeue/dequeue';
import { checkExists, UnreachableError } from "@akst.io/lib/base/types";
import { LiveFile } from "@akst.io/web-resume-dom/services/file_system/live_files_system_node";
import { Sprite, isSprite, deserializeSprite } from "@akst.io/web-resume-dom/services/sprite/types";

export type Color = number | undefined;
export type FillBuffer = Color[];;

export type Delta =
  | { type: 'unoptimized-grid-change', setBuffer: FillBuffer, gridWidth: number, gridHeight: number }
  | { type: 'unoptimized-buffer-change', setBuffer: FillBuffer }
  | { type: 'set-color', index: number, color: Color }
  | { type: 'col-pop', diff: number }
  | { type: 'row-pop', diff: number }
  | { type: 'col-push', diff: number, cols: FillBuffer }
  | { type: 'row-push', diff: number, rows: FillBuffer };

export class BufferStore {
  public file?: LiveFile;
  public gridWidth: number = 0;
  public gridHeight: number = 0;
  public modificationId: number = 0;
  public historyChangeId: number = 0;

  public buffer: FillBuffer = [];
  public undoHistory: Array<Delta> = [];
  public redoHistory: Array<Delta> = [];
  public disposeHistory?: () => void = undefined;

  constructor(file?: LiveFile) {
    this.file = file;

    makeObservable(this, {
      file: observable.ref,
      gridWidth: observable.ref,
      gridHeight: observable.ref,
      modificationId: observable.ref,
      historyChangeId: observable.ref,
      serialized: computed
    });
  }

  get serialized(): Sprite {
    return {
      type: 'machine',
      width: this.gridWidth,
      height: this.gridHeight,
      fills: this.buffer.map(v => v == null ? null : v),
    };
  }
}

export class BufferPresenter {
  constructor() {
    makeObservable(this, {
      onGridSizeChange: action,
      onFloodFill: action,
      onUndo: action,
      onRedo: action,
      onDelta: action
    });
  }

  public createStore(file: LiveFile | undefined): BufferStore {
    const store = new BufferStore(file);

    this.onGridSizeChange(store, 16, 16);
    store.undoHistory = [];

    store.disposeHistory = reaction(
        () => store.modificationId,
        () => store.redoHistory = [],
    );

    if (file != null) {
      this.loadFileContent(store, file);
    }

    return store;
  }

  public closeStore(store: BufferStore) {
    if (store.disposeHistory) {
      store.disposeHistory();
      store.undoHistory = [];
      store.redoHistory = [];
    }
  }

  public onGridSizeChange(store: BufferStore, newGridWidth: number, newGridHeight: number) {
    const widthDiff = store.gridWidth - newGridWidth;
    const heightDiff = store.gridHeight - newGridHeight;

    if (widthDiff < 0) {
      const diff = -widthDiff;
      const cols = new Array(diff * store.gridHeight);
      store.undoHistory.push(this.applyDelta(store, { type: 'col-push', diff, cols }));
      store.modificationId++;
    }
    if (heightDiff < 0) {
      const diff = -heightDiff;
      const rows = new Array(diff * store.gridWidth);
      store.undoHistory.push(this.applyDelta(store, { type: 'row-push', diff, rows }));
      store.modificationId++;
    }
    if (widthDiff > 0) {
      const applyDelta: Delta = { type: 'col-pop', diff: widthDiff };
      store.undoHistory.push(this.applyDelta(store, applyDelta));
      store.modificationId++;
    }
    if (heightDiff > 0) {
      const applyDelta: Delta = { type: 'row-pop', diff: heightDiff };
      store.undoHistory.push(this.applyDelta(store, applyDelta));
      store.modificationId++;
    }
  }

  public onFloodFill(store: BufferStore, color: Color, start: number) {
    const setBuffer = Array.from(store.buffer);
    if (this.execFloodFill(store, color, start)) {
      store.modificationId++;
      store.undoHistory.push({
        type: 'unoptimized-buffer-change',
        setBuffer,
      });
    }
  }

  public onUndo(store: BufferStore) {
    if (store.undoHistory.length) {
      const lastDelta = checkExists(
          store.undoHistory.pop(),
          'unfound history',
      );
      store.redoHistory.push(this.applyDelta(store, lastDelta));
      store.historyChangeId++;
    }
  }

  public onRedo(store: BufferStore) {
    if (store.redoHistory.length) {
      const lastDelta = checkExists(
          store.redoHistory.pop(),
          'unfound history',
      );
      store.undoHistory.push(this.applyDelta(store, lastDelta));
      store.historyChangeId++;
    }
  }

  public onDelta(store: BufferStore, delta: Delta) {
    const revert = this.applyDelta(store, delta);
    store.undoHistory.push(revert);
    store.modificationId++;
  }

  public applyDelta(store: BufferStore, delta: Delta): Delta {
    switch (delta.type) {
      case 'unoptimized-buffer-change': {
        const setBuffer = Array.from(store.buffer);
        store.buffer = Array.from(delta.setBuffer);
        return { type: 'unoptimized-buffer-change', setBuffer };
      }

      case 'unoptimized-grid-change': {
        const setBuffer = Array.from(store.buffer);
        const prevWidth = store.gridWidth;
        const prevHeight = store.gridHeight;

        store.buffer = Array.from(delta.setBuffer);
        store.gridWidth = delta.gridWidth;
        store.gridHeight = delta.gridHeight;

        return {
          type: 'unoptimized-grid-change',
          setBuffer,
          gridWidth: prevWidth,
          gridHeight: prevHeight,
        };
      }

      case 'set-color': {
        const originalColor = store.buffer[delta.index];
        store.buffer[delta.index] = delta.color;
        return { type: 'set-color', index: delta.index, color: originalColor };
      }

      case 'row-push': {
        store.buffer = store.buffer.concat(delta.rows);
        store.gridHeight += delta.diff;
        return { type: 'row-pop', diff: delta.diff };
      }

      case 'col-push': {
        const newWidth = delta.diff + store.gridWidth;
        const newBuffer = new Array(newWidth * store.gridHeight);

        for (let readY = 0; readY < store.gridHeight; readY++) {
          // current over the current state
          {
            const writeYOffset = newWidth * readY;
            const readYOffset = store.gridWidth * readY;

            for (let readX = 0; readX < store.gridWidth; readX++) {
              const rIndex = readYOffset + readX;
              const wIndex = writeYOffset + readX;
              newBuffer[wIndex] = store.buffer[rIndex];
            }
          }

          // over the delta state
          {
            const writeYOffset = (newWidth * readY) + store.gridWidth;
            const readYOffset = delta.diff * readY;

            for (let readX = 0; readX < delta.diff; readX++) {
              const rIndex = readYOffset + readX;
              const wIndex = writeYOffset + readX;
              newBuffer[wIndex] = delta.cols[rIndex];
            }
          }
        }

        store.buffer = newBuffer;
        store.gridWidth += delta.diff;

        return { type: 'col-pop', diff: delta.diff };
      }

      case 'row-pop': {
        const sliceLength = store.gridWidth * (store.gridHeight - delta.diff);
        const rows = store.buffer.slice(sliceLength);
        store.buffer = store.buffer.slice(0, sliceLength);
        store.gridHeight -= delta.diff;
        return { type: 'row-push', diff: delta.diff, rows };
      }

      case 'col-pop': {
        const newWidth = store.gridWidth - delta.diff;
        const newBuffer = new Array(newWidth * store.gridHeight);
        const revertCols = new Array(delta.diff * store.gridHeight);

        for (let readY = 0; readY < store.gridHeight; readY++) {
          {
            const readYOffset = store.gridWidth * readY;
            const writeYOffset = newWidth * readY;

            for (let readX = 0; readX < newWidth; readX++) {
              const rIndex = readYOffset + readX;
              const wIndex = writeYOffset + readX;
              newBuffer[wIndex] = store.buffer[rIndex];
            }
          }
          // generating the data needed for the diff
          {
            const readYOffset = (store.gridWidth * readY) + newWidth;
            const writeYOffset = delta.diff * readY;

            for (let readX = 0; readX < delta.diff; readX++) {
              const rIndex = readYOffset + readX;
              const wIndex = writeYOffset + readX;
              revertCols[wIndex] = store.buffer[rIndex];
            }
          }
        }

        store.buffer = newBuffer;
        store.gridWidth -= delta.diff;
        return { type: 'col-push', diff: delta.diff, cols: revertCols };
      }

      default:
        throw new UnreachableError(delta);
    }
  }

  public loadFileContent(store: BufferStore, file: LiveFile) {
    const [errorMessage, sprite] = deserializeSprite(file.unsafeData);
    if (sprite == null) throw new Error(`Could not open sprite file: ${errorMessage}`);

    let width = sprite.width;
    let height = sprite.height;
    let newBuffer = sprite.fills.map(v => v == null ? undefined : v);

    runInAction(() => {
      store.file = file;
      const revertDelta = this.applyDelta(store, {
        type: 'unoptimized-grid-change',
        setBuffer: newBuffer,
        gridWidth: width,
        gridHeight: height,
      });

      store.modificationId++;
      store.undoHistory.push(revertDelta);
    });
  }

  private execFloodFill(
      store: BufferStore,
      fillColor: Color,
      initialIndex: number,
  ): boolean {
    const replaceColor = store.buffer[initialIndex];

    if (replaceColor === fillColor) {
      return false;
    }

    const asCoord = (index: number) => [index % store.gridWidth, Math.floor(index / store.gridWidth)];
    const asIndex = (x: number, y: number) => (y * store.gridWidth) + x;
    const shouldQueue = (i: number) => store.buffer[i] === replaceColor;

    const queue = Dequeue.create<number>();
    queue.pushRight(initialIndex);
    store.buffer[initialIndex] = fillColor;

    while (queue.length) {
      const index = checkExists(queue.popLeft(), 'unfound index');
      const [x, y] = asCoord(index);

      if (x > 0) {
        const westIndex = asIndex(x - 1, y);
        if (shouldQueue(westIndex)) {
          store.buffer[westIndex] = fillColor;
          queue.pushRight(westIndex);
        }
      }

      if (x < (store.gridWidth - 1)) {
        const eastIndex = asIndex(x + 1, y);
        if (shouldQueue(eastIndex)) {
          store.buffer[eastIndex] = fillColor;
          queue.pushRight(eastIndex);
        }
      }

      if (y > 0) {
        const northIndex = asIndex(x, y - 1);
        if (shouldQueue(northIndex)) {
          store.buffer[northIndex] = fillColor;
          queue.pushRight(northIndex);
        }
      }

      if (y < (store.gridHeight - 1)) {
        const southIndex = asIndex(x, y + 1);
        if (shouldQueue(southIndex)) {
          store.buffer[southIndex] = fillColor;
          queue.pushRight(southIndex);
        }
      }
    }

    return true;
  }
}
