import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { checkExists } from '@akst.io/lib/base/types';
import { smallestUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { borderGreyInverted } from '@akst.io/web-resume-dom/ui/base/color/colors';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import { VideoViewerStore, VideoViewerPresenter } from './video_viewer_presenter';

export type VideoViewerProps = {
  url?: string;
  onLoad(video: HTMLVideoElement): void;
  loaded: boolean;
  Panel: React.ComponentType<Pick<PanelProps, 'children'>>;
};

export const VideoViewer = React.memo(function VideoViewer(props: VideoViewerProps) {
  const [maybeVideo, setVideo] = React.useState<HTMLVideoElement | null>();
  const { url, onLoad, Panel } = props;

  const onLoadInner = React.useCallback(() => {
    onLoad(checkExists(maybeVideo, `video: ${url}`));
  }, [maybeVideo, onLoad]);

  return (
      <Panel>
        <StyledVideo
            ref={setVideo}
            src={url}
            onLoad={onLoadInner}
            autoPlay={true}
            loop={true}
        />
      </Panel>
  );
});

export const StyledVideo = styled.video`
  display: block;
  box-sizing: border-box;
  max-width: 75vw;
  max-height: 50vh;

  border-color: ${borderGreyInverted};
  border-style: solid;
  border-width: ${smallestUnit}px;
`;

export type CreateOptions = {
  store: VideoViewerStore;
  presenter: VideoViewerPresenter;
  Panel: React.ComponentType<PanelProps>;
};

export function createVideoViewer(options: CreateOptions): React.ComponentType {
  const { store, presenter, Panel } = options;

  const onClose = () => {
    presenter.onClose(store);
  };

  const onLoad = (video: HTMLVideoElement) => {
    presenter.onLoad(store, video);
  };

  const WiredPanel = observer((props: Pick<PanelProps, 'children'>) => (
      <Panel
          title={presenter.getTitle(store)}
          onClose={onClose}
          {...props}
      />
  ));

  return observer(() => (
      <VideoViewer
          loaded={store.loaded}
          url={store.file.data}
          onLoad={onLoad}
          Panel={WiredPanel}
      />
  ));
}
