import * as React from 'react';
import { descendComparison } from '@akst.io/lib/base/numbers';
import * as Resume from '@akst.io/lib/resume/types';
import { ExperienceSkills } from './experience_skills';
import {
  createIdBreadCrumbId,
  ExperienceToc,
} from './experience_toc';
import { Rows } from '../../../ui/layout/layout';
import { Markdown } from '../../../ui/markdown/markdown';
import {
  Heading,
  List,
  ListItem,
  Normal,
  SubHeading,
  SubSubHeading,
  SubHeadingWithTimeAndTagline,
} from '../../../ui/type/type';

export const Experience = React.memo(({
  kind,
  experience,
  skills,
}: {
  kind: 'paid' | 'volunteer',
  experience: Resume.Experince
  skills: Resume.Skill,
}) => {
  const items = React.useMemo(() => (
    experience.data.map((experience: Resume.ExperinceData, index: number) => (
      <ExperienceItem
        key={index}
        skills={skills}
        experience={experience}
      />
    ))
  ), [experience, skills]);

  let title: string;
  if (kind === 'paid') {
    title = 'Experince';
  } else {
    title = 'Volunteering';
  }

  return (
      <Rows gridTemplateColumns="unset">
        <Heading subHeading={undefined}>{title}</Heading>
        {experience.data.length > 1 && (
          <ExperienceToc items={experience.data}/>
        )}
        {items}
      </Rows>
  );
});

const Br = () => <span> </span>;

export const ExperienceItem = React.memo(function ExperienceItem({
  experience,
  skills,
}: {
  experience: Resume.ExperinceData,
  skills: Resume.Skill,
}) {
  const {
    name,
    position,
    tenure,
    highlights,
    responsibilities,
  } = experience;

  const hightlightItems = highlights.map((it, index) => (
      <ListItem key={index}>
        <Markdown markdown={it.short} Br={Br}/>
      </ListItem>
  ));

  const responsibilitiesItems = responsibilities.map((it, index) => (
      <ListItem key={index}>
        <Markdown markdown={it.short} Br={Br}/>
      </ListItem>
  ));

  const hasHighlights = !!hightlightItems.length;
  const hasResponsibilities = !!responsibilitiesItems.length;

  return (
      <React.Fragment>
        <SubHeadingWithTimeAndTagline
            id={createIdBreadCrumbId(experience)}
            tenure={tenure}
            tagline={position}
        >
          {name}
        </SubHeadingWithTimeAndTagline>

        {hasHighlights && <>
          {hasResponsibilities && (
            <SubSubHeading>Highlights</SubSubHeading>
          )}
          <List>{hightlightItems}</List>
        </>}

        {hasResponsibilities && <>
          {hasHighlights && (
            <SubSubHeading>Responsibilities</SubSubHeading>
          )}
          <List>{responsibilitiesItems}</List>
        </>}
        <ExperienceSkills
          allSkills={skills}
          skills={experience.skills}
        />
      </React.Fragment>
  );
});
