import { action, observable, makeObservable } from 'mobx';
import { DesktopService } from './desktop_service';

export class DesktopServiceImpl implements DesktopService {
  private desktopWidth = 0;
  private desktopHeight = 0;

  constructor() {
    makeObservable<DesktopServiceImpl, "desktopWidth" | "desktopHeight">(this, {
      desktopWidth: observable.ref,
      desktopHeight: observable.ref,
      recordDesktopSize: action
    });
  }

  get width(): number {
    return this.desktopWidth;
  }

  get height(): number {
    return this.desktopHeight;
  }

  recordDesktopSize(width: number, height: number) {
    this.desktopWidth = width;
    this.desktopHeight = height;
  }
}
