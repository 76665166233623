import { unindent } from '@akst.io/lib/base/strings';
import { checkExists } from '@akst.io/lib/base/types';
import { parseHexString } from '@akst.io/web-resume-dom/ui/base/color/color';
import { Sprite } from './types';

type ColorBuffer = (number | null)[];
type ColorRecord = Record<string, number | null>;

export enum AsciiSection {
  DIMENSIONS,
  COLORS,
  LAYOUT,
}

function skipWhitespace(input: string, cursor: number): number {
  switch (input[cursor]) {
    case ' ': return skipWhitespace(input, cursor + 1);
    case '\n': return skipWhitespace(input, cursor + 1);
    case '\t': return skipWhitespace(input, cursor + 1);
    default: return cursor;
  }
}

export function parseUntil(
    input: string,
    initialCursor: number,
    predicate: (char: string) => boolean,
): [string, number] {
  let cursor = initialCursor;

  while (cursor < input.length) {
    if (predicate(input[cursor])) break;
    cursor += 1;
  }

  return [input.slice(initialCursor, cursor), cursor];
}

function parseUntilWhitespace(input: string, initialCursor: number): [string, number] {
  const whitespace = new Set([' ', '\n', '\t']);
  return parseUntil(input, initialCursor, char => whitespace.has(char));
}

function parseSectionLabel(input: string, initialCursor: number): [AsciiSection, number] {
  if (input[initialCursor] !== '#') throw new Error('expected section start');

  // +1 for the '#'
  let cursor = skipWhitespace(input, initialCursor + 1);
  let [sectionString, nextCursor] = parseUntilWhitespace(input, cursor);

  switch (sectionString.toLowerCase()) {
    case 'dimensions': return [AsciiSection.DIMENSIONS, nextCursor];
    case 'colors': return [AsciiSection.COLORS, nextCursor];
    case 'layout': return [AsciiSection.LAYOUT, nextCursor];
    default: {
      throw new Error(`unknown section, '${sectionString}'`);
    }
  }
}

function parseDimension(input: string, initialCursor: number): [number, number, number] {
  let width: undefined | number;
  let height: undefined | number;
  let cursor = initialCursor;

  while (cursor < input.length) {
    let label: string;
    let amountString: string;

    cursor = skipWhitespace(input, cursor);
    if (input[cursor] === '-') break;

    [label, cursor] = parseUntil(input, cursor, c => c == ':');

    cursor = skipWhitespace(input, cursor + 1);
    [amountString, cursor] = parseUntilWhitespace(input, cursor);

    switch (label) {
      case 'width':
        width = parseInt(amountString, 10);
        break;

      case 'height':
        height = parseInt(amountString, 10);
        break;

      default:
        throw new Error(`unexpected dimension label, '${label}'`);
    }
  }

  return [
    checkExists(width, 'expected width to be specified in dimensions'),
    checkExists(height, 'expected height to be specified in dimensions'),
    cursor,
  ];
}

function parseColors(input: string, initialCursor: number): [ColorRecord, number] {
  const colors: ColorRecord = {};
  let colorId: string;
  let colorValueString: string;
  let cursor = skipWhitespace(input, initialCursor);

  while (cursor < input.length) {
    cursor = skipWhitespace(input, cursor);
    if (input[cursor] === '-') break;

    [colorId, cursor] = parseUntil(input, cursor, c => c === ':');
    if (colorId.length !== 1) {
      throw new Error(`invalid color id, '${colorId}'`);
    }

    cursor = skipWhitespace(input, cursor + 1);
    [colorValueString, cursor] = parseUntilWhitespace(input, cursor);

    colors[colorId] = colorValueString === 'transparent'
        ? null
        : checkExists(parseHexString(colorValueString), `invalid char code, ${colorValueString}`);
  }

  return [
    colors,
    cursor,
  ];
}

function parseLayout(
    input: string,
    initialCursor: number,
    colors: ColorRecord,
    width: number,
    height: number,
): [ColorBuffer, number] {
  const buffer: ColorBuffer = [];
  let cursor = skipWhitespace(input, initialCursor);

  while (cursor < input.length && input[cursor] !== '-') {
    const colorCode = input[cursor];
    if (!(colorCode in colors)) throw new Error('invalid color code');

    buffer.push(colors[colorCode]);
    cursor = skipWhitespace(input, cursor + 1);
  }

  if (buffer.length !== height * width) throw new Error('invalid buffer length');

  return [buffer, cursor];
}

function skipDivider(input: string, initialCursor: number): number {
  let cursor = skipWhitespace(input, initialCursor);
  let [chunk, retCursor] = parseUntilWhitespace(input, cursor);

  for (let i = 0; i < chunk.length; i++) {
    if (chunk[i] !== '-') throw new Error('unexpected character in divider');
  }

  return retCursor;
}

export function asciiSprite(chunks: TemplateStringsArray, ...args: any): Sprite {
  const input = unindent(chunks, ...args);
  let layout: undefined | ColorBuffer;
  let colors: undefined | Record<string, number | null>;
  let width: undefined | number;
  let height: undefined | number;
  let cursor = 0;

  while (cursor < input.length) {
    let section: AsciiSection;

    cursor = skipWhitespace(input, cursor);
    [section, cursor] = parseSectionLabel(input, cursor);

    switch (section) {
      case AsciiSection.DIMENSIONS:
        [width, height, cursor] = parseDimension(input, cursor);
        break;

      case AsciiSection.COLORS:
        [colors, cursor] = parseColors(input, cursor);
        break;

      case AsciiSection.LAYOUT: {
        if (width == null || height == null || colors == null) {
          throw new Error('need width, height, & colors before parsing layout');
        }

        [layout, cursor] = parseLayout(input, cursor, colors, width, height);
      }
    }

    cursor = skipDivider(input, cursor);
  }

  return {
    type: 'machine',
    width: checkExists(width, 'a sprite needs a width'),
    height: checkExists(height, 'a sprite needs a height'),
    fills: checkExists(layout, 'a sprite needs a layout'),
  };
}
