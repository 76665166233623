import { computed, IComputedValue, makeObservable } from 'mobx';
import { checkExists } from '@akst.io/lib/base/types';
import {
  Directory,
  File,
  FileSystemNode,
  FilePathDescription,
  Shortcut,
} from './types';

export function liveFileId(node: LiveFileSystemNode): string {
  return `/${node.path.join('/')}`
}

export interface LiveFileSystemNode {
  readonly type: 'file' | 'directory' | 'shortcut';
  readonly path: ReadonlyArray<string>;
  readonly name: string;
  readonly parent: LiveDirectory | undefined;
  readonly exists: boolean;
  readonly canExist: boolean;
}

export interface LiveFile extends LiveFileSystemNode {
  readonly unsafeData: string;
  readonly data?: string;
}

export interface LiveDirectory extends LiveFileSystemNode  {
  readonly unsafeChildren: ReadonlyMap<string, LiveFileSystemNode>;
  readonly children?: ReadonlyMap<string, LiveFileSystemNode>;
  readonly isRoot: boolean;
}

export interface LiveShortcut extends LiveFileSystemNode {
  readonly unsafePathToFile: ReadonlyArray<string>;
  readonly pathToFile?: ReadonlyArray<string>;
}

export interface LiveFileSystemService {
  getLiveDirectory(path: FilePathDescription): LiveDirectory;
  getLiveFileSystemNode(path: FilePathDescription): LiveFileSystemNode | undefined;
}

export function isLiveFile(node: LiveFileSystemNode): node is LiveFile {
  return node.type === 'file';
}

export function isLiveDirectory(node: LiveFileSystemNode): node is LiveDirectory {
  return node.type === 'directory';
}

export function isLiveShortcut(node: LiveFileSystemNode): node is LiveShortcut {
  return node.type === 'shortcut';
}

function getParent(liveFileSystemService: LiveFileSystemService, path: FilePathDescription) {
  if (path.length < 1) return undefined;

  const parentPath = path.slice(0, path.length - 1);
  return liveFileSystemService.getLiveDirectory(parentPath);
}

function canExist(liveFileSystemService: LiveFileSystemService, path: FilePathDescription) {
  const parent = getParent(liveFileSystemService, path);
  return parent == null || (parent.exists && isLiveDirectory(parent));
}

export class LiveDirectoryImpl implements LiveDirectory {
  public readonly type = 'directory';

  constructor(
      private _path: FilePathDescription,
      private readonly computedDirectory: IComputedValue<FileSystemNode | undefined>,
      private readonly liveFileSystemService: LiveFileSystemService,
  ) {
    makeObservable<LiveDirectoryImpl, "directory">(this, {
      name: computed,
      isRoot: computed,
      children: computed,
      parent: computed,
      canExist: computed,
      directory: computed
    });
  }

  get name() {
    return this.isRoot ? '$ROOT$' : this._path[this._path.length - 1];
  }

  get isRoot(): boolean {
    return this._path.length < 1;
  }

  get path() {
    return this._path;
  }

  get children() {
    const { directory } = this;
    if (!directory) return;

    const keyPairs: ([string, LiveFileSystemNode])[] = [];

    for (const [k, f] of directory.children) {
      const liveNode = this.liveFileSystemService.getLiveFileSystemNode([...this.path, k]);
      keyPairs.push([k, checkExists(liveNode, `file: ${f}`)]);
    }

    return new Map(keyPairs);
  }

  get parent() {
    return getParent(this.liveFileSystemService, this._path);
  }

  get unsafeChildren() {
    return checkExists(this.children, 'live directory children');
  }

  get exists() {
    return this.directory != null;
  }

  get canExist() {
    return canExist(this.liveFileSystemService, this._path);
  }

  private get directory(): Directory | undefined {
    const directory = this.computedDirectory.get();
    return directory && directory.type === 'directory' ? directory : undefined;
  }
}

export class LiveFileImpl implements LiveFile {
  public readonly type = 'file';

  constructor(
      private _path: FilePathDescription,
      private readonly computedFile: IComputedValue<FileSystemNode | undefined>,
      private readonly liveFileSystemService: LiveFileSystemService,
  ) {
    makeObservable<LiveFileImpl, "file">(this, {
      name: computed,
      parent: computed,
      canExist: computed,
      file: computed
    });
  }

  get name() {
    return this._path[this._path.length - 1] || 'unknown file';
  }

  get path() {
    return this._path;
  }

  get data() {
    return this.file && this.file.data;
  }

  get unsafeData() {
    return checkExists(this.data, 'missing file data');
  }

  get parent() {
    return getParent(this.liveFileSystemService, this._path);
  }

  get exists() {
    return !this.file;
  }

  get canExist() {
    return canExist(this.liveFileSystemService, this._path);
  }

  private get file(): File | undefined {
    const file = this.computedFile.get();
    return file && file.type === 'file' ? file : undefined;
  }
}

export class LiveShortcutImpl implements LiveShortcut {
  public readonly type = 'shortcut';

  constructor(
      private _path: FilePathDescription,
      private readonly computedShortcut: IComputedValue<FileSystemNode | undefined>,
      private readonly liveFileSystemService: LiveFileSystemService,
  ) {
    makeObservable<LiveShortcutImpl, "shortcut">(this, {
      name: computed,
      parent: computed,
      canExist: computed,
      shortcut: computed
    });
  }

  get name() {
    return this._path[this._path.length - 1] || 'unknown file';
  }

  get path() {
    return this._path;
  }

  get pathToFile() {
    return this.shortcut && this.shortcut.path;
  }

  get unsafePathToFile() {
    return checkExists(this.pathToFile, 'unable to get file path');
  }

  get parent() {
    return getParent(this.liveFileSystemService, this._path);
  }

  get exists() {
    return !this.shortcut;
  }

  get canExist() {
    return canExist(this.liveFileSystemService, this._path);
  }

  private get shortcut(): Shortcut | undefined {
    const shortcut = this.computedShortcut.get();
    return shortcut && shortcut.type === 'shortcut' ? shortcut : undefined;
  }
}
