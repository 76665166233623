import { DeviceService } from './device_service';

type WindowAPI = Pick<Window, 'innerWidth' | 'innerHeight' | 'ontouchstart'>;

export class DeviceServiceImpl implements DeviceService {
  constructor(
      private readonly window: WindowAPI,
  ) {
  }

  hasTouchScreen(): boolean {
    return 'ontouchstart' in this.window;
  }

  getDisplaySize(): { x: number, y: number } {
    const { innerWidth: x, innerHeight: y } = this.window;
    return { x, y };
  }
}
