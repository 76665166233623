export class LazyQueue<T> {
  private buffer: T[] = [];
  private doneConsuming = false;
  private readonly iterator: Iterator<T>;

  constructor(iterator: Iterator<T>) {
    this.iterator = iterator;
  }

  get done(): boolean {
    return this.head == null;
  }

  get head(): T | undefined {
    return this.getOffset(0);
  }

  pop(): T | undefined {
    const value = this.head;
    this.advanceCursor(1);
    return value;
  }

  advanceCursor(amount: number = 1) {
    if (amount < 1) throw new Error('cursor needs to be advanced by a value greater than 0');

    if (this.buffer.length >= amount) {
      this.buffer = this.buffer.slice(amount);
    } else {
      this.buffer = [];

      if (this.doneConsuming) return;

      for (let i = this.buffer.length; i < amount; i++) {
        const { value, done } = this.iterator.next();
        if (done) {
          this.doneConsuming = true;
          break;
        }
      }
    }
  }

  getManyAhead(distance: number): ReadonlyArray<T> {
    const output = [];
    for (let i = 0; i < distance; i++) {
      const value = this.getOffset(i);
      if (value == null) break;
      output.push(value);
    }
    return output;
  }

  getOffset(distance: number): T | undefined {
    if (!this.doneConsuming && this.buffer.length <= distance) {
      for (let i = this.buffer.length; i <= distance; i++) {
        const { value, done } = this.iterator.next();
        if (done) {
          this.doneConsuming = true;
          break;
        }
        this.buffer.push(value);
      }
    }

    return distance > this.buffer.length
        ? undefined
        : this.buffer[distance];
  }

  static fromIterable<T>(i: Iterable<T>): LazyQueue<T> {
    return new LazyQueue(i[Symbol.iterator]());
  }
}
