import * as React from 'react';
import { isTouchDevice } from './device_capabilities';

export interface CrossPlatformEvent<E> {
  currentTarget: E;
  clientX: number;
  clientY: number;
  preventDefault(): void;
}

class XPlatformMouseEvent<E> implements CrossPlatformEvent<E> {
  constructor(private event: React.MouseEvent<E, MouseEvent>) {
  }

  public get currentTarget() {
    return this.event.currentTarget;
  }

  public get clientY() {
    return this.event.clientY;
  }

  public get clientX() {
    return this.event.clientX;
  }

  public preventDefault() {
    this.event.preventDefault();
  }
}

class XPlatformTouchEvent<E> implements CrossPlatformEvent<E> {
  constructor(private event: React.TouchEvent<E>) {
  }

  public get currentTarget() {
    return this.event.currentTarget;
  }

  public get clientY() {
    return this.event.targetTouches[0].clientY;
  }

  public get clientX() {
    return this.event.targetTouches[0].clientX;
  }

  public preventDefault() {
    this.event.preventDefault();
  }
}

export type XPlatformHandler<E> = (event: CrossPlatformEvent<E>) => boolean | undefined | void;
type MouseHandler<E> = (event: React.MouseEvent<E, MouseEvent>) => boolean | undefined | void;
type TouchHandler<E> = (event: React.TouchEvent<E>) => boolean | undefined | void;

const asMouseHandler = <E>(h: XPlatformHandler<E>): MouseHandler<E> => (e) => {
  return h(new XPlatformMouseEvent(e));
};

const asTouchEvent = <E>(h: XPlatformHandler<E>): TouchHandler<E> => (e) => {
  return h(new XPlatformTouchEvent(e));
};

export enum EventType {
  START = 1,
  END,
  CLICK,
  MOVE,
  START_OUTSIDE,
  END_OUTSIDE,
  MOVE_OUTSIDE,
  ENTER,
  LEAVE,
};

export type UsePointerEventsConfig<E> = {
  passiveStates?: Map<EventType, boolean>;
  onPointer?: XPlatformHandler<E>;
  onPointerEndOutside?: XPlatformHandler<Document>;
  onPointerStartOutside?: XPlatformHandler<Document>;
  onPointerMoveOutside?: XPlatformHandler<Document>;
  onPointerStart?: XPlatformHandler<E>;
  onPointerEnd?: XPlatformHandler<E>;
  onPointerMove?: XPlatformHandler<E>;
  onPointerEnter?: XPlatformHandler<E>;
  onPointerLeave?: XPlatformHandler<E>;
};

export function usePointerEventsOnElement<E extends Node>(
    element: E | undefined | null,
    config: UsePointerEventsConfig<E>,
    changeArray: ReadonlyArray<any> = [],
) {
  const { passiveStates = new Map() } = config;

  function eventConfig(type: EventType): { passive: boolean } {
    const passive = passiveStates.get(type);
    return { passive: passive == null ? true : false };
  }

  function createMouseHandlers<E extends Node>(
      element: E,
      config: UsePointerEventsConfig<E>,
  ): () => void {
    const asOnClickOutside = (h: XPlatformHandler<Document>): MouseHandler<Document> => (e) => {
      const target = (e as any).target;
      if (target && !element.contains(target)) {
        return h(new XPlatformMouseEvent(e));
      }
    };

    const onClick: any = config.onPointer && asMouseHandler(config.onPointer);
    const onMouseDown: any = config.onPointerStart && asMouseHandler(config.onPointerStart);
    const onMouseUp: any = config.onPointerEnd && asMouseHandler(config.onPointerEnd);
    const onMouseMove: any = config.onPointerMove && asMouseHandler(config.onPointerMove);
    const onMouseEnter: any = config.onPointerLeave && asMouseHandler(config.onPointerLeave);
    const onMouseLeave: any = config.onPointerEnter && asMouseHandler(config.onPointerEnter);
    const onMouseUpOutside: any = config.onPointerEndOutside && asOnClickOutside(config.onPointerEndOutside);
    const onMouseDownOutside: any = config.onPointerStartOutside && asOnClickOutside(config.onPointerStartOutside);
    const onMouseMoveOutside: any = config.onPointerMoveOutside && asOnClickOutside(config.onPointerMoveOutside);

    onClick && element.addEventListener('click', onClick, eventConfig(EventType.CLICK));
    onMouseDown && element.addEventListener('mousedown', onMouseDown, eventConfig(EventType.START));
    onMouseUp && element.addEventListener('mouseup', onMouseUp, eventConfig(EventType.END));
    onMouseMove && element.addEventListener('mousemove', onMouseMove, eventConfig(EventType.MOVE));
    onMouseEnter && element.addEventListener('mouseenter', onMouseEnter, eventConfig(EventType.ENTER));
    onMouseLeave && element.addEventListener('mouseenter', onMouseLeave, eventConfig(EventType.LEAVE));
    onMouseUpOutside && document.addEventListener('mouseup', onMouseUpOutside, eventConfig(EventType.END_OUTSIDE));
    onMouseDownOutside && document.addEventListener('mousedown', onMouseDownOutside, eventConfig(EventType.START_OUTSIDE));
    onMouseMoveOutside && document.addEventListener('mousemove', onMouseMoveOutside, eventConfig(EventType.MOVE_OUTSIDE));

    return function cleanUp() {
      onClick && element.removeEventListener('click', onClick);
      onMouseDown && element.removeEventListener('mousedown', onMouseDown);
      onMouseUp && element.removeEventListener('mouseup', onMouseUp);
      onMouseMove && element.removeEventListener('mousemove', onMouseMove);
      onMouseEnter && element.removeEventListener('mouseenter', onMouseEnter);
      onMouseLeave && element.removeEventListener('mouseenter', onMouseLeave);
      onMouseUpOutside && document.removeEventListener('mouseup', onMouseUpOutside);
      onMouseDownOutside && document.removeEventListener('mousedown', onMouseDownOutside);
      onMouseMoveOutside && document.removeEventListener('mousemove', onMouseMoveOutside);
    };
  }

  function createTouchHandlers<E extends Node>(
      element: E,
      config: UsePointerEventsConfig<E>,
  ): () => void {
    const asOnTouchOutside = (h: XPlatformHandler<Document>): TouchHandler<Document> => (e) => {
      const target = (e as any).target;
      if (target && !element.contains(target)) {
        return h(new XPlatformTouchEvent(e));
      }
    };

    const onClick: any = config.onPointer && asTouchEvent(config.onPointer);
    const onTouchStart: any = config.onPointerStart && asTouchEvent(config.onPointerStart);
    const onTouchEnd: any = config.onPointerEnd && asTouchEvent(config.onPointerEnd);
    const onTouchMove: any = config.onPointerMove && asTouchEvent(config.onPointerMove);
    const onMouseEnter: any = config.onPointerLeave && asMouseHandler(config.onPointerLeave);
    const onMouseLeave: any = config.onPointerEnter && asMouseHandler(config.onPointerEnter);
    const onTouchEndOutside: any = config.onPointerEndOutside && asOnTouchOutside(config.onPointerEndOutside);
    const onTouchStartOutside: any = config.onPointerStartOutside && asOnTouchOutside(config.onPointerStartOutside);
    const onTouchMoveOutside: any = config.onPointerMoveOutside && asOnTouchOutside(config.onPointerMoveOutside);

    onClick && element.addEventListener('click', onClick, eventConfig(EventType.CLICK));
    onTouchStart && element.addEventListener('touchstart', onTouchStart, eventConfig(EventType.START));
    onTouchEnd && element.addEventListener('touchend', onTouchEnd, eventConfig(EventType.END));
    onTouchMove && element.addEventListener('touchmove', onTouchMove, eventConfig(EventType.MOVE));
    onMouseEnter && element.addEventListener('mouseenter', onMouseEnter, eventConfig(EventType.ENTER));
    onMouseLeave && element.addEventListener('mouseenter', onMouseLeave, eventConfig(EventType.LEAVE));
    onTouchEndOutside && document.addEventListener('touchend', onTouchEndOutside, eventConfig(EventType.END));
    onTouchStartOutside && document.addEventListener('touchstart', onTouchStartOutside, eventConfig(EventType.START));
    onTouchMoveOutside && document.addEventListener('touchmove', onTouchMoveOutside, eventConfig(EventType.MOVE_OUTSIDE));

    return function cleanUp() {
      onClick && element.removeEventListener('click', onClick);
      onTouchStart && element.removeEventListener('touchstart', onTouchStart);
      onTouchEnd && element.removeEventListener('touchend', onTouchEnd);
      onTouchMove && element.removeEventListener('touchmove', onTouchMove);
      onMouseEnter && element.removeEventListener('mouseenter', onMouseEnter);
      onMouseLeave && element.removeEventListener('mouseenter', onMouseLeave);
      onTouchEndOutside && document.removeEventListener('touchend', onTouchEndOutside);
      onTouchStartOutside && document.removeEventListener('touchstart', onTouchStartOutside);
      onTouchMoveOutside && document.removeEventListener('touchmove', onTouchMoveOutside);
    };
  }

  React.useEffect(() => {
    if (element) {
      return isTouchDevice()
          ? createTouchHandlers(element, config)
          : createMouseHandlers(element, config);
    }
  }, [
    element,
    config.onPointer,
    config.onPointerStart,
    config.onPointerEnd,
    config.onPointerMove,
    config.onPointerEndOutside,
    config.onPointerStartOutside,
    config.onPointerEnter,
    config.onPointerLeave,
    passiveStates.get(EventType.START),
    passiveStates.get(EventType.END),
    passiveStates.get(EventType.END_OUTSIDE),
    passiveStates.get(EventType.START_OUTSIDE),
    passiveStates.get(EventType.MOVE),
    passiveStates.get(EventType.CLICK),
    passiveStates.get(EventType.LEAVE),
    passiveStates.get(EventType.ENTER),
    ...changeArray,
  ]);
}

export function usePointerEvents<E extends Node>(
    config: UsePointerEventsConfig<E>,
    changeArray?: ReadonlyArray<any>,
): (ref: E) => void {
  const [element, setElement] = React.useState<E>();
  usePointerEventsOnElement(element, config, changeArray);
  return setElement;
}

export type WindowEventType =
  | EventType.START
  | EventType.END
  | EventType.MOVE;

export type UseWindowPointerEventsConfig = {
  passiveStates?: Map<WindowEventType, boolean>;
  onPointerStart?: XPlatformHandler<Document>;
  onPointerEnd?: XPlatformHandler<Document>;
  onPointerMove?: XPlatformHandler<Document>;
};

export function useWindowPointerEvents(
    config: UseWindowPointerEventsConfig,
    changeArray: ReadonlyArray<any> = [],
): void {
  const { passiveStates = new Map() } = config;

  function isPassive(name: WindowEventType): boolean {
    const value = passiveStates.get(name);
    return value == null ? true : value;
  }

  function createMouseHandlers(config: UseWindowPointerEventsConfig): () => void {
    const onMouseDown: any = config.onPointerStart && asMouseHandler(config.onPointerStart);
    const onMouseUp: any = config.onPointerEnd && asMouseHandler(config.onPointerEnd);
    const onMouseMove: any = config.onPointerMove && asMouseHandler(config.onPointerMove);

    onMouseDown && document.addEventListener('mousedown', onMouseDown, { passive: isPassive(EventType.START) });
    onMouseUp && document.addEventListener('mouseup', onMouseUp, { passive: isPassive(EventType.END) });
    onMouseMove && document.addEventListener('mousemove', onMouseMove, { passive: isPassive(EventType.MOVE) });

    return function cleanUp() {
      onMouseDown && document.removeEventListener('mousedown', onMouseDown);
      onMouseUp && document.removeEventListener('mouseup', onMouseUp);
      onMouseMove && document.removeEventListener('mousemove', onMouseMove);
    };
  }

  function createTouchHandlers(config: UseWindowPointerEventsConfig): () => void {
    const onTouchStart: any = config.onPointerStart && asTouchEvent(config.onPointerStart);
    const onTouchEnd: any = config.onPointerEnd && asTouchEvent(config.onPointerEnd);
    const onTouchMove: any = config.onPointerMove && asTouchEvent(config.onPointerMove);

    onTouchStart && document.addEventListener('touchstart', onTouchStart, { passive: isPassive(EventType.START) });
    onTouchEnd && document.addEventListener('touchend', onTouchEnd, { passive: isPassive(EventType.END) });
    onTouchMove && document.addEventListener('touchmove', onTouchMove, { passive: isPassive(EventType.MOVE) });

    return function cleanUp() {
      onTouchStart && document.removeEventListener('touchstart', onTouchStart);
      onTouchEnd && document.removeEventListener('touchend', onTouchEnd);
      onTouchMove && document.removeEventListener('touchmove', onTouchMove);
    };
  }

  React.useEffect(() => {
    isTouchDevice() ? createTouchHandlers(config) : createMouseHandlers(config);
  }, [
    config.onPointerMove,
    config.onPointerStart,
    config.onPointerEnd,
    passiveStates.get('start'),
    passiveStates.get('end'),
    passiveStates.get('move'),
    ...changeArray,
  ]);
}
