import * as React from 'react';
import { observer } from 'mobx-react';
import { Clock } from '@akst.io/web-resume-dom/base/time/time';
import { wait } from '@akst.io/web-resume-dom/base/wait';
import { ApplicationService } from '@akst.io/web-resume-dom/services/application/application_service';
import { AudioService } from '@akst.io/web-resume-dom/services/audio/audio_service';
import { FileSystemService } from '@akst.io/web-resume-dom/services/file_system/file_system_service';
import { DesktopService } from '@akst.io/web-resume-dom/services/desktop/desktop_service';
import type { DragContainerProps } from '@akst.io/web-resume-dom/ui/system/draggable/create';
import type { FileGridProps } from '@akst.io/web-resume-dom/ui/system/file_grid/file_grid';
import { AssistantController } from '@akst.io/web-resume-dom/ui/application/assistant/type';
import { DesktopStep } from './desktop_step';
import { DesktopStepController } from './desktop_step_controller';
import {
  DesktopStepPresenter,
} from './desktop_step_presenter';
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { StartBar, StartBarProps } from './start_bar/start_bar';
import { StepNode, StepProps } from '../types';

export function createDesktopStep({
  applicationService,
  assistantController,
  audioService,
  clock,
  desktopService,
  DragContainer,
  fileSystemService,
  FileGrid,
  loggingService,
  username,
}: {
  applicationService: ApplicationService,
  assistantController: AssistantController,
  audioService: AudioService,
  clock: Clock,
  desktopService: DesktopService,
  DragContainer: React.ComponentType<DragContainerProps>,
  FileGrid: React.ComponentType<FileGridProps>,
  fileSystemService: FileSystemService,
  loggingService: LoggingService,
  username: string,
}): StepNode {
  const presenter = new DesktopStepPresenter(
      audioService,
      applicationService,
      fileSystemService,
      desktopService,
      loggingService,
      assistantController,
      clock,
      wait,
  );
  const liveDirectory = fileSystemService.getLiveDirectory(['desktop']);
  const store = presenter.createStore(liveDirectory);

  setInterval(() => presenter.tick(store));

  const onStartClick = () => presenter.onStartClick(store);
  const StartBarImpl = observer((props: StartBarProps) => (
      <StartBar {...props} onStartClick={onStartClick} time={store.time}/>
  ));

  return {
    id: 'desktop',
    Component: React.memo((props: StepProps) => (
        <DesktopStep
            store={store}
            presenter={presenter}
            DragContainer={DragContainer}
            FileGrid={FileGrid}
            StartBar={StartBarImpl}
            {...props}
        />
    )),
    controller: new DesktopStepController(
        store,
        presenter,
    ),
  };
}
