import styled, { keyframes } from 'styled-components';
import { turquoise, black, white } from '../../../base/color/colors';
import { fontFamily } from '../../../base/typography/fonts';

const loadingBarHeight = 30;
const homeFontFamily = `'Helvetica Neue', Helvetica, Arial, ${fontFamily}`;


export const Root = styled.div`
  position: relative;
  background: ${black};
  color: ${white};
  width: 100vw;
  height: var(--window-height);
  transition: opacity 0.5s linear;
`

export const FullContent = styled.div`
  width: 100%;
  height: 100%;
  transition: opacity 0.5s linear;
`;

export const TextContent = styled.div`
  height: calc(100% - ${loadingBarHeight}px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#5e98d1, #b1c6db);
`;

export const LoadingBarContainer = styled.div`
  position: relative;
  height: ${loadingBarHeight}px;
`;

export const Message = styled.div`
  text-align: center;
`;

export const ClickToContinueContainer = styled.div`
  position: relative;
`;

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

export const ClickToContinue = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  animation: ${fadeIn} 1s linear;
  margin-bottom: 0;
  margin-top: 3vmin;
  font-size: 4vmin;
  font-weight: 400;
  font-family: ${homeFontFamily};
`;

export const Logo = styled.img`
  width: auto;
  height: 50vmin;
  margin-bottom: 2vmin;
`;

export const Title = styled.h1`
  position: relative;
  margin: 0;
  letter-spacing: -0.08em;
  font-weight: bold;
  font-family: ${homeFontFamily};
  font-size: 15vmin;
  line-height: 1;
  color: ${black};
`;

export const Brand = styled.span`
  position: absolute;
  content: '';
  left: -7vmin;
  font-size: 5vmin;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 1;
  top: -3vmin;
  letter-spacing: initial;
`;

export const Version = styled.span`
  color: ${white};
`;
