import * as React from 'react';
import { FilePathDescription } from '@akst.io/web-resume-dom/services/file_system/types';
import { LiveFileSystemNode } from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import {
  usePointerEvents,
  useWindowPointerEvents,
  XPlatformHandler,
} from '@akst.io/web-resume-dom/ui/base/cross_platform/pointer_events';
import type { UseDragSlot } from '@akst.io/web-resume-dom/ui/system/draggable/create';
import { DragPositionInitHint } from '@akst.io/web-resume-dom/ui/system/draggable/types';
import {
  KeyboardEventDescriptor,
  useKeyboardEvents,
} from '@akst.io/web-resume-dom/ui/base/keyboard/use_keyboard_events';
import { useMeasureMap } from '@akst.io/web-resume-dom/ui/base/responsive/measure';
import { PanelToolBarProps } from './panel_toolbar/panel_toolbar';
import {
  Content,
  ResizeBarRoot,
  ResizeBox,
  Root,
} from './style';

export type InitialSize = { width?: number | string, height?: number | string };

type ImplPublicPropIntersection = {
  title: string,
  children: React.ReactNode,
  resizeable?: boolean,
  sprite?: JSX.Element,
  onClose?(): void,
  onMinimize?(): void,
  initialSize?: InitialSize,
  initialPosition?: DragPositionInitHint,
  keyboardEvents?: ReadonlyArray<KeyboardEventDescriptor<HTMLDivElement>>;
};

type InterallyInjectProps = {
  file?: LiveFileSystemNode;
  PanelToolBar: React.ComponentType<PanelToolBarProps>;
};

type PublicExclusiveProps = {
  pathOfOpenFile?: FilePathDescription;
};

export type PanelProps =
  & ImplPublicPropIntersection
  & PublicExclusiveProps;

export type PanelImplProps =
  & ImplPublicPropIntersection
  & InterallyInjectProps;

export const PanelImpl = React.memo(({
  children,
  file,
  keyboardEvents,
  initialSize,
  initialPosition,
  onClose,
  onMinimize,
  PanelToolBar,
  resizeable,
  title,
  useDragSlot,
}: PanelImplProps & {
  useDragSlot: UseDragSlot,
}) => {
  const { DragSlot } = useDragSlot(initialPosition);

  const ref = useKeyboardEvents({
    events: keyboardEvents || [],
    changeArray: [keyboardEvents],
  });

  return (
      <DragSlot>
        <PanelPure
            ref={ref}
            title={title}
            resizeable={resizeable}
            children={children}
            onClose={onClose}
            onMinimize={onMinimize}
            initialSize={initialSize}
            initialPosition={initialPosition}
            PanelToolBar={PanelToolBar}
            file={file}
        />
      </DragSlot>
  );
});

export const PanelPure = React.memo(React.forwardRef(function PanelPure(
  props: PanelImplProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const {
    children,
    file,
    initialSize,
    PanelToolBar,
    onMinimize,
    onClose,
    resizeable,
    title,
  } = props;

  const onResizeStart: XPlatformHandler<HTMLDivElement> = React.useCallback((event) => {
  }, []);

  const onResizeEnd: XPlatformHandler<HTMLDivElement> = React.useCallback((event) => {
  }, []);

  const onResizeChange: XPlatformHandler<Document> = React.useCallback((event) => {
  }, []);

  const [width, setElement] = useMeasureMap<HTMLDivElement, number | undefined>(
      snapshot => snapshot && snapshot.contentRect.width,
      [],
  );

  return (
      <Root ref={ref} style={initialSize} tabIndex={0}>
        <PanelToolBar
            file={file}
            width={width}
            title={title}
            onClose={onClose}
            onMinimize={onMinimize}
        />
        <Content ref={setElement}>
          {children}
        </Content>
        {resizeable && (
            <ResizeBar
                onPointerStart={onResizeStart}
                onPointerEnd={onResizeEnd}
                onPointerMove={onResizeChange}
            />
        )}
      </Root>
  );
}));

type ResizeBarProps = {
  onPointerStart: XPlatformHandler<HTMLDivElement>;
  onPointerEnd: XPlatformHandler<HTMLDivElement>;
  onPointerMove?: XPlatformHandler<Document>;
};

const ResizeBar = React.memo(function ResizeBar({
  onPointerStart,
  onPointerEnd,
  onPointerMove,
}: ResizeBarProps) {
  const setRef = usePointerEvents( { onPointerStart, onPointerEnd });
  useWindowPointerEvents({ onPointerMove });

  return (
      <ResizeBarRoot>
        <ResizeBox ref={setRef}/>
      </ResizeBarRoot>
  );
});

