import * as React from 'react';
import { observer } from 'mobx-react';
import { BufferPresenter, BufferStore } from '@akst.io/web-resume-dom/ui/application/sprite_maker/buffer/buffer_presenter';
import { ControlsStore, ControlsPresenter } from './controls_presenter';
import { Controls } from './controls';
import { DrawMode } from './types';

export function createControls(
    bufferStore: BufferStore,
    bufferPresenter: BufferPresenter,
): [ControlsStore, React.ComponentType] {
  const store = new ControlsStore(bufferStore);
  const presenter = new ControlsPresenter(bufferPresenter);

  const onGridSizeChange = (width: number, height: number) => {
    presenter.onGridSizeChange(store, width, height);
  };

  const onDrawModeChange = (mode: DrawMode) => {
    presenter.onDrawModeChange(store, mode);
  };

  const Component = observer(() => (
      <Controls
          mode={store.drawMode}
          gridWidth={store.bufferStore.gridWidth}
          gridHeight={store.bufferStore.gridHeight}
          onGridSizeChange={onGridSizeChange}
          onDrawModeChange={onDrawModeChange}
      />
  ));

  return [store, Component];
}
