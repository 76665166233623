import * as React from 'react';
import { Root } from './styles';

export type SlideProps = {
  value: number;
  max: number;
  min: number;
  onChange(value: number): void;
  backgroundImage?: string;
  backgroundColor?: string;
};

export const Slide = React.memo(function Slide(props: SlideProps) {
  const {
    value,
    onChange,
    max,
    min,
    backgroundColor,
    backgroundImage,
  } = props;

  const onInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(parseFloat(event.currentTarget.value));
  }, [onChange]);

  const style = React.useMemo(
      () => ({ backgroundColor, backgroundImage }),
      [backgroundColor, backgroundImage],
  );

  return (
      <Root
          style={style}
          type="range"
          max={max}
          min={min}
          value={value}
          onChange={onInputChange}
      />
  );
});
