import { action, computed, makeObservable } from 'mobx';
import { checkExists, UnreachableError } from '@akst.io/lib/base/types';
import { FileSystemService } from './file_system_service';
import {
  LiveFileSystemNode,
  LiveFile,
  LiveFileImpl,
  LiveDirectory,
  LiveDirectoryImpl,
  LiveShortcut,
  LiveShortcutImpl,
  isLiveShortcut,
} from './live_files_system_node';
import {
  Directory,
  File,
  FilePathDescription,
  FileSystemNode,
  Shortcut,
} from './types';

export class FileSystemServiceImpl implements FileSystemService {
  constructor(private readonly rootDirectory: Directory) {
    makeObservable(this, {
      makeDirectory: action,
      write: action
    });
  }

  public getLiveFileSystemNode(path: FilePathDescription): LiveFileSystemNode | undefined {
    const computedNode = computed(() => this.lookupNode(path));
    const current = computedNode.get();
    if (current == null) return;

    switch (current.type) {
      case 'file':
        return new LiveFileImpl(path, computedNode, this);

      case 'shortcut':
        return new LiveShortcutImpl(path, computedNode, this);

      case 'directory':
        return new LiveDirectoryImpl(path, computedNode, this);

      default:
        throw new UnreachableError(current);
    }
  }

  public createShortcut(location: FilePathDescription, shortcut: FilePathDescription) {
    if (location.length < 1) throw new Error('no');

    const dirPath = location.slice(0, location.length - 1);
    const directory = this.lookupNode(dirPath);

    if (!directory || directory.type !== 'directory') {
      // TODO provide better error message;
      throw new Error('directory does not exist' + dirPath);
    }

    const fileName = location[location.length - 1];
    directory.children.set(fileName, Shortcut.create(shortcut));
  }

  public getLiveDirectory(path: FilePathDescription): LiveDirectory {
    const computedNode = computed(() => this.lookupNode(path));
    return new LiveDirectoryImpl(path, computedNode, this);
  }

  public getLiveFile(path: FilePathDescription): LiveFile {
    const computedNode = computed(() => this.lookupNode(path));
    return new LiveFileImpl(path, computedNode, this);
  }

  public getLiveShortcut(path: FilePathDescription): LiveShortcut {
    const computedNode = computed(() => this.lookupNode(path));
    return new LiveShortcutImpl(path, computedNode, this);
  }

  public makeDirectory(parent: FilePathDescription, name: string) {
    const directory = this.lookupNode(parent);
    if (!directory || directory.type !== 'directory') {
      throw new Error('parent directory does not exist');
    }

    if (directory.children.has(name)) {
      throw new Error('directory already exists');
    }

    directory.children.set(name, Directory.create([]));
  }

  public write(path: FilePathDescription, data: string) {
    const directoryPath = path.slice(0, path.length - 1);
    const fileName = checkExists(path[path.length - 1], 'file name');
    const directory = this.lookupNode(directoryPath);

    if (!directory || directory.type !== 'directory') {
      // TODO provide better error message;
      throw new Error('directory does not exist');
    }

    directory.children.set(fileName, File.create(data));
  }

  public read(path: FilePathDescription): string | undefined {
    throw new Error('not implemented');
  }

  public fileExists(path: FilePathDescription) {
    return this.lookupNode(path) !== undefined;
  }

  public lookupShortcut(shortcut: LiveShortcut): LiveFileSystemNode | undefined {
    const seen = new WeakSet();
    let resolvedFile: LiveFileSystemNode | undefined = shortcut;

    while (resolvedFile && isLiveShortcut(resolvedFile)) {
      if (seen.has(resolvedFile)) throw new Error('recursive shortcut');

      seen.add(resolvedFile);
      resolvedFile = this.getLiveFileSystemNode(resolvedFile.unsafePathToFile);
    }

    return resolvedFile;
  }

  public getApplicationIdentifier(ref: LiveFile): string {
    const split = ref.name.split('.');
    const fileExtension = split[split.length - 1];
    switch (fileExtension) {
      case 'exe':
        return ref.unsafeData;

      default:
        return fileExtension;
    }
  }

  private lookupNode(path: FilePathDescription): undefined | FileSystemNode {
    let index = 0;
    let currentFile: FileSystemNode | undefined = this.rootDirectory;

    while (index < path.length && currentFile && currentFile.type === 'directory') {
      const pathStep = path[index++];
      currentFile = currentFile.children.get(pathStep);
    }

    return currentFile;
  }
}
