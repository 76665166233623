import * as React from 'react';
import styled from 'styled-components';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';

export const Rows = React.memo(({
  children,
  gridTemplateColumns,
}: {
  children: React.ReactNode,
  gridTemplateColumns: 'unset' | '1fr',
}) => {
  const style: React.CSSProperties = {};

  if (gridTemplateColumns !== 'unset') {
    style.gridTemplateColumns = gridTemplateColumns;
  }

  return (
      <RowsContainer
          style={style}
          children={children}
      />
  );
});

const RowsContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${baseUnit * 2}px;
  align-items: flex-start;
  align-self: flex-start;
`;

export const CopyContainer = styled.div`
  display: grid;
  grid-gap: ${baseUnit * 2}px;
  padding: ${baseUnit * 2}px;
  grid-template-rows: auto;
`;
