import { observer } from 'mobx-react';
import * as React from 'react';
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { getUniqueString } from '@akst.io/web-resume-dom/base/string';
import { isTouchDevice } from '@akst.io/web-resume-dom/ui/base/cross_platform/device_capabilities';
import { useMeasure } from '@akst.io/web-resume-dom/ui/base/responsive/measure';
import { CrossPlatformEvent } from '@akst.io/web-resume-dom/ui/base/cross_platform/pointer_events';
import {
  DragContainer,
  DraggableSlot,
  DragPoint,
  DragPointProps,
} from './draggable';
import {
  DraggableSlotStore,
  DraggableSlotPresenter,
} from './draggable_slot_presenter';
import {
  DraggableContainerStore,
  DraggableContainerPresenter,
} from './draggable_container_presenter';
import {
  DragSlotController,
  DragPositionInitHint,
} from './types';

export type DragSlotProps = {
  children: JSX.Element;
};

export type DragContainerProps = {
  children: JSX.Element;
};

export type UseDragSlot = (
    initialPositionHint: DragPositionInitHint | undefined,
) => {
  DragSlot: React.ComponentType<DragSlotProps>,
  controller: DragSlotController,
};

/**
 * Used to initialize a DraggableSlot and it's controller,
 * this is done as an alternative to making the children of
 * DraggableSlot a render prop.
 */
export function createDraggable({
  loggingService,
}: {
  loggingService: LoggingService,
}): {
  DragPoint: React.ComponentType<DragPointProps>,
  DragContainer: React.ComponentType<DragContainerProps>,
  useDragSlot: UseDragSlot,
} {
  const slotPresenter = new DraggableSlotPresenter(
    loggingService.createChild('DraggableSlotPresenter'),
    getUniqueString,
    () => Math.random(),
  );
  const presenter = new DraggableContainerPresenter<HTMLDivElement>(
    loggingService.createChild('DraggablePresenter'),
    slotPresenter,
  );
  const DragContext = React.createContext<DraggableContainerStore<HTMLDivElement> | undefined>(undefined);
  const SlotContext = React.createContext<DraggableSlotStore | undefined>(undefined);

  const DragPointImpl = observer((props: DragPointProps) => {
    const [pointerDown, setPointerDown] = React.useState(false);
    const store = React.useContext(DragContext);
    const slot = React.useContext(SlotContext);

    if (store == null) throw new Error('Improper use of a DragPoint');
    if (slot == null) throw new Error('Improper use of a DragPoint');

    const onPointerStart = React.useCallback((event: CrossPlatformEvent<HTMLDivElement>) => {
      setPointerDown(true);
      slotPresenter.onPointerDown(slot, event);
      presenter.bumpSlotPriority(store, slot);
      return false;
    }, [slot, store]);

    const onPointerMove = React.useCallback((event: CrossPlatformEvent<Document>) => {
      // This prevents scrolls around when the user moves an element on screen.
      // Unforunately this cannot be fixed by `touch-action: manipulate`.
      isTouchDevice() && event.preventDefault();
      slotPresenter.onPointerMove(slot, event, store.portalBounds);
      return false;
    }, [slot, store]);

    const onPointerEnd = React.useCallback((event: CrossPlatformEvent<any>) => {
      setPointerDown(false);
      slotPresenter.onPointerUp(slot);
      return false;
    }, [slot]);

    return (
        <DragPoint
            pointerDown={pointerDown}
            onPointerStart={onPointerStart}
            onPointerMove={onPointerMove}
            onPointerEnd={onPointerEnd}
            {...props}
        />
    );
  });

  const DragContainerImpl = observer(({ children }: DragContainerProps) => {
    const store = React.useMemo(() => new DraggableContainerStore<HTMLDivElement>(), []);

    const setPortalRef = React.useCallback((element: HTMLDivElement) => {
      presenter.setPortalElement(store, element);
    }, [store]);

    const [measurement, setParentRef] = useMeasure(undefined, []);

    React.useEffect(() => {
      if (!measurement) return;
      presenter.setPortalBounds(store, measurement);
    }, [
      store,
      measurement,
    ]);

    return (
        <DragContainer
            setPortalRef={setPortalRef}
            setParentRef={setParentRef}
        >
          <DragContext.Provider value={store}>
            {children}
          </DragContext.Provider>
        </DragContainer>
    );
  });

  const useDragSlot: UseDragSlot = (hint) => {
    const store = React.useContext(DragContext);

    if (store == null) {
      throw new Error('illegal use of drag slot');
    }

    const slot = React.useMemo(() => {
      const usedHint = hint ?? ({ kind: 'random' });
      return presenter.createDraggableSlotStore(store, usedHint);
    }, [store, hint]);

    React.useEffect(() => {
      return () => presenter.removeSlot(store, slot);
    }, [store, slot]);

    const [measurement, setElement] = useMeasure(undefined, []);

    React.useEffect(() => {
      if (measurement == null) return;
      slotPresenter.onResize(slot, measurement);
    }, [slot, measurement]);

    const ctrl = React.useMemo((): DragSlotController => {
      return {
        setPosition: ({ x, y }) => slotPresenter.setPosition(slot, x, y),
        getPosition: () => ({ x: slot.x, y: slot.y }),
        onPointerDown: coord => slotPresenter.onPointerDown(slot, coord),
        onPointerMove: coord => slotPresenter.onPointerMove(slot, coord, store.portalBounds),
        onPointerUp: () => slotPresenter.onPointerUp(slot),
      };
    }, [store, slot]);

    const DragSlot: React.ComponentType<DragSlotProps> = React.useMemo(() => {
      return observer(({ children }: DragSlotProps) => (
          <SlotContext.Provider value={slot}>
            <DraggableSlot
                portalElement={store.portalElement}
                setElement={setElement}
                zIndex={presenter.getZIndex(store, slot)!}
                positionStyle={slotPresenter.getPositionStyle(slot)}
                children={children}
            />
          </SlotContext.Provider>
      ));
    }, [
      store,
      slot,
      setElement,
    ]);

    return { DragSlot, controller: ctrl };
  };

  return {
    useDragSlot,
    DragPoint: DragPointImpl,
    DragContainer: DragContainerImpl,
  };
}
