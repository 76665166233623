import * as React from 'react';
import Measure from 'react-measure';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { Button } from '@akst.io/web-resume-dom/ui/base/button/button';
import { usePointerEvents } from '@akst.io/web-resume-dom/ui/base/cross_platform/pointer_events';
import { isTouchDevice } from '@akst.io/web-resume-dom/ui/base/cross_platform/device_capabilities';
import { rgbToHexString as rgbToHex } from '@akst.io/web-resume-dom/ui/base/color/color';
import { Slide } from '@akst.io/web-resume-dom/ui/base/form/slide/slide';
import { Normal } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import {
  buttonWidth,
  InputRow,
  Pallet,
  PalletEntryContainer,
  PalletHint,
  Preview,
  RightContent,
  Root,
  SaveButton,
  SliderRow,
  SliderRowName,
  Sliders,
} from './color_styles';

export type ColorProps = {
  r: number;
  g: number;
  b: number;
  pallet: ReadonlyArray<string>;
  onRGBChange(r: number, g: number, b: number): void;
  onPalletChange(pallet: ReadonlyArray<string>): void;
};

export const Color = React.memo(function Color(props: ColorProps) {
  const { r, g, b, pallet, onRGBChange, onPalletChange } = props;

  const [controlHeight, setControlHeight] = React.useState(0);
  const [controlWidth, setControlWidth] = React.useState(0);

  const onRedChange = React.useCallback((r: number) => onRGBChange(r, g, b), [onRGBChange, g, b]);
  const onGreenChange = React.useCallback((g: number) => onRGBChange(r, g, b), [onRGBChange, r, b]);
  const onBlueChange = React.useCallback((b: number) => onRGBChange(r, g, b), [onRGBChange, r, g]);

  const onResize = React.useCallback(({ bounds }: {
    bounds?: { height: number, width: number },
  }) => {
    setControlHeight(bounds ? bounds.height : 0);
    setControlWidth(bounds ? bounds.width : 0);
  }, [setControlHeight, setControlWidth]);

  const onRemoveColor = React.useCallback((value: string) => {
    const index = pallet.indexOf(value);
    if (index === -1) return;

    onPalletChange(pallet.slice(0, index).concat(pallet.slice(index + 1)));
  }, [pallet, onPalletChange]);

  const onSavingColor = React.useCallback(() => {
    const newPallet = [...pallet];
    newPallet.push(rgbToHex(r, g, b));
    onPalletChange(newPallet);
  }, [pallet, r, g, b, onPalletChange]);


  const rightContentStyle = React.useMemo(() => (
      { width: controlHeight, height: controlHeight }
  ), [controlHeight]);

  const previewStyle = React.useMemo(() => (
      { backgroundColor: rgbToHex(r, g, b) }
  ), [r, g, b, controlHeight]);

  const slidersStyle = React.useMemo(() => {
    if (controlHeight) {
      return { width: `calc(100% - ${controlHeight + buttonWidth}px)` };
    }
  }, [controlHeight]);

  const palletEntryStyle = React.useMemo(() => (
      {
        width: controlWidth / 20,
        height: controlWidth / 20,
      }
  ), [r, g, b, controlWidth]);

  const renderedPallet = React.useMemo(() => {
    if (!pallet.length) {
      return;
    }

    const entries = pallet.map((backgroundColor, index) => (
      <PalletEntry
          key={index}
          onRemove={onRemoveColor}
          onColorSelect={onRGBChange}
          palletShade={backgroundColor}
          dimensions={palletEntryStyle}
      />
    ))

    return (
        <>
          <Pallet>{entries}</Pallet>
          {!isTouchDevice() && (
              <PalletHint>
                <Normal>(Right click to remove pallet entry...)</Normal>
              </PalletHint>
          )}
        </>
    );
  }, [pallet, palletEntryStyle, onRGBChange]);

  function renderNumberInput(
      label: string,
      callback: (value: number) => void,
      value: number,
      backgroundImage: string,
  ) {
    return (
        <SliderRow>
          <SliderRowName>
            <Normal weight="bold">{label}</Normal>
          </SliderRowName>
          <Slide
              min={0}
              max={255}
              value={value}
              onChange={callback}
              backgroundImage={backgroundImage}
          />
        </SliderRow>
    );
  }

  return (
      <Root>
        <Measure bounds={true} onResize={onResize}>
          {({ measureRef }) => (
              <InputRow ref={measureRef}>
                <Sliders style={slidersStyle}>
                  {renderNumberInput("Red", onRedChange, r, linearGradient(r, g, b, 'red'))}
                  {renderNumberInput("Green", onGreenChange, g, linearGradient(r, g, b, 'green'))}
                  {renderNumberInput("Blue", onBlueChange, b, linearGradient(r, g, b, 'blue'))}
                </Sliders>
                <RightContent style={rightContentStyle}>
                  <Preview style={previewStyle}/>
                  <SaveButton>
                    <Button onPointer={onSavingColor}>Save</Button>
                  </SaveButton>
                </RightContent>
              </InputRow>
          )}
        </Measure>
        {renderedPallet}
      </Root>
  );
});

type PalletEntryProps = {
  palletShade: string;
  dimensions: { width: number, height: number };
  onRemove(value: string): void;
  onColorSelect(r: number, g: number, b: number): void;
};

const PalletEntry = React.memo(function PalletEntry(props: PalletEntryProps) {
  const { onColorSelect, dimensions, palletShade, onRemove } = props;

  const onContextMenu = React.useCallback((event: { preventDefault: () => void }) => {
    event.preventDefault();
    onRemove(palletShade);
  }, [onRemove, palletShade]);

  const onPointer = React.useCallback(() => {
    const r = parseInt(palletShade.slice(1, 3), 16);
    const g = parseInt(palletShade.slice(3, 5), 16);
    const b = parseInt(palletShade.slice(5, 7), 16);
    onColorSelect(r, g, b);
  }, [onColorSelect, palletShade]);

  const setRef = usePointerEvents<HTMLDivElement>({ onPointer });

  return (
      <PalletEntryContainer
          ref={setRef}
          onContextMenu={onContextMenu}
          style={{ ...dimensions, backgroundColor: palletShade }}
      />
  );
});

function linearGradient(r: number, g: number, b: number, color: 'red' | 'green' | 'blue'): string {
  const asGradient = (from: string, to: string) => `linear-gradient(90deg, ${from}, ${to}`;
  switch (color) {
    case 'red':
      return asGradient(rgbToHex(0, g, b), rgbToHex(255, g, b));
    case 'green':
      return asGradient(rgbToHex(r, 0, b), rgbToHex(r, 255, b));
    case 'blue':
      return asGradient(rgbToHex(r, g, 0), rgbToHex(r, g, 255));
  }
}
