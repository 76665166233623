import * as React from 'react';
import { observer } from 'mobx-react';
import { computed, makeObservable } from 'mobx';
import { LiveFileSystemNode } from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import { SpriteService } from '@akst.io/web-resume-dom/services/sprite/sprite_service';
import { Sprite, SpriteProps } from '@akst.io/web-resume-dom/ui/system/sprite/sprite';

export type CreateOptions = {
  spriteService: SpriteService;
};

export type FileSpriteProps = Pick<SpriteProps, 'sizing'> & {
  fileSystemNode: LiveFileSystemNode;
};

export function createFileSprite({ spriteService }: CreateOptions): React.ComponentType<FileSpriteProps> {
  const FileSprite = observer(class FileSprite extends React.Component<FileSpriteProps> {
    constructor(props: FileSpriteProps) {
      super(props);

      makeObservable(this, {
        sprite: computed
      });
    }

    get sprite() {
      const { fileSystemNode } = this.props;
      return spriteService.getSprite(fileSystemNode);
    }

    render() {
      return (
          <Sprite sprite={this.sprite} sizing={this.props.sizing}/>
      );
    }
  });

  return FileSprite;
}
