import { UnreachableError } from '@akst.io/lib/base/types';
import * as geo from '@akst.io/web-resume-dom/base/geometry';
import { DesktopService } from '@akst.io/web-resume-dom/services/desktop/desktop_service';
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { DragSlotController } from '@akst.io/web-resume-dom/ui/system/draggable/types';
import { Movement, Position } from '../type';

export type TranslateCtrl = Pick<DragSlotController, 'getPosition' | 'setPosition'>;

export class AnimatorTransformStore {
  translateCtrl: TranslateCtrl | undefined;

  constructor(
      translateCtrl: TranslateCtrl,
      readonly bounds: Readonly<geo.Vector>,
  ) {
    this.translateCtrl = translateCtrl;
  }
}

export class AnimatorTransformPresenter {
  constructor(
      private readonly logging: LoggingService,
      private readonly desktopService: DesktopService,
      private readonly now: () => number,
  ) {
  }

  createStore(mover: TranslateCtrl, bounds: Readonly<geo.Vector>) {
    return new AnimatorTransformStore(mover, bounds);
  }

  move(
      store: AnimatorTransformStore,
      movement: Movement,
      _duration: number,
  ) {
    switch (movement.kind) {
      case 'goto':
        const position = this.findPosition(store, movement.position);
        store.translateCtrl?.setPosition(position);
        break;

      case 'move-to':
        break;

      default:
        throw new UnreachableError(movement);
    }
  }

  update(store: AnimatorTransformStore, frame: number) {
  }

  pause(store: AnimatorTransformStore) {
    store.translateCtrl = undefined;
  }

  resume(store: AnimatorTransformStore, translateCtrl: TranslateCtrl) {
    store.translateCtrl = translateCtrl;
  }

  private findPosition(store: AnimatorTransformStore, position: Position): geo.Vector {
    if (position.kind === 'offset') {
      const { coord } = position;
      const bottom = this.desktopService.height - store.bounds.y;
      const right = this.desktopService.width - store.bounds.x;
      switch (position.corner) {
        case 'top-left':
          return coord;
        case 'top-right':
          return { x: right - coord.x, y: coord.y };
        case 'bottom-right':
          return { x: right - coord.x, y: bottom - coord.y };
        case 'bottom-left':
          return { x: coord.x, y: bottom - coord.y };
        default:
          throw new UnreachableError(position.corner);
      }
    } else {
      const { coord } = position;
      const bottom = store.bounds.y;
      const right = store.bounds.x;
      switch (position.corner) {
        case 'top-left':
          return coord;
        case 'top-right':
          return { x: coord.x - right, y: coord.y };
        case 'bottom-right':
          return { x: coord.x - right, y: coord.y - bottom };
        case 'bottom-left':
          return { x: coord.x, y: coord.y - bottom };
        default:
          throw new UnreachableError(position.corner);
      }
    }
  }
}
