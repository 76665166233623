import classNames from 'classnames';
import * as React from 'react';
import styled, { css } from 'styled-components';
import * as colors from '@akst.io/web-resume-dom/ui/base/color/colors';
import * as fonts from '@akst.io/web-resume-dom/ui/base/typography/fonts';
import * as units from '@akst.io/web-resume-dom/ui/base/units/units';
import {
  UsePointerEventsConfig,
  usePointerEvents,
  EventType,
} from '@akst.io/web-resume-dom/ui/base/cross_platform/pointer_events';

export type ButtonEventType =
  | EventType.START
  | EventType.END
  | EventType.CLICK
  | EventType.ENTER
  | EventType.LEAVE
  | EventType.START_OUTSIDE
  | EventType.END_OUTSIDE;

type CommonButtonProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  passiveStates?: Map<ButtonEventType, boolean>;
} & Pick<
  UsePointerEventsConfig<HTMLButtonElement>,
  | 'onPointer'
  | 'onPointerEnter'
  | 'onPointerLeave'
  | 'onPointerEndOutside'
  | 'onPointerStartOutside'
  | 'onPointerEnd'
  | 'onPointerStart'
>;

export type ButtonProps =
    & CommonButtonProps
    & { ellipsis?: boolean };

export const Button = React.memo(function Button({
  onPointer,
  onPointerEndOutside,
  onPointerStartOutside,
  onPointerStart,
  onPointerEnd,
  passiveStates,
  children,
  disabled,
  ellipsis = false,
}: ButtonProps) {
  const setButtonRef = usePointerEvents({
    passiveStates,
    onPointer,
    onPointerEndOutside,
    onPointerStartOutside,
    onPointerStart,
    onPointerEnd,
    onPointerEnter: undefined,
    onPointerLeave: undefined,
  });

  return (
      <ButtonStyles
        ref={setButtonRef}
        ellipsis={ellipsis}
        disabled={disabled}
      >
        <ButtonInner disabled={disabled}/>
        {children}
      </ButtonStyles>
  );
});

const ButtonInner = styled.div<{
  disabled: boolean | undefined,
}>`
  content: ' ';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-style: solid;
  border-color: ${colors.borderGrey};
  border-width: ${units.smallestUnit}px;
  max-width: 100%;

  ${({ disabled }) => disabled && css`
    border-color: ${colors.borderGrey};
  `}
`;

const ButtonStyles = styled.button<{
  ellipsis: boolean,
}>`
  /* safari has this annoying default margin */
  margin: 0;
  /* prevents zoom on double tap */
  touch-action: manipulation;

  position: relative;
  display: inline-block;
  border: solid black ${units.smallestUnit}px;
  font-family: ${fonts.fontFamily};
  padding: ${units.baseUnit / 1.5}px ${units.baseUnit}px;
  background: ${colors.bgGrey};
  color: black;

  &:focus {
    outline: none;
    font-style: italic;
    text-decoration: underline;
    outline: none;
  }

  &:hover {
    background: #ececec;
  }

  &:active {
    background: #c6c6c6;
  }

  &:active > ${ButtonInner} {
    border-color: ${colors.borderGreyInverted};
  }

  &:disabled {
    background: ${colors.bgGrey};
    color: ${colors.blackA25};
  }

  ${({ ellipsis }) => ellipsis && css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    max-width: 100%;
  `}
`;

export const ChromelessButton = React.memo(function ChromelessButton({
  onPointer,
  onPointerEndOutside,
  onPointerStartOutside,
  onPointerStart,
  onPointerEnd,
  passiveStates,
  children,
  disabled,
}: CommonButtonProps) {
  const setButtonRef = usePointerEvents({
    passiveStates,
    onPointer,
    onPointerEndOutside,
    onPointerStartOutside,
    onPointerStart,
    onPointerEnd,
    onPointerEnter: undefined,
    onPointerLeave: undefined,
  });

  return (
      <ChromelessStyles
        ref={setButtonRef}
        disabled={disabled}
      >
        {children}
      </ChromelessStyles>
  );
});

const ChromelessStyles = styled.button`
  /* safari has this annoying default margin */
  margin: 0;
  /* prevents zoom on double tap */
  touch-action: manipulation;

  cursor: pointer;
  background: none;
  border: none;
  box-sizing: border-box;
  color: inherit;
  outline: none;
`;

export const LinkButton = React.memo(function ChromelessButton({
  onPointer,
  onPointerEndOutside,
  onPointerStartOutside,
  onPointerStart,
  onPointerEnd,
  passiveStates,
  children,
  disabled,
}: CommonButtonProps) {
  const setButtonRef = usePointerEvents({
    passiveStates,
    onPointer,
    onPointerEndOutside,
    onPointerStartOutside,
    onPointerStart,
    onPointerEnd,
    onPointerEnter: undefined,
    onPointerLeave: undefined,
  });

  return (
      <LinkStyles ref={setButtonRef} disabled={disabled}>
        {children}
      </LinkStyles>
  );
});

const LinkStyles = styled(ChromelessStyles)`
  padding: 0;
  color: ${colors.intenseBlue};

  &:hover {
    color: ${colors.retroRed};
  }

  &:hover:disabled,
  &:disabled {
    color: ${colors.black};
    opacity: 0.5;
  }
`;
