import * as React from 'react';
import styled from 'styled-components';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import {
  borderGrey,
  borderGreyInverted,
} from '@akst.io/web-resume-dom/ui/base/color/colors';
import * as Unit from '@akst.io/web-resume-dom/ui/base/units/units';

export type ImageProps = {
  url: string,
  width: Unit.Unit,
  height: Unit.Unit,
  loadingSpeed: number,
};

export function createImage({
  performance,
}: {
  performance: { now(): number },
}): React.ComponentType<ImageProps> {
  const now = () => performance.now();
  return (props: ImageProps) => (
      <Image {...props} now={now}/>
  );
}

export const Image = React.memo(({
  url,
  width: widthIn,
  height: heightIn,
  loadingSpeed,
  now,
}: ImageProps & {
  now: () => number,
}) => {
  const startTime = React.useRef(-1);

  if (startTime.current === -1) {
    startTime.current = performance.now();
  }

  const width = Unit.toString(widthIn);
  const height = Unit.toString(heightIn);

  return (
      <ImageRoot style={{ width, height }}>
        <ImageImage src={url}/>
      </ImageRoot>
  );
});

const ImageRoot = styled.div`
  border-style: solid;
  border-width: calc(${baseUnit}px / 8);
  border-color: ${borderGreyInverted};
`;

const ImageImage = styled.img`
  object-fit: cover;
  border-style: solid;
  border-width: calc(${baseUnit}px / 2);
  border-color: ${borderGrey};
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  display: block;
`;
