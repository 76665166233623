export async function loadFont(url: string, name: string): Promise<void> {
  const styleSheet = document.createElement('style');
  styleSheet.textContent = [
    '@font-face {',
    `font-family: '${name}';`,
    `src: url('${url}');`,
    '}',
  ].join('');
  document.head.appendChild(styleSheet);

  console.log(url, name);
  await (document as any).fonts.load(name, 'BESbswy');
}
