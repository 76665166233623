import * as React from 'react';
import { UnreachableError } from '@akst.io/lib/base/types';
import * as Resume from '@akst.io/lib/resume/types';
import { Button, LinkButton } from '@akst.io/web-resume-dom/ui/base/button/button';
import { Scrollable } from '@akst.io/web-resume-dom/ui/base/scrollable/scrollable';
import { StyleProvider, Normal } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { DragPositionInitHint } from '@akst.io/web-resume-dom/ui/system/draggable/types';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import { ResumeTab } from './tabs_presenter';
import {
  ContentContainer,
  ContentPadding,
  NavigationContainer,
  ResumeWindowContainer,
} from './tabs_styles';

type ResumeContentProps = {
  tab: ResumeTab,
  Loading: React.ComponentType,
  Experience: React.ComponentType,
  Volunteer: React.ComponentType,
  Education: React.ComponentType,
  Interests: React.ComponentType,
  References: React.ComponentType,
  Skills: React.ComponentType,
  Welcome: React.ComponentType,
};

export const ResumeContent = React.memo(({
  tab,
  Experience,
  Education,
  Interests,
  Loading,
  Skills,
  References,
  Volunteer,
  Welcome,
}: ResumeContentProps) => {
  let element: JSX.Element;
  switch (tab.kind) {
    case 'education':
      element = <Education/>;
      break;

    case 'experience':
      element = <Experience/>;
      break;

    case 'interests':
      element = <Interests/>;
      break;

    case 'references':
      element = <References/>;
      break;

    case 'skills':
      element = <Skills/>;
      break;

    case 'volunteer':
      element = <Volunteer/>;
      break;

    case 'welcome':
      element = <Welcome/>;
      break;

    default:
      throw new UnreachableError(tab);
  }

  return (
      <Scrollable key={tab.kind}>
        <ContentPadding>
          {element}
        </ContentPadding>
      </Scrollable>
  );
});

export const Navigation = React.memo(({
  onBack,
  breadCrumbs,
}: {
  breadCrumbs: readonly ResumeTab[],
  onBack: () => void,
}) => {
  const elements = useBreadCrumbs(breadCrumbs, onBack);
  const disabled = breadCrumbs.length < 2;
  const { length } = breadCrumbs;

  return (
      <NavigationContainer>
        <Button onPointer={onBack} disabled={disabled}>
          Back
        </Button>
        {elements}
      </NavigationContainer>
  );
});

export const BreadCrumb = React.memo(({
  depth,
  target,
  onBack,
}: {
  depth: number,
  target: ResumeTab,
  onBack: () => void,
}) => {
  const label = React.useMemo(() => {
    switch (target.kind) {
      case 'experience': return 'Experience';
      case 'education': return 'Education';
      case 'interests': return 'Interests';
      case 'skills': return 'Skills';
      case 'references': return 'References';
      case 'welcome': return 'Welcome';
      case 'volunteer': return 'Volunteer';
      default: throw new UnreachableError(target);
    }
  }, [target]);

  const onClick = React.useCallback(() => {
    for (let i = 0; i < depth; i++) onBack();
  }, [onBack, depth]);

  return (
    <LinkButton
        onPointer={onClick}
        disabled={depth === 0}
    >
        <Normal weight="bold" lineHeight="1">{label}</Normal>
      </LinkButton>
  );
});

export const ResumeWindow = React.memo(({
  Content,
  Navigation,
  onMinimize,
  Panel,
  position,
}: {
  Content: React.ComponentType,
  Navigation: React.ComponentType,
  onMinimize: () => void,
  Panel: React.ComponentType<PanelProps>,
  position: DragPositionInitHint,
}) => (
    <Panel
        title="Angus"
        onMinimize={onMinimize}
        initialPosition={position}
    >
      <ResumeWindowContainer>
        <Navigation/>
        <ContentContainer>
          <StyleProvider value="monospace">
            <Content/>
          </StyleProvider>
        </ContentContainer>
      </ResumeWindowContainer>
    </Panel>
));

function useBreadCrumbs(
  breadCrumbs: readonly ResumeTab[],
  onBack: () => void,
) {
  return React.useMemo(() => {
    const crumbs = breadCrumbs.map((target, index) => (
        <BreadCrumb
            key={index}
            target={target}
            onBack={onBack}
            depth={(breadCrumbs.length - 1) - index}
        />
    ));

    const spliced = [];
    spliced.push((
      <React.Fragment key={breadCrumbs[0].kind}>{crumbs[0]}</React.Fragment>
    ));

    for (let i = 1; i < crumbs.length; i++) {
      spliced.push(<Normal key={i}>{">"}</Normal>);
      spliced.push((
        <React.Fragment key={breadCrumbs[i].kind}>
          {crumbs[i]}
        </React.Fragment>
      ));
    }

    return spliced;
  }, [breadCrumbs, onBack]);
}
