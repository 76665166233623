import * as React from 'react';
import { descendComparison } from '@akst.io/lib/base/numbers';
import {
  Education as ResumeEducation,
  EducationData,
  Tenure,
} from '@akst.io/lib/resume/types';
import { formatEducationCert } from '@akst.io/lib/resume/format';
import { Spoiler } from '@akst.io/web-resume-dom/ui/base/spoiler/spoiler';
import { Small } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { Rows } from '../../../ui/layout/layout';
import { Markdown } from '../../../ui/markdown/markdown';
import {
  Heading,
  List,
  ListItem,
  Normal,
  SubHeading,
  SubSubHeading,
  SubHeadingWithTimeAndTagline,
} from '../../../ui/type/type';

const prioritySort = (l: { priority: number }, r: { priority: number }) => (
    descendComparison(l.priority, r.priority)
);

const Br = () => <><br/><br/></>;

export const Education = React.memo(({
  education,
}: {
  education: ResumeEducation,
}) => {
  const items = React.useMemo(() => {
    const sorted = education.data.sort(prioritySort);
    return sorted.map((education: EducationData, index: number) => {
      const {
        about,
        course: { cert, area, institution: inst },
        tenure,
      } = education;
      const certt = formatEducationCert({ cert });

      const institution = inst.long ?? inst.short;

      return (
          <React.Fragment key={index}>
            <SubHeadingWithTimeAndTagline
                tenure={tenure}
                tagline={area}
                subtagline={certt}
            >
              <Markdown markdown={institution} Br={Br}/>
            </SubHeadingWithTimeAndTagline>
            <Normal>
              <Markdown markdown={about} Br={Br}/>
            </Normal>
          </React.Fragment>
      );
    });
  }, [education]);

  return (
      <Rows gridTemplateColumns="unset">
        <Heading subHeading={undefined}>
          Education
        </Heading>
        {items}
      </Rows>
  );
});
