import { checkExists } from '@akst.io/lib/base/types';

export type Movement = -1 | 0 | 1;
export type Diff = [Movement, Movement];
export type Point = [number, number];

enum Direction {
  NORTH = 1,
  EAST = 2,
  SOUTH = 3,
  WEST = 4,
}

const directions = [
  Direction.NORTH,
  Direction.EAST,
  Direction.SOUTH,
  Direction.WEST,
];

function * clockWiseFrom(direction: Direction): Iterator<Direction> {
  const readStart: number = direction - 1;
  const length = directions.length;
  for (let i = 0; i < length; i++) {
    yield directions[(readStart + i) % length];
  }
}

function * counterClockWiseFrom(direction: Direction): Iterator<Direction> {
  const readStart: number = direction - 1;
  const length = directions.length;
  for (let i = 0; i < length; i++) {
    yield directions[((length + readStart) - i) % length];
  }
}

function leftDirection(direction: Direction): Direction {
  const readStart = direction - 1;
  const length = directions.length;
  return directions[((length + readStart) - 1) % length];
}

function rightDirection(direction: Direction): Direction {
  const readStart = direction - 1;
  const length = directions.length;
  return directions[(readStart + 1) % length];
}

function movePoint(point: Point, direction: Direction): Point {
  switch (direction) {
    case Direction.NORTH: return [point[0], point[1] - 1];
    case Direction.EAST: return [point[0] + 1, point[1]];
    case Direction.SOUTH: return [point[0], point[1] + 1];
    case Direction.WEST: return [point[0] - 1, point[1]];
  }
}

function pointEqual(a: Point, b: Point): boolean {
  return a[0] === b[0] && a[1] === b[1];
}

class Position {
  constructor(
      public x: number,
      public y: number,
      private direction: Direction,
  ) {
  }

  read<T>(buffer: ReadonlyArray<T>, width: number, height: number): T | undefined {
    const index = this.getIndex(width, height);
    return index == null ? undefined : buffer[index];
  }

  getIndex(width: number, height: number): number | undefined {
    if (this.x < 0 || this.x === width) return;
    if (this.y < 0 || this.y === height) return;
    return (this.y * width) + this.x;
  }

  get forward(): Position {
    switch (this.direction) {
      case Direction.NORTH: return this.north;
      case Direction.EAST: return this.east;
      case Direction.SOUTH: return this.south;
      case Direction.WEST: return this.west;
    }
  }

  get turnRight(): Position {
    return new Position(this.x, this.y, rightDirection(this.direction));
  }

  get turnLeft(): Position {
    return new Position(this.x, this.y, leftDirection(this.direction));
  }

  get north(): Position {
    return new Position(this.x, this.y - 1, this.direction);
  }

  get south(): Position {
    return new Position(this.x, this.y + 1, this.direction);
  }

  get west(): Position {
    return new Position(this.x - 1, this.y, this.direction);
  }

  get east(): Position {
    return new Position(this.x + 1, this.y, this.direction);
  }

  static createFromIndex(index: number, width: number, direction: Direction) {
    return new Position(
        (index % width),
        Math.floor(index / width),
        direction,
    );
  }
}

export function startLocation<T>(
    x: number,
    y: number,
    width: number,
    values: ReadonlyArray<T>,
): number {
  let index = (y * width) + x;
  const black = values[index];

  while (index % width !== 0) {
    const nextIndex = index - 1;
    if (values[nextIndex] !== black) break;
    index = nextIndex;
  }

  return index;
}

/**
 * Traces the boundaries of an object using the Theo Pavlidis algorithm.
 */
export function boundaryTrace<T>(
    x: number,
    y: number,
    width: number,
    height: number,
    values: ReadonlyArray<T>,
): [ReadonlyArray<Point>, ReadonlyArray<Point>] {
  const startIndex = startLocation(x, y, width, values);
  const trace: Point[] = [];
  const boundaries: Point[] = [];

  // These are positions of the boundaries of the object
  let boundaryPosition = Position.createFromIndex(startIndex, width, Direction.NORTH);

  // The trace position are point for generating a vector path that covers
  // the boundaries of the object, it moves independently from the boundaryPosition
  let tracePosition = Position.createFromIndex(startIndex, width, Direction.NORTH);

  // black doesn't really mean the color black here but the concept
  // of a blacked out square.
  const black = checkExists(
      boundaryPosition.read(values, width, height),
      'failed to read position'
  );

  outer: do {
    let rotation = 0;

    boundaries.push([boundaryPosition.x, boundaryPosition.y]);

    do {
      trace.push([tracePosition.x, tracePosition.y]);

      const p1 = boundaryPosition.forward.turnLeft.forward;
      const p1Value = p1.read(values, width, height);
      if (p1Value === black) {
        boundaryPosition = p1;
        tracePosition = tracePosition.turnLeft.forward;
        continue outer;
      }

      const p2 = boundaryPosition.forward;
      const p2Value = p2.read(values, width, height);
      if (p2Value === black) {
        boundaryPosition = p2;
        tracePosition = tracePosition.forward;
        continue outer;
      }

      const p3 = boundaryPosition.forward.turnRight;
      const p3Value = p3.read(values, width, height);
      if (p3Value === black) {
        boundaryPosition = p3;
        tracePosition = tracePosition.turnRight.forward;
        continue outer;
      }

      boundaryPosition = boundaryPosition.turnRight;
      tracePosition = tracePosition.turnRight.forward;
    } while (++rotation < 3);
  } while (boundaryPosition.getIndex(width, height) != startIndex);

  trace.push([tracePosition.x, tracePosition.y]);

  return [trace, boundaries];
}

export function boundaries<T>(
    x: number,
    y: number,
    width: number,
    height: number,
    values: ReadonlyArray<T>,
): ReadonlyArray<Point> {
  return boundaryTrace(x, y, width, height, values)[1];
}

export function trace<T>(
    x: number,
    y: number,
    width: number,
    height: number,
    values: ReadonlyArray<T>,
): ReadonlyArray<Point> {
  return boundaryTrace(x, y, width, height, values)[0];
}
