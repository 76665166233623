import * as t from './types';

type Proficiency = { level: number, symbol: string, label: string };

export const skillProficiency: Record<t.Proficiency, Proficiency> = {
  [1]: {
    level: 1,
    symbol: '👟',
    label: 'Begininer',
  },
  [2]: {
    level: 2,
    symbol: '🚲',
    label: 'Not bad',
  },
  [3]: {
    level: 3,
    symbol: '🚗',
    label: 'Intermediate',
  },
  [4]: {
    level: 4,
    symbol: '✈️',
    label: 'Good',
  },
  [5]: {
    level: 5,
    symbol: '🚀',
    label: 'Great',
  },
};
