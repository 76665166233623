import { AssetService } from '@akst.io/web-resume-dom/services/asset/asset_service';
import { AudioService } from '@akst.io/web-resume-dom/services/audio/audio_service';
import { SpeechService } from '@akst.io/web-resume-dom/services/speech/speech_service';
import { createComponent } from './startup_step';
import { StartupStepPresenter } from './startup_step_presenter';
import { StepNode } from '../types';

type CreateOptions = {
  assetService: AssetService;
  audioService: AudioService;
  speechService: SpeechService;
};

export function createStartupStep(options: CreateOptions): StepNode {
  const presenter = new StartupStepPresenter(
      options.assetService,
      options.audioService,
      options.speechService,
  );
  const store = presenter.createStore();
  return {
    id: 'startup',
    Component: createComponent({
      store,
      presenter,
    }),
    controller: {
      async start() {
        await presenter.start(store);
      }
    },
  };
}
