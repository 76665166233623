import styled from 'styled-components';
import { borderGreyInverted, white } from '@akst.io/web-resume-dom/ui/base/color/colors';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';

export const ResumeWindowContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: min(90vw, ${baseUnit * 76}px);
  grid-gap: ${baseUnit / 2}px;
`;

export const NavigationContainer = styled.div`
  display: grid;
  grid-gap: ${baseUnit}px;
  justify-items: flex-start;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: baseline;
  padding-right: ${baseUnit * 2}px;
`;

export const ContentContainer = styled.div`
  background-color: ${white};
  border-width: ${baseUnit / 2}px;
  border-style: solid;
  border-color: ${borderGreyInverted};
  max-height: 60vh;
  overflow-y: hidden;
  display: grid;
  grid-template-rows: 1fr;
`;

export const ContentPadding = styled.div`
  padding: ${baseUnit}px;
  overflow-y: hidden;
`;

