import * as React from 'react';
import { LiveFileSystemNode } from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import { FileSpriteProps } from '@akst.io/web-resume-dom/ui/system/file_sprite/file_sprite';
import { Normal } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { Button, ButtonProps } from '@akst.io/web-resume-dom/ui/base/button/button';
import {
  Toolbar,
  ToolbarButtonText,
  ToolbarControls,
  ToolbarLeft,
  ToolbarSprite,
  ToolbarText,
} from './styles';

type ExternallyInjectProps = {
  FileSprite: React.ComponentType<FileSpriteProps>;
}

type InternallyInjectedProps = {
  Button: React.ComponentType<ButtonProps>;
};

export type PanelToolBarProps = {
  file?: LiveFileSystemNode;
  title: string;
  width?: number;
  onMinimize?(): void;
  onClose?(): void;
};

export type PanelToolBarImplProps =
  & ExternallyInjectProps
  & InternallyInjectedProps
  & PanelToolBarProps;

type OnPointer = ButtonProps['onPointer'];

export const PanelToolBarImpl = React.memo(function PanelToolBarImpl(props: PanelToolBarImplProps) {
  const {
    file,
    FileSprite,
    onMinimize,
    onClose,
    title,
    width,
  } = props;

  function renderButton(content: string, callback?: OnPointer) {
    return (
        <Button onPointer={callback} disabled={!callback}>
          <ToolbarButtonText>{content}</ToolbarButtonText>
        </Button>
    );
  }

  return (
      <Toolbar style={{ maxWidth: width }}>
        <ToolbarLeft title={title}>
          {file && (
              <ToolbarSprite>
                <FileSprite fileSystemNode={file} sizing="fill-parent"/>
              </ToolbarSprite>
          )}
          <ToolbarText>
            <Normal weight="bold" ellipsis={true}>{title}</Normal>
          </ToolbarText>
        </ToolbarLeft>
        <ToolbarControls>
          {renderButton('—', onMinimize)}
          {renderButton('𝗫', onClose)}
        </ToolbarControls>
      </Toolbar>
  );
});

export function createPanelToolBar(options: ExternallyInjectProps): React.ComponentType<PanelToolBarProps> {
  return (props: PanelToolBarProps) => (
      <PanelToolBarImpl
          Button={Button}
          {...options}
          {...props}
      />
  );
}
