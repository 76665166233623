import { checkExists } from '@akst.io/lib/base/types';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { CurrentView } from './flow_presenter';
import {
  StepProps,
  TransitionProgress,
  TransitionState,
} from './steps/types';

export type FlowProps = {
  transitionProgress: TransitionProgress,
  view: CurrentView,
  stepId: string;
  onStepTransition: (id: string, ms?: number) => void;
  transitioningTo?: string,
};

export const Flow = React.memo(({
  transitionProgress,
  stepId,
  view,
  onStepTransition,
  transitioningTo,
}: FlowProps) => {
  const exitTransition = React.useMemo((): TransitionState => {
    return { type: 'exiting', transitionProgress };
  }, [transitionProgress]);

  const enterTransition = React.useMemo((): TransitionState => {
    return { type: 'entering', transitionProgress };
  }, [transitionProgress]);

  /*
   * Note I know this looks like shit, but I took the
   * fastest route to refactoring based on the previous
   * shit code.
   */
  const step = React.useMemo(() => {
    switch (view.type) {
      case 'show':
        return (
            <>
              <div key={stepId}>
                <view.View
                    transitionState={{ type: "active" }}
                    onStepTransition={onStepTransition}
                />
              </div>
            </>
        );

      case 'transition': {
        const t = checkExists(transitioningTo, 'transition');
        return (
            <>
              <div key={t} style={{ zIndex: 1 }}>
                <view.To
                  transitionState={enterTransition}
                  onStepTransition={onStepTransition}
                />
              </div>
              <div key={stepId} style={{
                zIndex: 2,
                position: 'absolute',
                pointerEvents: 'none',
                top: 0,
                left: 0,
              }}>
                <view.From
                  transitionState={exitTransition}
                  onStepTransition={onStepTransition}
                />
              </div>
            </>
        );
      }
    }
  }, [
    view,
    transitioningTo,
    onStepTransition,
    enterTransition,
    exitTransition,
  ]);

  return (
    <div style={{ position: 'relative' }}>
      {step}
    </div>
  );
});
