import classNames from 'classnames';
import * as React from 'react';
import { isTouchDevice } from '@akst.io/web-resume-dom/ui/base/cross_platform/device_capabilities';
import { usePointerEvents } from '@akst.io/web-resume-dom/ui/base/cross_platform/pointer_events';
import { Normal } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import {
  FlyoutMenu,
  FlyoutMenuItem,
  Root,
  TopLevelNode,
  TopLevelNodeWrapper,
} from './styles';

export type TreeNodeCommon = {
  label: string;
  disabled?: boolean;
};

export type TreeNodeMenu = (TreeNodeCommon & { type: 'menu', options: ReadonlyArray<TreeNode> });
export type TreeNodeAction = (TreeNodeCommon & { type: 'action', onClick(): void; });

export type TreeNode = TreeNodeMenu | TreeNodeAction;

export type TreeMenuProps = {
  tree: ReadonlyArray<TreeNodeMenu>;
};

export const TreeMenu = React.memo(function TreeMenu(props: TreeMenuProps) {
  const { tree } = props;
  const [activeLabel, setActiveLabel] = React.useState<string | undefined>(undefined);

  const onExit = React.useCallback(() => setActiveLabel(undefined), [setActiveLabel]);

  const items = React.useMemo(() => (
      tree.map(treeNode => (
          <TopLevelMenu
              key={treeNode.label}
              label={treeNode.label}
              isActive={activeLabel === treeNode.label}
              options={treeNode.options}
              onEnter={setActiveLabel}
              onExit={onExit}
          />
      ))
  ), [
    activeLabel,
    onExit,
    setActiveLabel,
    tree,
  ]);

  return (
      <Root>{items}</Root>
  );
});

type TopLevelMenuProps = {
  label: string;
  isActive: boolean;
  options: ReadonlyArray<TreeNode>;
  onEnter(label: string): void;
  onExit(): void;
};

const TopLevelMenu = React.memo(function TopLevelMenu(props: TopLevelMenuProps) {
  const {
    label,
    onEnter,
    onExit,
    options,
    isActive,
  } = props;

  const onPointer = React.useCallback(() => {
    isActive ? onExit() : onEnter(label);
  }, [
    label,
    isActive,
    onExit,
    onEnter,
  ]);

  const setRef = usePointerEvents<HTMLDivElement>({ onPointer });

  return (
    <TopLevelNodeWrapper>
      <TopLevelNode ref={setRef}>
        <Normal lineHeight="1">{label}</Normal>
      </TopLevelNode>
      {isActive && !!options.length && (
          <TreeMenuFlyout
              options={options}
              direction="down"
              onLeave={onExit}
          />
      )}
    </TopLevelNodeWrapper>
  );
});

type FlyoutDirection = 'right' | 'down';

type TreeMenuFlyoutProps = {
  options: ReadonlyArray<TreeNode>;
  direction: FlyoutDirection;
  onLeave(): void;
};

const TreeMenuFlyout = React.memo(function TreeMenuFlyout(props: TreeMenuFlyoutProps) {
  const { options, direction, onLeave } = props;

  const [hasEntered, setHasEntered] = React.useState<boolean>(false);
  const [activeOption, setActiveOption] = React.useState<string | undefined>(undefined);

  const onLeaveSubOption = React.useCallback(() => setActiveOption(undefined), [setActiveOption]);

  const renderSubMenu = (options: ReadonlyArray<TreeNode>, label: string) => {
    const onEnter = () => setActiveOption(label);
    const onLeave = () => setActiveOption(undefined);
    return (
        <FlyoutMenuItem
            key={label}
            onClick={onEnter}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
          <Normal>{label}</Normal>
        </FlyoutMenuItem>
    );
  };

  const renderAction = (node: TreeNodeAction) => (
      <Action key={node.label} onClick={node.onClick} onLeave={onLeave} label={node.label}/>
  );

  const items = React.useMemo(() => (
      options.map(option => (
          option.type === 'action'
              ? renderAction(option)
              : renderSubMenu(option.options, option.label)
      ))
  ), [options]);

  /*
   * On touch devices if you touch outside close the menu.
   *
   * On non-touch, if the mouse enters & leaves hides it.
   */
  const onMouseEnter = React.useCallback(() => {
    setHasEntered(true);
  }, [setHasEntered]);

  const onMouseLeave = React.useCallback(() => {
    hasEntered && onLeave();
  }, [hasEntered]);

  const setReference = isTouchDevice()
      // eslint-disable-next-line react-hooks/rules-of-hooks
      ? usePointerEvents<HTMLDivElement>({ onPointerStartOutside: onLeave })
      // eslint-disable-next-line react-hooks/rules-of-hooks
      : React.useCallback(() => null, []);

  return (
      <FlyoutMenu
          $direction={direction}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          ref={setReference}
      >
        {items}
      </FlyoutMenu>
  );
});

type ActionProps = {
  onClick(): void;
  onLeave(): void;
  label: string;
};

const Action = React.memo(function Action(props: ActionProps) {
  const { onClick: onClickImpl, label, onLeave } = props

  const onClick = React.useCallback(() => {
    onClickImpl();
    onLeave();
  }, [onClickImpl, onLeave]);

  return (
      <FlyoutMenuItem onClick={onClick}>
        <Normal>{label}</Normal>
      </FlyoutMenuItem>
  );
});
