import styled from 'styled-components';
import {
  retroRed,
  retroPurple,
  white,
} from '@akst.io/web-resume-dom/ui/base/color/colors';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';

export const Sorry = styled.div`
  padding: ${baseUnit * 2}px;
  color: white;
  text-align: center;
  background-image:
      linear-gradient(
          to bottom right,
          ${retroRed},
          ${retroPurple});
`;

export const ResumeLink = styled.a`
  color: ${white};
`;

export const LayoutNarrow = styled.div`
`;

export const LayoutWide = styled.div`
  display: grid;
  grid-gap: ${baseUnit * 2}px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
`;

export const List = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  display: grid;
  grid-gap: ${baseUnit}px;
  list-style: none;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
`;
