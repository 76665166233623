import * as React from 'react';
import { LocalApplicationStarter } from '@akst.io/web-resume-dom/services/application/types';
import { ApplicationService } from "@akst.io/web-resume-dom/services/application/application_service";
import { AudioService } from '@akst.io/web-resume-dom/services/audio/audio_service';
import { FileSystemService } from "@akst.io/web-resume-dom/services/file_system/file_system_service";
import {
  isLiveFile,
  LiveFileSystemNode,
} from "@akst.io/web-resume-dom/services/file_system/live_files_system_node";
import { SpriteService } from '@akst.io/web-resume-dom/services/sprite/sprite_service';
import { unsafeSpriteCast } from '@akst.io/web-resume-dom/services/sprite/types';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import sprite from '@akst.io/web-resume-dom/ui/sprites/image_sprite.json';
import {
  DocViewerPresenter,
  DocViewerStore,
} from './doc_viewer_presenter'

type CreateOptions = {
  applicationService: ApplicationService;
  audioService: AudioService;
  fileSystemService: FileSystemService;
  spriteService: SpriteService;
  Panel: React.ComponentType<PanelProps>;
};

const applicationId = 'doc_viewer';
const FILE_EXTENSION = 'doc';

export function installDocViewer({
  applicationService,
  audioService,
  fileSystemService,
  spriteService,
  Panel,
}: CreateOptions) {
  const presenter = new DocViewerPresenter();

  const withFile: LocalApplicationStarter = (file: LiveFileSystemNode | undefined, procId: number) => {
    if (!file || !isLiveFile(file)) {
      return { ok: false, error: { type: 'incorrect-file-type', fileType: 'file' } };
    }
    return { ok: false, error: { type: 'incorrect-file-type', fileType: 'file' } };
  };

  spriteService.register(
      FILE_EXTENSION,
      'DocViewer.sprite',
      unsafeSpriteCast(sprite),
  );
  applicationService.register(
      FILE_EXTENSION,
      { initializer: { type: 'local', withFile } },
      ['programs', 'DocViewer.exe'],
  );
}
