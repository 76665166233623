import * as React from 'react';
import styled from 'styled-components';

import * as Resume from '@akst.io/lib/resume/types';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import {
  SkillList,
  SkillItem,
  useSkills,
} from '../skills/skills';
import {
  SubSubHeading,
} from '../../../ui/type/type';

export type ExperienceSkillsProps = {
  skills: readonly Resume.ExperinceDataSkill[],
};

export const ExperienceSkills = React.memo(({
  skills,
  allSkills: skill,
}: ExperienceSkillsProps & {
  allSkills: Resume.Skill,
}) => {
  // TODO lift in to parent
  const annonated = useSkills({ skill });

  const relevantSkills = React.useMemo(() => {
    const ids = new Set(skills.map(s => s.id));
    return annonated.filter(s => ids.has(s.id));
  }, [annonated, skills]);

  return (
    <Skills>
      <SubSubHeading>Skills</SubSubHeading>
      <SkillList>
        {relevantSkills.map(s => (
          <SkillItem shadow={s.color} padding=".4rem">
            {s.icon} {s.skill.label ?? s.skill.id}
          </SkillItem>
        ))}
      </SkillList>
    </Skills>
  );
});

const Skills = styled.div`
  display: grid;
  padding: 0 ${baseUnit}px;
  grid-gap: ${baseUnit * 2}px;
`;
