import * as React from 'react';
import styled from 'styled-components';
import { Button, LinkButton } from '@akst.io/web-resume-dom/ui/base/button/button';
import * as colors from '@akst.io/web-resume-dom/ui/base/color/colors';
import { Normal } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { ResumeTab } from '@akst.io/web-resume-dom/ui/application/resume_viewer/tabs/tabs_presenter';

export type LinkProps = {
  children: any,
  target: Readonly<ResumeTab>,
  style: 'chromeless' | 'link',
};

export const Link = React.memo(({
  children,
  target,
  onNavigateClick,
  style,
}: LinkProps & {
  onNavigateClick: (item: ResumeTab) => void,
}) => {
  const onClick = React.useCallback(() => {
    onNavigateClick(target);
  }, []);

  const Container = style === 'link'
      ? LinkButton
      : Button;

  const textDecoration = style === 'link' ? 'underline' : undefined;

  return (
    <Container
        onPointer={onClick}
        disabled={false}
    >
      <Normal
          weight="bold"
          decoration={textDecoration}
      >
        {children}
      </Normal>
    </Container>
  );
});

export const ExternalLink = React.memo(({
  href,
  children,
}: {
  href: string,
  children: any,
}) => {
  return (
    <ExternalLinkStyle href={href} target="_blank">
      {children}
    </ExternalLinkStyle>
  );
});

const ExternalLinkStyle = styled.a`
  cursor: pointer;
  padding: 0;
  color: ${colors.intenseBlue};

  &:hover {
    color: ${colors.retroRed};
  }

  &:hover:disabled,
  &:disabled {
    color: ${colors.black};
    opacity: 0.5;
  }
`;
