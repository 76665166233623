import * as React from 'react';
import { observer } from 'mobx-react';
import {
  CrossPlatformEvent,
  usePointerEventsOnElement,
  useWindowPointerEvents,
} from '@akst.io/web-resume-dom/ui/base/cross_platform/pointer_events';
import { baseUnit } from "@akst.io/web-resume-dom/ui/base/units/units";
import { BufferStore, BufferPresenter } from '@akst.io/web-resume-dom/ui/application/sprite_maker/buffer/buffer_presenter';
import { ColorStore } from '../color/color_presenter';
import { ControlsStore } from '../controls/controls_presenter';
import { useAnimation as useAnimationImpl } from './use_animation/use_animation';
import { Canvas as CanvasImpl, CanvasProps } from './canvas';
import {
  ContentRectBounds,
  CanvasPresenter,
  CanvasStore,
} from './canvas_presenter';

export function createCanvas(
    bufferStore: BufferStore,
    bufferPresenter: BufferPresenter,
    colorStore: ColorStore,
    controlsStore: ControlsStore,
): [CanvasStore, React.ComponentType] {
  const store = new CanvasStore(bufferStore, controlsStore, colorStore, baseUnit);
  const presenter = new CanvasPresenter(bufferPresenter);

  const onResize = (canvas?: HTMLCanvasElement, bounds?: ContentRectBounds) => {
    presenter.onResize(store, bounds, canvas);
  };

  const onCanvasPointerStart = (event: CrossPlatformEvent<HTMLCanvasElement>) => {
    presenter.onCanvasPointerStart(store, event);
  };

  const onCanvasPointerMove = (event: CrossPlatformEvent<HTMLCanvasElement>) => {
    presenter.onCanvasPointerMove(store, event);
  };

  const onCanvasPointerEnd = () => {
    presenter.onCanvasPointerEnd(store);
  };

  const useAnimation = (canvas?: HTMLCanvasElement) => useAnimationImpl(store, canvas);

  const Canvas = observer(() => (
      <CanvasImpl
          onResize={onResize}
          onCanvasPointerMove={onCanvasPointerMove}
          onCanvasPointerStart={onCanvasPointerStart}
          onCanvasPointerEnd={onCanvasPointerEnd}
          useAnimation={useAnimation}
      />
  ));

  return [store, Canvas];
}
