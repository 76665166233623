import * as React from 'react';
import * as t from '@akst.io/lib/resume/types';
import { UnreachableError } from '@akst.io/lib/base/types';
import { formatMarkdown2 } from '@akst.io/lib/resume/markdown';
import styled from 'styled-components';

const Strong = styled.strong`
  font-weight: 700;
  color: rgb(102, 57, 255);
`;

const Em = styled.em`
  color: #e528ff;
`;

export const Markdown = React.memo(({
  Br,
  markdown,
}: {
  Br: React.ComponentType,
  markdown: t.Markdown
}) => {
  const chunks = React.useMemo<readonly JSX.Element[]>(() => {
    const items = formatMarkdown2<JSX.Element>({
      desc: markdown.desc,
      links: markdown.links,
      createSeperator: () => <Br/>,
      createLink: (t, link) => <a href={link}>{t}</a>,
      createText: t => {
        switch (t.kind) {
          case 'strong': return <Strong>{t.t}</Strong>;
          case 'em': return <Em>{t.t}</Em>;
          case 'text': return <>{t.t}</>;
          default: throw new UnreachableError(t);
        }
      },
    });

    return items.map((it, key) => (
        <React.Fragment key={key}>{it}</React.Fragment>
    ));
  }, [markdown]);

  return <>{chunks}</>;
});
