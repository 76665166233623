import styled from 'styled-components';
import { borderGreyInverted } from '@akst.io/web-resume-dom/ui/base/color/colors';
import { baseUnit, smallestUnit } from '@akst.io/web-resume-dom/ui/base/units/units';

export const LayoutRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LayoutCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CanvasWrapper = styled.div`
  border-color: ${borderGreyInverted};
  border-width: ${smallestUnit};
  border-style: solid;
  display: inline-block;
  box-sizing: content-box;
`;

export const ColorsContainer = styled.div`
  position: relative;
  padding: ${baseUnit}px;
`;
