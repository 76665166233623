export const descendComparison = (l: number, r: number) => r - l;

export const ascendComparison = (l: number, r: number) => l - r;

export function incrementer(options: { start?: number, step?: number } = {}): () => number {
  const { start = 1, step = 1 } = options;
  let value = start;

  return () => {
    const a = value;
    value += step;
    return a;
  };
}

