import * as React from 'react';
import { reaction } from 'mobx';
import { checkExists } from '@akst.io/lib/base/types';
import { hexAsString } from '@akst.io/web-resume-dom/ui/base/color/color';
import { CanvasStore } from '../canvas_presenter';

type Cancel = () => void;
type CanvasRef = React.MutableRefObject<HTMLCanvasElement | null>;

/**
 * This is the magic powering the canvas, there are a couple of
 * layers to the way it renders content, but overall it's still
 * quite simple.
 *
 * ## The Transparency Layer
 *
 * This is basically a checker layer of white & grey squares,
 * it has that photoshop aethetic.
 *
 * ## The Content Layer
 *
 * The content layer, the simple
 */
export function animation(store: CanvasStore, canvas?: HTMLCanvasElement): Cancel {
  let animationFrame = 0;

  if (canvas == null) {
    return () => undefined;
  }

  const white = '#fff';
  const grey = '#ccc';
  const ctx = checkExists(canvas.getContext('2d'), 'no canvas context');
  const { bufferStore: bStore } = store;

  function tFill(x: number, y: number) {
    const xOdd = x % 2 == 1;
    const yOdd = y % 2 == 1;
    return xOdd
        ? (yOdd ? white : grey)
        : (yOdd ? grey : white);
  }

  function renderFrame() {
    const { width, height } = store;
    if (width == null || height == null) {
      return requestAnimationFrame(renderFrame);
    }

    const tCols = width / store.transparentGridSize;
    const tRows = height / store.transparentGridSize;

    // draws the transparency background

    for (let iC = 0; iC < tCols; iC++) {
      for (let iR = 0; iR < tRows; iR++) {
        const x = iC * store.transparentGridSize;
        const y = iR * store.transparentGridSize;
        ctx.fillStyle = tFill(iC, iR);
        ctx.fillRect(x, y, store.transparentGridSize, store.transparentGridSize);
      }
    }

    // draws the sprite

    for (let yGrid = 0; yGrid < bStore.gridHeight; yGrid++) {
      for (let xGrid = 0; xGrid < bStore.gridWidth; xGrid++) {
        const readIndex = (yGrid * bStore.gridWidth) + xGrid;
        const fillValue = bStore.buffer[readIndex];
        if (fillValue == null) continue;

        const x = xGrid * store.gridSlotWidth;
        const y = yGrid * store.gridSlotHeight;
        ctx.fillStyle = hexAsString(fillValue);

        // the plus one just makes it so 2 squares next to each
        // with the same color don't have that awkward transparent
        // line between them.
        ctx.fillRect(x, y, store.gridSlotWidth + 1, store.gridSlotHeight + 1);
      }
    }
  }

  const cancleReaction = reaction(
      () => store.paintId,
      () => {
        cancelAnimationFrame(animationFrame);
        animationFrame = requestAnimationFrame(renderFrame);
      },
      { fireImmediately: true },
  );

  return () => {
    cancelAnimationFrame(animationFrame)
    cancleReaction();
  };
}

export function useAnimation(store: CanvasStore, canvas?: HTMLCanvasElement) {
  React.useEffect(() => animation(store, canvas), [store, canvas]);
}
