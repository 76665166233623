export type Rect = {
  x: number;
  y: number;
  width: number;
  height: number;
  fill: string;
};

const asFill = (color: number) => `#${color.toString(16).padStart(6, '0')}`;

export function createRects(fills: ReadonlyArray<number | undefined | null>, width: number, height: number) {
  const rects: Readonly<Rect>[] = [];

  const pushRect = (options: { x: number, y: number, width: number, fill: number }) => {
    const { x, y, width, fill: fillNumber } = options;
    const fill = asFill(fillNumber);
    rects.push({ fill, x, y, width, height: 1 });
  };

  for (let y = 0; y < height; y++) {
    const yReadOffset = y * width;
    let lastDiffX = 0;

    for (let x = 0; x < width; x++) {
      const readColor = fills[yReadOffset + x];
      const lastColor = fills[yReadOffset + lastDiffX];

      // when the same color continue;
      if (readColor === lastColor) continue;

      if (lastColor != undefined) {
        const w = x - lastDiffX;
        pushRect({ x: lastDiffX, y, width: w, fill: lastColor });
      }

      lastDiffX = x;
    }

    const lastColor = fills[yReadOffset + lastDiffX];
    if (lastColor != undefined) {
      const w = width - lastDiffX;
      pushRect({ x: lastDiffX, y, width: w, fill: lastColor });
    }
  }

  return rects;
}

export function renderRects(options: {
  context: CanvasRenderingContext2D,
  rects: ReadonlyArray<Readonly<Rect>>,
  scaleWidth: number,
  scaleHeight: number,
}) {
  const { context, rects, scaleWidth, scaleHeight } = options;

  for (let i = 0; i < rects.length; i++) {
    const rect = rects[i];

    context.fillStyle = rect.fill;
    context.fillRect(
        rect.x * scaleWidth,
        rect.y * scaleHeight,
        (rect.width * scaleWidth) + 0.5,
        (rect.height * scaleHeight) + 0.5,
    );
  }
}
