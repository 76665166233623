import { createAtom, action, observable, makeObservable } from 'mobx';
import * as Result from '@akst.io/lib/base/result';
import { parseToml } from '@akst.io/lib/base/toml';
import * as Ajax from '@akst.io/web-base/ajax_singleton';
import * as Resume from '@akst.io/lib/resume/types';
import { isResume } from '@akst.io/lib/resume/validate';
import { ApplicationService } from '@akst.io/web-resume-dom/services/application/application_service';
import { AudioService } from '@akst.io/web-resume-dom/services/audio/audio_service';
import { DeviceService } from '@akst.io/web-resume-dom/services/device/device_service';
import { ResourceService } from '@akst.io/web-resume-dom/services/resource/resource_service';
import { AssistantController } from '@akst.io/web-resume-dom/ui/application/assistant/type';
import { DragPositionInitHint } from '@akst.io/web-resume-dom/ui/system/draggable/types';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';

export type ResumeTab =
  | { kind: 'experience' }
  | { kind: 'volunteer' }
  | { kind: 'education' }
  | { kind: 'interests' }
  | { kind: 'references' }
  | { kind: 'skills' }
  | { kind: 'welcome' };


export class TabsStore {
  procId: number | undefined = undefined;
  readonly pagesMutations = createAtom("Page Mutations");
  readonly pages: Array<ResumeTab> = observable.array([{ kind: 'welcome' }]);
  resume: Ajax.T<Resume.Resume> = Ajax.Init();

  constructor() {
    makeObservable(this, {
      resume: observable.ref
    });
  }
}

export type OnStartResult =
  | { kind: 'already-running', procId: number }
  | { kind: 'ok' };

export class TabsPresenter {
  constructor(
      private readonly applicationService: ApplicationService,
      private readonly assistantController: AssistantController,
      private readonly audioService: AudioService,
      private readonly deviceService: DeviceService,
      private readonly resourceService: ResourceService,
      private readonly resumeUrl: string,
      private readonly wait: (ms: number) => Promise<void>,
  ) {
    makeObservable<TabsPresenter, "setResume">(this, {
      quit: action,
      goBack: action,
      navigateTo: action,
      setResume: action
    });
  }

  getInitPosition(): DragPositionInitHint {
    const size = this.deviceService.getDisplaySize();
    const x = Math.min(size.x * 0.9, baseUnit * 76);
    const y = size.y * 0.9;
    return { kind: 'with-content-size', size: { x, y } };
  }

  onStart(store: TabsStore, procId: number): OnStartResult {
    if (store.procId != null) {
      return { kind: 'already-running', procId: store.procId };
    }

    if (this.deviceService.hasTouchScreen()) {
      this.handleMobileBehaviour();
    }

    this.loadResume(store);
    this.audioService.playSound('audio/windows_ding');
    store.procId = procId;
    return { kind: 'ok' };
  }

  private async loadResume(store: TabsStore) {
    const started = Ajax.start(store.resume, async () => {
      const query = { id: { url: this.resumeUrl, kind: 'unicode' } } as const;
      const result = await this.resourceService.findResource({ query });
      if (!result.ok) return Result.Err(undefined);
      if (result.value.kind !== 'unicode') return Result.Err(undefined);
      return getResult(result.value.data);
    });

    this.setResume(store, started);

    const [[finished]] = await Promise.all([
      Ajax.toResult(started),
      this.wait(4000),
    ]);

    this.setResume(store, finished);
  }

  quit(store: TabsStore) {
    if (store.procId == null) return;
    while (store.pages.length > 1) store.pages.pop();
    this.applicationService.stopProc(store.procId);
    store.procId = undefined;
  }


  minimize(store: TabsStore) {
    if (store.procId == null) return;
    this.applicationService.toggleMinization(store.procId);
  }

  goBack(store: TabsStore) {
    if (this.canGoBack(store)) {
      store.pages.pop()
      store.pagesMutations.reportChanged();
    }
  }

  navigateTo(store: TabsStore, tab: ResumeTab) {
    if (store.resume.kind !== 'value') return;

    store.pages.push(tab);
    store.pagesMutations.reportChanged();
  }

  getBreadCrumbs(store: TabsStore): readonly ResumeTab[] {
    store.pagesMutations.reportObserved();
    return [...store.pages];
  }

  canGoBack(store: TabsStore): boolean {
    store.pagesMutations.reportObserved();
    return store.pages.length > 1;
  }

  getCurrentPage(store: TabsStore): ResumeTab {
    store.pagesMutations.reportObserved();
    return store.pages[store.pages.length - 1];
  }

  private setResume(store: TabsStore, resume: Ajax.T<Resume.Resume>) {
    store.resume = resume;
  }

  private async handleMobileBehaviour() {
    await this.wait(1000);

    const maybeAssistant = this.assistantController.findOrCreateAnAssistant();
    if (!maybeAssistant.ok) return;

    await this.wait(2000);
    this.assistantController.queueAssistantExpression({
      id: maybeAssistant.value,
      expression: {
        kind: 'animation',
        animation: 'get-attention',
        message: `
            Hi. Looks like you\'re trying to view
            a resumé on a mobile device. View on
            Desktop for the best experience, or
            download the pdf.
        `,
      },
    });
  }
}

function getResult(input: string): Result.T<Resume.Resume, undefined> {
  try {
    const resume = parseToml(input);
    if (!isResume(resume)) return Result.Err(undefined);
    return Result.Ok(resume);
  } catch (e) {
    return Result.Err(undefined);
  }
}
