export type FontLoader = (url: string, name: string) => Promise<void>;
export type Fetch = typeof fetch;

export type ImageAsset =
  { type: 'image', image: HTMLImageElement };

export type VideoAsset =
  { type: 'video' };

export type AudioAsset =
  { type: 'audio', buffer: AudioBuffer };

export type FontAsset =
  { type: 'font', name: string };

export type FontAssetId = never;

export type ImageAssetId =
  | 'image/assistant/bonzi'
  | 'image/assistant/clippy'
  | 'image/windows_logo'
  | 'image/icon_chip'
  | 'image/icon_computer'
  | 'image/icon_exe'
  | 'image/icon_text'
  | 'image/icon_word'
  | 'image/icon_folder'
  | 'image/meme_1'
  | 'image/meme_2'
  | 'image/meme_3'
  | 'image/meme_4'
  | 'image/meme_5'
  | 'image/meme_6'
  | 'image/meme_7'
  | 'image/meme_8'
  | 'image/meme_9'
  | 'image/meme_10'
  | 'image/meme_11'
  | 'image/meme_12'
  | 'image/meme_13'
  | 'image/meme_14'
  | 'image/meme_15';

export type VideoAssetId =
  | 'video/meme_1'
  | 'video/meme_2'
  | 'video/meme_3'
  | 'video/meme_4'
  | 'video/meme_5'
  | 'video/meme_6';

export type AudioAssetId =
  | 'audio/assistant/bonzi/1'
  | 'audio/assistant/bonzi/2'
  | 'audio/assistant/bonzi/3'
  | 'audio/assistant/bonzi/4'
  | 'audio/assistant/bonzi/5'
  | 'audio/assistant/bonzi/6'
  | 'audio/assistant/bonzi/7'
  | 'audio/assistant/clippy/1'
  | 'audio/assistant/clippy/2'
  | 'audio/assistant/clippy/3'
  | 'audio/assistant/clippy/4'
  | 'audio/assistant/clippy/5'
  | 'audio/assistant/clippy/6'
  | 'audio/assistant/clippy/7'
  | 'audio/assistant/clippy/8'
  | 'audio/assistant/clippy/9'
  | 'audio/assistant/clippy/10'
  | 'audio/assistant/clippy/11'
  | 'audio/assistant/clippy/12'
  | 'audio/assistant/clippy/13'
  | 'audio/assistant/clippy/14'
  | 'audio/assistant/clippy/15'
  | 'audio/windows_chime'
  | 'audio/windows_chord'
  | 'audio/windows_ding'
  | 'audio/windows_tada'
  | 'audio/windows_start';

export type AssetId =
  | ImageAssetId
  | VideoAssetId
  | AudioAssetId
  | FontAssetId;

declare function require(moduleName: string): string;

export function getAssetPath(id: AssetId): string {
  // TODO(Angus): move the URLs to where they're used.
  switch (id) {
    case 'image/assistant/bonzi':
      return require('url:@akst.io/web-resume-dom/assets/image/assistant/bonzi_map.png');
    case 'image/assistant/clippy':
      return require('url:@akst.io/web-resume-dom/assets/image/assistant/clippy_map.png');
    case 'image/windows_logo':
      return require('url:@akst.io/web-resume-dom/assets/image/windows_95_logo.png');
    case 'image/icon_chip':
      return require('url:@akst.io/web-resume-dom/assets/image/icons/chip.png');
    case 'image/icon_computer':
      return require('url:@akst.io/web-resume-dom/assets/image/icons/computer.png');
    case 'image/icon_exe':
      return require('url:@akst.io/web-resume-dom/assets/image/icons/exe.png');
    case 'image/icon_text':
      return require('url:@akst.io/web-resume-dom/assets/image/icons/text.png');
    case 'image/icon_word':
      return require('url:@akst.io/web-resume-dom/assets/image/icons/word.png');
    case 'image/icon_folder':
      return require('url:@akst.io/web-resume-dom/assets/image/icons/folder.png');
    case 'image/meme_1':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0001.jpg');
    case 'image/meme_2':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0002.jpg');
    case 'image/meme_3':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0003.jpg');
    case 'image/meme_4':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0004.jpg');
    case 'image/meme_5':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0005.jpg');
    case 'image/meme_6':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0006.jpg');
    case 'image/meme_7':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0007.jpg');
    case 'image/meme_8':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0008.jpg');
    case 'image/meme_9':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0009.jpg');
    case 'image/meme_10':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0010.jpg');
    case 'image/meme_11':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0011.png');
    case 'image/meme_12':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0012.jpg');
    case 'image/meme_13':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0013.jpg');
    case 'image/meme_14':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0014.jpg');
    case 'image/meme_15':
      return require('url:@akst.io/web-resume-dom/assets/image/meme/IMG_0015.png');
    case 'video/meme_1':
      return require('url:@akst.io/web-resume-dom/assets/video/meme/meme-1.mov');
    case 'video/meme_2':
      return require('url:@akst.io/web-resume-dom/assets/video/meme/meme-2.mov');
    case 'video/meme_3':
      return require('url:@akst.io/web-resume-dom/assets/video/meme/meme-3.mp4');
    case 'video/meme_4':
      return require('url:@akst.io/web-resume-dom/assets/video/meme/meme-4.mov');
    case 'video/meme_5':
      return require('url:@akst.io/web-resume-dom/assets/video/meme/meme-5.mp4');
    case 'video/meme_6':
      return require('url:@akst.io/web-resume-dom/assets/video/meme/meme-6.mp4');
    case 'audio/assistant/bonzi/1':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/bonzi/bonzi_1.mp3');
    case 'audio/assistant/bonzi/2':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/bonzi/bonzi_2.mp3');
    case 'audio/assistant/bonzi/3':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/bonzi/bonzi_3.mp3');
    case 'audio/assistant/bonzi/4':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/bonzi/bonzi_4.mp3');
    case 'audio/assistant/bonzi/5':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/bonzi/bonzi_5.mp3');
    case 'audio/assistant/bonzi/6':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/bonzi/bonzi_6.mp3');
    case 'audio/assistant/bonzi/7':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/bonzi/bonzi_7.mp3');
    case 'audio/assistant/clippy/1':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_01.mp3');
    case 'audio/assistant/clippy/2':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_02.mp3');
    case 'audio/assistant/clippy/3':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_03.mp3');
    case 'audio/assistant/clippy/4':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_04.mp3');
    case 'audio/assistant/clippy/5':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_05.mp3');
    case 'audio/assistant/clippy/6':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_06.mp3');
    case 'audio/assistant/clippy/7':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_07.mp3');
    case 'audio/assistant/clippy/8':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_08.mp3');
    case 'audio/assistant/clippy/9':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_09.mp3');
    case 'audio/assistant/clippy/10':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_10.mp3');
    case 'audio/assistant/clippy/11':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_11.mp3');
    case 'audio/assistant/clippy/12':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_12.mp3');
    case 'audio/assistant/clippy/13':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_13.mp3');
    case 'audio/assistant/clippy/14':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_14.mp3');
    case 'audio/assistant/clippy/15':
      return require('url:@akst.io/web-resume-dom/assets/audio/assistant/clippy/clippy_15.mp3');
    case 'audio/windows_chime':
      return require('url:@akst.io/web-resume-dom/assets/audio/chimes.wav');
    case 'audio/windows_chord':
      return require('url:@akst.io/web-resume-dom/assets/audio/chord.wav');
    case 'audio/windows_ding':
      return require('url:@akst.io/web-resume-dom/assets/audio/ding.wav');
    case 'audio/windows_tada':
      return require('url:@akst.io/web-resume-dom/assets/audio/tada.wav');
    case 'audio/windows_start':
      return require('url:@akst.io/web-resume-dom/assets/audio/windows_start_sound.wav');
  }
}

const ALL_AUDIO_ASSETS_IDS: ReadonlyArray<AudioAssetId> = [
  'audio/windows_chime',
  'audio/windows_chord',
  'audio/windows_ding',
  'audio/windows_tada',
  'audio/windows_start',
  'audio/assistant/clippy/1',
  'audio/assistant/clippy/2',
  'audio/assistant/clippy/3',
  'audio/assistant/clippy/4',
  'audio/assistant/clippy/5',
  'audio/assistant/clippy/6',
  'audio/assistant/clippy/7',
  'audio/assistant/clippy/8',
  'audio/assistant/clippy/9',
  'audio/assistant/clippy/10',
  'audio/assistant/clippy/11',
  'audio/assistant/clippy/12',
  'audio/assistant/clippy/13',
  'audio/assistant/clippy/14',
  'audio/assistant/clippy/15',
  'audio/assistant/bonzi/1',
  'audio/assistant/bonzi/2',
  'audio/assistant/bonzi/3',
  'audio/assistant/bonzi/4',
  'audio/assistant/bonzi/5',
  'audio/assistant/bonzi/6',
  'audio/assistant/bonzi/7',
];

const ALL_VIDEO_ASSETS_IDS: ReadonlyArray<VideoAssetId> = [
];

const ALL_FONT_ASSETS_IDS: ReadonlyArray<FontAssetId> = [
];

export function getFontName(id: FontAssetId): string {
  switch (id) {
    default: throw new Error();
  }
}

export class AssetService {
  private readonly imageAssets: Map<ImageAssetId, ImageAsset> = new Map();
  private readonly audioAssets: Map<AudioAssetId, AudioAsset> = new Map();

  constructor(
      private readonly fetch: Fetch,
      private readonly audioContext: AudioContext,
      private readonly loadFont: FontLoader,
  ) {
  }

  getAudioAsset(id: AudioAssetId): AudioAsset | undefined {
    return this.audioAssets.get(id);
  }

  getImageAsset(id: ImageAssetId): ImageAsset | undefined {
    return this.imageAssets.get(id);
  }

  async loadAllAssets(): Promise<void> {
    await Promise.all([
      ...ALL_AUDIO_ASSETS_IDS.map(id => void this.loadAudioAsset(id)),
      ...ALL_FONT_ASSETS_IDS.map(id => this.loadFontAsset(id)),
    ]);
  }

  loadImageAsset(id: ImageAssetId): Promise<ImageAsset> {
    const image = new Image();
    return new Promise((resolve, reject) => {
      const cleanUp = () => {
        image.removeEventListener('load', onLoad);
        image.removeEventListener('error', onError);
      };
      const onLoad = () => {
        cleanUp();
        const asset: ImageAsset = { type: 'image', image };
        this.imageAssets.set(id, asset);
        resolve(asset);
      };
      const onError = (error: any) => {
        cleanUp();
        reject(error);
      };
      image.onload = onLoad;
      image.onerror = onError;
      image.src = getAssetPath(id);
    });
  }

  async loadAudioAsset(id: AudioAssetId): Promise<AudioAsset> {
    const response = await this.fetch(getAssetPath(id));
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await this.decodeAudioData(arrayBuffer);
    const audioAsset: AudioAsset = { type: 'audio', buffer: audioBuffer };
    this.audioAssets.set(id, audioAsset);
    return audioAsset;
  }

  async loadFontAsset(id: FontAssetId): Promise<void> {
    return this.loadFont(
        getAssetPath(id),
        getFontName(id),
    );
  }

  private decodeAudioData(arrayBuffer: ArrayBuffer): Promise<AudioBuffer> {
    return new Promise((resolve, reject) => {
      // turns out this is the only way to get this to work with safari :(
      this.audioContext.decodeAudioData(arrayBuffer, resolve, reject);
    });
  }
}
