import styled, { keyframes } from 'styled-components';

const colors = keyframes`
  from { filter: hue-rotate(0deg); }
  to { filter: hue-rotate(360deg); }
`;

export const LetterWithColorAnimation = styled.span`
  animation-name: ${colors};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: var(--colorAnimationDuration);
  color: hsl(0deg, var(--saturation), var(--brightness));
`;

const waves = keyframes`
  from { transform: translate(0, var(--displacementMin)); }
  to { transform: translate(0, var(--displacementMax)); }
`;

export const LetterWithWaveAnimation = styled.span`
  display: inline-block;
  white-space: pre;
  animation-name: ${waves};
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: var(--waveAnimationDuration);
`;

// TODO RENAME THIS
const bitchTranslation = keyframes`
  from { transform: translate(0, var(--translationMin)); }
  to { transform: translate(0, var(--translationMax)); }
`;

// TODO RENAME THIS
export const BitchImTranslatingOnMyYAxis = styled.div`
  animation-name: ${bitchTranslation};
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: var(--waveAnimationDuration);
`;

const bitchRotation = keyframes`
  from { transform: rotate(var(--rotationMin)); }
  to { transform: rotate(var(--rotationMax)); }
`;

export const BitchImRotating = styled.div`
  animation-name: ${bitchRotation};
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: var(--waveAnimationDuration);
`;
