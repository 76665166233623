export function pick<T>(items: T[]): T {
  const count = items.length;
  return items[Math.floor(Math.random() * count) % count];
}

export function last<T>(array: ReadonlyArray<T>): T | undefined {
  return array[array.length - 1];
}

export function init<T>(array: ReadonlyArray<T>): ReadonlyArray<T> | undefined {
  return array.length > 0 ? array.slice(0, array.length - 1) : undefined;
}
