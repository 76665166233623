import * as React from 'react';
import styled, { keyframes } from 'styled-components';

export type LoadingBarProps = {
  complete: boolean;
};

export const LoadingBar = React.memo(({ complete }: LoadingBarProps) => (
  <Root>
    <Gradient style={{ opacity: complete ? 0 : 1 }}/>
  </Root>
));

const background = '#5e98d1';

const Root = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: ${background};
`;

const gradientSlide = keyframes`
  from { left: 0%; }
  to { left: -400%; }
`;

const Gradient = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 500%;
  transition: opacity 1s linear;
  animation: ${gradientSlide} 2s linear infinite;
  background-image: linear-gradient(
      to right,
      white,
      ${background},
      white,
      ${background},
      white,
      ${background}
  );
`;
