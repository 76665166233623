import { UnreachableError } from '@akst.io/lib/base/types';
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { action, observable, makeObservable } from 'mobx';
import {
  Bounds,
  ClientCoord,
  DragPositionInitHint,
  PositionStyle,
} from './types';

export class DraggableSlotStore {
  priority: number;
  hasBounds = false;
  height?: number = undefined;
  width?: number = undefined;
  x: number = 0;
  y: number = 0;

  readonly id: string;

  /*
   * These properties are not observerable as they're
   * more about preserving states between mouse up and
   * down when dragging the elements.
   */
  moving: boolean = false;
  mouseStartX: number = 0;
  mouseStartY: number = 0;
  elementStartLeft: number = 0;
  elementStartTop: number = 0;

  constructor(
      id: string,
      priority: number,
  ) {
    this.id = id;
    this.priority = priority;

    makeObservable(this, {
      priority: observable.ref,
      hasBounds: observable.ref,
      height: observable.ref,
      width: observable.ref,
      x: observable.ref,
      y: observable.ref
    });
  }
}

export class DraggableSlotPresenter {
  constructor(
      private readonly logging: LoggingService,
      private readonly getNewId: () => string,
      private readonly rand: () => number,
  ) {
    makeObservable(this, {
      resetPosition: action,
      setPriority: action,
      setPosition: action,
      onPointerDown: action,
      onPointerUp: action,
      onResize: action
    });
  }

  createDraggableSlotStore(zIndex: number): DraggableSlotStore {
    return new DraggableSlotStore(this.getNewId(), zIndex);
  }

  getPositionStyle(store: DraggableSlotStore): PositionStyle {
    const { x, y } = store;
    return {
      transform: `translateX(${x}px) translateY(${y}px)`,
      opacity: store.hasBounds ? 1 : 0,
    };
  }

  resetPosition(store: DraggableSlotStore, hint: DragPositionInitHint, bounds: Bounds) {
    switch (hint.kind) {
      case 'random': {
        const { width, height } = bounds;
        store.x = 100 + (this.rand() * (width / 10));
        store.y = (height / 10) + (this.rand() * (height / 5));
        break;
      }

      case 'with-content-size': {
        const { width, height } = bounds;
        store.x = this.rand() * (width - hint.size.x);
        store.y = this.rand() * (height - hint.size.y);
        break;
      }

      case 'exact':
        store.x = hint.at.x;
        store.y = hint.at.y;
        break;

      case 'between':
        store.x = hint.from.x + (this.rand() * (hint.to.x - hint.from.x));
        store.y = hint.from.y + (this.rand() * (hint.to.y - hint.from.y));
        break;

      default:
        throw new UnreachableError(hint);
    }
  }

  setPriority(store: DraggableSlotStore, priority: number) {
    store.priority = priority;
  }

  setPosition(store: DraggableSlotStore, x: number, y: number) {
    store.x = x;
    store.y = y;
  }

  onPointerDown(store: DraggableSlotStore, startAt: ClientCoord) {
    store.mouseStartX = startAt.clientX;
    store.mouseStartY = startAt.clientY;
    store.elementStartLeft = store.x;
    store.elementStartTop = store.y;
    store.moving = true;
  }

  onPointerMove(
      store: DraggableSlotStore,
      cursorInfo: ClientCoord,
      portalBounds: Bounds | undefined,
  ) {
    if (!store.moving) return;

    let x: number, y: number;
    if (portalBounds) {
      const maybeX = store.elementStartLeft - (store.mouseStartX - cursorInfo.clientX);
      const xMin = portalBounds.left;
      const xMax = xMin + portalBounds.width - (store.width || 0);
      x = Math.max(xMin, Math.min(maybeX, xMax));

      const maybeY = store.elementStartTop - (store.mouseStartY - cursorInfo.clientY);
      const yMin = portalBounds.top;
      const yMax = yMin + portalBounds.height - (store.height || 0);
      y = Math.max(yMin, Math.min(maybeY, yMax));
    } else {
      x = store.elementStartLeft;
      y = store.elementStartTop;
    }

    this.setPosition(store, x, y);
  }

  onPointerUp(store: DraggableSlotStore) {
    store.moving = false;
  }

  onResize(store: DraggableSlotStore, rect: { width: number, height: number }) {
    store.width = rect.width;
    store.height = rect.height;
    store.hasBounds = true;
  }
}
