import styled from 'styled-components';
import { baseUnit, smallestUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { white, borderGreyInverted } from '@akst.io/web-resume-dom/ui/base/color/colors';

export const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Buttons = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: ${baseUnit}px;
  padding: ${baseUnit}px;
`;

export const ButtonContainer = styled.div`
  display: block;
`;

export const FileGridOuter = styled.div`
  flex-grow: 2;
  position: relative;
  background: ${white};
  border-color: ${borderGreyInverted};
  border-style: solid;
  border-width: ${smallestUnit}px;

  /*
   * CROSS PLATFORM NOTICE:
   *
   * Initially the overflow & max-height, however it caused the
   * height of "fileGridInner" to be 0 in Safari.
   *
   * I'm not entirely sure how that happened, but it worked in
   * chrome & firefox without issue.
   */
  max-height: 100%;
  overflow: scroll;
`;

export const FileGridInner = styled.div`
  height: 100%;
`;
