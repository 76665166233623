import { action, observable, makeObservable } from 'mobx';
import { BufferPresenter, BufferStore } from '@akst.io/web-resume-dom/ui/application/sprite_maker/buffer/buffer_presenter';
import { DrawMode } from './types';

export class ControlsStore {
  drawMode: DrawMode = DrawMode.DRAWING;

  constructor(public bufferStore: BufferStore) {
    makeObservable(this, {
      drawMode: observable.ref
    });
  }
}

export class ControlsPresenter {
  constructor(public bufferPresenter: BufferPresenter) {
    makeObservable(this, {
      onDrawModeChange: action
    });
  }

  public onGridSizeChange(store: ControlsStore, width: number, height: number) {
    this.bufferPresenter.onGridSizeChange(
        store.bufferStore,
        width,
        height,
    );
  }

  public onDrawModeChange(store: ControlsStore, mode: DrawMode) {
    store.drawMode = mode;
  }
}
