import * as React from 'react';
import styled from 'styled-components';
import { baseUnit, smallestUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { RadioInput } from '@akst.io/web-resume-dom/ui/base/form/radio/radio';
import { Normal } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { GridSizeInput } from './grid_size_input/grid_size_input';
import { DrawMode } from './types';

export const controlWidth = baseUnit * 10;

export type ControlsProps = {
  mode: DrawMode;
  gridWidth: number;
  gridHeight: number;
  onGridSizeChange(width: number, height: number): void;
  onDrawModeChange(drawMode: DrawMode): void;
};


/**
 * - Modifying the grid width
 * - Modifying the grid height
 * - Switching between drawing & erasing.
 */
export const Controls = React.memo(function Controls(props: ControlsProps) {
  const modes = React.useMemo(() => ([
    { key: 'a', value: DrawMode.DRAWING, label: <Normal>Draw</Normal> },
    { key: 'b', value: DrawMode.ERASING, label: <Normal>Erase</Normal> },
    { key: 'c', value: DrawMode.EYE_DROP, label: <Normal>iDrop</Normal> },
    { key: 'd', value: DrawMode.FLOOD_FILL, label: <Normal>Fill</Normal> },
  ]), []);

  return (
      <ControlsContainer>
        <ControlInput>
          <GridSizeInput
              inputSpacing={baseUnit}
              onGridSizeChange={props.onGridSizeChange}
              gridWidth={props.gridWidth}
              gridHeight={props.gridHeight}
          />
        </ControlInput>
        <ControlInput>
          <RadioInput
              label="Mode"
              options={modes}
              value={props.mode}
              onChange={props.onDrawModeChange}
          />
        </ControlInput>
      </ControlsContainer>
  );
});

const ControlsContainer = styled.div`
  position: relative;
  padding: ${baseUnit}px;
  width: ${controlWidth}px;
`;

const ControlInput = styled.div`
  & + & {
    margin-top: baseUnit;
  }
`;
