import * as React from 'react';

export type MarqueeProps = {
  children: any;
  direction?: 'up' | 'down' | 'left' | 'right',
  behavior?: 'scroll' | 'slide' | 'alternate',
  loop?: number,
};

export const Marquee = React.memo(function Marquee(props: MarqueeProps) {
  return React.createElement("marquee", {
    style: { display: 'block' },
    direction: props.direction,
    behavior: props.behavior,
    loop: props.loop == null ? -1 : props.loop,
  }, [
    props.children,
  ]);
});
