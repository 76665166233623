import * as React from 'react';
import styled from 'styled-components';

import { skillProficiency } from '@akst.io/lib/resume/consts';
import * as Resume from '@akst.io/lib/resume/types';
import { funnyFontFamily } from '@akst.io/web-resume-dom/ui/base/typography/fonts';
import { Spoiler } from '@akst.io/web-resume-dom/ui/base/spoiler/spoiler';
import { Small } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { Rows } from '../../../ui/layout/layout';
import {
  Heading,
  List,
  ListItem,
  Normal,
  SubHeading,
  SubSubHeading,
  SubHeadingWithTimeAndTagline,
} from '../../../ui/type/type';

const colors = [
  '#ff8700',
  '#b36cff',
  '#00dbff',
  '#ff01fe',
  '#e7d000',
  '#6aff00',
];

export type GroupItem = {
  readonly id: string,
  readonly color: string,
  readonly group: Resume.SkillData,
}

export type SkillItem = {
  readonly id: string,
  readonly group: string;
  readonly skill: Resume.SkillDataData,
  readonly priority: number,
  readonly color: string,
  readonly icon: string,
};

export type SkillGroupItem = {
  readonly id: string,
  readonly color: string,
  readonly group: Resume.SkillData,
  readonly priority: number,
};


export function useSkillGroups({
  skill: { data },
}: {
  skill: Resume.Skill,
}): {
  groups: readonly SkillGroupItem[],
} {
  return React.useMemo(() => ({
    groups: data.map((group, i) => ({
      id: group.id,
      group,
      color: colors[i],
      priority: group.priority,
    })),
  }), [data]);
}

export function useSkills({
  skill: data,
}: {
  skill: Resume.Skill,
}): readonly SkillItem[] {
  return React.useMemo(() => {
    const items = data.data.flatMap<SkillItem>((group, i) => {
      const color = colors[i];

      return group.data.map(skill => {
        return {
          id: skill.id,
          priority: skill.priority * group.priority,
          group: group.id,
          color,
          skill,
          icon: skillProficiency[skill.proficiency].symbol,
        };
      });
    });

    items.sort((l, r) => r.priority - l.priority);

    return items;
  }, [data]);
}

export const Skills = React.memo(({
  skill,
}: {
  skill: Resume.Skill,
}) => {
  const { groups } = useSkillGroups({ skill });
  const skills = useSkills({ skill });

  const items = React.useMemo(() => (
    skills.map((s) => (
      <SkillItem shadow={s.color} key={s.id}>
        {s.icon} {s.skill.label ?? s.skill.id}
      </SkillItem>
    ))
  ), [skills]);

  const groupItems = React.useMemo(() => (
    groups.map((g) => (
      <SkillItem shadow={g.color} key={g.id}>
        {g.group.name}
      </SkillItem>
    ))
  ), [skills]);

  const proficiencyItems = React.useMemo(() => (
    Object.values(skillProficiency).map(s => (
      <SkillItem key={s.level} shadow="black">
        {s.symbol} {s.label}
      </SkillItem>
    ))
  ), []);

  return (
      <Rows gridTemplateColumns="unset">
        <Heading subHeading={undefined}>
          Skills
        </Heading>
        <SubHeading margin={true} marquee={false}>
          Legend
        </SubHeading>
        <Legend>
          <SkillList>
            <Label>Kind:</Label> {groupItems}
          </SkillList>
          <SkillList>
            <Label>Rating:</Label> {proficiencyItems}
          </SkillList>
        </Legend>
        <SubHeading margin={true} marquee={false}>
          Skills
        </SubHeading>
        <SkillList>
          {items}
        </SkillList>
      </Rows>
  );
});

const Label = styled.strong`
  font-weight: 900;
  font-family: ${funnyFontFamily};
`;

const Legend = styled.div`
  display: grid;
  grid-gap: ${baseUnit}px;
  grid-auto-flow: rows;
`;

export const SkillList = styled.ul`
  gap: ${baseUnit}px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 0;
  list-style: none;
  margin: 0;
`;

export const SkillItem = styled.li<{
  shadow: string,
  padding?: string,
  fontSize?: string
}>`
  padding: ${p => p.padding ?? `${baseUnit * 0.4}px`};
  background-color: white;
  border: ${p => `${p.shadow} solid  4px`};
  border-width: 1px 1px 4px 1px;
  color: black;
  line-height: 1;
  font-size: ${p => p.fontSize ?? '.65rem'};
  font-family: ${funnyFontFamily};
`;
