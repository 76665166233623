import { FilePathDescription } from '@akst.io/web-resume-dom/services/file_system/types';
import { FileSystemService } from '@akst.io/web-resume-dom/services/file_system/file_system_service';
import { FileHelperService, FileHelperResult as Result } from '@akst.io/web-resume-dom/services/file_helper/file_helper_service';

export class FileHelperServiceImpl implements FileHelperService {
  constructor(private readonly fileSystemService: FileSystemService) {
  }

  async saveAs(data: string, _: string, startDirectoryPath: FilePathDescription): Promise<Result> {
    const defaultValue = `/${startDirectoryPath.join('/')}`;
    const value = prompt('Where are you saving your file?', defaultValue);
    if (value == null) return { type: 'cancel' };

    const actualPath = value.slice(1).split('/');
    this.fileSystemService.write(actualPath, data);

    const file = this.fileSystemService.getLiveFile(actualPath);
    return { type: 'ok', file };
  }

  async open(_: string, startDirectoryPath: FilePathDescription): Promise<Result> {
    const defaultValue = `/${startDirectoryPath.join('/')}`;
    const value = prompt('Which file are you opening?', defaultValue);
    if (value == null) return { type: 'cancel' };

    const actualPath = value.slice(1).split('/');
    const file = this.fileSystemService.getLiveFile(actualPath);
    return { type: 'ok', file };
  }

  save(data: string, saveTo: FilePathDescription) {
    this.fileSystemService.write(saveTo, data);
  }
}
