import { observer } from 'mobx-react';
import * as React from 'react';
import { FileSystemService } from '@akst.io/web-resume-dom/services/file_system/file_system_service';
import {
  LiveDirectory,
  LiveFile,
  LiveFileSystemNode,
} from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import { Button, ButtonProps } from '@akst.io/web-resume-dom/ui/base/button/button';
import { Small } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { FileGridProps, createFileGrid } from '@akst.io/web-resume-dom/ui/system/file_grid/file_grid';
import { FileNavigatorStore, FileNavigatorPresenter } from './file_navigator_presenter';
import {
  Buttons,
  ButtonContainer,
  FileGridOuter,
  FileGridInner,
  Root,
} from './styles';

export type FileNavigatorProps = {
  store: FileNavigatorStore;
  onFileOpen(file: LiveFile): void;
};

export type InternallyInjectedProps = {
  Button: React.ComponentType<ButtonProps>;
  FileGrid: React.ComponentType<FileGridProps>;
  presenter: FileNavigatorPresenter;
};

export type ExternallyInjectedProps = {
  store: FileNavigatorStore;
  FileGrid: React.ComponentType<FileGridProps>;
};

export type FileNavigatorImplProps =
  & ExternallyInjectedProps
  & InternallyInjectedProps
  & FileNavigatorProps;

export const FileNavigator = observer(class FileNavigator extends React.Component<FileNavigatorImplProps> {
  render() {
    const {
      store,
      presenter,
      Button,
      FileGrid,
    } = this.props;

    const { directory } = store;
    const disableBack = store.backHistory.length === 0;
    const disableFoward = store.forwardHistory.length === 0;
    const disableGoToParent = store.directory.path.length === 0;

    return (
        <Root>
          <Buttons>
            <ButtonContainer>
              <Button onPointer={this.onHistoryBack} disabled={disableBack}>
                <Small> Back </Small>
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button onPointer={this.onHistoryForward} disabled={disableFoward}>
                <Small> Forward </Small>
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button onPointer={this.onGoToParent} disabled={disableGoToParent}>
                <Small> To Parent </Small>
              </Button>
            </ButtonContainer>
          </Buttons>
          <FileGridOuter>
            <FileGridInner>
              <FileGrid
                  directory={directory}
                  onFileOpen={this.onFileOpen}
                  flexDirection="row"
              />
            </FileGridInner>
          </FileGridOuter>
        </Root>
    );
  }

  private readonly onFileOpen = (file: LiveFileSystemNode) => {
    const { onFileOpen, store, presenter } = this.props;
    presenter.onFileOpen(store, file, onFileOpen);
  };

  private readonly onHistoryBack = () => {
    this.props.presenter.onHistoryBack(this.props.store);
  };

  private readonly onHistoryForward = () => {
    this.props.presenter.onHistoryForward(this.props.store);
  };

  private readonly onGoToParent = () => {
    this.props.presenter.onGoToParent(this.props.store);
  };
});

export function createFileNavigator(options: {
  fileSystemService: FileSystemService;
  FileGrid: React.ComponentType<FileGridProps>;
}) {
  const presenter = new FileNavigatorPresenter(options.fileSystemService);

  return React.memo((props: FileNavigatorProps) => {
    return (
        <FileNavigator
            Button={Button}
            FileGrid={options.FileGrid}
            presenter={presenter}
            {...props}
        />
    );
  });
}

