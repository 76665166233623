import * as React from 'react';
import * as Result from '@akst.io/lib/base/result';
import * as Arrays from '@akst.io/web-resume-dom/base/array';
import { ApplicationService } from "@akst.io/web-resume-dom/services/application/application_service";
import {
  ApplicationInstance,
  ApplicationInitError,
  LocalApplicationController,
} from '@akst.io/web-resume-dom/services/application/types';
import { AssetService } from '@akst.io/web-resume-dom/services/asset/asset_service';
import { DesktopService } from '@akst.io/web-resume-dom/services/desktop/desktop_service';
import { FileSystemService } from "@akst.io/web-resume-dom/services/file_system/file_system_service";
import { isLiveFile, LiveFileSystemNode } from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { SpeechService } from '@akst.io/web-resume-dom/services/speech/speech_service';
import { UseDragSlot } from '@akst.io/web-resume-dom/ui/system/draggable/create';
import { DragPointProps } from '@akst.io/web-resume-dom/ui/system/draggable/draggable';
import { AnimatorPresenter } from './animator/animator_presenter';
import { AnimatorCanvasPresenter } from './animator/animator_canvas_presenter';
import { AnimatorTransformPresenter } from './animator/animator_transform_presenter';
import { Assistant } from './assistant';
import { AssistantLoader, AssistantImagePresenter } from './assistant_image_presenter';
import { AssistantInstancePresenter, AssistantInstanceStore } from './assistant_instance_presenter';
import { AssistantStore, AssistantPresenter } from './assistant_presenter';
import { AssistantDefinition } from './configs/types'
import {
  AssistantController,
  QueueAssistantExpressionRequest,
} from './type'

type FactoryResult = Result.T<ApplicationInstance, ApplicationInitError>;
type FactoryOptions = { procId: number, file: LiveFileSystemNode | undefined };
type Factory = { createAssistant: (o: FactoryOptions) => FactoryResult };
type Module = { config: AssistantDefinition };

const findAssistantConfig: AssistantLoader = (assistantId: string) => {
  switch (assistantId) {
    case 'bonzi':
      return Result.Ok(() => import(
        '@akst.io/web-resume-dom/ui/application/assistant/configs/bonzi/config'
      ).then(m => m.config));

    case 'clippy':
      return Result.Ok(() => import(
        '@akst.io/web-resume-dom/ui/application/assistant/configs/clippy/config'
      ).then(m => m.config));

    default:
      return Result.Err(assistantId);
  }
};

export type Services = {
  applicationService: ApplicationService,
  assetService: AssetService,
  desktopService: DesktopService,
  fileSystemService: FileSystemService,
  loggingService: LoggingService,
  speechService: SpeechService,
};

export function createAssistant({
  DragPoint,
  services: {
    applicationService,
    assetService,
    desktopService,
    fileSystemService,
    loggingService,
    speechService,
  },
  useDragSlot,
}: {
  DragPoint: React.ComponentType<DragPointProps>,
  services: Services,
  useDragSlot: UseDragSlot,
}): {
  controller: AssistantController,
  factory: Factory,
} {
  const store = new AssistantStore();
  const imagePresenter = new AssistantImagePresenter(
      loggingService.createChild('AssistantImagePresenter'),
      assetService,
      findAssistantConfig,
  );
  const now = () => window.performance.now();
  const instancePresenter = new AssistantInstancePresenter(
      loggingService.createChild('AssistantInstancePresenter'),
      imagePresenter,
      new AnimatorPresenter(
          loggingService.createChild('AnimatorPresenter'),
          speechService,
          now,
          () => Math.random(),
          new AnimatorTransformPresenter(
              loggingService.createChild('AnimatorTransformPresenter'),
              desktopService,
              now,
          ),
          new AnimatorCanvasPresenter(
              loggingService.createChild('AnimatorCanvasPresenter'),
              now,
              window.devicePixelRatio,
          ),
          f => window.requestAnimationFrame(f),
          t => window.cancelAnimationFrame(t),
      ),
  );
  const presenter = new AssistantPresenter(
      fileSystemService,
      loggingService.createChild('AssistantPresenter'),
      instancePresenter,
      imagePresenter,
  );

  class AssistantApplicationController implements LocalApplicationController {
    title = 'Assistant';

    constructor(readonly instance: AssistantInstanceStore) {
    }

    onQuit() {
    }
  }

  const RAND = { kind: 'random' } as const;
  const createComponent = (instance: AssistantInstanceStore) => React.memo(() => {
    const { DragSlot, controller } = useDragSlot(RAND);

    React.useEffect(() => {
      return () => instancePresenter.pause(instance);
    }, []);

    const setRef = React.useCallback((e: HTMLCanvasElement | null) => {
      if (e == null) return;
      instancePresenter.resume(instance, e, controller);
    }, [controller]);

    return (
        <DragSlot>
          <Assistant setRef={setRef} DragPoint={DragPoint}/>
        </DragSlot>
    );
  });

  const factory: Factory = {
    createAssistant({ procId, file }) {
      if (file == null || !isLiveFile(file)) {
        return Result.Err({ type: 'incorrect-file-type', fileType: 'file' } as const);
      }

      const instance = presenter.maybeCreateInstance(store, procId, file);

      if (instance.ok) {
        loggingService.debug(`creating instance for ${file.name}`);
      } else {
        loggingService.error(`unable able to create instance for ${file.name}`);
        return Result.Err({ type: 'bad-file' } as const);
      }

      const Component = createComponent(instance.value);
      const controller = new AssistantApplicationController(instance.value);
      return Result.Ok({ Component, controller } as const);
    },
  };

  const controller: AssistantController = {
    createAssistant(id: string) {
      const file = presenter.getAssistantFile(store, id);
      if (!file.ok) return file;
      return applicationService.openFile(file.value);
    },

    findOrCreateAnAssistant() {
      const assistant = this.findAssisants()[0];
      if (assistant != null) return Result.Ok(assistant);
      const images = this.findAssisantImages();
      return this.createAssistant(Arrays.pick(images));
    },

    findAssisants(): number[] {
      return Array.from(store.instances.values(), i => i.procId);
    },

    findAssisantImages(): string[] {
      presenter.indexImages(store);
      return Array.from(store.images.values(), i => i.id);
    },

    queueAssistantExpression({ id, expression }: QueueAssistantExpressionRequest) {
      loggingService.debug(expression.message);
      presenter.queueAssistantExpression(store, id, expression);
      return Result.Ok(undefined);
    },
  };

  return { controller, factory };
}
