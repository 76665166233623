import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FilePathDescription } from '@akst.io/web-resume-dom/services/file_system/types';
import { MultilineInput, MultilineInputProps } from '@akst.io/web-resume-dom/ui/base/form/input/input';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import {
  TreeMenu,
  TreeMenuProps,
  TreeNodeMenu,
} from '@akst.io/web-resume-dom/ui/system/tree_menu/tree_menu';
import { NotePadPresenter, NotePadStore } from './note_pad_presenter';

export type ExternallyInjectedProps = {
  store: NotePadStore;
  presenter: NotePadPresenter;
  Panel: React.ComponentType<PanelProps>;
  filePath: FilePathDescription;
};

export type InternallyInjectedProps = {
  MultilineInput: React.ComponentType<MultilineInputProps>;
  TreeMenu: React.ComponentType<TreeMenuProps>;
};

export type NotePadProps =
  & ExternallyInjectedProps
  & InternallyInjectedProps;

export const NotePad = observer(class NotePad extends React.Component<NotePadProps> {
  private initialPanelSize = { width: '250px' };
  private initialTextAreaSize = { height: '20vh' };

  constructor(props: NotePadProps) {
    super(props);

    makeObservable(this, {
      tree: computed
    });
  }

  render() {
    const { MultilineInput, Panel, store, filePath } = this.props;
    return (
        <Panel
            pathOfOpenFile={filePath}
            title={store.title}
            resizeable={false}
            onClose={this.onClose}
            onMinimize={this.onMinimize}
            initialSize={this.initialPanelSize}
        >
          <TreeMenu tree={this.tree}/>
          <MultilineInput
              value={store.unsavedData}
              resizeable={false}
              onChange={this.onChange}
              initialSize={this.initialTextAreaSize}
          />
        </Panel>
    );
  }

  get tree(): ReadonlyArray<TreeNodeMenu> {
    const { presenter, store } = this.props;
    const onSave = () => presenter.onSave(store);
    const onSaveAs = () => presenter.onSaveAs(store);
    const onOpen = () => presenter.onOpen(store);
    return [
      {
        type: 'menu',
        label: 'File',
        options: [
          {
            type: 'action',
            label: 'Save',
            onClick: onSave,
          },
          {
            type: 'action',
            label: 'Save As',
            onClick: onSaveAs,
          },
          {
            type: 'action',
            label: 'Open',
            onClick: onOpen,
          },
          {
            type: 'action',
            label: 'Exit',
            onClick: this.onClose,
          },
        ],
      },
    ];
  }

  private readonly onMinimize = () => {
    const { presenter, store } = this.props;
    presenter.onMinimize(store);
  };

  private readonly onClose = () => {
    const { presenter, store } = this.props;
    presenter.onClose(store);
  };

  private readonly onChange = (value: string) => {
    const { presenter, store } = this.props;
    presenter.onTextChange(store, value);
  };
});

export type CreateOptions = ExternallyInjectedProps;

export function createNotePad(injected: CreateOptions): React.ComponentType {
  return React.memo(() => (
      <NotePad
          MultilineInput={MultilineInput}
          TreeMenu={TreeMenu}
          {...injected}
      />
  ));
}
