import * as React from 'react';
import styled from 'styled-components';
import { Button } from '@akst.io/web-resume-dom/ui/base/button/button';
import * as colors from '@akst.io/web-resume-dom/ui/base/color/colors';
import { Normal } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { baseUnit, smallestUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { Time } from '../desktop_step_presenter';

export type StartBarProps = {
  activeItems: ReadonlyArray<[number, string]>,
  onClickItem(procId: number): void,
};

const ShowTime = ({
  second,
  minute,
  hour,
}: Time) => {
  const hS = hour.toString().padStart(2, '0');
  const mS = minute.toString().padStart(2, '0');
  const sS = second.toString().padStart(2, '0');
  let time: string;
  if (second % 2 === 0) {
    time = `${hS}:${mS}:${sS}`;
  } else {
    time = `${hS} ${mS} ${sS}`;
  }

  return (
      <Time>
        <Normal style="monospace" margins="none">{time}</Normal>
      </Time>
  );
};

export const StartBar = React.memo(function StartBar({
  activeItems,
  onClickItem,
  onStartClick,
  time,
}: StartBarProps & {
  time: Time,
  onStartClick(): void,
}) {
  const startBarItems = React.useMemo(
    () => activeItems.map(([id, name]: [number, string]) => (
      <StartItem onClickItem={onClickItem} title={name} id={id} key={id}/>
    )),
    [activeItems, onClickItem],
  );

  return (
      <Root>
        <Button onPointer={onStartClick}>
          <Normal weight="bold">Start</Normal>
        </Button>
        <StartBarItemList>
          {startBarItems}
        </StartBarItemList>
        <ShowTime {...time}/>
      </Root>
  );
});

type StartItemProps = {
  title: string;
  id: number;
  onClickItem(id: number): void;
};

const StartItem = React.memo(function StartItem(props: StartItemProps) {
  const { title, onClickItem, id } = props;
  const onClick = React.useCallback(() => onClickItem(id), [onClickItem, id]);

  return (
      <ListItem>
        <Button onPointer={onClick} ellipsis={true}>
          <Normal>{title}</Normal>
        </Button>
      </ListItem>
  );
});

export const startBarHeight = baseUnit * 4;
const buttonHeight = (baseUnit * 4) - (2 * smallestUnit);

const Root = styled.div`
  position: relative;

  display: grid;
  grid-gap: ${baseUnit}px;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: ${buttonHeight}px;
  align-items: center;

  max-width: 100%;
  padding: 0 ${smallestUnit}px;

  background: ${colors.bgGrey};
  border-width: ${smallestUnit}px 0 ;
  border-style: solid;
  border-color: ${colors.borderGrey};
`;

const StartBarItemList = styled.ul`
  display: grid;
  grid-gap: ${smallestUnit}px;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  padding: 0;
  overflow: hidden;
  list-style: none;
  margin: 0;
`;

// TODO check that button fills full height of list item
const ListItem = styled.li`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin: 0;
`;

const Time = styled.div`
  border-width: ${smallestUnit}px;
  border-style: solid;
  border-color: ${colors.borderGreyInverted};

  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 2px);
  padding: 0 ${baseUnit}px;
`;


