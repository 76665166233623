export class Dequeue<T> {
  private pointer: number = 0;
  private results: T[] = [];

  get head(): T | undefined {
    return this.results[this.pointer];
  }

  get last(): T | undefined {
    return this.empty ? undefined : this.results[this.results.length - 1];
  }

  get empty() {
    return this.results.length === this.pointer;
  }

  get length() {
    return this.results.length - this.pointer;
  }

  pushRight(value: T) {
    this.results.push(value);
  }

  pushLeft(value: T) {
    if (this.pointer > 0) {
      this.pointer -= 1;
      this.results[this.pointer] = value;
    } else {
      this.results = [value].concat(this.results);
    }
  }

  popRight(): T | undefined {
    if (!this.empty) {
      const value = this.results.pop()
      this.defragment();
      return value;
    }
  }

  popLeft(): T | undefined {
    if (!this.empty) {
      const value = this.results[this.pointer];
      this.pointer++;
      this.defragment();
      return value;
    }
  }

  private defragment() {
    if (this.empty) {
      this.results = [];
      this.pointer = 0;
    }
  }

  static create<T>() {
    return new Dequeue<T>();
  }
}
