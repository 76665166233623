import { FlowStore, FlowPresenter } from './flow_presenter';

export class FlowController {
  constructor(
      private readonly store: FlowStore,
      private readonly presenter: FlowPresenter,
  ) {
  }

  async startFlow(): Promise<void> {
    await this.presenter.start(this.store);
  }
}
