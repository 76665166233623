import { ApplicationService } from "@akst.io/web-resume-dom/services/application/application_service";
import {
  LocalApplicationStarter,
  LocalApplicationController,
} from '@akst.io/web-resume-dom/services/application/types';
import { FileSystemService } from "@akst.io/web-resume-dom/services/file_system/file_system_service";
import {
  isLiveFile,
  LiveFileSystemNode,
} from "@akst.io/web-resume-dom/services/file_system/live_files_system_node";
import { FileHelperService } from '@akst.io/web-resume-dom/services/file_helper/file_helper_service';
import { SpriteService } from "@akst.io/web-resume-dom/services/sprite/sprite_service";
import { unsafeSpriteCast } from '@akst.io/web-resume-dom/services/sprite/types';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import sprite from '@akst.io/web-resume-dom/ui/sprites/sprite_maker_sprite.json';
import {
  BufferStore,
  BufferPresenter,
} from './buffer/buffer_presenter';
import { createCanvas } from './ui/canvas/create';
import { createControls } from './ui/controls/create';
import { createColor } from './ui/color/create';
import { createSpriteMaker } from './sprite_maker';
import { SpriteMakerStore, SpriteMakerPresenter } from './sprite_maker_presenter';

type Options = {
  applicationService: ApplicationService;
  fileSystemService: FileSystemService;
  fileHelperService: FileHelperService;
  spriteService: SpriteService;
  Panel: React.ComponentType<PanelProps>;
};

const FILE_EXTENSION = 'sprite';

export function installSpriteMaker({
  applicationService,
  fileSystemService,
  fileHelperService,
  spriteService,
  Panel,
}: Options) {
  const bufferPresenter = new BufferPresenter();
  const presenter = new SpriteMakerPresenter(
      bufferPresenter,
      applicationService,
      fileHelperService,
  );

  const filePath = ['programs', 'SpriteMaker.exe'];

  class SpriteMakerController implements LocalApplicationController {
    constructor(
        private readonly store: SpriteMakerStore,
    ) {
    }

    get title(): string {
      return this.store.title;
    }

    onQuit() {
      presenter.closeStore(this.store);
    }
  }

  const withFile: LocalApplicationStarter = (file: LiveFileSystemNode | undefined, procId: number) => {
    if (file && !isLiveFile(file)) {
      return { ok: false, error: { type: 'incorrect-file-type', fileType: 'directory' } };
    }

    const bufferStore = bufferPresenter.createStore(file);
    const [controlsStore, Controls] = createControls(bufferStore, bufferPresenter);
    const [colorStore, Color] = createColor();
    const [canvasStore, Canvas] = createCanvas(
        bufferStore,
        bufferPresenter,
        colorStore,
        controlsStore,
    );
    const store = presenter.createStore(
        bufferStore,
        canvasStore,
        procId,
    );

    const controller = new SpriteMakerController(store);

    const Component = createSpriteMaker({
      store,
      presenter,
      Canvas,
      Controls,
      Color,
      Panel,
      filePath,
    });

    return { ok: true, value: { controller, Component } };
  };

  spriteService.register(
      FILE_EXTENSION,
      'sprite_maker.sprite',
      unsafeSpriteCast(sprite),
  );

  applicationService.register(
      FILE_EXTENSION,
      { initializer: { type: 'local', withFile } },
      filePath,
  );
}
