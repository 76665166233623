import { BluescreenStep } from './bluescreen_step';
import { StepNode } from '../types';

const controller = {
  start() {
    return Promise.resolve();
  }
}

export function createBluescreenStep(): StepNode {
  return {
    id: 'bluescreen_of_death',
    Component: BluescreenStep,
    controller: {
      async start() {
        console.error(
            'look.. just don\'t resize the screen & you\'ll be fine'
        );
      },
    },
  };
}
