export function iterator<A>(iterable: Iterable<A>): Iterator<A> {
  return iterable[Symbol.iterator]();
}

export function next<A>(iterator: Iterator<A>): A | undefined {
  return iterator.next().value;
}

export function seal<A>(iterator: Iterator<A>): Iterable<A> {
  return {
    [Symbol.iterator]() {
      return iterator;
    },
  }
}
