import { FileSystemService } from '@akst.io/web-resume-dom/services/file_system/file_system_service';
import { FilePathDescription } from '@akst.io/web-resume-dom/services/file_system/types';
import { asciiSprite } from '@akst.io/web-resume-dom/services/sprite/ascii_sprite';
import { Sprite } from '@akst.io/web-resume-dom/services/sprite/types';

const WIDTH = 4;
const i_WIDTH = 1;
const I_WIDTH = 3;
const M_WIDTH = 5;
const HEIGHT = 8;

export function installAlphabetSprites(
  fileSystemService: FileSystemService,
  path: FilePathDescription,
): void {
  for (const [letter, data] of alphabet) {
    fileSystemService.write(
        [...path, `${letter}.sprite`],
        JSON.stringify(data),
    );
  }
}

export const numbers: ReadonlyMap<string, Sprite> = new Map<string, Sprite>([
  [
    '0',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .##.
      #..#
      ##.#
      #.##
      #..#
      #..#
      .##.
      ....
    `,
  ],
  [
    '1',
    asciiSprite`
      # Dimensions

      width: ${I_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .#.
      ##.
      .#.
      .#.
      .#.
      .#.
      ###
      ...
    `,
  ],
  [
    '2',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .##.
      #..#
      ...#
      ..#.
      .#..
      #...
      ####
      ....
    `,
  ],
  [
    '3',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .##.
      #..#
      ...#
      ..#.
      ...#
      #..#
      .##.
      ....
    `,
  ],
  [
    '4',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      #..#
      #..#
      #..#
      #..#
      ####
      ...#
      ...#
      ....
    `,
  ],
  [
    '5',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ####
      #...
      ###.
      #..#
      ...#
      #..#
      .##.
      ....
    `,
  ],
  [
    '6',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .##.
      #...
      #...
      ###.
      #..#
      #..#
      .##.
      ....
    `,
  ],
  [
    '7',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ####
      #..#
      ..#.
      .#..
      .#..
      .#..
      .#..
      ....
    `,
  ],
  [
    '8',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .##.
      #..#
      #..#
      .##.
      #..#
      #..#
      .##.
      ....
    `,
  ],
  [
    '9',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .##.
      #..#
      #..#
      .###
      ...#
      ...#
      .##.
      ....
    `,
  ],
]);

export const alphabetLowercase: ReadonlyMap<string, Sprite> = new Map<string, Sprite>([
  [
    'a',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ....
      .##.
      ...#
      .###
      #..#
      #..#
      .##.
      ....
    `,
  ],
  [
    'b',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      #...
      #...
      #...
      ###.
      #..#
      #..#
      ###.
      ....
    `,
  ],
  [
    'c',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ....
      ....
      .##.
      #..#
      #...
      #..#
      .##.
      ....
    `,
  ],
  [
    'd',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ...#
      ...#
      ...#
      .###
      #..#
      #..#
      .###
      ....
    `,
  ],
  [
    'e',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ....
      ....
      .##.
      #..#
      ####
      #...
      .##.
      ....
    `,
  ],
  [
    'f',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ..##
      .#..
      .#..
      ####
      .#..
      .#..
      .#..
      ....
    `,
  ],
  [
    'g',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ....
      ....
      ....
      .###
      #..#
      .###
      ...#
      .##.
    `,
  ],
  [
    'h',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      #...
      #...
      #...
      ###.
      #..#
      #..#
      #..#
      ....
    `,
  ],
  [
    'i',
    asciiSprite`
      # Dimensions

      width: ${i_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .
      .
      #
      .
      #
      #
      #
      .
    `,
  ],
  [
    'j',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ....
      ...#
      ....
      ...#
      ...#
      #..#
      .##.
      ....
    `,
  ],
  [
    'k',
    asciiSprite`
      # Dimensions

      width: ${I_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ...
      #..
      #.#
      #.#
      ##.
      #.#
      #.#
      ...
    `,
  ],
  [
    'l',
    asciiSprite`
      # Dimensions

      width: ${I_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ...
      .#.
      ##.
      .#.
      .#.
      .#.
      .##
      .#.
    `,
  ],
  [
    'm',
    asciiSprite`
      # Dimensions

      width: ${M_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .....
      .....
      .....
      #...#
      ##.##
      #.#.#
      #...#
      .....
    `,
  ],
  [
    'n',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ....
      ....
      ....
      #..#
      ##.#
      #.##
      #..#
      ....
    `,
  ],
  [
    'o',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ....
      ....
      ....
      .##.
      #..#
      #..#
      .##.
      ....
    `,
  ],
  [
    'p',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ....
      ....
      ###.
      #..#
      #..#
      ###.
      #...
      #...
    `,
  ],
  [
    'q',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ....
      ....
      .###
      #..#
      #..#
      .###
      ...#
      ...#
    `,
  ],
  [
    'r',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ....
      ....
      ....
      #.##
      ##..
      #...
      #...
      ....
    `,
  ],
  [
    's',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ....
      ....
      .##.
      #...
      .##.
      ...#
      .##.
      ....
    `,
  ],
  [
    't',
    asciiSprite`
      # Dimensions

      width: ${I_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ...
      .#.
      ###
      .#.
      .#.
      .#.
      .#.
      ...
    `,
  ],
  [
    'u',
    asciiSprite`
      # Dimensions

      width: ${I_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ...
      ...
      ...
      ...
      #.#
      #.#
      .##
      ...
    `,
  ],
  [
    'v',
    asciiSprite`
      # Dimensions

      width: ${I_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ...
      ...
      ...
      ...
      #.#
      #.#
      .#.
      ...
    `,
  ],
  [
    'w',
    asciiSprite`
      # Dimensions

      width: ${M_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .....
      .....
      .....
      .....
      #...#
      #.#.#
      ##.##
      .....
    `,
  ],
  [
    'x',
    asciiSprite`
      # Dimensions

      width: ${I_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ...
      ...
      ...
      ...
      #.#
      .#.
      #.#
      ...
    `,
  ],
  [
    'y',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ....
      ....
      ....
      #..#
      #..#
      .###
      ...#
      .##.
    `,
  ],
  [
    'z',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ....
      ....
      ....
      ####
      ..#.
      .#..
      ####
      ....
    `,
  ],
]);

export const alphabetUppercase: ReadonlyMap<string, Sprite> = new Map<string, Sprite>([
  [
    'A',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .##.
      #..#
      #..#
      ####
      #..#
      #..#
      #..#
      ....
    `,
  ],
  [
    'B',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ###.
      #..#
      #..#
      ###.
      #..#
      #..#
      ###.
      ....
    `,
  ],
  [
    'C',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .##.
      #..#
      #...
      #...
      #...
      #..#
      .##.
      ....
    `,
  ],
  [
    'D',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ###.
      #..#
      #..#
      #..#
      #..#
      #..#
      ###.
      ....
    `,
  ],
  [
    'E',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ####
      #...
      #...
      ####
      #...
      #...
      ####
      ....
    `,
  ],
  [
    'F',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ####
      #...
      #...
      ####
      #...
      #...
      #...
      ....
    `,
  ],
  [
    'G',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .##.
      #..#
      #...
      #...
      #.##
      #..#
      .##.
      ....
    `,
  ],
  [
    'H',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      #..#
      #..#
      #..#
      ####
      #..#
      #..#
      #..#
      ....
    `,
  ],
  [
    'I',
    asciiSprite`
      # Dimensions

      width: ${I_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ###
      .#.
      .#.
      .#.
      .#.
      .#.
      ###
      ...
    `,
  ],
  [
    'J',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ####
      ...#
      ...#
      ...#
      ...#
      #..#
      .##.
      ....
    `,
  ],
  [
    'K',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      #..#
      #..#
      #.#.
      ##..
      #.#.
      #..#
      #..#
      ....
    `,
  ],
  [
    'L',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      #...
      #...
      #...
      #...
      #...
      #...
      ####
      ....
    `,
  ],
  [
    'M',
    asciiSprite`
      # Dimensions

      width: ${M_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      #...#
      ##.##
      #.#.#
      #...#
      #...#
      #...#
      #...#
      .....
    `,
  ],
  [
    'N',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      #..#
      ##.#
      #.##
      #..#
      #..#
      #..#
      #..#
      ....
    `,
  ],
  [
    'O',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .##.
      #..#
      #..#
      #..#
      #..#
      #..#
      .##.
      ....
    `,
  ],
  [
    'P',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ###.
      #..#
      #..#
      ###.
      #...
      #...
      #...
      ....
    `,
  ],
  [
    'Q',
    asciiSprite`
      # Dimensions

      width: ${M_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .###.
      #...#
      #...#
      #...#
      #.#.#
      #..##
      .###.
      .....
    `,
  ],
  [
    'R',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ###.
      #..#
      #..#
      ###.
      #.#.
      #..#
      #..#
      ....
    `,
  ],
  [
    'S',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      .##.
      #..#
      #...
      .##.
      ...#
      #..#
      .##.
      ....
    `,
  ],
  [
    'T',
    asciiSprite`
      # Dimensions

      width: ${I_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ###
      .#.
      .#.
      .#.
      .#.
      .#.
      .#.
      ...
    `,
  ],
  [
    'U',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      #..#
      #..#
      #..#
      #..#
      #..#
      #..#
      .##.
      ....
    `,
  ],
  [
    'V',
    asciiSprite`
      # Dimensions

      width: ${M_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      #...#
      #...#
      #...#
      #...#
      #...#
      .#.#.
      ..#..
      .....
    `,
  ],
  [
    'W',
    asciiSprite`
      # Dimensions

      width: ${M_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      #...#
      #...#
      #...#
      #...#
      #.#.#
      ##.##
      #...#
      .....
    `,
  ],
  [
    'X',
    asciiSprite`
      # Dimensions

      width: ${M_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      #...#
      #...#
      .#.#.
      ..#..
      .#.#.
      #...#
      #...#
      .....
    `,
  ],
  [
    'Y',
    asciiSprite`
      # Dimensions

      width: ${M_WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      #...#
      #...#
      .#.#.
      ..#..
      ..#..
      ..#..
      ..#..
      .....
    `,
  ],
  [
    'Z',
    asciiSprite`
      # Dimensions

      width: ${WIDTH}
      height: ${HEIGHT}

      -------------
      # Colors

      #: 0x000000
      .: transparent

      -------------
      # Layout

      ####
      ...#
      ..#.
      .#..
      #...
      #...
      ####
      ....
    `,
  ],
]);

export const alphabet: ReadonlyMap<string, Sprite> = new Map([
  ...numbers.entries(),
  ...alphabetLowercase.entries(),
  ...alphabetUppercase.entries(),
]);
