import * as React from 'react';
import { observer } from 'mobx-react';
import { Color } from './color';
import {
  ColorPresenter,
  ColorStore,
} from './color_presenter';


export function createColor(): [ColorStore, React.ComponentType] {
  const store = new ColorStore();
  const presenter = new ColorPresenter();

  const onRGBChange = (r: number, g: number, b: number) => {
    presenter.onRGBChange(store, r, g, b);
  };

  const onPalletChange = (pallet: ReadonlyArray<string>) => {
    presenter.onPalletChange(store, pallet);
  };

  const ColorImpl = observer(() => (
      <Color
          r={store.r}
          g={store.g}
          b={store.b}
          pallet={store.pallet}
          onRGBChange={onRGBChange}
          onPalletChange={onPalletChange}
      />
  ));

  return [store, ColorImpl];
}
