export function wait(time: number): Promise<void> {
  return new Promise(r => setTimeout(r, time));
}

export type Clock = {
  now(): number,
};

export function getClock(): Clock {
  const tz = new Date().getTimezoneOffset();
  const tzMsDiff = tz * 1000 * 60;

  // we aren't too worried about accuracy tbh.
  return {
    now() {
      return Date.now() - tzMsDiff;
    },
  };
}

export type TimeSpan =
  | 'year'
  | 'month'
  | 'day-of-month'
  | 'hour'
  | 'minute'
  | 'second'
  | 'milliseconds';

export type PartialTime =
  | { kind: 'y', time: [number] }
  | { kind: 'ym', time: [number, number] }
  | { kind: 'ymd', time: [number, number, number] }
  | { kind: 'ymd-h', time: [number, number, number, number] }
  | { kind: 'ymd-hm', time: [number, number, number, number, number] }
  | { kind: 'ymd-hms', time: [number, number, number, number, number, number] }
  | { kind: 'ymd-hms:m', time: [number, number, number, number, number, number, number] };

type Ord = 'gt' | 'eq' | 'lt';

export function equalOrGreaterThan(clock: Clock, time: PartialTime): boolean {
  const order = partialTimeOperation(
      new Date(clock.now()),
      time,
      (date: number, time: number): Ord => date > time ? 'gt' : date === time ? 'eq' : 'lt',
      (a: Ord, b: Ord): Ord => a === 'gt' || a === 'lt' ? a : b,
      'eq',
  );

  return order === 'gt' || order === 'eq';
}


function partialTimeOperation<T, R>(
    date: Date,
    time: PartialTime,
    operation: (a: number, b: number) => T,
    combinator: (acc: R, next: T) => R,
    init: R,
): R {
  return iteratePartialTime(time, (span: TimeSpan, amount: number): T => {
    switch (span) {
      case 'year': return operation(date.getFullYear(), amount);
      case 'month': return operation(date.getMonth(), amount);
      case 'day-of-month': return operation(date.getDate() - 1, amount);
      case 'hour': return operation(date.getHours(), amount);
      case 'minute': return operation(date.getMinutes(), amount);
      case 'second': return operation(date.getSeconds(), amount);
      case 'milliseconds': return operation(date.getMilliseconds(), amount);
      default: throw new Error();
    }
  }).reduce(combinator, init);
}

export type TimeSpanApply<T> = (span: TimeSpan, amount: number) => T;

const spans: readonly TimeSpan[] = [
  'year',
  'month',
  'day-of-month',
  'hour',
  'minute',
  'second',
  'milliseconds',
];

export function iteratePartialTime<T>(
    time: PartialTime,
    apply: TimeSpanApply<T>,
): T[] {
  return time.time.map((t, i) => apply(spans[i], t));
}

export function iteratePartialTimeWithPlaceholder<T>(
    time: PartialTime,
    placeholder: number,
    apply: TimeSpanApply<T>,
): T[] {
  return spans.map((span, i) => {
    const t = i >= time.time.length ? placeholder : time.time[0];
    return apply(span, t);
  });
}

export function createDate(time: PartialTime): Date {
  const d = new Date();

  iteratePartialTimeWithPlaceholder(time, 0, (span: TimeSpan, amount: number): void => {
    switch (span) {
      case 'year':
        d.setFullYear(amount);
        break;

      case 'month':
        d.setMonth(amount);
        break;

      case 'day-of-month':
        d.setDate(amount + 1);
        break;

      case 'hour':
        d.setHours(amount);
        break;

      case 'minute':
        d.setMinutes(amount);
        break;

      case 'second':
        d.setSeconds(amount);
        break;

      case 'milliseconds':
        d.setMilliseconds(amount);
        break;

      default:
        throw new Error(span + ' is not a span');
    }
  });

  return d;
}
