import classNames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';
import * as Resume from '@akst.io/lib/resume/types';
import { Blink } from '@akst.io/web-resume-dom/ui/base/blink/blink';
import {
  retroPurple,
  retroRed,
  white,
} from '@akst.io/web-resume-dom/ui/base/color/colors';
import { Marquee } from '@akst.io/web-resume-dom/ui/base/marquee/marquee';
import { useMeasureMap } from '@akst.io/web-resume-dom/ui/base/responsive/measure';
import {
  BitchImLeviating,
  useChildrenAsTextAnimationProps,
  useRainbowAnimation,
  useWaveAnimation,
} from '@akst.io/web-resume-dom/ui/base/typography/animated_typography';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import * as Typography from '@akst.io/web-resume-dom/ui/base/typography/typography';

export const List = styled.ul`
  margin: 0;
  padding-top: ${baseUnit * 2}px;
  padding-bottom: ${baseUnit * 2}px;
  padding-left: ${baseUnit * 4}px;
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${baseUnit}px;
`;

export const ListItem = React.memo(({
  children,
}: {
  children: any,
}) => (
    <li><Normal>{children}</Normal></li>
));

const rotations = [{
  animationForce: 0.01,
  animationDelay: 0,
  animationDuration: 2500,
}, {
  animationForce: -0.01,
  animationDelay: 0,
  animationDuration: 3000,
}] as const;

const translations = [{
  animationForce: 4,
  animationDelay: 0,
  animationDuration: 2500,
}, {
  animationForce: 4,
  animationDelay: 0,
  animationDuration: 4000,
}] as const;

export const Heading = React.memo(({
  children,
  subHeading,
}: {
  children: string
  subHeading: undefined | string | JSX.Element | JSX.Element[],
}) => {
  const {
    elements: title,
    elementStyles: titleStyles,
  } = useWaveAnimation({
     displacement: 2,
     animationDistance: 10,
     animationDuration: 1000,
     children: useChildrenAsTextAnimationProps(children),
  });

  const subHeadingEl = subHeading && (
      <>
        <br/>
        <Typography.Span
            size="h1"
            weight="bold"
            margins="none"
            style="funny"
            letterSpacing="wide"
        >
          <HeadingSubHeading marquee={true}>{subHeading}</HeadingSubHeading>
        </Typography.Span>
      </>
  );

  return (
      <HeadingOuter>
        <BitchImLeviating rotations={rotations} yTranslations={translations}>
          <HeadingInner>
            <Typography.Heading
                style="monospace"
                display="inline-block"
                weight="bold"
                margins="none"
            >
              <span style={titleStyles}>{title}</span>
              {subHeadingEl}
            </Typography.Heading>
          </HeadingInner>
        </BitchImLeviating>
      </HeadingOuter>
  );
});

const HeadingInner = styled.div`
  display: inline-block;
  background:
      linear-gradient(
          to bottom right,
          ${retroRed},
          ${retroPurple});
  box-shadow: ${retroRed} 4px 4px, ${retroPurple} 8px 8px;
  text-shadow: ${retroRed} 3px 3px, ${retroPurple} 6px 6px;
  padding: ${baseUnit * 2}px;
  color: ${white};
`;

const HeadingOuter = styled.div`
  padding-top: ${baseUnit * 3}px;
  padding-bottom: ${baseUnit * 3}px;
`;

export const HeadingSubHeading = React.memo(({
  children,
  marquee,
}: {
  children: string | JSX.Element | JSX.Element[],
  marquee: boolean,
}) => {
  const {
    elements: subtitle,
    elementStyles: subtitleStyles,
  } = useWaveAnimation({
     displacement: 2,
     animationDistance: 10,
     animationDuration: 1000,
     children: useChildrenAsTextAnimationProps(children),
  });

  const content = <span style={subtitleStyles}>{subtitle}</span>;

  return marquee ? <Marquee children={content}/> : content;
});

export const SubHeading = React.memo(({
  children,
  margin,
  marquee,
}: {
  children: string | JSX.Element | JSX.Element[],
  marquee: boolean,
  margin: boolean
}) => (
    <SubHeadingContainer $marginBottom={margin ? baseUnit : 0}>
      <Typography.SubHeading
          weight="bold"
          margins="none"
          style="funny"
          letterSpacing="wide"
      >
        <HeadingSubHeading marquee={marquee}>{children}</HeadingSubHeading>
      </Typography.SubHeading>
    </SubHeadingContainer>
));

const SubHeadingContainer = styled.div<{
  $marginBottom: number,
}>`
  background:
      linear-gradient(
          to bottom right,
          ${retroRed},
          ${retroPurple});
  box-shadow: ${retroRed} 4px 4px, ${retroPurple} 8px 8px;
  text-shadow: ${retroRed} 3px 3px, ${retroPurple} 6px 6px;
  padding: ${baseUnit}px;
  color: ${white};
  margin-bottom: ${({ $marginBottom }) => $marginBottom}px;
`;

export const SubHeadingWithTimeAndTagline = React.memo(({
  id,
  children,
  tagline,
  subtagline,
  tenure,
}: {
  id?: string,
  children: string | JSX.Element | JSX.Element[],
  tagline: string | JSX.Element | JSX.Element[],
  subtagline?: string | JSX.Element | JSX.Element[],
  tenure: Resume.Tenure,
}) => {
  const [mode, setElement] = useMeasureMap<any, 'split' | 'column'>((snapshot) => {
    return snapshot == null || snapshot.contentRect.width > 500
        ? 'split'
        : 'column';
  }, []);

  const Type = subtagline == null || mode === 'column'
      ? Typography.Normal
      : Typography.Small;


  let rootStyles: React.CSSProperties;
  let Side: React.ComponentType<{ children: any }>;
  if (mode === 'split') {
    rootStyles = { gridTemplateColumns: '1fr auto' };
    Side = SubheadingTaglineSideAsCol;
  } else {
    rootStyles = { gridTemplateColumns: '1fr' };
    Side = SubheadingTaglineSideAsRow;
  }

  return (
    <SubheadingWithTimeAndTaglineContainer
        id={id}
        style={rootStyles}
        ref={setElement}
    >
      <SubHeading marquee={false} margin={false}>{children}</SubHeading>
      <Side>
        <Type
            margins="none"
            style="monospace"
            lineHeight="1"
            letterSpacing="wide"
        >
          <Tenure tenure={tenure}/>
        </Type>
        <Type
            margins="none"
            style="monospace"
            lineHeight="1"
            letterSpacing="wide"
        >
          <strong>{tagline}</strong>
        </Type>
        {subtagline && (
            <Type
                margins="none"
                style="monospace"
                lineHeight="1"
                letterSpacing="wide"
            >
              <em><strong>{subtagline}</strong></em>
            </Type>
        )}
      </Side>
    </SubheadingWithTimeAndTaglineContainer>
  );
});

const SubheadingWithTimeAndTaglineContainer = styled.div`
  display: grid;
  grid-gap: ${baseUnit * 3}px ${baseUnit * 4}px;
  align-items: center;
`;

const SubheadingTaglineSideAsCol = styled.div`
  display: grid;
  gap: ${baseUnit}px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  padding-top: ${baseUnit}px;
  justify-items: flex-end;
`;

const SubheadingTaglineSideAsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${baseUnit}px;
  justify-content: flex-start;
  align-items: center;
`;

export const SubSubHeading = React.memo(({
  children,
  brightness = 0.75,
}: {
  children: string | JSX.Element | JSX.Element[]
  brightness?: number,
}) => {
  const {
    elements: title,
    elementStyles: titleStyles,
  } = useWaveAnimation({
     displacement: 2,
     animationDistance: 10,
     animationDuration: 1000,
     children: useRainbowAnimation({
       brightness: 0.6,
       animationDuration: 2500,
       children: useChildrenAsTextAnimationProps(children),
     }),
  });

  return (
    <Typography.SubHeading margins="none" weight="bold" style="funny" letterSpacing="wide">
      <span style={titleStyles}>{title}</span>
    </Typography.SubHeading>
  );
});

export const RainboxText = React.memo(({
  children,
  brightness = 0.75,
}: {
  brightness?: number,
  children: string | JSX.Element | JSX.Element[]
}) => {
  const {
    elements: title,
    elementStyles: titleStyles,
  } = useWaveAnimation({
     displacement: 2,
     animationDistance: 5,
     animationDuration: 1000,
     children: useRainbowAnimation({
       brightness,
       animationDuration: 2500,
       children: useChildrenAsTextAnimationProps(children),
     }),
  });

  return (
      <span style={titleStyles}>{title}</span>
  );
});

export const Normal = React.memo(({
  children,
}: {
  children: any
}) => (
    <Typography.Normal margins="none" style="normal" lineHeight="1.8">
      {children}
    </Typography.Normal>
));

export const Tenure = React.memo(({ tenure }: { tenure: Resume.Tenure }) => (
    <span>
      {tenure.startTime}
      {' - '}
      {tenure.kind === 'on-going' ? 'Present' : tenure.endTime}
    </span>
));
