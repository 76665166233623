import * as React from 'react';
import { getOrSetGet } from '@akst.io/web-resume-dom/base/map';
import { keyboardType, KeyboardType } from '@akst.io/web-resume-dom/ui/base/cross_platform/device_capabilities';

type EventIndex<E> = Map<string, KeyboardEventDescriptor<E>[]>;
type GroupedEvents<E> = { upEvents: EventIndex<E>, downEvents: EventIndex<E> };

export type KeyboardEventDescriptor<E> = {
  handler(event: React.KeyboardEvent<E>): boolean | undefined | void;
  type: 'down' | 'up',
  key: string;
  meta?: boolean;
  ctrl?: boolean;
  alt?: boolean;
  keyboardType?: Set<KeyboardType>;
};

function modifierScore(d: KeyboardEventDescriptor<any>) {
  let m = d.meta ? 0b001 : 0;
  let c = d.meta ? 0b010 : 0;
  let a = d.meta ? 0b100 : 0;
  return m + c + a;
}

function compare(a: KeyboardEventDescriptor<any>, b: KeyboardEventDescriptor<any>) {
  const as = modifierScore(a);
  const bs = modifierScore(b);
  if (as > bs) return -1;
  if (as < bs) return 1;
  if (a.key > b.key) return -1;
  if (a.key < b.key) return 1;
  return 0;
}

/**
 * Rebinds the specified events when the changeArray
 * changes. No attempt is made to provide a sensible
 * default, as a number of things could influence the
 * values of descriptors, and it would be nice to
 * inline the descriptors as objects.
 */
export function useKeyboardEventsOnElement<E extends Node>(
    element: E | undefined | null,
    events: ReadonlyArray<KeyboardEventDescriptor<E>>,
    changeArray: ReadonlyArray<any>,
) {

  const { upEvents, downEvents }: GroupedEvents<E> = React.useMemo(() => {
    const ownKeyboardType = keyboardType();
    const downEvents: EventIndex<E> = new Map();
    const upEvents: EventIndex<E> = new Map();

    for (const descriptor of events) {
      const target = descriptor.keyboardType;
      if (target != null && !target.has(ownKeyboardType)) continue;

      const map = descriptor.type === 'down' ? downEvents : upEvents;
      const eventsForKey = getOrSetGet(map, descriptor.key, []);
      eventsForKey.push(descriptor);
    }

    sortEventGroup(upEvents);
    sortEventGroup(downEvents);

    return { upEvents, downEvents };
  }, [element, ...changeArray]);

  const onKeyDown = React.useCallback((event: React.KeyboardEvent<E>) => {
    fireHandler(event, downEvents);
  }, [element, downEvents]);

  const onKeyUp = React.useCallback((event: React.KeyboardEvent<E>) => {
    fireHandler(event, upEvents);
  }, [element, upEvents]);

  React.useEffect(() => {
    if (element == null) return;

    element.addEventListener('keyup', onKeyUp as any);
    element.addEventListener('keydown', onKeyDown as any);

    return () => {
      element.removeEventListener('keyup', onKeyUp as any);
      element.removeEventListener('keydown', onKeyDown as any);
    };
  }, [element, ...changeArray]);
}

export function useKeyboardEvents<E extends Node>({ events, changeArray = [] }: {
    events: ReadonlyArray<KeyboardEventDescriptor<E>>;
    changeArray?: ReadonlyArray<any>;
}): (ref: E) => void {
  const [element, setElement] = React.useState<E>();
  useKeyboardEventsOnElement(element, events, changeArray);
  return setElement;
}

function fireHandler<E>(event: React.KeyboardEvent<E>, index: EventIndex<E>): boolean | undefined | void {
  const descriptors = index.get(event.key);
  if (!descriptors) return;

  for (const descriptor of descriptors) {
    if (descriptor.ctrl && !event.getModifierState('Control')) continue;
    if (descriptor.meta && !event.getModifierState('Meta')) continue;
    if (descriptor.alt && !event.getModifierState('Alt')) continue;
    return descriptor.handler(event);
  }
}

function sortEventGroup(index: EventIndex<any>) {
  for (const [k, v] of index.entries()) {
    index.set(k, v.sort(compare));
  }
}
