import {
  GetVoicesRequest,
  SayRequest,
  SpeechService,
} from './speech_service';

export class SpeechServiceImpl implements SpeechService {
  constructor(
      private readonly speechSynthesis: SpeechSynthesis,
      private readonly createUtterance: (t: string) => SpeechSynthesisUtterance,
  ) {
  }

  getVoices({ langQuery }: GetVoicesRequest) {
    let voices = this.speechSynthesis.getVoices();
    if (langQuery != null) {
      voices = voices.filter(it => it.voiceURI.includes(langQuery));
    }
    return { voices };
  }

  say({
    voiceURI,
    message,
    volume = 1,
    pitch = 1,
    rate = 1,
  }: SayRequest) {
    if (voiceURI == null) return;

    const utterance = this.createUtterance(message);
    const voice = this.speechSynthesis.getVoices()
        .find(voice => voice.voiceURI === voiceURI);

    if (voice) {
      utterance.voice = voice;
    }

    utterance.volume = volume;
    utterance.pitch = pitch;
    utterance.rate = rate;
    this.speechSynthesis.speak(utterance);
  }

  static create() {
    return new SpeechServiceImpl(
        window.speechSynthesis,
        (t) => new SpeechSynthesisUtterance(t),
    );
  }
}
