import { UnreachableError } from '@akst.io/lib/base/types';
import * as Result from '@akst.io/lib/base/result';

type Pending<V, E> = { kind: 'pending', promise: Promise<Result.T<V, E>> };

export type Finished<V, E> =
  | { kind: 'value', value: V }
  | { kind: 'error', error: E };

export type Started<V, E> =
  | Pending<V, E>
  | Finished<V, E>;

export type T<V, E = undefined> =
  | { kind: 'initial' }
  | Pending<V, E>
  | Finished<V, E>;

export function Init<V, E>(): T<V, E> {
  return { kind: 'initial' };
}

export function hasStarted<V, E>(s: T<V, E>): s is Started<V, E> {
  return s.kind !== 'initial';
}

export function start<V, E>(
    s: T<V, E>,
    exec: () => Promise<Result.T<V, E>>,
): Started<V, E> {
  switch (s.kind) {
    case 'initial':
      return { kind: 'pending', promise: exec() };

    case 'pending':
    case 'value':
    case 'error':
      return s;
    default:
      throw new UnreachableError(s);
  }
}

export async function toResult<V, E>(started: Started<V, E>): Promise<[Finished<V, E>, Result.T<V, E>]> {
  switch (started.kind) {
    case 'value':
      return [started, Result.Ok(started.value)];

    case 'error':
      return [started, Result.Err(started.error)];

    case 'pending': {
      const result = await started.promise;

      if (result.ok) {
        return [{ kind: 'value', value: result.value }, result];
      } else {
        return [{ kind: 'error', error: result.error }, result];
      }
    }
    default:

      throw new UnreachableError(started);
  }
}
