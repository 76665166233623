import { observer } from 'mobx-react';
import * as React from 'react';
import { UnreachableError } from '@akst.io/lib/base/types';
import resumeConfig from 'url:@akst.io/data-resume/resume.txt';
import * as Resume from '@akst.io/lib/resume/types';
import { wait } from '@akst.io/web-resume-dom/base/wait';
import { ApplicationService } from '@akst.io/web-resume-dom/services/application/application_service';
import { ApplicationInstance } from '@akst.io/web-resume-dom/services/application/types';
import { AudioService } from '@akst.io/web-resume-dom/services/audio/audio_service';
import { DeviceService } from '@akst.io/web-resume-dom/services/device/device_service';
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { ResourceService } from '@akst.io/web-resume-dom/services/resource/resource_service';
import { AssistantController } from '@akst.io/web-resume-dom/ui/application/assistant/type';
import { ImageProps } from '@akst.io/web-resume-dom/ui/system/image/image';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import {
  Experience,
} from './pages/experience/experience';
import { Education } from './pages/education/education';
import { Interests } from './pages/interests/interests';
import { Loading } from './pages/loading/loading';
import { References } from './pages/references/references';
import { Skills } from './pages/skills/skills';
import { Welcome } from './pages/welcome/welcome';
import { Link, LinkProps, ExternalLink } from '../ui/link/link';
import { OnStartResult, ResumeTab, TabsStore, TabsPresenter } from './tabs_presenter';
import { Navigation, ResumeContent, ResumeWindow } from './tabs';
import profilePicture from './angus.jpg';

export type Services = {
  applicationService: ApplicationService,
  audioService: AudioService,
  deviceService: DeviceService,
  loggingService: LoggingService,
  resourceService: ResourceService,
};

function createExternalLink(href: string) {
  return ({ children }: { children: any }) => (
      <ExternalLink href={href}>{children}</ExternalLink>
  );
}

const SKETCH_LINK = 'https://pbs.twimg.com/media/E0lHKyAVUAA5GoP?format=jpg&name=large';

export function createTabs({
  assistantController,
  services: {
    applicationService,
    audioService,
    deviceService,
    resourceService,
  },
  Image,
  Panel,
}: {
  assistantController: AssistantController,
  services: Services,
  Image: React.ComponentType<ImageProps>,
  Panel: React.ComponentType<PanelProps>,
}): ApplicationInstance  & {
  onStart(procId: number): OnStartResult,
} {
  const store = new TabsStore();
  const presenter = new TabsPresenter(
      applicationService,
      assistantController,
      audioService,
      deviceService,
      resourceService,
      resumeConfig,
      wait,
  );

  const onNavigate = (tab: ResumeTab) => presenter.navigateTo(store, tab);

  const OpenSketch = createExternalLink(SKETCH_LINK);

  const LinkImpl = (props: LinkProps) => (
      <Link onNavigateClick={onNavigate} {...props}/>
  );

  const downloadSection: 'top' | 'bottom' =
      deviceService.hasTouchScreen()
          ? 'top'
          : 'bottom';

  const WelcomeImpl = createResumeSection({
    store,
    Body: (props: SectionProps) => (
        <Welcome
            downloadSection={downloadSection}
            about={props.resume.about}
            profilePicture={profilePicture}
            Link={LinkImpl}
            Image={Image}
        />
    ),
  });

  const InterestsImpl = createResumeSection({
    store,
    Body: (props: SectionProps) => (
        <Interests OpenSketch={OpenSketch}/>
    ),
  });

  const ReferenceImpl = createResumeSection({
    store,
    Body: (props: SectionProps) => (
        <References
            reference={props.resume.reference}
            experience={props.resume.experince}
        />
    ),
  });

  const EducationImpl = createResumeSection({
    store,
    Body: (props: SectionProps) => (
        <Education education={props.resume.education}/>
    ),
  });

  const ExperienceImpl = createResumeSection({
    store,
    Body: (props: SectionProps) => (
      <Experience
        skills={props.resume.skill}
        experience={props.resume.experince}
        kind="paid"
      />
    ),
  });

  const VolunteerImpl = createResumeSection({
    store,
    Body: (props: SectionProps) => (
      <Experience
        skills={props.resume.skill}
        experience={props.resume.volunteering}
        kind="volunteer"
      />
    ),
  });

  const SkillImpl = createResumeSection({
    store,
    Body: (props: SectionProps) => (
        <Skills skill={props.resume.skill}/>
    ),
  });

  const Content = observer(() => (
      <ResumeContent
          tab={presenter.getCurrentPage(store)}
          Experience={ExperienceImpl}
          Volunteer={VolunteerImpl}
          Education={EducationImpl}
          Interests={InterestsImpl}
          Loading={Loading}
          References={ReferenceImpl}
          Skills={SkillImpl}
          Welcome={WelcomeImpl}
      />
  ));

  const onBack = () => presenter.goBack(store);

  const NavigationImpl = observer(() => (
      <Navigation
          onBack={onBack}
          breadCrumbs={presenter.getBreadCrumbs(store)}
      />
  ));

  const onQuit = () => presenter.quit(store);
  const onMinimize = () => presenter.minimize(store);

  return {
    onStart: (procId: number) => presenter.onStart(store, procId),
    controller: { onQuit, title: 'Angus' },
    Component: observer(() => {
      const position = React.useMemo(() => presenter.getInitPosition(), []);
      return (
          <ResumeWindow
              position={position}
              onMinimize={onMinimize}
              Content={Content}
              Navigation={NavigationImpl}
              Panel={Panel}
          />
      );
    }),
  };
}


type SectionProps = { resume: Resume.Resume };

const createResumeSection = ({
  store,
  Body,
}: {
  store: TabsStore,
  Body: React.ComponentType<SectionProps>,
}): React.ComponentType => observer(() => {
  const { resume } = store;
  switch (resume.kind) {
    case 'initial':
    case 'pending':
      return <Loading/>;

    case 'error':
      return <div>An error occured</div>;

    case 'value':
      return <Body resume={resume.value}/>;

    default:
      throw new UnreachableError(resume);
  }
});
