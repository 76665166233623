import { observer } from 'mobx-react';
import * as React from 'react';
import { FileSystemService } from '@akst.io/web-resume-dom/services/file_system/file_system_service';
import { FileSpriteProps } from '@akst.io/web-resume-dom/ui/system/file_sprite/file_sprite';
import type { UseDragSlot } from '@akst.io/web-resume-dom/ui/system/draggable/create';
import type { DragPointProps } from '@akst.io/web-resume-dom/ui/system/draggable/draggable';
import { PanelImpl, PanelProps } from './panel';
import { createPanelToolBar, PanelToolBarProps } from './panel_toolbar/panel_toolbar';

type CreateOptions = {
  DragPoint: React.ComponentType<DragPointProps>,
  FileSprite: React.ComponentType<FileSpriteProps>,
  fileSystemService: FileSystemService,
  useDragSlot: UseDragSlot,
};

export function createPanel({
  DragPoint,
  fileSystemService,
  FileSprite,
  useDragSlot,
}: CreateOptions): React.ComponentType<PanelProps> {
  const PanelToolBar = createPanelToolBar({ FileSprite });
  const PanelToolBarImpl = React.memo((props: PanelToolBarProps) => (
      <DragPoint>
        <PanelToolBar {...props}/>
      </DragPoint>
  ));

  return observer((props: PanelProps) => {
    const { pathOfOpenFile, ...otherProps } = props;
    const file = pathOfOpenFile && fileSystemService.getLiveFileSystemNode(pathOfOpenFile);

    return (
        <PanelImpl
            file={file}
            PanelToolBar={PanelToolBarImpl}
            useDragSlot={useDragSlot}
            {...otherProps}
        />
    );
  });
}
