import { action, observable, makeObservable } from 'mobx';
import { checkExists } from '@akst.io/lib/base/types';
import { FileSystemService } from '@akst.io/web-resume-dom/services/file_system/file_system_service';
import {
  isLiveDirectory,
  isLiveFile,
  LiveDirectory,
  LiveFile,
  LiveFileSystemNode,
  isLiveShortcut,
} from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';

export class FileNavigatorStore {
  directory: LiveDirectory;
  backHistory: LiveDirectory[] = [];
  forwardHistory: LiveDirectory[] = [];

  constructor(directory: LiveDirectory) {
    this.directory = directory;

    makeObservable(this, {
      directory: observable.ref,
      backHistory: observable.deep,
      forwardHistory: observable.deep
    });
  }
}

export class FileNavigatorPresenter {
  constructor(

      private readonly fileSystemService: FileSystemService,
  ) {
    makeObservable<FileNavigatorPresenter, "enterDirectoryOutsideOfHistoryNavigation">(this, {
      onHistoryBack: action,
      onHistoryForward: action,
      enterDirectoryOutsideOfHistoryNavigation: action
    });
  }

  onFileOpen(
      store: FileNavigatorStore,
      file: LiveFileSystemNode,
      onFileClicked: (file: LiveFile) => void,
  ) {
    let resolvedFile = isLiveShortcut(file)
        ? this.fileSystemService.lookupShortcut(file)
        : file;

    if (resolvedFile == null) return;

    if (isLiveDirectory(resolvedFile)) {
      this.enterDirectoryOutsideOfHistoryNavigation(store, resolvedFile);
    } else if (isLiveFile(resolvedFile)) {
      onFileClicked(resolvedFile);
    }
  }

  onHistoryBack(store: FileNavigatorStore) {
    if (store.backHistory.length > 0) {
      const last = checkExists(store.backHistory.pop(), 'history');
      store.forwardHistory.push(store.directory);
      store.directory = last;
    }
  }

  onHistoryForward(store: FileNavigatorStore) {
    if (store.forwardHistory.length > 0) {
      const last = checkExists(store.forwardHistory.pop(), 'history');
      store.backHistory.push(store.directory);
      store.directory = last;
    }
  }

  onGoToParent(store: FileNavigatorStore) {
    if (store.directory.path.length > 0) {
      const currPath = store.directory.path;
      const nextPath = currPath.slice(0, currPath.length - 1);
      const directory = this.fileSystemService.getLiveDirectory(nextPath);
      this.enterDirectoryOutsideOfHistoryNavigation(store, directory);
    }
  }

  private enterDirectoryOutsideOfHistoryNavigation(store: FileNavigatorStore, directory: LiveDirectory) {
     store.backHistory.push(store.directory);
     store.forwardHistory = [];
     store.directory = directory;
  }
}
