export function rgbToHex(r: number, g: number, b: number): number {
  return r << 16 | g << 8 | b;
}

export function rgbToHexString(r: number, g: number, b: number): string {
  return hexAsString(rgbToHex(r, g, b));
}

export function hexAsString(fill: number): string {
  return `#${fill.toString(16).padStart(6, '0')}`;
}

export function parseHexString(fillIn: string): number | undefined {
  const ZERO_CHAR_CODE = '0'.charCodeAt(0);
  const NINE_CHAR_CODE = '9'.charCodeAt(0);
  const A_CHAR_CODE = 'a'.charCodeAt(0);
  const F_CHAR_CODE = 'f'.charCodeAt(0);

  const fill = fillIn.toLowerCase();

  if (fill.length !== 8) return;
  if (fill.slice(0, 2) !== '0x') return;
  for (let i = 0; i < 6; i++) {
    let c = fill[i + 2];
    const cc = c.charCodeAt(0);
    const DIGIT_CHAR = cc >= ZERO_CHAR_CODE && cc <= NINE_CHAR_CODE;
    const ALPHA_CHAR = cc >= A_CHAR_CODE && cc <= ZERO_CHAR_CODE;
    if (DIGIT_CHAR || ALPHA_CHAR) continue;

    return;
  }

  return parseInt(fill.slice(2), 16);
}
