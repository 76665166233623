import * as React from 'react';
import styled from 'styled-components'

import { intenseBlue } from '@akst.io/web-resume-dom/ui/base/color/colors';

import { StepProps } from '../types';

export type BluescreenStepProps = StepProps;

export const BluescreenStep = React.memo((props: BluescreenStepProps) => (
  <Background>
    <Copy>
      <TextChunk>
        A problem has been detect and Michealsoft Windows™️
        has been shut down to pervent damage to your
        computer.
        <br/>
        <br/>
      </TextChunk>
      <TextChunk>
        ILLEGAL_WINDOW_SCALING_EVENT
      </TextChunk>
      <TextChunk>
        If this is the first time you've seen this
        Stop error screen, restart your computer.
        If this screen appears again, follow these
        steps:
      </TextChunk>
      <TextChunk>
        Check to make sure any new hardware of
        software is propertly installed. If
        this is a new installation, ask your
        hardware or software manufacturer for
        any Michealsoft Windows updates you
        might need.
      </TextChunk>
      <TextChunk>
        If Problems contrinue, disable or remove
        any newly installed hardware or software.
        Disable BIOS memory options such as caching
        or shadowing. If you need to use Safe Mode to
        remove or disable componets, restar your
        computed, press F8 to select Adevanced Startup
        options, and then select Safe Mode.
      </TextChunk>
      <TextChunk>
        Technical infromation:
      </TextChunk>
      <TextChunk>
        --- STOP: 0x000000EA (0x00000000, 0x00000000)
      </TextChunk>
    </Copy>
  </Background>
));

const Background = styled.div`
  color: white;
  background: ${intenseBlue};
  height: 100vh;
  width: 100vw;
  font-family: monospace;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Copy = styled.div`
  max-width: 80vw;
`;

const TextChunk = styled.p`
  font-family: Menlo, monospace;
  font-weight: 700;
  font-size: 2vmin;
`;

