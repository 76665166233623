import { UnreachableError } from '@akst.io/lib/base/types';
import * as Result from '@akst.io/lib/base/result';
import * as t from './resource_service';
import { loadImage, decodeAudio } from './resource_service_impl_util';

export function createResourceService({
  audioContext,
}: {
  audioContext: AudioContext,
}) {
  return new ResourceServiceImpl(
      window.fetch.bind(window),
      loadImage,
      buffer => decodeAudio(audioContext, buffer),
      new Map(),
      new Map(),
      new Map(),
  );
}

type ImageLoader = typeof loadImage;
type DecodeAudio = (arrayBuffer: ArrayBuffer) => Promise<Result.T<AudioBuffer, Error>>;

export class ResourceServiceImpl implements t.ResourceService {
  constructor(
      private readonly fetch: typeof window.fetch,
      private readonly loadImage: ImageLoader,
      private readonly decodeAudioBuffer: DecodeAudio,
      private readonly unicodeCache: Map<string, string>,
      private readonly imageCache: Map<string, HTMLImageElement>,
      private readonly audioCache: Map<string, AudioBuffer>,
  ) {
  }

  async findResource({ query: { id }, cache = {} }: t.FindResourceRequest): Promise<t.FindResourceResponse> {
    return this.internalFindResource(id, this.hydrateCacheOptions(cache));
  }

  async findResources({ query, cache = {} }: t.FindResourcesRequest): Promise<t.FindResourcesResponse> {
    const hyrated = this.hydrateCacheOptions(cache);
    const results = [];

    for (const id of query.ids) {
      const result = await this.internalFindResource(id, hyrated);
      if (!result.ok) return result;
      results.push(result.value);
    }

    return Result.Ok(results);
  }

  private hydrateCacheOptions(cache: Partial<t.CacheOptions>): t.CacheOptions {
    return {
      sourceOrder: ['cache', 'network'],
      writeCache: true,
      ...cache,
    }
  }

  private internalFindResource(id: t.ResourceId, cache: t.CacheOptions): Promise<Result.T<t.Resource, Error>> {
    switch (id.kind) {
      case 'audio':
        return fetchFromSource<AudioBuffer>({
          url: id.url,
          cacheOptions: cache,
          cache: this.audioCache,
          find: this.loadAudio.bind(this),
          toResource: data => ({ data, kind: 'audio' }),
        });

      case 'image':
        return fetchFromSource<HTMLImageElement>({
          url: id.url,
          cacheOptions: cache,
          cache: this.imageCache,
          find: this.loadImage,
          toResource: data => ({ data, kind: 'image' }),
        });

      case 'unicode':
        return fetchFromSource<string>({
          url: id.url,
          cacheOptions: cache,
          cache: this.unicodeCache,
          find: this.loadUnicode.bind(this),
          toResource: data => ({ data, kind: 'unicode' }),
        });

      default:
        throw new UnreachableError(id.kind);
    }
  }

  private async loadUnicode(url: string): Promise<Result.T<string, Error>> {
    try {
      const response = await this.fetch(url);
      return Result.Ok(await response.text());
    } catch (error) {
      return Result.Err(error as Error);
    }
  }

  private async loadAudio(url: string): Promise<Result.T<AudioBuffer, Error>> {
    try {
      const response = await this.fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      return this.decodeAudioBuffer(arrayBuffer);
    } catch (error) {
      return Result.Err(error as Error);
    }
  }
}

async function fetchFromSource<T>({
  url,
  cacheOptions: { sourceOrder, writeCache },
  cache,
  find,
  toResource,
}: {
  url: string,
  cacheOptions: t.CacheOptions,
  cache: Map<string, T>,
  find: (url: string) => Promise<Result.T<T, Error>>,
  toResource: (data: T) => t.Resource,
}): Promise<Result.T<t.Resource, Error>> {
  let result: Result.T<t.Resource, Error> = Result.Err(new Error());

  for (const source of sourceOrder) {
    if (source === 'network') {
      const resource = await find(url);

      if (!resource.ok) {
        result = resource;
        continue;
      }

      if (writeCache) {
        cache.set(url, resource.value);
      }

      return Result.Ok(toResource(resource.value));
    } else if (source === 'cache') {
      const resource = cache.get(url);
      if (resource == null) continue;
      return Result.Ok(toResource(resource));
    } else {
      throw new UnreachableError(source);
    }
  }

  return result;
}
