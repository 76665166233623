import { action, computed, runInAction, observable, makeObservable } from 'mobx';
import { checkExists } from '@akst.io/lib/base/types';
import { enumerate } from '@akst.io/web-resume-dom/base/iterator';
import {
  LiveDirectory,
  LiveFileSystemNode,
} from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';

export type FlexDirection = 'column' | 'row';
export type File = { name: string };
export type FileGridStoreFactory = (
  directory: LiveDirectory,
  flexDirection: FlexDirection,
  width: number,
  height: number,
) => FileGridStore;

export type FileIconDimensionDescriptor = {
  width: number;
  // icons are square so this is width & height
  iconSize: number;

  textMarginTop: number;
  textFontSize: number;
  textLineHeight: number;
  textLineCount: number;
};

export class GridSlot {
  file?: LiveFileSystemNode = undefined;

  constructor(
      readonly x: number,
      readonly y: number,
      readonly xi: number,
      readonly yi: number,
  ) {
    makeObservable(this, {
      file: observable.ref
    });
  }
}

export class FileGridStore {
  clickedItem?: LiveFileSystemNode = undefined;
  draggingIndex?: number = undefined;
  directory: LiveDirectory;
  flexDirection: FlexDirection;
  readonly width: number = 0;
  readonly height: number = 0;

  constructor(
      readonly paddingSize: number,
      readonly itemSpacing: number,
      readonly fileDimensions: FileIconDimensionDescriptor,
      directory: LiveDirectory,
      flexDirection: FlexDirection,
      width: number,
      height: number,
  ) {
    this.directory = directory;
    this.flexDirection = flexDirection;
    this.width = width;
    this.height = height;

    makeObservable(this, {
      clickedItem: observable.ref,
      draggingIndex: observable.ref,
      directory: observable.ref,
      flexDirection: observable.ref,
      textMaxHeight: computed,
      fileHeight: computed,
      usableWidth: computed,
      usableHeight: computed,
      verticalIconCapacity: computed,
      horizontalIconCapacity: computed,
      slotInformation: computed
    });
  }

  get textMaxHeight(): number {
    const fd = this.fileDimensions;
    return fd.textFontSize * fd.textLineHeight * fd.textLineCount;
  }

  get fileHeight(): number {
    const fd = this.fileDimensions;
    return fd.iconSize + fd.textMarginTop + this.textMaxHeight;
  }

  get usableWidth(): number {
    return this.width - (this.paddingSize * 2);
  }

  get usableHeight(): number {
    return this.height - (this.paddingSize * 2);
  }

  get verticalIconCapacity(): number {
    return this.calcCapacity(this.usableHeight, this.fileHeight);
  }

  get horizontalIconCapacity(): number {
    return this.calcCapacity(this.usableWidth, this.fileDimensions.width);
  }

  get slotInformation(): ReadonlyArray<GridSlot> {
    const { directory } = this;
    const slots = this.createSlotInformation(directory.unsafeChildren.size);
    if (slots == null) return [];

    for (const [file, index] of enumerate(directory.unsafeChildren.values())) {
      if (slots.length <= index) break;
      slots[index].file = file;
    }

    return slots;
  }

  private createSlotInformation(length: number): ReadonlyArray<GridSlot> | undefined {
    const perCol = this.verticalIconCapacity;
    const perRow = this.horizontalIconCapacity;
    if (perCol === 0 || perRow === 0) return undefined;

    const isCol = this.flexDirection === 'column';
    const linebreakLength = isCol ? perCol : perRow;
    const divLinebreakLen = (index: number) => Math.floor(index / linebreakLength);
    const modLinebreakLen = (index: number) => index % linebreakLength;
    const getXi = isCol ? divLinebreakLen : modLinebreakLen;
    const getYi = isCol ? modLinebreakLen : divLinebreakLen;

    return Array.from({ length }, (_, index) => {
      const xi = getXi(index);
      const yi = getYi(index);
      const x = this.paddingSize + (xi * (this.fileDimensions.width + this.itemSpacing));
      const y = this.paddingSize + (yi * (this.fileHeight + this.itemSpacing));
      return new GridSlot(x, y, xi, yi);
    });
  }

  private calcCapacity(total: number, entry: number): number {
    let count = 0;
    let remaining = total;

    while (remaining - entry > 0) {
      remaining -= (entry + this.itemSpacing);
      count += 1;
    }

    return Math.max(count, 1);
  }
}

export class FileGridPresenter {
  constructor() {
    makeObservable(this, {
      onUnclickFile: action,
      onDragStart: action,
      onDrop: action
    });
  }

  createFactory(
      paddingSize: number,
      itemSpacing: number,
      fileDimensions: FileIconDimensionDescriptor,
  ): FileGridStoreFactory {
    return (
        directory: LiveDirectory,
        flexDirection: FlexDirection,
        width: number,
        height: number,
    ) => (
        new FileGridStore(
            paddingSize,
            itemSpacing,
            fileDimensions,
            directory,
            flexDirection,
            width,
            height,
        )
    );
  }

  onUnclickFile(store: FileGridStore) {
    store.clickedItem = undefined;
  }

  onFileClick(
      store: FileGridStore,
      file: LiveFileSystemNode,
      onFileOpen: (ref: LiveFileSystemNode) => void,
  ) {
    if (store.clickedItem) {
      runInAction(() => store.clickedItem = undefined);
      onFileOpen(file);
    } else {
      runInAction(() => store.clickedItem = file);
    }
  }

  onDragStart(store: FileGridStore, index: number) {
    store.clickedItem = undefined;
    store.draggingIndex = index;
  }

  onDrop(store: FileGridStore, dropIndex: number) {
    const startIndex = checkExists(store.draggingIndex, 'drag index');
    const startSlot = store.slotInformation[startIndex];
    const dropSlot = store.slotInformation[dropIndex]

    if (dropSlot.file == null) {
      const filename = checkExists(startSlot.file, 'file');
      dropSlot.file = filename;
      startSlot.file = undefined;
    }

    store.draggingIndex = undefined;
  }
}
