import * as React from 'react';
import { DragPointProps } from '@akst.io/web-resume-dom/ui/system/draggable/draggable';

export const Assistant = React.memo(({
  setRef,
  DragPoint,
}: {
  setRef: (canvas: HTMLCanvasElement) => void,
  DragPoint: React.ComponentType<DragPointProps>,
}) => {
  return (
    <DragPoint>
      <canvas ref={setRef} style={{
        width: 200,
        height: 200,
      }}/>
    </DragPoint>
  );
});
