import * as React from 'react';
import {
  LocalApplicationStarter,
  LocalApplicationController,
} from '@akst.io/web-resume-dom/services/application/types';
import { ApplicationService } from "@akst.io/web-resume-dom/services/application/application_service";
import { FileSystemService } from "@akst.io/web-resume-dom/services/file_system/file_system_service";
import {
  isLiveFile,
  LiveFileSystemNode,
} from "@akst.io/web-resume-dom/services/file_system/live_files_system_node";
import { SpriteService } from '@akst.io/web-resume-dom/services/sprite/sprite_service';
import { unsafeSpriteCast } from '@akst.io/web-resume-dom/services/sprite/types';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import sprite from '@akst.io/web-resume-dom/ui/sprites/video_sprite.json';
import { VideoViewerStore, VideoViewerPresenter } from './video_viewer_presenter';
import { createVideoViewer } from './video_viewer';

type CreateOptions = {
  applicationService: ApplicationService;
  fileSystemService: FileSystemService;
  spriteService: SpriteService;
  Panel: React.ComponentType<PanelProps>;
};

const applicationId = 'video_viewer';
const FILE_EXTENSION = 'vid';

export function installVideoViewer({
  applicationService,
  fileSystemService,
  spriteService,
  Panel,
}: CreateOptions) {
  const presenter = new VideoViewerPresenter(applicationService);

  class VideoViewerController implements LocalApplicationController {
    constructor(private readonly store: VideoViewerStore) {
    }

    get title(): string {
      return presenter.getTitle(this.store);
    }

    onQuit() {
    }
  }

  const withFile: LocalApplicationStarter = (file: LiveFileSystemNode | undefined, procId: number) => {
    if (!file || !isLiveFile(file)) {
      return { ok: false, error: { type: 'incorrect-file-type', fileType: 'file' } };
    }

    const store = presenter.createStore(procId, file);
    const controller = new VideoViewerController(store);
    const Component = createVideoViewer({ store, presenter, Panel });
    return { ok: true, value: { controller, Component } };
  };


  spriteService.register(
      FILE_EXTENSION,
      'VideoViewer.sprite',
      unsafeSpriteCast(sprite),
  );
  applicationService.register(
      FILE_EXTENSION,
      { initializer: { type: 'local', withFile } },
      ['programs', 'VideoViewer.exe'],
  );
}
