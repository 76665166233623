import { LoggingService } from './logging_service';

type Target = Pick<Console, 'debug' | 'warn' | 'error' | 'info'>;

export class LoggingServiceImpl implements LoggingService {
  private constructor(
      private readonly path: readonly string[],
      private readonly target: Target,
  ) {
  }

  static create(target: Target) {
    return new LoggingServiceImpl([], target);
  }

  debug(...args: any[]) {
    this.target.debug(this.getPathString(), ...args);
  }

  warn(...args: any[]) {
    this.target.warn(this.getPathString(), ...args);
  }

  info(...args: any[]) {
    this.target.info(this.getPathString(), ...args);
  }

  error(...args: any[]) {
    this.target.error(this.getPathString(), ...args);
  }

  createChild(name: string) {
    return new LoggingServiceImpl([...this.path, name], this.target);
  }

  private getPathString() {
    return `[${this.path.join('.')}]`;
  }
}
