import * as Result from '@akst.io/lib/base/result';
import { AssetService } from '@akst.io/web-resume-dom/services/asset/asset_service';
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { AssistantDefinition } from './configs/types';
import { LiveFile } from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';


export type AssistantLoader = (id: string) => Result.T<() => Promise<AssistantDefinition>, string>;
export type Assets = {
  config: AssistantDefinition,
  images: HTMLImageElement,
};

export type ImageConfigState =
  | { kind: 'initial' }
  | { kind: 'error' }
  | { kind: 'loading', promise: Promise<Result.T<Assets, undefined>> }
  | { kind: 'loaded', assets: Assets };

export class AssistantImageStore {
  state: ImageConfigState = { kind: 'initial' };

  constructor(
      readonly id: string,
      readonly file: LiveFile,
  ) {
  }
}

export class AssistantImagePresenter {
  constructor(
      private readonly logging: LoggingService,
      private readonly assetService: AssetService,
      private readonly getConfigLoader: AssistantLoader,
  ) {
  }

  async load(store: AssistantImageStore): Promise<Result.T<Assets, undefined>> {
    if (store.state.kind === 'initial') {
      const promise = this.loadImpl(store);
      store.state = { kind: 'loading', promise };
      return promise;
    } else if (store.state.kind === 'loading') {
      return store.state.promise;
    } else {
      return Result.Err(undefined);
    }
  }

  private async loadImpl(store: AssistantImageStore): Promise<Result.T<Assets, undefined>> {
    this.logging.debug('loading image', store.id);
    const loader = this.getConfigLoader(store.id);
    if (!loader.ok) {
      store.state = { kind: 'error' };
      return Result.Err(undefined);
    }

    try {
      const config = await loader.value();
      this.logging.debug('loading spritesheet', store.id);
      const { image: images } = await this.assetService.loadImageAsset(config.spriteSheet);
      this.logging.debug('finished loading', store.id);
      store.state = { kind: 'loaded', assets: { config, images } };
      return Result.Ok({ config, images });
    } catch (e) {
      store.state = { kind: 'error' };
      this.logging.error(e);
      return Result.Err(undefined);
    }
  }

  isValidImage(store: AssistantImageStore): boolean {
    return this.getConfigLoader(store.id) != null;
  }

  createImage(id: string, file: LiveFile): AssistantImageStore {
    return new AssistantImageStore(id, file);
  }
}
