import styled from 'styled-components';
import { turquoise, white } from '@akst.io/web-resume-dom/ui/base/color/colors';
import { startBarHeight } from './start_bar/start_bar';

export const Background = styled.div`
  position: relative;
  color: ${white};
  background-color: ${turquoise};
  user-select: none;
`;

export const Desktop = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  height: calc(var(--window-height) - ${startBarHeight}px);
`;

export const FileGridContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
