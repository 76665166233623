import { computed, makeObservable } from 'mobx';
import { ApplicationService } from '@akst.io/web-resume-dom/services/application/application_service';
import { FileSystemService } from '@akst.io/web-resume-dom/services/file_system/file_system_service';
import {
  isLiveDirectory,
  isLiveFile,
  LiveFile,
  LiveDirectory,
  LiveFileSystemNode,
  liveFileId,
  isLiveShortcut,
} from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import { FileNavigatorStore } from '@akst.io/web-resume-dom/ui/system/file_navigator/file_navigator_presenter';

export class FileExplorerStore {
  constructor(
      public readonly procId: number,
      public readonly entryDirectory: LiveDirectory,
  ) {
    makeObservable(this, {
      fileNavigatorStore: computed,
      directory: computed,
      title: computed
    });
  }

  get fileNavigatorStore(): FileNavigatorStore {
    return new FileNavigatorStore(this.entryDirectory);
  }

  get directory(): LiveDirectory {
    return this.fileNavigatorStore.directory;
  }

  get title(): string {
    return `File Explorer - ${liveFileId(this.directory)}`;
  }
}

export class FileExplorerPresenter {
  constructor(
      private readonly applicationService: ApplicationService,
      private readonly fileSystemService: FileSystemService,
  ) {
  }

  createStore(procId: number, file: LiveDirectory): FileExplorerStore {
    return new FileExplorerStore(procId, file);
  }

  onClose(store: FileExplorerStore) {
    this.applicationService.stopProc(store.procId);
  }

  onMinimize(store: FileExplorerStore) {
    this.applicationService.toggleMinization(store.procId);
  }

  onFileOpen(store: FileExplorerStore, file: LiveFile) {
    this.applicationService.open(file);
  }
}
