import classnames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import {
  CrossPlatformEvent,
  usePointerEvents,
  useWindowPointerEvents,
  EventType,
  WindowEventType,
} from '@akst.io/web-resume-dom/ui/base/cross_platform/pointer_events';
import { PositionStyle } from './types';

/**
 * DraggableContainer - Items within this are draggable relative to this element.
 */
export const DragContainer = React.memo(({
  children,
  setPortalRef,
  setParentRef,
}: {
  children: React.ReactNode,
  setPortalRef: (element: HTMLDivElement) => void,
  setParentRef: (element: HTMLDivElement) => void,
}) => (
    <div ref={setParentRef} style={{ position: 'relative' }}>
      <div style={{ zIndex: 1 }}>
        {children}
      </div>
      <div ref={setPortalRef} style={{ zIndex: 2 }}/>
    </div>
));

/**
 * DragPoint - A point on an element that contains draggable point
 * which works on touch & non-touch screens.
 */
export const DraggableSlot = React.memo(function DraggableSlot({
  setElement,
  zIndex,
  positionStyle,
  children,
  portalElement,
}: {
  setElement(el: HTMLDivElement): void,
  zIndex: number,
  positionStyle: PositionStyle,
  children: JSX.Element;
  portalElement: HTMLDivElement | undefined,
}) {
  if (!portalElement) {
    return (
        <div style={{ opacity: 0 }}>{children}</div>
    );
  }

  const withZIndex = { ...positionStyle, zIndex };

  return createPortal((
      <DraggableItem style={withZIndex} ref={setElement}>
        {children}
      </DraggableItem>
  ), portalElement);
});

const DraggableItem = styled.div`
  position: absolute;
  z-index: 1;
  display: inline-block;
  width: min-content;
  left: 0;
  top: 0;
`;

/**
 * DragPoint - A point on an element that contains draggable point
 * which works on touch & non-touch screens.
 */
export const DragPoint = React.memo(function DragPoint({
  children,
  pointerDown,
  onPointerStart,
  onPointerMove,
  onPointerEnd,
}: DragPointProps & {
  pointerDown: boolean,
  onPointerStart(event: CrossPlatformEvent<any>): void,
  onPointerMove(event: CrossPlatformEvent<any>): void,
  onPointerEnd(event: CrossPlatformEvent<any>): void,
}) {
  // element events
  const setDivRef = usePointerEvents({
    onPointerStart: !pointerDown ? onPointerStart : undefined,
    onPointerEnd: pointerDown ? onPointerEnd : undefined,
    passiveStates: new Map<EventType, boolean>([
      [EventType.START, true],
      [EventType.END, true],
    ]),
  }, [pointerDown]);

  // window events
  useWindowPointerEvents({
    onPointerMove: pointerDown ? onPointerMove : undefined,
    onPointerEnd: pointerDown ? onPointerEnd : undefined,
    passiveStates: new Map<WindowEventType, boolean>([
      [EventType.MOVE, false],
      [EventType.END, true],
    ]),
  }, [pointerDown])

  return (
      <div ref={setDivRef} style={{ touchAction: 'none' }}>
        {children}
      </div>
  );
});

export type DragPointProps = {
  children: any;
};
