import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { UnreachableError } from "@akst.io/lib/base/types";
import { BufferStore, BufferPresenter } from '@akst.io/web-resume-dom/ui/application/sprite_maker/buffer/buffer_presenter';
import { CrossPlatformEvent } from '@akst.io/web-resume-dom/ui/base/cross_platform/pointer_events';
import { rgbToHex } from '@akst.io/web-resume-dom/ui/base/color/color';
import { ColorStore } from '../color/color_presenter';
import { ControlsStore } from '../controls/controls_presenter';
import { DrawMode } from '../controls/types';

export type ContentRectBounds = { width: number, height: number };
type ResizeableCanvas = Pick<HTMLCanvasElement, 'width' | 'height'>;

export class CanvasStore {
  public width?: number = undefined;
  public height?: number = undefined;
  public cursorDown: boolean = false;

  constructor(
      public bufferStore: BufferStore,
      public controlsStore: ControlsStore,
      public colorInputStore: ColorStore,
      /**
       * The size of the squares that make up the transparent
       * grid in the background in the sprite maker.
       */
      public transparentGridSize: number,
  ) {
    makeObservable(this, {
      width: observable.ref,
      height: observable.ref,
      cursorDown: observable.ref,
      paintId: computed,
      gridSlotWidth: computed,
      gridSlotHeight: computed,
      fillColor: computed
    });
  }

  get paintId(): number {
    return this.bufferStore.modificationId + this.bufferStore.historyChangeId;
  }

  /**
   * The width of a slot on the grid.
   */
  get gridSlotWidth(): number {
    const width = this.width || 0;
    return width / this.bufferStore.gridWidth;
  }

  /**
   * The width of a slot on the grid.
   */
  get gridSlotHeight(): number {
    const height = this.height || 0;
    return height / this.bufferStore.gridHeight;
  }

  get fillColor(): number {
    return rgbToHex(
        this.colorInputStore.r,
        this.colorInputStore.g,
        this.colorInputStore.b,
    );
  }
}

export class CanvasPresenter {
  constructor(public bufferPresenter: BufferPresenter) {
    makeObservable(this, {
      onResize: action,
      setCursorDown: action
    });
  }

  public onResize(
    store: CanvasStore,
    bounds: ContentRectBounds | undefined,
    ref: ResizeableCanvas | undefined
  ) {
    if (!bounds) return;

    store.width = bounds.width;
    store.height = bounds.height;

    if (ref) {
      ref.width = bounds.width;
      ref.height = bounds.height;
    }
  }

  public setCursorDown(store: CanvasStore, value: boolean) {
    store.cursorDown = value;
  }

  public onCanvasPointerStart(store: CanvasStore, event: CrossPlatformEvent<HTMLCanvasElement>) {
    this.setCursorDown(store, true);
    this.withEventCoords(store, event);
  }

  public onCanvasPointerMove(store: CanvasStore, event: CrossPlatformEvent<HTMLCanvasElement>) {
    if (store.cursorDown) {
      this.withEventCoords(store, event);
    }
  }

  public onCanvasPointerEnd(store: CanvasStore) {
    this.setCursorDown(store, false);
  }

  private withEventCoords(store: CanvasStore, event: CrossPlatformEvent<HTMLCanvasElement>) {
    const bounds = event.currentTarget.getBoundingClientRect() as { x: number, y: number };
    const { x: canvasX = 0, y: canvasY = 0 } = bounds;
    const { clientX, clientY } = event;
    const x = Math.floor(clientX - canvasX);
    const y = Math.floor(clientY - canvasY);

    const { buffer, gridWidth, gridHeight } = store.bufferStore;
    const gridX = Math.floor(x / store.gridSlotWidth);
    const gridY = Math.floor(y / store.gridSlotHeight);
    const bufferIndex = (gridY * gridWidth) + gridX;

    switch (store.controlsStore.drawMode) {
      case DrawMode.DRAWING: {
        if (buffer[bufferIndex] !== store.fillColor) {
          const color = store.fillColor;
          const index = bufferIndex;
          this.bufferPresenter.onDelta(
              store.bufferStore,
              { type: 'set-color', index, color },
          );
        }
        break;
      }

      case DrawMode.ERASING: {
        if (buffer[bufferIndex] != null) {
          const color = undefined;
          const index = bufferIndex;
          this.bufferPresenter.onDelta(
              store.bufferStore,
              { type: 'set-color', index, color },
          );
        }
        break;
      }

      case DrawMode.EYE_DROP: {
        const fetchedColor = buffer[bufferIndex];
        if (fetchedColor) {
          runInAction(() => {
            store.colorInputStore.r = fetchedColor >> 16 & 255;
            store.colorInputStore.g = fetchedColor >> 8 & 255;
            store.colorInputStore.b = fetchedColor & 255;
          });
        }
        break;
      }

      case DrawMode.FLOOD_FILL: {
        this.bufferPresenter.onFloodFill(
            store.bufferStore,
            store.fillColor,
            bufferIndex,
        );
        break;
      }

      default:
        throw new UnreachableError(store.controlsStore.drawMode);
    }
  }

}
