import classNames from 'classnames';
import { action, computed, reaction, observable, makeObservable } from 'mobx';
import { observer, disposeOnUnmount } from 'mobx-react';
import * as React from 'react';
import Measure, { ContentRect } from 'react-measure';
import styled, { css } from 'styled-components';
import { checkExists } from '@akst.io/lib/base/types';
import {
  LiveDirectory,
  LiveFileSystemNode,
} from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import { ChromelessButton, ButtonEventType } from '@akst.io/web-resume-dom/ui/base/button/button';
import { XPlatformHandler, EventType } from '@akst.io/web-resume-dom/ui/base/cross_platform/pointer_events';
import { FileSpriteProps } from '@akst.io/web-resume-dom/ui/system/file_sprite/file_sprite';
import {
  FlexDirection,
  FileGridPresenter,
  FileGridStore,
  FileGridStoreFactory,
  FileIconDimensionDescriptor,
  GridSlot,
} from './file_grid_presenter';
import {
  DragSlotsContainer,
  FileInnerButtonRoot,
  FileRoot,
  FileSpriteContainer,
  FileSpacer,
  FileTitle,
  SeeSaw,
} from './styles';

type SlotMapper<T> = (i: number, xi: number, yi: number, x: number, y: number) => T;

export type ExternallyInjectedProps = {
  FileSprite: React.ComponentType<FileSpriteProps>;
};

export type InternallyInjectedProps = {
  storeFactory: FileGridStoreFactory;
  presenter: FileGridPresenter;
};

export type FileGridProps = {
  flexDirection: FlexDirection;
  directory: LiveDirectory;
  onFileOpen(file: LiveFileSystemNode): void;
};

export type FileGridImplProps =
  & FileGridProps
  & InternallyInjectedProps
  & ExternallyInjectedProps;

export const FileGridImpl = observer(class FileGridImpl extends React.Component<FileGridImplProps> {
  containerDiv?: HTMLDivElement = undefined;
  width: number = 0;
  height: number = 0;

  constructor(props: FileGridImplProps) {
    super(props);

    makeObservable<FileGridImpl, "dragSlots">(this, {
      containerDiv: observable.ref,
      width: observable.ref,
      height: observable.ref,
      store: computed,
      dragSlots: computed
    });
  }

  componentDidMount() {
    disposeOnUnmount(this, reaction(
        () => this.containerDiv && this.store,
        () => {
          const style = this.containerDiv && this.containerDiv.style;
          const store = this.store;
          const fd = store.fileDimensions;

          // here we are putting common css variables into the dom
          // tree to make it so
          if (style != null) {
            style.setProperty('--fileHeight', `${store.fileHeight}px`);
            style.setProperty('--fileWidth', `${fd.width}px`);
            style.setProperty('--iconSize', `${fd.iconSize}px`);
            style.setProperty('--textLineHeight', `${fd.textLineHeight}`);
            style.setProperty('--textMarginTop', `${fd.textMarginTop}px`);
            style.setProperty('--textFontSize', `${fd.textFontSize}px`);
            style.setProperty('--textMaxHeight', `${store.textMaxHeight}px`);
          }
        },
        { fireImmediately: true },
    ));
  }

  get store(): FileGridStore {
    const { width, height } = this;
    const { storeFactory, directory, flexDirection } = this.props;
    return storeFactory(directory, flexDirection, width, height);
  }

  render() {
    const { dragSlots, props: { flexDirection } } = this;

    return (
        <Measure onResize={this.onResize} bounds={true}>
          {({ measureRef }) => (
            <Root ref={measureRef}>{dragSlots}</Root>
          )}
        </Measure>
    );
  }

  private get dragSlots(): React.ReactNode {
    const { store } = this;
    const { presenter } = this.props;
    const slots = store.slotInformation.map((gridSlot, i) => (
        <React.Fragment key={i}>
          <DropPoint
              index={i}
              store={store}
              presenter={presenter}
              gridSlot={gridSlot}
          />
          {this.renderFile(gridSlot, i)}
        </React.Fragment>
    ));

    return (
        <DragSlotsContainer
            ref={this.captureContainerDiv}
            style={{ padding: store.paddingSize }}
        >
          {slots}
        </DragSlotsContainer>
    );
  }

  private renderFile(gridSlot: GridSlot, index: number): React.ReactNode {
    if (gridSlot.file) {
      const { FileSprite, onFileOpen, presenter } = this.props;
      return (
          <File
              index={index}
              store={this.store}
              file={gridSlot.file}
              gridSlot={gridSlot}
              presenter={presenter}
              onFileOpen={onFileOpen}
              FileSprite={FileSprite}
          />
      );
    }
  }

  private readonly captureContainerDiv = action((element: HTMLDivElement) => {
    this.containerDiv = element;
  });

  private readonly createOnDrop = (index: number) => (event: React.DragEvent) => {
    const { presenter } = this.props;
    event.preventDefault();
    presenter.onDrop(this.store, index);
  };

  private readonly onResize = action((contentRect: ContentRect) => {
    const bounds = checkExists(contentRect.bounds, 'contentRect.bounds');
    this.width = bounds.width;
    this.height = bounds.height;
  });
});

export const Root = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`;

type DropPointProps = {
  store: FileGridStore;
  presenter: FileGridPresenter;
  index: number;
  gridSlot: GridSlot;
};

const DropPoint = React.memo(function DropPoint(props: DropPointProps) {
  const { store, presenter, index, gridSlot } = props;

  const onDragOver = React.useCallback((event: React.DragEvent) => {
    event.preventDefault();
  }, []);

  const onDrop = React.useCallback((event: React.DragEvent) => {
    presenter.onDrop(store, index);
  }, [presenter, store, index]);

  return (
      <DragSlot
          onDrop={onDrop}
          onDragOver={onDragOver}
          style={{
            left: gridSlot.x,
            top: gridSlot.y,
          }}
      />
  );
});

const DragSlot = styled.div`
  position: absolute;
  width: var(--fileWidth);
  height: var(--fileHeight);
`;

type FileProps = {
  store: FileGridStore;
  presenter: FileGridPresenter;
  index: number;
  file: LiveFileSystemNode;
  gridSlot: Pick<GridSlot, 'x' | 'y'>;
  onFileOpen(file: LiveFileSystemNode): void;
  FileSprite: React.ComponentType<FileSpriteProps>;
};

const File = observer(class File extends React.Component<FileProps> {
  private passiveStates = new Map<ButtonEventType, boolean>([
    [EventType.START, false],
  ]);

  private delay = Math.random();

  constructor(props: FileProps) {
    super(props);

    makeObservable(this, {
      isClicked: computed
    });
  }

  render() {
    const { FileSprite, file, gridSlot, store, index } = this.props;
    const { name } = file;
    const style = { left: gridSlot.x, top: gridSlot.y };
    const iconStyle = {};

    const onPointerOutside =
        store.clickedItem === file ? this.onUnclickFile : undefined;

    return (
        <FileRoot
            $clicked={this.isClicked}
            style={style}
            title={name}
            draggable={true}
            onDragStart={this.onDragStart}
        >
          <ChromelessButton
              passiveStates={this.passiveStates}
              onPointer={this.onPointer}
              onPointerStartOutside={onPointerOutside}
          >
            <FileInnerButtonRoot>
              <FileSpriteContainer style={iconStyle}>
                <FileSprite fileSystemNode={file} sizing="fill-parent"/>
              </FileSpriteContainer>
              <FileSpacer/>
              <FileTitle $clicked={this.isClicked}>
                {name}
              </FileTitle>
            </FileInnerButtonRoot>
          </ChromelessButton>
        </FileRoot>
    );
  }

  get isClicked(): boolean {
    const { store, index, file } = this.props;
    return store.clickedItem === file && index !== store.draggingIndex;
  }

  private readonly onUnclickFile = () => {
    const { store, presenter } = this.props;
    presenter.onUnclickFile(store);
  };

  private readonly onPointer: XPlatformHandler<HTMLButtonElement> = (event) => {
    const { store, file, onFileOpen, presenter } = this.props;
    presenter.onFileClick(store, file, onFileOpen);
  };

  private readonly onDragStart = (event: React.DragEvent) => {
    const { store, presenter, index } = this.props;
    event.dataTransfer.setData("index", index + "");
    presenter.onDragStart(store, index);
  };
});

export function createFileGrid(injected: ExternallyInjectedProps): React.ComponentType<FileGridProps> {
  const paddingSize = 8;
  const itemSpacing = 8;

  const fileDimensions: FileIconDimensionDescriptor = {
    width: 8 * 9,
    iconSize: 8 * 7,
    textMarginTop: 8 / 2,
    textFontSize: 8 * 1.25,
    textLineHeight: 1.5,
    textLineCount: 2,
  };

  const presenter = new FileGridPresenter();
  const storeFactory = presenter.createFactory(
      paddingSize,
      itemSpacing,
      fileDimensions,
  );

  return React.memo(function FileGrid(props: FileGridProps) {
    return (
        <FileGridImpl
            {...props}
            {...injected}
            storeFactory={storeFactory}
            presenter={presenter}
        />
    );
  });
}
