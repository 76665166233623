import { UnreachableError } from '@akst.io/lib/base/types';
import * as Result from '@akst.io/lib/base/result';
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { SpeechService } from '@akst.io/web-resume-dom/services/speech/speech_service';
import { AssistantExpression } from '../type';
import {
  AssistantDefinition,
  DeterministicFrame,
  isRenderFrame,
} from '../configs/types';
import {
  AnimatorCanvasStore,
  AnimatorCanvasPresenter,
} from './animator_canvas_presenter';
import {
  AnimatorTransformStore,
  AnimatorTransformPresenter,
  TranslateCtrl,
} from './animator_transform_presenter';

const START_EXPRESSSION: AssistantExpression = {
  kind: 'animation',
  animation: 'show',
  movement: {
    kind: 'goto',
    position: {
      kind: 'offset',
      corner: 'bottom-right',
      coord: { x: 0, y: 0 },
    },
  },
};

type LivenessState =
  | { kind: 'init' }
  | { kind: 'running', from: number }
  | { kind: 'paused', from: number };

type WorkState =
  | { kind: 'idle' }
  | { kind: 'running-task', til: number };

export class AnimatorStore {
  nextAnimationFrame: number | undefined;
  animationQueue: AssistantExpression[] = [];
  work: WorkState = { kind: 'idle' };
  liveness: LivenessState = { kind: 'init' };

  constructor(
      readonly transform: AnimatorTransformStore,
      readonly canvas: AnimatorCanvasStore,
      readonly config: AssistantDefinition,
  ) {
  }
}

export class AnimatorPresenter {
  constructor(
      private readonly logging: LoggingService,
      private readonly speechService: SpeechService,
      private readonly now: () => number,
      private readonly rand: () => number,
      private readonly transform: AnimatorTransformPresenter,
      private readonly canvas: AnimatorCanvasPresenter,
      private readonly requestAnimationFrame: (f: (t: number) => void) => number,
      private readonly cancelAnimationFrame: (f: number) => void,
  ) {
  }

  createStore(
      canvas: HTMLCanvasElement,
      sheet: HTMLImageElement,
      config: AssistantDefinition,
      location: TranslateCtrl,
  ): Result.T<AnimatorStore, undefined> {
    const bounds = config.frameSize;
    const result = this.canvas.createStore(canvas, sheet, bounds);
    if (!result.ok) return result;

    const transform = this.transform.createStore(location, bounds);
    const store = new AnimatorStore(transform, result.value, config);

    this.logging.debug('created Animator');
    this.queueExpression(store, START_EXPRESSSION);
    this.restartAnimation(store);
    return Result.Ok(store);
  }

  queueExpression(store: AnimatorStore, e: AssistantExpression) {
    store.animationQueue.push(e);
  }

  update(store: AnimatorStore, frame: number) {
    this.transform.update(store.transform, frame);

    const expression = this.getNextExpression(store, frame);
    if (expression == null) return;
    this.logging.debug('will express', expression);
    const { movement, message } = expression;
    const [duration, frames] = this.getExpressionVariant(store, expression);

    store.work = { kind: 'running-task', til: frame + duration };

    if (frames) {
      const renderFrames = frames.filter(isRenderFrame);
      this.canvas.queueFrames(store.canvas, renderFrames);
    }

    if (message) {
      this.logging.debug('sending to speech synth', );
      const { voices } = this.speechService.getVoices({})
      const ordered = voices
          .sort(() => .5 - Math.random())
          .filter(v => v.lang != 'en-US');
      const voice = ordered.find(v => v.lang.includes('en')) ?? ordered?.[0];
      this.speechService.say({
        message,
        volume: 0.8,
        pitch: 0.85,
        rate: 1.05,
        voiceURI: voice?.voiceURI,
      });
    }

    if (movement) {
      this.transform.move(store.transform, movement, duration);
    }
  }

  render(store: AnimatorStore, frame: number) {
    this.canvas.render(store.canvas, frame);
  }

  resume(
      store: AnimatorStore,
      canvas: HTMLCanvasElement,
      translateCtrl: TranslateCtrl,
  ) {
    this.transform.resume(store.transform, translateCtrl);
    this.canvas.resume(store.canvas, canvas);
    this.restartAnimation(store);
  }

  pause(store: AnimatorStore) {
    this.transform.pause(store.transform);
    this.canvas.pause(store.canvas);

    if (store.nextAnimationFrame != null) {
      this.cancelAnimationFrame(store.nextAnimationFrame);
      store.nextAnimationFrame = undefined;
    }
    store.liveness = { kind: 'paused', from: this.now() };
  }

  private getExpressionVariant(
      store: AnimatorStore,
      ex: AssistantExpression,
  ): [number, DeterministicFrame[]] {
    switch (ex.kind) {
      case 'no-animation':
        return [ex.duration, []];

      case 'animation':
        break;

      default:
        throw new UnreachableError(ex);
    }

    const possibilities = store.config.animations[ex.animation];
    if (possibilities == null) return [0, []];
    const index = Math.floor(possibilities.length * this.rand())
    const animation = possibilities[index];
    const frames: DeterministicFrame[] = [];

    let duration = 0;
    let pointer = 0;
    while (animation.frames[pointer] != null && frames.length < 50) {
      const frame = animation.frames[pointer++];

      switch (frame.kind) {
        case 'wait':
          duration += frame.duration;
          frames.push(frame);
          continue;

        case 'play-sound':
          frames.push(frame);
          continue;

        case 'queue-frame':
          duration += frame.frame.duration;
          frames.push(frame);
          continue;

        case 'rnd-branching':
          break;

        default:
          throw new UnreachableError(frame);
      }

      const threshold = this.rand() * 100;
      let branchIndex = 0;
      let accumulation = 0;
      while (accumulation < threshold) {
        accumulation += frame.branches[branchIndex].weight;
      }

      pointer = frame.branches[branchIndex].frame;
    }

    return [duration, frames];
  }

  private restartAnimation(store: AnimatorStore) {
    const self = this;
    store.liveness = { kind: 'running', from: this.now() };
    store.nextAnimationFrame = this.requestAnimationFrame(function f(frame: number) {
      if (store.liveness.kind === 'running') {
        self.update(store, frame);
        self.render(store, frame);
        store.nextAnimationFrame = self.requestAnimationFrame(f);
      }
    });
  }

  private getNextExpression(store: AnimatorStore, frame: number): AssistantExpression | undefined {
    const { work } = store;

    switch (work.kind) {
      case 'running-task':
        if (work.til > frame) {
          return;
        } else {
          store.work = { kind: 'idle' };
          break;
        }

      case 'idle':
        break;

      default:
        throw new UnreachableError(work);
    }

    return store.animationQueue.shift();
  }
}
