import { last } from './arrays';
import { checkExists } from './types';
import { LazyQueue } from './lazy_queue';
import { rowColOfOffset, nthIndexOf } from './strings';

/**
 * Why did I implement a toml parser? I don't
 * really have a great reason I just wanted to,
 * to be honest.
 */

export type Symbol =
  | '[[' | ']]' | '[' | ']'
  | '='  | '.'  | '{' | '}'
  | ',';

export type Position = { cursor: number };
export type Token = TokenRepr & { pos: Position };
export type TokenRepr =
  | { type: 'symbol', symbol: Symbol }
  | { type: 'number', value: number }
  | { type: 'string', value: string }
  | { type: 'boolean', value: boolean }
  | { type: 'identifier', name: string };

export type Toml = Record<string, any>;
export type TomlPath = (number | string)[]

export class UnexpectedTokenError extends Error {
  constructor(
      readonly token: Readonly<Token>,
      readonly expected?: string,
  ) {
    super(UnexpectedTokenError.createMessage(token, expected));
  }

  private static createMessage(token: Readonly<Token>, expected: string | undefined) {
    const expectedS = expected ? `, expected "${expected}` : '';
    return `Unexpected token${expectedS}, found ${JSON.stringify(token)}`;
  }
}

export function * parseTokens(input: string): Iterable<Token> {
  const a_CODE = 'a'.charCodeAt(0);
  const z_CODE = 'z'.charCodeAt(0);
  const A_CODE = 'A'.charCodeAt(0);
  const Z_CODE = 'Z'.charCodeAt(0);
  const ZERO_CODE = '0'.charCodeAt(0);
  const NINE_CODE = '9'.charCodeAt(0);
  const TAB_CODE = '\t'.charCodeAt(0);
  const SPACE_CODE = ' '.charCodeAt(0);
  const NEWLINE_CODE = '\n'.charCodeAt(0);

  const isAlpha = (a: string) => {
    const b = a.charCodeAt(0);
    return (
        (b >= a_CODE && b <= z_CODE) ||
        (b >= A_CODE && b <= Z_CODE)
    );
  };

  const isNumerical = (a: string) => {
    const b = a.charCodeAt(0);
    return (b >= ZERO_CODE && b <= NINE_CODE);
  };

  const isWhiteSpace = (a: string) => {
    const b = a.charCodeAt(0);
    return (
        b === SPACE_CODE ||
        b === NEWLINE_CODE ||
        b === TAB_CODE
    );
  };

  const isAlphaNumerical = (c: string) => isAlpha(c) || isNumerical(c);

  const consumeWhile = (
      p: (c: string, rest: string) => boolean,
      input: string, cursor: number,
  ): string => {
    if (
        cursor >= input.length ||
        !p(input[cursor], input.slice(cursor))
    ) {
      return "";
    }

    let lookAhead = 1;
    while (cursor + lookAhead < input.length) {
      const offset = cursor + lookAhead;
      if (!p(input[offset], input.slice(offset))) break;
      lookAhead += 1;
    }

    return input.slice(cursor, cursor + lookAhead);
  };

  const parseNumber = (input: string, cursor: number): [number, number] => {
    let offset = cursor;
    const value = consumeWhile(isNumerical, input, cursor);
    offset += value.length;

    if (input[offset] === '.') {
      const maybeValue = consumeWhile(isNumerical, input, offset + 1);
      const maybeFullValue = value + "." + maybeValue;
      return maybeValue === ""
          ? [parseInt(value, 10), value.length]
          : [parseFloat(maybeFullValue), maybeFullValue.length];
    } else {
      return [parseInt(value, 10), value.length];
    }
  };

  let cursor = 0;

  while (cursor < input.length) {
    const read = input[cursor];

    switch (read) {
      // standalone symbols
      case '.':
      case ',':
      case '=':
      case '{':
      case '}': {
        yield { type: 'symbol', symbol: read, pos: { cursor } };
        cursor++;
        continue;
      }

      // opening braces
      case '[': {
        if (input[cursor + 1] === '[') {
          yield { type: 'symbol', symbol: '[[', pos: { cursor } };
          cursor += 2;
        } else {
          yield { type: 'symbol', symbol: '[', pos: { cursor } };
          cursor += 1;
        }
        continue;
      }

      // closing braces
      case ']': {
        if (input[cursor + 1] === ']') {
          yield { type: 'symbol', symbol: ']]', pos: { cursor } };
          cursor += 2;
        } else {
          cursor += 1;
          yield { type: 'symbol', symbol: ']', pos: { cursor } };
        }
        continue;
      }

      // whitespace
      case ' ':
      case '\n':
      case '\t': {
        cursor += 1;
        continue;
      }

      // strings
      case '\'':
      case '"': {
        if (input.slice(cursor, cursor + 3) === "'''") {
          const endQuote = (_: string, rest: string) => !rest.startsWith("'''");
          const string = consumeWhile(endQuote, input, cursor + 3);
          yield { type: 'string', value: string, pos: { cursor } };
          cursor += string.length + 6;
        } else {
          const endQuote = (c: string) => c !== read;
          const string = consumeWhile(endQuote, input, cursor + 1);
          yield { type: 'string', value: string, pos: { cursor } };
          cursor += string.length + 2;
        }
        continue;
      }

      // numbers starting with '-'
      case '-': {
        const [number, length] = parseNumber(input, cursor + 1);
        yield { type: 'number', value: -number, pos: { cursor } };
        cursor += length + 1;
        continue;
      }
    }

    if (isAlpha(read)) {
      const identifer = read + consumeWhile(isAlphaNumerical, input, cursor + 1);
      if (identifer === 'true') {
        yield { type: 'boolean', value: true, pos: { cursor } }
      } else if (identifer === 'false') {
        yield { type: 'boolean', value: false, pos: { cursor } }
      } else {
        yield { type: 'identifier', name: identifer, pos: { cursor } };
      }
      cursor += identifer.length;
      continue;
    }

    if (isNumerical(read)) {
      const [number, length] = parseNumber(input, cursor);
      yield { type: 'number', value: number, pos: { cursor } };
      cursor += length;
      continue;
    }

    throw new Error(`unsupported character, ${read}`);
  }
}

const isSymbolOf = (t: Token, s: Symbol) => t.type === 'symbol' && t.symbol === s;

function equalToken(l: TokenRepr, r: TokenRepr): boolean {
  if (l.type !== r.type) return false;
  else if (l.type === 'symbol' && r.type === 'symbol') return r.symbol === l.symbol;
  else if (l.type === 'number' && r.type === 'number') return l.value === r.value;
  else if (l.type === 'string' && r.type === 'string') return l.value === r.value;
  else if (l.type === 'boolean' && r.type === 'boolean') return l.value === r.value;
  else if (l.type === 'identifier' && r.type === 'identifier') return l.name === r.name;
  return false;
}

function readPath(root: Toml, path: TomlPath, expectLastAs: 'object'): Toml;
function readPath(root: Toml, path: TomlPath, expectLastAs: 'array'): Toml[];
function readPath(
    root: Toml,
    path: TomlPath,
    expectLastAs: 'object' | 'array',
): Toml | Toml[] {
  let object: any = root;

  for (let i = 0; i < path.length; i++) {
    const key = path[i];

    /**
     * When you're entering a table inside of an array
     * of tables you're normally entering the last table
     * of the current object record, this is reflected in
     * testing examples and the fact the parser goes top
     * to bottom.
     *
     * This is the scenario I'm referring to in the syntax,
     * when you get the `arrayEntry.subTable` portion.
     *
     * ```
     * [[arrayEntry]]
     * a = 2
     * [[arrayEntry]]
     * b = 3
     *   [arrayEntry.subTable]
     *   c = 4
     * ```
     */
    if (typeof key === 'string' && Array.isArray(object)) {
      object = last(object);
    }

    const value = object[key];

    if (value == null) {
      const isLast = i === path.length - 1;
      if (isLast && expectLastAs === 'array') {
        object = object[key] = [];
      }
      else if (isLast && expectLastAs === 'object') {
        object = object[key] = {};
      }
      else {
        object = object[key] = {};
      }
    }
    else if (typeof value === 'object') {
      object = value;
    }
    else {
      throw new Error('cannot get none object value');
    }
  }

  return object;
}

function parseSeperatedBy<T>(
    tokens: LazyQueue<Token>,
    consumer: (tokens: LazyQueue<Token>) => T,
    seperator: TokenRepr,
    endWith: TokenRepr,
): T[] {
  const initialHead = checkExists(tokens.head, 'unexpected end of token stream');
  if (equalToken(initialHead, endWith)) {
    tokens.advanceCursor();
    return [];
  }

  const values: T[] = [];

  while (true) {
    values.push(consumer(tokens));

    if (tokens.head == null) {
      throw new Error(`unexpected end of tokens`);
    }

    if (equalToken(tokens.head, seperator)) {
      tokens.advanceCursor();
      continue;
    }

    if (equalToken(tokens.head, endWith)) {
      tokens.advanceCursor();
      break;
    }

    throw new UnexpectedTokenError(tokens.head);
  }

  return values;
}

function parsePath(tokens: LazyQueue<Token>, endWith: TokenRepr): TomlPath {
  const getIdentifierChunk = (tokens: LazyQueue<Token>) => {
    const head = checkExists(tokens.head, 'expected identifier');
    if (head.type !== 'identifier') throw new Error('expected identifier');
    tokens.advanceCursor();
    return head.name;
  };

  const period: TokenRepr = { type: 'symbol', symbol: '.' };
  return parseSeperatedBy(tokens, getIdentifierChunk, period, endWith);
}

function parseArray(tokens: LazyQueue<Token>): any[] {
  const comma: TokenRepr = { type: 'symbol', symbol: ',' };
  const closeArray: TokenRepr = { type: 'symbol', symbol: ']' };
  return parseSeperatedBy(tokens, parseValue, comma, closeArray);
}

function parseInlinedTable(tokens: LazyQueue<Token>): Record<string, any> {
  const parseKeyValue = (tokens: LazyQueue<Token>): [string, any] => {
    const identifier = checkExists(tokens.pop(), 'expected identifier');
    if (identifier.type !== 'identifier') throw new Error('expected identifier');

    const maybeEqualSign = checkExists(tokens.pop(), 'expected equals sign');
    if (!isSymbolOf(maybeEqualSign, '=')) throw new Error('expected equals sign');

    return [identifier.name, parseValue(tokens)];
  };

  const comma: TokenRepr = { type: 'symbol', symbol: ',' };
  const closeTable: TokenRepr = { type: 'symbol', symbol: '}' };
  const entries = parseSeperatedBy(tokens, parseKeyValue, comma, closeTable);
  const record: Record<string, any> = {};

  for (const [name, value] of entries) {
    record[name] = value;
  }

  return record;
}

function parseValue(tokens: LazyQueue<Token>): any | undefined {
  const token = tokens.pop();
  if (token == null) return;

  switch (token.type) {
    case 'boolean':
    case 'string':
    case 'number': {
      return token.value;
    }
    case 'symbol': {
      if (isSymbolOf(token, '[')) {
        return parseArray(tokens);
      }
      else if (isSymbolOf(token, '{')) {
        return parseInlinedTable(tokens);
      }
      break;
    }

    default:
      throw new UnexpectedTokenError(token, 'value');
  }
}

export function parseToml(input: string): Object {
  const root: Toml = {};
  let writeCursor: TomlPath = [];

  const tokens = LazyQueue.fromIterable(parseTokens(input));

  while (!tokens.done) {
    const token = tokens.pop();
    const head = checkExists(token, `${tokens.done} ${JSON.stringify(root)}`);

    try {
      switch (head.type) {
        case 'identifier': {
          const fieldName = head.name;
          const maybeEqualSign = checkExists(tokens.pop(), 'expected token "="');
          if (!isSymbolOf(maybeEqualSign, '=')) throw new UnexpectedTokenError(head, '=');

          const value = parseValue(tokens);
          const record = readPath(root, writeCursor, 'object');
          record[fieldName] = checkExists(value, `expected value to assign to '${fieldName}'`);
          break;
        }

        case 'symbol': {
          if (isSymbolOf(head, '[')) {
            writeCursor = checkExists(parsePath(tokens, { type: 'symbol', symbol: ']' }), 'writeCursor');
          } else if (isSymbolOf(head, '[[')) {
            const path = checkExists(parsePath(tokens, { type: 'symbol', symbol: ']]' }), 'path');
            const array = readPath(root, path, 'array');
            writeCursor = [...path, array.length];
            array.push({});
          } else {
            throw new UnexpectedTokenError(head);
          }
          break;
        }

        default:
          throw new Error(`unknown token ${head.type}`);
      }
    } catch (e) {
      const token = e instanceof UnexpectedTokenError ? e.token : head;
      const lastLineBreak = input.slice(0, token.pos.cursor).lastIndexOf('\n');
      const sliceStr = lastLineBreak === -1 ? token.pos.cursor : lastLineBreak;
      const sliceEnd = nthIndexOf(input, 5, '\n', sliceStr);
      const { row, col } = rowColOfOffset(input, sliceStr);
      console.error(
          'Parsing failed after',
          `(${row}, ${col})`,
          JSON.stringify(head),
      );
      console.error(input.slice(sliceStr, sliceEnd));
      throw e;
    }
  }

  return root;
}
