import { checkExists, UnreachableError } from '@akst.io/lib/base/types';

export type JsonFieldType =
  | 'array:object'
  | 'array:boolean'
  | 'array:number'
  | 'array:string'
  | 'object'
  | 'boolean'
  | 'number'
  | 'string';

export type JsonFieldMapping<T extends JsonFieldType, O> =
  T extends 'object' ? O :
  T extends 'boolean' ? boolean :
  T extends 'number' ? number :
  T extends 'string' ? string :
  T extends 'array:object' ? O[] :
  T extends 'array:boolean' ? boolean[] :
  T extends 'array:number' ? number[] :
  T extends 'array:string' ? string[] :
  never;

export type JsonFieldReader =
  | (
      <
        T extends JsonFieldType,
        B extends boolean,
        D
      >(
          type: T,
          field: string,
          optional: B,
          def: D,
      ) => (
          B extends false ?
          JsonFieldMapping<T, JsonFieldReader> :
          JsonFieldMapping<T, JsonFieldReader> | D
      )
    )
  ;

export interface JsonArray extends Array<Json> {}

export type JsonObject = { [k: string]: Json };

export type Json =
  | string
  | boolean
  | number
  | undefined
  | JsonArray
  | JsonObject;

export function urlPairs(query: string): Array<[string, string | undefined]> {
  return query.split('&').map((item: string) => {
    const splitAt = item.indexOf('=');
    const key = splitAt === -1 ? item : item.slice(0, splitAt);
    const value = splitAt === -1 ? undefined : item.slice(splitAt + 1);
    return [key, value];
  });
}

export function readKey(cursor: JsonObject | JsonArray, key: string): Json {
  return Array.isArray(cursor) ? cursor[parseInt(key, 10)] : cursor[key];
}

export function writeKey(cursor: JsonObject | JsonArray, key: string, value: Json) {
  if (Array.isArray(cursor)) {
    cursor[parseInt(key, 10)] = value;
  } else {
    cursor[key] = value;
  }
}

function parseKey(rawKey: string): [string, boolean] {
  const last2Keys = rawKey.length - 2;
  const arrayField = rawKey.slice(last2Keys) === '[]';
  const key = arrayField ? rawKey.slice(0, last2Keys) : rawKey;
  return [key, arrayField];
}

export function setPath(
    object: JsonObject,
    path: string,
    value: string | undefined,
) {
  let cursor: JsonObject | JsonArray = object;
  const cursorPath = path.split('.');
  const rawWritePath = checkExists(cursorPath.pop(), 'impossible');
  const [writePath, writePathIsArray] = parseKey(rawWritePath);
  if (writePathIsArray) {
    throw new Error('cannot write a value to an array field');
  }

  for (const rawKey of cursorPath) {
    const [key, arrayField] = parseKey(rawKey);

    if (!(key in cursor)) {
      writeKey(cursor, key, arrayField ? [] : {});
    }

    const cursorRead = readKey(cursor, key);
    if (typeof cursorRead !== 'object') {
      const message = `invalid key read: ${key}, ${JSON.stringify(cursor)}`;
      throw new Error(message);
    }
    cursor = cursorRead;
  }

  if (writePath in cursor) {
    throw new Error('cannot override existing field');
  }

  writeKey(cursor, writePath, value);
}

export function urlToJson(query: string): JsonObject {
  const object: JsonObject = {};

  for (const [path, value] of urlPairs(query)) {
    setPath(object, path, value);
  }

  return object;
}

function castJsonValue(j: Json, t: 'boolean'): boolean;
function castJsonValue(j: Json, t: 'string'): string;
function castJsonValue(j: Json, t: 'number'): number;
function castJsonValue(j: Json, t: 'object'): JsonObject;
function castJsonValue(j: Json, t: 'array:boolean'): boolean[];
function castJsonValue(j: Json, t: 'array:string'): string[];
function castJsonValue(j: Json, t: 'array:number'): number[];
function castJsonValue(j: Json, t: 'array:object'): JsonObject[];
function castJsonValue(value: Json, t: JsonFieldType) {
  switch (t) {
    case 'number': switch (typeof value) {
      case 'boolean': return value ? 1 : 0;
      case 'number': return value;
      case 'string': return parseFloat(value);
      default: throw new Error('impossible cast');
    }
    case 'string': switch (typeof value) {
      case 'boolean': return value ? 'true' : 'false';
      case 'number': return value.toString();
      case 'string': return value;
      default: throw new Error('impossible cast');
    }
    case 'boolean': switch (typeof value) {
      case 'number': return value !== 0;
      case 'string': return value === 'true';
      case 'boolean': return value;
      default: throw new Error('impossible cast');
    }
    case 'object': {
      if (typeof value !== 'object' || Array.isArray(value)) throw new Error('impossible cast');
      return value;
    }
    case 'array:number': {
      if (!Array.isArray(value)) throw new Error('impossible cast');
      return value.map((value: Json) => castJsonValue(value, 'number'));
    }
    case 'array:string': {
      if (!Array.isArray(value)) throw new Error('impossible cast');
      return value.map((value: Json) => castJsonValue(value, 'string'));
    }
    case 'array:boolean': {
      if (!Array.isArray(value)) throw new Error('impossible cast');
      return value.map((value: Json) => castJsonValue(value, 'boolean'));
    }
    case 'array:object': {
      if (!Array.isArray(value)) throw new Error('impossible cast');
      return value.map((value: Json) => castJsonValue(value, 'object'));
    }
    default:
      throw new UnreachableError(t);
  }
}

function castJsonField(j: JsonObject, t: 'boolean', f: string): boolean | undefined;
function castJsonField(j: JsonObject, t: 'string', f: string): string | undefined;
function castJsonField(j: JsonObject, t: 'number', f: string): number | undefined;
function castJsonField(j: JsonObject, t: 'object', f: string): JsonObject | undefined;
function castJsonField(j: JsonObject, t: 'array:boolean', f: string): boolean[] | undefined;
function castJsonField(j: JsonObject, t: 'array:string', f: string): string[] | undefined;
function castJsonField(j: JsonObject, t: 'array:number', f: string): number[] | undefined;
function castJsonField(j: JsonObject, t: 'array:object', f: string): JsonObject[] | undefined;
function castJsonField(
    json: JsonObject,
    wantType: JsonFieldType,
    field: string,
) {
  const read = json[field];
  if (typeof read === 'undefined') return undefined;

  switch (wantType) {
    case 'number': return castJsonValue(read, 'number');
    case 'string': return castJsonValue(read, 'string');
    case 'boolean': return castJsonValue(read, 'boolean');
    case 'object': return castJsonValue(read, 'object');
    case 'array:number': {
      if (!Array.isArray(read)) throw new Error('impossible cast');
      return read.map((value: Json) => castJsonValue(value, 'number'));
    }
    case 'array:string': {
      if (!Array.isArray(read)) throw new Error('impossible cast');
      return read.map((value: Json) => castJsonValue(value, 'string'));
    }
    case 'array:boolean': {
      if (!Array.isArray(read)) throw new Error('impossible cast');
      return read.map((value: Json) => castJsonValue(value, 'boolean'));
    }
    case 'array:object': {
      if (!Array.isArray(read)) throw new Error('impossible cast');
      return read.map((value: Json) => castJsonValue(value, 'object'));
    }
    default:
      throw new UnreachableError(wantType);
  }
};

export function readerFor(json: JsonObject): JsonFieldReader {
  const identity = <T>(value: T): T => value;

  const mapper: JsonFieldReader = <T extends JsonFieldType>(
      t: JsonFieldType,
      field: string,
      optional: boolean,
      def?: any,
  ): any => {
    const optionalCheck = <T>(t: T | undefined) =>
        (!optional && def == null) ? checkExists(t, 'missing prop') : t;

    switch (t) {
      case 'string':
        return optionalCheck(castJsonField(json, 'string', field)) || def;
      case 'number':
        return optionalCheck(castJsonField(json, 'number', field)) || def;
      case 'boolean':
        return optionalCheck(castJsonField(json, 'boolean', field)) || def;
      case 'object': {
        const value = optionalCheck(castJsonField(json, 'object', field));
        return value ? readerFor(value) : def;
      }
      case 'array:string':
        return optionalCheck(castJsonField(json, 'array:string', field)) || def;
      case 'array:number':
        return optionalCheck(castJsonField(json, 'array:number', field)) || def;
      case 'array:boolean':
        return optionalCheck(castJsonField(json, 'array:boolean', field)) || def;
      case 'array:object': {
        const value = optionalCheck(castJsonField(json, 'array:object', field));
        return value ? value.map(readerFor) : def;
      }
      default:
        throw new UnreachableError(t);
    }
  };

  return mapper;
}

export function parseUrl<T>(query: string, parse: (o: JsonFieldReader) => T): T {
  const withoutQuestionMark = query.slice(1);
  return parse(readerFor(urlToJson(withoutQuestionMark)));
}
