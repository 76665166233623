import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { LiveFile } from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import { Small } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { FileNavigatorProps } from '@akst.io/web-resume-dom/ui/system/file_navigator/file_navigator';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import { FileExplorerStore, FileExplorerPresenter } from './file_explorer_presenter';

export type FileExplorerProps = {
};

export type InternallyInjectedProps = {
  Panel: React.ComponentType<Pick<PanelProps, 'children'>>;
  FileNavigator: React.ComponentType;
};

export type FileExplorerImplProps =
  & InternallyInjectedProps
  & FileExplorerProps;

export const FileExplorer = React.memo((props: FileExplorerImplProps) => {
  const { FileNavigator, Panel } = props;

  return (
      <Panel>
        <Root>
          <FileNavigator/>
        </Root>
      </Panel>
  );
});

const size = baseUnit * 32;

const Root = styled.div`
  max-width: ${size}px;
  max-height: ${size}px;
  width: ${size}px;
  height: ${size}px;
`;

export type CreateOptions = {
  store: FileExplorerStore;
  presenter: FileExplorerPresenter;
  FileNavigator: React.ComponentType<FileNavigatorProps>;
  Panel: React.ComponentType<PanelProps>;
};

export function createFileExplorer(options: CreateOptions) {
  const { presenter, store, FileNavigator, Panel } = options;

  const onClose = () => {
    presenter.onClose(store);
  };

  const onMinimize = () => {
    presenter.onMinimize(store);
  };

  const onFileOpen = (file: LiveFile) => {
    presenter.onFileOpen(store, file);
  };

  const FileNavigatorImpl = observer(() => (
      <FileNavigator store={store.fileNavigatorStore} onFileOpen={onFileOpen}/>
  ));

  const PanelImpl = observer((props: Pick<PanelProps, 'children'>) => (
      <Panel
          pathOfOpenFile={store.directory.path}
          title={store.title}
          onClose={onClose}
          onMinimize={onMinimize}
      >
        {props.children}
      </Panel>
  ));

  return React.memo((props: FileExplorerProps) => (
      <FileExplorer
          FileNavigator={FileNavigatorImpl}
          Panel={PanelImpl}
          {...props}
      />
  ));
}

