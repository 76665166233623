import * as React from 'react';

export const useMouseMove = (
  onMove: (event: MouseEvent) => void,
  deps: any[]
) => {
  React.useEffect(() => {
    window.addEventListener('pointermove', onMove);
    return () => {
      window.removeEventListener('pointermove', onMove);
    };
  }, deps);
};
