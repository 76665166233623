import * as React from 'react';
import styled from 'styled-components';
import { Button } from '@akst.io/web-resume-dom/ui/base/button/button';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { bgGrey } from '@akst.io/web-resume-dom/ui/base/color/colors';

export const Spoiler = React.memo(({
  children,
  spoilerMessage,
}: {
  children: any,
  spoilerMessage: React.ReactNode,
}) => {
  const [open, setOpen] = React.useState(false);
  const buttonLabel = open ? "Show Less" : "Show More";
  const onPointer = () => setOpen(!open);

  return (
      <SpoilerRoot>
        <SpoilerButton>
          <Button onPointer={onPointer}>{buttonLabel}</Button>
        </SpoilerButton>
        <Content>
          {open ? children : spoilerMessage}
        </Content>
      </SpoilerRoot>
  );
});

const SpoilerRoot = styled.div`
  padding: calc(${baseUnit}px * 2);
  position: relative;
`;

const SpoilerButton = styled.div`
  position: absolute;
  top: 0;
  left: calc(${baseUnit}px * 4);
`;

const Content = styled.div`
  padding: calc(${baseUnit}px * 2);
  border: ${bgGrey} solid 1px;
`;
