import * as Result from './result';
import * as Iterator from './iterator';

export function consume<A>(iterable: Iterable<A>): [A | undefined, Iterable<A>] {
  const iter = Iterator.iterator(iterable);
  const next = Iterator.next(iter);
  return [next, Iterator.seal(iter)];
}

export function reduce<A, B>(
    iterable: Iterable<A>,
    init: B,
    operator: (b: B, a: A) => B,
): B {
  let result = init;

  for (const a of iterable) {
    result = operator(result, a);
  }

  return result;
}

export function reduceResults<A, B, E>(
    iterable: Iterable<A>,
    init: B,
    operator: (b: B, a: A) => Result.T<B, E>,
): Result.T<B, E> {
  let result: Result.T<B, E> = Result.Ok(init);

  for (const a of iterable) {
    if (!result.ok) break;
    result = operator(result.value, a);
  }

  return result;
}

export function foreachResult<A, E>(
    iterable: Iterable<A>,
    operator: (a: A) => Result.T<undefined, E>,
): Result.T<undefined, E> {
  for (const a of iterable) {
    const result = operator(a);
    if (!result.ok) return result;
  }

  return { ok: true, value: undefined };
}

export function * filter<A>(
    iterable: Iterable<A>,
    predicate: (a: A) => boolean,
): Iterable<A> {
  for (const a of iterable) {
    if (predicate(a)) yield a;
  }
}

export function * filterMap<A, B>(
    iterable: Iterable<A>,
    operator: (a: A) => Result.T<B, unknown>,
): Iterable<B> {
  for (const a of iterable) {
    const result = operator(a);
    if (result.ok) yield result.value;
  }
}

export function * enumerate<T>(iterable: Iterable<T>): Iterable<[T, number]> {
  let index = 0;
  for (const value of iterable) {
    yield [value, index];
    index += 1;
  }
}

export function * map<A, B>(
    iterable: Iterable<A>,
    operator: (a: A) => B,
): Iterable<B> {
  for (const a of iterable) {
    yield operator(a);
  }
}

export function eagerMapResults<V, A, B>(
    iterable: Iterable<V>,
    operator: (a: V) => Result.T<A, B>,
): Result.T<A[], B> {
  const values: A[] = [];

  for (const value of iterable) {
    const result = operator(value);
    if (result.ok) {
      values.push(result.value);
    } else {
      return Result.Err(result.error);
    }
  }

  return Result.Ok(values);
}

export function partionResults<A, B>(
    results: Iterable<Result.T<A, B>>,
): [A[], B[]] {
  const left: A[] = [];
  const right: B[] = [];

  for (const result of results) {
    if (result.ok) {
      left.push(result.value);
    } else {
      right.push(result.error);
    }
  }

  return [left, right];
}

export function partion<I, OA, OB>(
  iterable: Iterable<I>,
  operator: (value: I) => Result.T<OA, OB>,
): [readonly OA[], readonly OB[]] {
  return partionResults(map(iterable, operator));
}
