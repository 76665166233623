import { computed, runInAction, makeObservable } from "mobx";
import { ApplicationService } from "@akst.io/web-resume-dom/services/application/application_service";
import { FileHelperService } from '@akst.io/web-resume-dom/services/file_helper/file_helper_service';
import { CanvasStore } from './ui/canvas/canvas_presenter';
import { BufferStore, BufferPresenter } from './buffer/buffer_presenter';

export type ContentRectBounds = { width: number, height: number };

export class SpriteMakerStore {
  constructor(
      public bufferStore: BufferStore,
      public canvasStore: CanvasStore,
      public readonly procId: number,
  ) {
    makeObservable(this, {
      title: computed
    });
  }

  get title(): string {
    const file = this.bufferStore.file;
    const filePath = file && file.path.join('/');
    return `Sprite Maker ${filePath ? `- /${filePath}` : ''}`;
  }
}

export class SpriteMakerPresenter {
  constructor(
      private readonly bufferPresenter: BufferPresenter,
      private readonly applicationService: ApplicationService,
      private readonly fileHelperService: FileHelperService,
  ) {
  }

  public createStore(
      bufferStore: BufferStore,
      canvasStore: CanvasStore,
      procId: number,
  ): SpriteMakerStore {
    return new SpriteMakerStore(
        bufferStore,
        canvasStore,
        procId,
    );
  }

  public closeStore(store: SpriteMakerStore) {
    this.bufferPresenter.closeStore(store.bufferStore);
  }

  public async onSave(store: SpriteMakerStore) {
    const file = store.bufferStore.file;
    if (file) {
      const serialized = JSON.stringify(store.bufferStore.serialized);
      this.fileHelperService.save(serialized, file.path);
    } else {
      await this.onSaveAs(store);
    }
  }

  public async onSaveAs(store: SpriteMakerStore) {
    const serialized = JSON.stringify(store.bufferStore.serialized);
    const result = await this.fileHelperService.saveAs(serialized, 'sprite', ['sprites'], store.procId);

    if (result.type === 'ok') {
      runInAction(() => {
        store.bufferStore.file = result.file;
      });
    }
  }

  public async onOpen(store: SpriteMakerStore) {
    const result = await this.fileHelperService.open('sprite', ['sprites'], store.procId);
    if (result.type !== 'ok') return;

    this.bufferPresenter.loadFileContent(
        store.bufferStore,
        result.file,
    );
  }

  public onUndo(store: SpriteMakerStore) {
    this.bufferPresenter.onUndo(store.bufferStore);
  }

  public onRedo(store: SpriteMakerStore) {
    this.bufferPresenter.onRedo(store.bufferStore);
  }

  public onClear(store: SpriteMakerStore) {
    const { gridWidth, gridHeight } = store.bufferStore;
    this.bufferPresenter.onDelta(store.bufferStore, {
      type: 'unoptimized-buffer-change',
      setBuffer: new Array(gridWidth * gridHeight),
    });
  }

  public onMinimize(store: SpriteMakerStore) {
    this.applicationService.toggleMinization(store.procId);
  }

  public onClose(store: SpriteMakerStore) {
    this.applicationService.stopProc(store.procId);
  }
}
