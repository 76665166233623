import * as React from 'react';
import styled from 'styled-components';
import { seperateBy } from '@akst.io/lib/base/arrays';
import { checkExists, UnreachableError } from '@akst.io/lib/base/types';
import {
  Experince,
  ExperinceData,
  Reference,
  ReferenceData,
} from '@akst.io/lib/resume/types';
import { formatReferenceRelation } from '@akst.io/lib/resume/format';
import { retroRed, retroPurple } from '@akst.io/web-resume-dom/ui/base/color/colors';
import * as Type from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { Rows } from '../../../ui/layout/layout';
import {
  Heading,
  Normal,
  SubHeading,
  SubSubHeading,
  SubHeadingWithTimeAndTagline,
} from '../../../ui/type/type';
import { Markdown } from '../../../ui/markdown/markdown';

type It =
  | { kind: 'sep' }
  | { kind: 'ref', it: ReferenceData };

type ReferenceItemProps = { item: ReferenceData };
export const References = React.memo(({
  reference,
  experience,
}: {
  reference: Reference,
  experience: Experince,
}) => {

  const items = React.useMemo<JSX.Element[]>(() => {
    const prep: It[] = reference.data.map(it => ({ kind: 'ref', it }))
    const data: It[] = seperateBy(prep, { kind: 'sep' })

    return data.map((it, index) => {
      switch (it.kind) {
        case 'ref': {
          const { it: ref } = it;
          const { workplace } = ref;
          const maybe = experience.data.find(it => it.id === ref.workplace);
          const ex = checkExists(maybe, `Associated entry in resume.experience.data for "${workplace}"`);
          return <ReferenceItem item={ref} experience={ex} key={index}/>
        };

        case 'sep':
          return <Divider/>;

        default: throw new UnreachableError(it);
      }
    });
  }, [
    reference,
    experience,
  ]);

  return (
      <Rows gridTemplateColumns="1fr">
        <Heading subHeading={undefined}>
          References
        </Heading>
        {items}
      </Rows>
  );
});

const Br = () => <><br/><br/></>;

const ReferenceItem = ({
  item,
  experience,
}: {
  item: ReferenceData,
  experience: ExperinceData,
}) => {
  const content = React.useMemo(() => {
    const author = (
        <>
          {item.identity.name}<br/>
          <small>{item.identity.title}</small>
        </>
    );

    switch (item.reference.kind) {
      case 'written':
        return (
            <Rows gridTemplateColumns="1fr">
              <ReferenceBody>
                <Markdown markdown={item.reference.body} Br={Br}/>
              </ReferenceBody>
              <AuthorHeading>{author}</AuthorHeading>
            </Rows>
        );

      case 'provided-upon-request':
        return (
            <Rows gridTemplateColumns="1fr">
              <div>
                <br/>
                <ReferenceBody>Available upon Request</ReferenceBody>
                <br/>
              </div>
              <AuthorHeading>{author}</AuthorHeading>
            </Rows>
        );

      default:
        throw new UnreachableError(item.reference);
    }
  }, [item, experience]);

  return (
      <>
        <SubHeadingWithTimeAndTagline
            tenure={experience.tenure}
            tagline={experience.name}
        >
          {item.identity.name}
        </SubHeadingWithTimeAndTagline>
        {content}
      </>
  );
};

const ReferenceBody = ({ children }: { children: any }) => (
    <Quote>
      <Type.Large
          margins="none"
          style="body"
          lineHeight="1.8"
          letterSpacing="normal"
      >
        <em>{children}</em>
      </Type.Large>
    </Quote>
);

const AuthorHeading = ({ children }: { children: any }) => (
    <Type.SubSubHeading
        weight="bold"
        style="monospace"
        lineHeight="1.8"
        letterSpacing="medium"
    >
        {children}
    </Type.SubSubHeading>
);

const Quote = styled.div`
  padding-left: ${baseUnit * 4}px;
  opacity: 0.8;
`;

const Divider = styled.hr`
  width: 100%;
  height: ${baseUnit}px;
  background:
      linear-gradient(
          to bottom right,
          ${retroRed},
          ${retroPurple});
`;
