import * as React from 'react';
import { ChromelessButton } from '@akst.io/web-resume-dom/ui/base/button/button';
import { useMouseMove } from '@akst.io/web-resume-dom/ui/base/mouse/mouse';
import { useMeasure, useMeasureWithElement } from '@akst.io/web-resume-dom/ui/base/responsive/measure';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import * as styles from './styles';

export const Scrollable = React.memo(({
  children,
}: {
  children: any,
}) => {
  const [element, setElement] = React.useState<null | HTMLDivElement>(null);
  const [handle, setHandle] = React.useState<null | HTMLDivElement>(null);
  const [scroll, setScroll] = React.useState(0);
  const [holdingHandle, setHoldingHandle] = React.useState(false);
  const bounds = useMeasureWithElement(element, undefined, [element]);

  const onScroll = React.useCallback(() => {
    element && setScroll(element.scrollTop);
  }, [element]);

  const height = bounds && bounds.height;
  const scrollHeight = element && element.scrollHeight;

  const handlePosition = React.useMemo(() => {
    if (scrollHeight == null || height == null) return 0;
    const handleHeight = baseUnit * 2;
    const scrollableDistance = height - (handleHeight + 4);
    const scrollDifference = scrollHeight - height
    return scrollableDistance * (scroll / scrollDifference);
  }, [height, scroll]);

  const transform = `translate(0, ${handlePosition}px)`;

  const handleDown = React.useCallback(() => setHoldingHandle(true), []);
  const handleUp = React.useCallback(() => setHoldingHandle(false), []);

  useMouseMove((event: MouseEvent) => {
    if (!holdingHandle) return;
    if (element == null) return;
    if (height == null) return;
    if (scrollHeight == null) return;

    const scrollDifference = scrollHeight - height

    const { top } = element.getBoundingClientRect();
    const scrollPercent = Math.min(1, Math.max(0, event.clientY - top) / height);
    element.scrollTo({ top: (scrollHeight - height) * scrollPercent });
  }, [
    height,
    element,
    handle,
    holdingHandle,
  ]);

  return (
      <styles.ScrollableContainer>
        <styles.Scrollable
            ref={setElement}
            onScroll={onScroll}
        >
          {children}
          <styles.ScrollBar>
            <styles.ScrollHandlePosition
                ref={setHandle}
                style={{transform}}
            >
              <ChromelessButton
                  onPointerEnd={handleUp}
                  onPointerEndOutside={handleUp}
                  onPointerStart={handleDown}
              />
            </styles.ScrollHandlePosition>
          </styles.ScrollBar>
        </styles.Scrollable>
      </styles.ScrollableContainer>
  );
});
