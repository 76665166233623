import { configure } from 'mobx';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { unindent } from '@akst.io/lib/base/strings';
import { getAudioContextConstructor } from '@akst.io/web-resume-dom/base/polyfill';
import { pick } from '@akst.io/web-resume-dom/base/array';
import {
  equalOrGreaterThan,
  getClock,
  PartialTime,
} from '@akst.io/web-resume-dom/base/time/time';

import { ApplicationServiceImpl } from '@akst.io/web-resume-dom/services/application/application_service_impl';
import { startRemoteApplication } from '@akst.io/web-resume-dom/services/application/remote_application_starter';
import { AssetService } from '@akst.io/web-resume-dom/services/asset/asset_service';
import { loadFont } from '@akst.io/web-resume-dom/services/asset/load_font';
import { AudioServiceImpl } from '@akst.io/web-resume-dom/services/audio/audio_service_impl';
import { activateAudioOnReady } from '@akst.io/web-resume-dom/services/audio/activate';
import { DesktopServiceImpl } from '@akst.io/web-resume-dom/services/desktop/desktop_service_impl';
import { DeviceServiceImpl } from '@akst.io/web-resume-dom/services/device/device_service_impl';
import { createFileSystemService } from '@akst.io/web-resume-dom/services/file_system/create';
import { LoggingServiceImpl } from '@akst.io/web-resume-dom/services/logging/logging_service_impl';
import { createResourceService } from '@akst.io/web-resume-dom/services/resource/resource_service_impl';
import { SpeechServiceImpl } from '@akst.io/web-resume-dom/services/speech/speech_service_impl';
import { SpriteService } from '@akst.io/web-resume-dom/services/sprite/sprite_service';

import { installAssistant } from '@akst.io/web-resume-dom/ui/application/assistant/install';
import { installDocViewer } from '@akst.io/web-resume-dom/ui/application/doc_viewer/install';
import { installFileAccessManager } from '@akst.io/web-resume-dom/ui/application/file_access_manager/install';
import { installImageViewer } from '@akst.io/web-resume-dom/ui/application/image_viewer/install';
import { installResumeViewer } from '@akst.io/web-resume-dom/ui/application/resume_viewer/install';
import { installVideoViewer } from '@akst.io/web-resume-dom/ui/application/video_viewer/install';
import { installNotePad } from '@akst.io/web-resume-dom/ui/application/note_pad/install';
import { installSpriteMaker } from '@akst.io/web-resume-dom/ui/application/sprite_maker/install';
import { installFileExplorer } from '@akst.io/web-resume-dom/ui/application/file_explorer/install';
import { createFlow } from '@akst.io/web-resume-dom/ui/app_flow/create';
import { installAlphabetSprites } from '@akst.io/web-resume-dom/ui/sprites/alphabet';
import { createDraggable } from '@akst.io/web-resume-dom/ui/system/draggable/create';
import { createFileGrid } from '@akst.io/web-resume-dom/ui/system/file_grid/file_grid';
import { createFileNavigator } from '@akst.io/web-resume-dom/ui/system/file_navigator/file_navigator';
import { createFileSprite } from '@akst.io/web-resume-dom/ui/system/file_sprite/file_sprite';
import { createImage } from '@akst.io/web-resume-dom/ui/system/image/image';
import { createPanel } from '@akst.io/web-resume-dom/ui/system/panel/create';

import { Bootstrap } from './bootstrap';
import { GlobalStyles } from './global_styles';

configure({ enforceActions: "observed" });

try {
  const FILE_EXPLORER_APPLICATION_ID = 'file-explorer';

  const clock = getClock();
  const bootstrap = Bootstrap.parseFromUrl(window.location.search);
  const userNameNumber = pick([1, 2, 3, 69, 420]);
  const wordSeperator = pick(['-', '_', ' ', '']);
  const seperateWords = (...words: string[]) => words.join(wordSeperator);

  const username = pick([
    `superblade${userNameNumber}`,
    seperateWords('master', 'of', 'da', 'blade'),
    `dark10rd${userNameNumber}`,
    'he11m@$ter',
    seperateWords('super', 'bad', 'as$', `${userNameNumber}`),
    seperateWords('1ink1npark', 'fan'),
    seperateWords('pwn', 'master', `${userNameNumber}`),
    seperateWords('super', 'skate', '10'),
  ]);

  const password = pick([
    seperateWords('Can', 'I', 'get', 'ahhhhhhh'),
    seperateWords('monty', 'mole'),
    seperateWords('maybe', 'so'),
    `password${userNameNumber}`,
    'qwertyuiop',
    'secret',
    'batman',
    'loooooooooooooooool',
    '10-28-1990',
    'hehe',
    seperateWords('pepperoni', 'secret'),
    seperateWords('panini', 'why', 'you', 'such', 'a', 'menie'),
    seperateWords('i', 'got', 'the', 'horses', 'in', 'the', 'back'),
  ]);

  const AudioContext = getAudioContextConstructor();
  const audioContext = new AudioContext();
  const resourceService = createResourceService({ audioContext });
  const assetService = new AssetService(
      fetch.bind(window),
      audioContext,
      loadFont,
  );
  const deviceService = new DeviceServiceImpl(window);
  const audioService = new AudioServiceImpl(
      assetService,
      audioContext,
  );

  activateAudioOnReady(audioService, audioContext);

  const fileSystemService = createFileSystemService({
    bootstrap,
    password,
    userName: username,
  });

  const loggingService = LoggingServiceImpl.create(window.console);

  const speechService = SpeechServiceImpl.create();

  const spriteService = new SpriteService(
      ['sprites', 'folder.sprite'],
      ['sprites'],
      fileSystemService,
  );

  installAlphabetSprites(
      fileSystemService,
      ['sprites', 'alphabet'],
  );

  const desktopService = new DesktopServiceImpl();

  const applicationService = new ApplicationServiceImpl(
      FILE_EXPLORER_APPLICATION_ID,
      250,
      startRemoteApplication,
      fileSystemService,
  );

  const FileSprite = createFileSprite({ spriteService });

  const Image = createImage({
    performance: window.performance,
  });

  const {
    DragPoint,
    DragContainer,
    useDragSlot,
  } = createDraggable({
    loggingService: loggingService.createChild('draggable'),
  });

  const Panel = createPanel({
    DragPoint,
    fileSystemService,
    FileSprite,
    useDragSlot,
  });

  const FileGrid = createFileGrid({ FileSprite });

  const FileNavigator = createFileNavigator({
    fileSystemService,
    FileGrid,
  });

  const fileHelperService = installFileAccessManager({
    fileSystemService,
    FileNavigator,
    Panel,
  });

  const {
    assistantController,
  } = installAssistant({
    DragPoint,
    services: {
      applicationService,
      assetService,
      audioService,
      desktopService,
      fileSystemService,
      loggingService: loggingService.createChild('Assistant'),
      speechService,
      spriteService,
    },
    useDragSlot,
  });

  installFileExplorer({
    applicationId: FILE_EXPLORER_APPLICATION_ID,
    fileSystemService,
    applicationService,
    FileNavigator,
    spriteService,
    Panel,
  });

  installImageViewer({
    fileSystemService,
    applicationService,
    spriteService,
    audioService,
    Panel,
  });

  installDocViewer({
    fileSystemService,
    applicationService,
    spriteService,
    audioService,
    Panel,
  });

  installResumeViewer({
    assistantController,
    Panel,
    Image,
    services: {
      applicationService,
      audioService,
      deviceService,
      loggingService: loggingService.createChild('ResumeViewer'),
      fileSystemService,
      resourceService,
      spriteService,
    },
  });

  installVideoViewer({
    fileSystemService,
    applicationService,
    spriteService,
    Panel,
  });

  installNotePad({
    fileHelperService,
    applicationService,
    spriteService,
    Panel,
  });

  installSpriteMaker({
    fileSystemService,
    fileHelperService,
    applicationService,
    spriteService,
    Panel,
  });

  const { controller, Component: Flow } = createFlow({
    applicationService,
    assistantController,
    audioService,
    assetService,
    clock,
    desktopService,
    DragContainer,
    fileSystemService,
    FileGrid,
    loggingService: loggingService.createChild('Flow'),
    speechService,
    username,
  });

  const rootEl = document.getElementById('root');
  if (rootEl == null) throw new Error('missing root');

  const root = createRoot(rootEl);
  root.render((
    <>
      <GlobalStyles/>
      <Flow/>
    </>
  ));
  controller.startFlow();
} catch (e) {
  console.error(e);
}
