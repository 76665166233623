import * as Result from '@akst.io/lib/base/result';
import * as t from './types';

type Path = string[];
type KeyValueValidator<T extends Record<string, any>> =
    <K extends keyof T>(k: K, value: T[K] | undefined) => Result.T<T[K], Path>;

function withKey<T extends Record<string, any>>(
    object: Partial<T>,
    withEntry: KeyValueValidator<T>,
): Result.T<T, Path> {
  const output: Partial<T> = {};

  for (const [key, value] of Object.entries(object)) {
    const result = withEntry(key, value);
    if (!result.ok) return Result.Err([key, ...result.error]);
    (output as any)[key] = result.value;
  }

  return Result.Ok(output as T);
}

export function isResume(object: Partial<t.Resume>): object is t.Resume {
  if (object.about == null || !isResumeAbout(object.about)) return false;
  if (object.skill == null || !isResumeSkill(object.skill)) return false;
  if (object.experince == null || !isResumeExperince(object.experince)) return false;
  if (object.education == null) return false;
  if (object.reference == null || !isResumeReference(object.reference)) return false;
  return true;
}

export function isResumeAbout(about: Partial<t.About>): about is t.About {
  if (about.name == null) return false;
  if (typeof about.name.long !== 'string') return false;
  if (typeof about.name.short !== 'string') return false;
  if (typeof about.name.title !== 'string') return false;
  if (about.links == null) return false;
  if (!isTextWithLink(about.links.website)) return false;
  if (typeof about.links.resume !== 'string') return false;
  if (about.contact == null) return false;
  if (!isTextWithLink(about.contact.email)) return false
  if (about.contact.phone && !isTextWithLink(about.contact.phone)) return false
  if (about.contact.location == null) return false
  return true;
}

export function isResumeSkill(skill: Partial<t.Skill>): skill is t.Skill {
  function isSkillData(data: Partial<t.SkillData>): data is t.SkillData {
    if (!isPriority(data.priority)) return false;
    if (typeof data.name !== 'string') return false;
    if (!data.kind || !['pill', 'bullet'].includes(data.kind)) return false;
    if (typeof data.id !== 'string') return false;
    if (!Array.isArray(data.data) || !data.data.every(isSkillDataData)) return false;
    return true;
  }

  function isSkillDataData(data: Partial<t.SkillDataData>): data is t.SkillDataData {
    if (!isPriority(data.priority)) return false;
    if (typeof data.priority !== 'number') return false;
    if (typeof data.proficiency !== 'number') return false;
    if (!['string', 'undefined'].includes(typeof data.url)) return false;
    if (!['string', 'undefined'].includes(typeof data.label)) return false;
    if (!['boolean', 'undefined'].includes(typeof data.feature)) return false;
    if (!['number', 'string', 'undefined'].includes(typeof data.since)) return false;
    return true;
  }

  if (!Array.isArray(skill.data) || !skill.data.every(isSkillData)) return false;
  return true;
}

export function isResumeExperince(e: Partial<t.Experince>): e is t.Experince {
  const layouts = new Set<string | undefined>(['collapse', 'minimal', 'full']);
  function isItem(it: Partial<t.ExperinceData>): it is t.ExperinceData {
    if (it.id == null) return false;
    if (it.name == null) return false;
    if (!isOptional(it.about, isMarkdown)) return false;
    if (!layouts.has(it.printLayout)) return false;
    //if (it.position == null) return false;
    return true;
  }

  if (e.data == null) return false;
  return e.data.every(isItem);
}

export function isResumeReference(reference: Partial<t.Reference>): reference is t.Reference {
  const kinds = new Set<string | undefined>(['current-coworker', 'former-boss']);

  function isReferenceData(data: Partial<t.ReferenceData>): data is t.ReferenceData {
    if (!kinds.has(data.kind)) return false;
    if (!isStr(data.workplace)) return false;
    if (data.identity == null) return false;
    if (!isStr(data.identity.name)) return false;
    if (!isStr(data.identity.title)) return false;
    if (!isOptional(data.comment, isMarkdown)) return false;
    return true;
  }

  if (!Array.isArray(reference.data) || !reference.data.every(isReferenceData)) return false;
  return true;
}

export function isLongShort(m: Partial<t.LongShort>): m is t.LongShort {
  if (!m.short || !isMarkdown(m.short)) return false;
  if (m.long != null && isMarkdown(m.long)) return false;
  return true;
}

export function isMarkdown(m: Partial<t.Markdown>): m is t.Markdown {
  if (typeof m.desc !== 'string') return false;
  if (m.links != null && typeof m.links !== 'object') return false;
  return true;
}

export function isTextWithLink(p: t.TextWithLink | undefined): p is t.TextWithLink {
  if (p == null) return false;
  if (p.text == null || p.href == null) return false;
  return true;
}

export function isPriority(p: number | undefined): p is t.Priority {
  if (p == null) return false;
  if (p < 1 || p > 5) return false;
  return true;
}

function isStr(x: any): x is string {
  return typeof x === 'string';
}

function isOptional<T>(x: any, f: (n: any) => n is T): x is (T | undefined) {
  if (x == null) return true;
  return f(x);
}
