import { observer } from 'mobx-react';
import * as React from 'react';
import { Normal } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import {
  StartupStepPresenter,
  StartupStepStore,
} from './startup_step_presenter';
import { LoadingBar, LoadingBarProps } from './loading_bar/loading_bar';
import { StepProps } from '../types';
import {
  Brand,
  ClickToContinue,
  ClickToContinueContainer,
  FullContent,
  LoadingBarContainer,
  Logo,
  Message,
  Root,
  TextContent,
  Title,
  Version,
} from './styles';

export type ExternallyInjectedProps = {
  store: StartupStepStore,
  presenter: StartupStepPresenter,
};

export type InternallyInjectedProps = {
  LoadingBar: React.ComponentType<LoadingBarProps>;
};

export type StartupStepProps =
    & ExternallyInjectedProps
    & InternallyInjectedProps
    & StepProps;

const ANIMATION_TIME = 2000;

export const StartupStep = observer(class StartupStep extends React.Component<StartupStepProps> {
  render() {
    const { store, LoadingBar } = this.props;
    const rootStyle = {
      opacity: store.transitioningAway ? 0 : 1,
      transitionDuration: this.transitionDuration,
    };
    const contentStyle = {
      opacity: store.loadedMicrosoftLogo ? 1 : 0.005,
      transitionDuration: this.transitionDuration,
    };
    return (
        <Root onClick={this.onClick} style={rootStyle}>
          <FullContent style={contentStyle}>
            <TextContent>
              <Logo src={store.microsoftLogo} onLoad={this.onImageLoad}/>
              <Message>
                <Title>
                  <Brand>Michealsoft</Brand>
                  {" Windows "}
                  <Version>64</Version>
                </Title>
                {this.maybeRenderClickToContinue()}
              </Message>
            </TextContent>
            <LoadingBarContainer>
              <LoadingBar complete={store.loadedGlobalAssets}/>
            </LoadingBarContainer>
          </FullContent>
        </Root>
    );
  }

  get transitionDuration(): string {
    return `${ANIMATION_TIME / 1000}s`;
  }

  get backgroundImage(): string {
    const { backgroundImage } = this.props.store;
    return backgroundImage ? `url('${backgroundImage}')` : '';
  }

  private maybeRenderClickToContinue(): JSX.Element | undefined {
    if (this.props.store.loadedGlobalAssets) {
      return (
          <ClickToContinueContainer>
            <ClickToContinue>
              Click to Continue
            </ClickToContinue>
          </ClickToContinueContainer>
      );
    }
  }

  private readonly onImageLoad = () => {
    const { presenter, store } = this.props;
    presenter.onLoadedImage(store, ANIMATION_TIME);
  };

  private readonly onClick = () => {
    if (this.props.transitionState.type === "active") {
      const { presenter, store, onStepTransition } = this.props;
      presenter.onContinue(
          store,
          onStepTransition,
          ANIMATION_TIME,
      );
    }
  };
});

export function createComponent(
    injected: ExternallyInjectedProps,
): React.ComponentType<StepProps> {
  return React.memo((props: StepProps) => (
      <StartupStep
          LoadingBar={LoadingBar}
          {...injected}
          {...props}
      />
  ));
}
