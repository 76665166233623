import styled from 'styled-components';
import { black, bgGrey, borderGrey } from '@akst.io/web-resume-dom/ui/base/color/colors';
import { baseUnit, smallestUnit } from '@akst.io/web-resume-dom/ui/base/units/units';

export const Root = styled.div`
  background: ${bgGrey};
  border-color: ${borderGrey};
  border-style: solid;
  border-width: ${smallestUnit}px;
  padding: ${smallestUnit}px;
  user-select: none;
  display: flex;
  flex-direction: column;

  &:focus { outline: none; }
`;

export const Content = styled.div`
  display: block;
  color: ${black};
  flex-grow: 1;
`;

export const ResizeBarRoot = styled.div`
  position: relative;
  height: ${baseUnit * 3}px;
`;

export const ResizeBox = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: ${baseUnit * 3}px;
  height: 100%;
  background: ${bgGrey};
`;

