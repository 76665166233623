import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { checkExists } from '@akst.io/lib/base/types';
import { ApplicationService } from '@akst.io/web-resume-dom/services/application/application_service';
import { FileHelperService } from '@akst.io/web-resume-dom/services/file_helper/file_helper_service';
import { LiveFile } from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';

export const FILE_EXTENSION = 'txt';

export class NotePadStore {
  file?: LiveFile;
  unsavedData: string;

  constructor(
      file: LiveFile | undefined,
      public readonly procId: number,
      unsavedData: string = '',
  ) {
    this.file = file;
    this.unsavedData = unsavedData;

    makeObservable(this, {
      file: observable.ref,
      unsavedData: observable.ref,
      title: computed,
      isSaved: computed
    });
  }

  get title(): string {
    const fileName = this.file ? this.file.name : 'Untitled';
    return `NotePad - ${fileName}`;
  }

  get isSaved(): boolean {
    return this.file != null && this.unsavedData === this.file.data;
  }
}

export class NotePadPresenter {
  constructor(
      private readonly applicationService: ApplicationService,
      private readonly fileHelperService: FileHelperService,
  ) {
    makeObservable(this, {
      onTextChange: action
    });
  }

  createStore(file: LiveFile | undefined, prodId: number) {
    return new NotePadStore(file, prodId, file ? file.unsafeData : '');
  }

  onSave(store: NotePadStore) {
    if (store.file) {
      this.fileHelperService.save(store.unsavedData, store.file.path);
    }
  }

  async onSaveAs(store: NotePadStore) {
    if (store.isSaved) return;

    const result = await this.fileHelperService.saveAs(
        store.unsavedData,
        FILE_EXTENSION,
        store.file && store.file.parent ? store.file.parent.path : ['documents'],
        store.procId,
    );

    if (result.type === 'ok') {
      runInAction(() => store.file = result.file);
    }
  }

  async onOpen(store: NotePadStore) {
    const result = await this.fileHelperService.open(
        FILE_EXTENSION,
        store.file && store.file.parent ? store.file.parent.path : ['documents'],
        store.procId,
    );

    if (result.type === 'ok') {
      runInAction(() => {
        store.file = result.file;
        store.unsavedData = checkExists(
            result.file.data,
            'unable to read data',
        );
      });
    }
  }

  onMinimize(store: NotePadStore) {
    this.applicationService.toggleMinization(store.procId);
  }

  onClose(store: NotePadStore) {
    this.applicationService.stopProc(store.procId);
  }

  onTextChange(store: NotePadStore, value: string) {
    store.unsavedData = value;
  }
}
