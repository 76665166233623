import { checkExists, UnreachableError } from '@akst.io/lib/base/types';

export type ParagraphState =
  | { kind: 'text', from: number }
  | { kind: 'link-read-copy', from: number }
  | { kind: 'link-read-url', from: number, copy: string };

export function formatMarkdown<O>(args: FormatArgs<string, O>) {
  return baseFormatMarkdown(args);
}

export type TextSegment =
  | { kind: 'text', t: string }
  | { kind: 'strong', t: string }
  | { kind: 'em', t: string }

export function * createSegments(t: string): Iterable<TextSegment> {
  type S = TextSegment['kind'];
  let start = 0, end = 0, state: S = 'text';

  for (; end < t.length; end++) {
    const c = t[end];
    const d = t[end - 1];
    let nextState: S | undefined = undefined;

    if (state === 'text' && c === '*' && d !== '\\') {
      nextState = 'strong';
    } else if (state === 'strong' && c === '*' && d !== '\\') {
      nextState = 'text';
    } else if (state === 'text' && c === '_' && d !== '\\') {
      nextState = 'em';
    } else if (state === 'em' && c === '_' && d !== '\\') {
      nextState = 'text';
    }

    if (nextState != null) {
      yield { kind: state, t: t.slice(start, end) };
      start = end + 1;
      state = nextState;
    }
  }

  yield { kind: state, t: t.slice(start, end) };
}

export function formatMarkdown2<O>(args: FormatArgs<TextSegment, O>): readonly O[] {
  return baseFormatMarkdown<O[]>({
    ...args,
    createSeperator: () => [args.createSeperator()],
    createLink: (t, url) => [args.createLink(t, url)],
    createText: t => Array.from(createSegments(t), args.createText),
  }).flat();
}

type FormatArgs<T, O> = {
  desc: string,
  links: Record<string, string> | undefined,
  createText: (t: T) => O,
  createLink: (t: string, url: string) => O,
  createSeperator: () => O,
}

function baseFormatMarkdown<O>({
  desc,
  links = {},
  createSeperator,
  createText,
  createLink,
}: FormatArgs<string, O>): readonly O[] {
  const chunks = desc.split('\n\n');

  const createParagraph = (text: string): O[] => {
    const output: O[] = [];

    let i = 0;
    let state: ParagraphState = { kind: 'text', from: 0 };
    for (; i < text.length; i += 1) {
      const character = text[i];

      switch (state.kind) {
        case 'text':
          if (character === '\\' && text[i + 1] === '[') {
            i += 1;
          } else if (character === '[') {
            output.push(createText(text.slice(state.from, i)));
            state = { kind: 'link-read-copy', from: i + 1 };
          }
          break;

        case 'link-read-copy':
          if (character === '\\' && text[i + 1] === ']') {
            i += 1;
          } else if (character === ']') {
            const copy = text.slice(state.from, i);
            state = { kind: 'link-read-url', from: i + 2, copy };

            if (text[i + 1] !== '[') {
              throw new Error('invalid markdown');
            }

            i += 1;
          }
          break;

        case 'link-read-url':
          if (character === '\\' && text[i + 1] === ']') {
            i += 1;
          } else if (character === ']') {
            const { copy } = state;
            const link = text.slice(state.from, i);
            const url = checkExists(links[link], `markdown url ${link}`);
            output.push(createLink(copy, url));
            state = { kind: 'text', from: i + 1 };
          }
          break;

        default:
          throw new UnreachableError(state);
      }
    }

    if (state.kind === 'text') {
      output.push(createText(text.slice(state.from)));
    } else {
      throw new Error(`Exited on an invalid state, ${JSON.stringify(state)}`);
    }

    return output;
  };

  const lastParagraph = chunks.pop();
  const output: O[] = [];

  for (const paragraph of chunks) {
    output.push(...createParagraph(paragraph));
    output.push(createSeperator());
  }

  if (lastParagraph) {
    output.push(...createParagraph(lastParagraph));
  }

  return output;
}
