
export type Ok<V> = { ok: true, value: V };
export type Err<E> = { ok: false, error: E };
export type T<V, E> = Ok<V> | Err<E>;
export type GetValue<R> = R extends T<infer V, any> ? V : never;
export type GetError<R> = R extends T<any, infer E> ? E : never;

export function Ok<A, E>(value: A): T<A, E> {
  return { ok: true, value };
}

export function Err<A, E>(error: E): T<A, E> {
  return { ok: false, error };
}

export function all3<A1, A2, A3, E>(rs: [T<A1, E>, T<A2, E>, T<A3, E>]): T<[A1, A2, A3], E>{
  return all(rs)
}

export function all2<A1, A2, E>(rs: [T<A1, E>, T<A2, E>]): T<[A1, A2], E>{
  return all(rs)
}

export function all1<A1, E1>(rs: [T<A1, E1>]): T<[A1], E1> {
  return all(rs)
}


type UnwrapResultErrorsInit<R> =
    R extends [T<any, infer E>, ...(infer Tail)] ? UnwrapResultErrors2<E, Tail> :
    R extends [T<any, infer E>] ? E :
    never;

type UnwrapResultErrors2<E, R> =
    R extends [T<any, E>, ...(infer Tail)] ? UnwrapResultErrors2<E, Tail> :
    R extends [T<any, E>] ? E :
    never;

type UnwrapResultOks<R> =
    R extends [T<infer V, any>, ...(infer Tail)] ? [V, ...UnwrapResultOks<Tail>] :
    R extends [T<infer V, any>] ? [V] :
    [];

export function all<R extends [...any[]]>(
    rs: [...R],
): T<UnwrapResultOks<R>, UnwrapResultErrorsInit<R>> {
  const output: any[] = []
  for (const it of rs as any) {
    if (!it.ok) return it as any;
    output.push(it.value);
  }
  return Ok(output) as any;
}

// type ChainReturn<Chain, VOut> =
//     VOut extends T<infer VI, infer EI> ?
//       Chain extends [(_: VI) => T<infer VO, infer EO>, ...(infer Rest)] ?
//         ChainReturn<Rest, T<VO, EI | EO>> :
//       Chain extends [(_: VI) => T<infer VO, infer EO>] ?
//         T<VO, EI | EO> :
//       never :
//     never;
//
// type ChainFactory<F +
//
// export function chain<A, C extends [...any[]]>(input: A, fns: [...C]): ChainReturn<C, T<A, never>> {
//   let acc: T<any, any> = Ok(input);
//   for (const f of fns) {
//     const next = andThen(acc, f);
//     if (!next.ok) return next as any;
//     acc = next;
//   }
//   return acc as any;
// }
//
// const a: T<number, undefined> = chain(Ok(2), [
//   (a: number): T<string, undefined> => Ok(a.toString()),
//   (a: string): T<number, undefined> => Ok(parseInt(a, 10)),
// ]);

type ResultifyHash<E, R extends Record<string, T<any, E>>> =
  T<{ [K in keyof R]: GetValue<R[K]> }, E>;

export function hash<
  E,
  HI extends Record<string, T<any, E>>,
>(
  hashIn: HI,
): ResultifyHash<E, HI> {
  const hashOut: GetValue<ResultifyHash<E, HI>> = {};

  for (const [key, val] of Object.entries(hashIn)) {
    if (!val.ok) return val;
    (hashOut as any)[key] = val.value;
  }

  return unit(hashOut as any);
}

export function run<V, E>(f: () => T<V, E>): T<V, E> {
  return f();
}

export function removeError<A, E>(result: T<A, E>): [E | undefined, T<A, undefined>] {
  if (!result.ok) {
    return [result.error, Err(undefined)];
  }
  return [undefined, result];
}

export function invert<A, B>(r: T<A, B>): T<B, A> {
  return r.ok
      ? ({ ok: false, error: r.value })
      : ({ ok: true, value: r.error });
}

export function unit<A, Z>(a: A): T<A, Z> {
  return Ok(a);
}

export function map<A, B, Z>(a: T<A, Z>, f: (a: A) => B): T<B, Z> {
  return andThen(a, a => unit(f(a)));
}

export function mapErr<A, Y, Z>(a: T<A, Y>, f: (a: Y) => Z): T<A, Z> {
  if (a.ok) return a;
  return Err(f(a.error));
}

export function andThen<A, B, Z>(a: T<A, Z>, f: (a: A) => T<B, Z>): T<B, Z> {
  return a.ok ? f(a.value) : a;
}

export function apply<A, B, E>(f: T<(a: A) => B, E>, a: T<A, E>): T<B, E> {
  return andThen(a, a => map(f, f => f(a)));
}

export function unwrap<A>(r: T<A, unknown>): A {
  if (r.ok) return r.value;
  throw r.error;
}

export function isOk<A>(r: T<A, unknown>): r is { ok: true, value: A } {
  return r.ok;
}

export function merge<A, B, C>(r: T<A, B>, fa: (a: A) => C, fb: (b: B) => C): C {
  return r.ok ? fa(r.value) : fb(r.error);
}

export function bool<A, B>(p: boolean, a: A, b: B): T<A, B> {
  return p ? Ok(a) : Err(b);
}
