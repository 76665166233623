export type Sprite = {
  type: 'machine';
  width: number;
  height: number;
  fills: (number | null)[];
};

export function deserializeSprite(unparsed: string): [string | undefined, Sprite | undefined] {
  try {
    const parsed = JSON.parse(unparsed);
    if (isSprite(parsed)) {
      return [undefined, parsed];
    } else {
      return ['noncompliant sprite file', undefined];
    }
  } catch {
    return ['malformed json', undefined];
  }
}

export function unsafeSpriteCast(json: object): Sprite {
  if (isSprite(json)) return json;
  throw new Error('json is not an sprite');
}

export function isSprite(sprite: Object): sprite is Sprite {
  return isSpriteMachine(sprite);
}

export function isSpriteMachine(sprite: Object): sprite is Sprite {
  if (!('type' in sprite)) return false;
  if (!('width' in sprite)) return false;
  if (!('height' in sprite)) return false;

  let s: any = sprite;

  if (s['type'] !== 'machine') return false;
  if (typeof s['width'] !== 'number') return false;
  if (typeof s['height'] !== 'number') return false;
  if (!Array.isArray(s['fills'])) return false;
  return true;
}
