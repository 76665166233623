import * as Result from './result';

export function isEqual<A>(xs: A[], ys: A[], isEqual: (a: A, b: A) => boolean): boolean {
  if (xs.length !== ys.length) return false;
  return xs.every((x, i) => isEqual(x, ys[i]));
}

export function filterMap<A, B>(
  array: readonly A[],
  operator: (value: A) => Result.T<B, unknown>,
): readonly B[] {
  const output: B[] = [];

  for (const value of array) {
    const result = operator(value);
    if (result.ok) {
      output.push(result.value);
    }
  }

  return output;
}

export function seperateBy<A, B>(
  array: readonly A[],
  seperator: B,
): (A | B)[] {
  const output: (A | B)[] = [];
  for (const item of array.slice(0, -1)) {
    output.push(item);
    output.push(seperator);
  }
  output.push(...array.slice(-1));
  return output;
}

export async function collectAsync<A>(xs: AsyncIterable<A>): Promise<A[]> {
  const output: A[] = [];

  for await (const x of xs) {
    output.push(x);
  }

  return output;
}

export function last<A>(ts: A[]): A {
  return ts[ts.length - 1];
}

export function flatten<A>(ts: readonly A[][]): A[] {
  return ts.flat();
}
