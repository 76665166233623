import * as Result from '@akst.io/lib/base/result';
import { LiveFile, isLiveFile } from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import { FileSystemService } from "@akst.io/web-resume-dom/services/file_system/file_system_service";
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { AssistantExpression } from './type';
import { AssistantImageStore, AssistantImagePresenter } from './assistant_image_presenter';
import { AssistantInstanceStore, AssistantInstancePresenter } from './assistant_instance_presenter';

export class AssistantStore {
  images = new Map<string, AssistantImageStore>();
  instances = new Map<number, AssistantInstanceStore>();
}

export class AssistantPresenter {
  constructor(
      private readonly fileSystemService: FileSystemService,
      private readonly logger: LoggingService,
      private readonly instancePresenter: AssistantInstancePresenter,
      private readonly imagePresenter: AssistantImagePresenter,
  ) {
  }

  getAssistantFile(store: AssistantStore, id: string): Result.T<LiveFile, undefined> {
    this.indexImages(store);

    const image = store.images.get(id);
    if (image != null) {
      return Result.Ok(image.file);
    } else {
      return Result.Err(undefined);
    }
  }

  indexImages(store: AssistantStore) {
    if (store.images.size > 0) return;

    const { children } = this.fileSystemService.getLiveDirectory(['assistants']);
    if (children == null) return;

    for (const child of children.values()) {
      if (!isLiveFile(child)) {
        this.logger.warn('found non file while indexing assistant', child.name);
        continue;
      }

      const { data } = child;
      if (data == null) continue;

      const image = this.imagePresenter.createImage(data, child);
      if (this.imagePresenter.isValidImage(image)) {
        store.images.set(data, image);
      } else {
        this.logger.warn('unable able to create image for', child.name);
      }
    }

    this.logger.debug('loaded images', store.images);
  }

  queueAssistantExpression(
      store: AssistantStore,
      id: number,
      expression: AssistantExpression,
  ): Result.T<undefined, undefined> {
    const instance = store.instances.get(id);
    if (instance == null) return Result.Err(undefined);
    this.instancePresenter.queueAssistantExpression(instance, expression);
    return Result.Ok(undefined);
  }

  maybeCreateInstance(
      store: AssistantStore,
      proc: number,
      file: LiveFile,
  ): Result.T<AssistantInstanceStore, undefined> {
    const image = file.data != null
        ? store.images.get(file.data)
        : undefined;

    if (image == null) return Result.Err(undefined);

    const instance = this.instancePresenter.createInstance(proc, image);
    store.instances.set(proc, instance);
    return Result.Ok(instance);
  }
}
