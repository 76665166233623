import { action, observable, makeObservable } from 'mobx';
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { ContentRectSnapshot } from '@akst.io/web-resume-dom/ui/base/responsive/measure';
import {
  DraggableSlotPresenter,
  DraggableSlotStore,
} from './draggable_slot_presenter';
import { Bounds, DragPositionInitHint } from './types';

export class DraggableContainerStore<E> {
  nextPriority: number = 1;
  readonly slots: Map<string, DraggableSlotStore> = observable.map();
  zIndexes: ReadonlyMap<string, number> = new Map();
  portalElement: E | undefined = undefined;
  portalBounds: Bounds = { top: 0, left: 0, width: 0, height: 0 };

  constructor() {
    makeObservable(this, {
      zIndexes: observable.ref,
      portalElement: observable.ref,
      portalBounds: observable.ref
    });
  }
}

export class DraggableContainerPresenter<E> {
  constructor(
      private readonly logging: LoggingService,
      private readonly slotPresenter: DraggableSlotPresenter,
  ) {
    makeObservable<DraggableContainerPresenter<E>, "computeOrderedDragPoints">(this, {
      setPortalElement: action,
      setPortalBounds: action,
      removeSlot: action,
      createDraggableSlotStore: action,
      computeOrderedDragPoints: action
    });
  }

  setPortalElement(store: DraggableContainerStore<E>, element: E) {
    store.portalElement = element;
  }

  setPortalBounds(store: DraggableContainerStore<E>, bounds: ContentRectSnapshot) {
    store.portalBounds = bounds;
  }

  bumpSlotPriority(
      store: DraggableContainerStore<E>,
      slot: DraggableSlotStore,
  ) {
    if (slot == null) throw new Error('unreachable');
    this.logging.debug('reordering windows');
    this.slotPresenter.setPriority(slot, store.nextPriority++);
    this.computeOrderedDragPoints(store);
  }

  getZIndex(store: DraggableContainerStore<E>, slot: DraggableSlotStore): number | undefined {
    return store.zIndexes.get(slot.id);
  }

  removeSlot(store: DraggableContainerStore<E>, slot: DraggableSlotStore) {
    store.slots.delete(slot.id);
  }

  createDraggableSlotStore(store: DraggableContainerStore<E>, hint: DragPositionInitHint) {
    const priority = store.nextPriority++;
    const slot = this.slotPresenter.createDraggableSlotStore(priority);
    store.slots.set(slot.id, slot);
    this.slotPresenter.resetPosition(slot, hint, store.portalBounds);
    return slot;
  }

  private computeOrderedDragPoints(store: DraggableContainerStore<E>) {
    store.zIndexes = this.getZIndexes(store);
  }

  private getZIndexes(store: DraggableContainerStore<E>): ReadonlyMap<string, number> {
    const mapAsArray = Array.from(store.slots.values());

    // get an array with the lowerest at the start.
    const sortedPairs: DraggableSlotStore[] = mapAsArray.sort((a, b) => {
      if (a.priority > b.priority) return 1;
      if (a.priority < b.priority) return -1;
      return 0;
    });

    return new Map(
        sortedPairs.map((store, index): [string, number] => [store.id, index + 1]),
    );
  }
}
