import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import Measure, { ContentRect } from 'react-measure';
import { LiveFileSystemNode } from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import { TypographyProps } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import type { DragContainerProps } from '@akst.io/web-resume-dom/ui/system/draggable/create';
import { FileGridProps } from '@akst.io/web-resume-dom/ui/system/file_grid/file_grid';
import { StepProps } from '../types';
import { StartBar, StartBarProps } from './start_bar/start_bar';
import { Background, Desktop, FileGridContainer } from './styles';
import {
  DesktopStepPresenter,
  DesktopStepStore,
} from './desktop_step_presenter';

export type DesktopStepProps = StepProps & {
  store: DesktopStepStore,
  presenter: DesktopStepPresenter,
  FileGrid: React.ComponentType<FileGridProps>,
  StartBar: React.ComponentType<StartBarProps>,
  DragContainer: React.ComponentType<DragContainerProps>,
};

export const DesktopStep = observer(class DesktopStep extends React.Component<DesktopStepProps> {
  constructor(props: DesktopStepProps) {
    super(props);

    makeObservable<DesktopStep, "applications">(this, {
      activeItems: computed,
      applications: computed
    });
  }

  get activeItems(): StartBarProps['activeItems'] {
    return this.props.store.runningApplications.map(([id, instance]): [number, string] => {
      return [id, instance.controller.title];
    });
  }

  private get applications(): JSX.Element[] {
    const { store } = this.props;
    return store.applicationWindows.map(([key, instance]) => (
        <instance.Component key={key}/>
    ));
  }

  render() {
    const {
      activeItems,
      applications,
      props: {
        FileGrid,
        StartBar,
        DragContainer,
        store: { directory },
      },
    } = this;

    return (
        <>
          <Measure onResize={this.onResize} bounds={true}>
            {({ measureRef }) => (
              <DragContainer>
                <Background>
                  <Desktop ref={measureRef}>
                    {applications}
                  </Desktop>
                  <FileGridContainer>
                    <FileGrid
                        directory={directory}
                        onFileOpen={this.onFileOpen}
                        flexDirection="column"
                    />
                  </FileGridContainer>
                </Background>
              </DragContainer>
            )}
          </Measure>
          <StartBar
              activeItems={activeItems}
              onClickItem={this.onStartBarItemClick}
          />
        </>
    );
  }

  private readonly onStartBarItemClick = (id: number) => {
    const { presenter, store } = this.props;
    presenter.onToggleMinization(store, id);
  };

  private readonly onFileOpen = (file: LiveFileSystemNode) => {
    const { presenter, store } = this.props;
    presenter.onFileOpen(store, file);
  };

  private readonly onResize = (contentRect: ContentRect) => {
    const { store, presenter, onStepTransition } = this.props;
    const boundingRect = contentRect.bounds || { width: 0, height: 0 };
    presenter.onResize(store, onStepTransition, boundingRect);
  };

  private readonly onContinue = () => {
    console.log('continue');
  };
});
