import { AudioService } from './audio_service';

export function activateServiceOnEvent(
  enterEvent: 'mousedown' | 'touchstart',
  leaveEvent: 'mouseup' | 'touchend',
  audioContext: AudioContext,
) {
  return new Promise<void>((resolve, reject) => {
    if (audioContext.state === 'running') resolve();

    const eventHandler = () => {
      audioContext.resume().then(() => {
        resolve();
        document.body.removeEventListener(enterEvent, eventHandler);
        document.body.removeEventListener(leaveEvent, eventHandler);
      }, reject);
    };

    document.body.addEventListener(enterEvent, eventHandler, false);
    document.body.addEventListener(leaveEvent, eventHandler, false);
  })
};

export function activateAudioOnReady(audioService: AudioService, audioContext: AudioContext) {
  const resumePromise = 'ontouchstart' in window
      ? activateServiceOnEvent('touchstart', 'touchend', audioContext)
      : activateServiceOnEvent('mousedown', 'mouseup', audioContext);
  audioService.setResumePromise(resumePromise);
}
