import { FileSystemService } from '@akst.io/web-resume-dom/services/file_system/file_system_service';
import {
  isLiveDirectory,
  isLiveShortcut,
  isLiveFile,
  LiveFileSystemNode,
} from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import { FilePathDescription } from '@akst.io/web-resume-dom/services/file_system/types';
import {
  deserializeSprite,
  Sprite,
} from './types';

export class SpriteService {
  private readonly sprites: Map<string, FilePathDescription> = new Map();

  constructor(
      private readonly folderSpritePath: FilePathDescription,
      private readonly spriteDirectoryPath: ReadonlyArray<string>,
      private readonly fileSystemService: FileSystemService,
  ) {

  }

  register(extension: string, filename: string, sprite: Sprite) {
    const filePath = [...this.spriteDirectoryPath, filename];
    const serializedSprite = JSON.stringify(sprite);
    this.sprites.set(extension, filePath);
    this.fileSystemService.write(filePath, serializedSprite);
  }

  getSprite(file: LiveFileSystemNode): Sprite | undefined {
    const resolved = isLiveShortcut(file)
        ? this.fileSystemService.lookupShortcut(file)
        : file;

    if (resolved == null) return;

    const path = this.getSpritePath(resolved);
    if (!path) return;

    const fileData = this.fileSystemService.getLiveFile(path).data;
    if (fileData == null) return;

    const [errorMessage, sprite] = deserializeSprite(fileData);
    if (sprite == null) throw new Error(errorMessage);

    return sprite;
  }

  private getSpritePath(file: LiveFileSystemNode): undefined | FilePathDescription {
    const split = file.name.split('.');
    if (isLiveFile(file)) {
      const extension =  split[split.length - 1];
      const spriteId = extension === 'exe' ? file.unsafeData : extension;
      return this.sprites.get(spriteId);
    } else if (isLiveDirectory(file)) {
      return this.folderSpritePath;
    }
  }
}
