import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';
import { checkExists } from '@akst.io/lib/base/types';
import { smallestUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { borderGreyInverted } from '@akst.io/web-resume-dom/ui/base/color/colors';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import { ImageViewerStore, ImageViewerPresenter } from './image_viewer_presenter';

export type ImageViewerProps = {
  url?: string;
  onLoad(image: HTMLImageElement): void;
  loaded: boolean;
  Panel: React.ComponentType<Pick<PanelProps, 'children'>>;
};

export const ImageViewer = React.memo(function ImageViewer(props: ImageViewerProps) {
  const [maybeImage, setImage] = React.useState<HTMLImageElement | null>();
  const { url, onLoad, Panel } = props;

  const onLoadInner = React.useCallback(() => {
    onLoad(checkExists(maybeImage, `Cannot find image ${url}`));
  }, [maybeImage, onLoad]);

  return (
      <Panel>
        <StyledImage ref={setImage} src={url} onLoad={onLoadInner}/>
      </Panel>
  );
});

export const StyledImage = styled.img`
  display: block;
  box-sizing: border-box;
  max-width: 75vw;
  max-height: 50vh;

  border-color: ${borderGreyInverted};
  border-style: solid;
  border-width: ${smallestUnit}px;
`;

export type CreateOptions = {
  store: ImageViewerStore;
  presenter: ImageViewerPresenter;
  Panel: React.ComponentType<PanelProps>;
};

export function createImageViewer(options: CreateOptions): React.ComponentType {
  const { store, presenter, Panel } = options;

  const onClose = () => {
    presenter.onClose(store);
  };

  const onLoad = (image: HTMLImageElement) => {
    presenter.onLoad(store, image);
  };

  const WiredPanel = observer((props: Pick<PanelProps, 'children'>) => (
      <Panel
          title={presenter.getTitle(store)}
          onClose={onClose}
          {...props}
      />
  ));

  return observer(() => (
      <ImageViewer
          loaded={store.loaded}
          url={store.file.data}
          onLoad={onLoad}
          Panel={WiredPanel}
      />
  ));
}
