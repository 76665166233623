import * as React from 'react';
import styled from 'styled-components';
import { getUniqueString } from '@akst.io/web-resume-dom/base/string';
import { Normal } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { usePointerEvents } from '@akst.io/web-resume-dom/ui/base/cross_platform/pointer_events';
import { baseUnit } from '@akst.io/web-resume-dom/ui/base/units/units';

export type RadioOption<T> = { key: string, value: T, label: React.ReactNode };

export type RadioInputProps<T> = {
  value: T;
  label: React.ReactNode;
  options: ReadonlyArray<RadioOption<T>>;
  onChange(value: T): void;
};

export const RadioInput = React.memo(function RadioInput<T>(props: RadioInputProps<T>) {
  const { label, value, options, onChange } = props;
  const name = React.useMemo(() => getUniqueString(), []);

  const radios: React.ReactNode = React.useMemo(() => (
      options.map((option) => (
          <RadioRow
              {...option}
              key={option.key}
              onChange={onChange}
              checked={option.value === value}
          />
      ))
  ), [value, options, onChange]);

  return (
      <Form>
        <Normal weight="bold">{label}</Normal>
        {radios}
      </Form>
  );
});

type RadioRowProps<T> = {
  value: T;
  label: React.ReactNode;
  checked: boolean;
  onChange(value: T): void;
};

const RadioRow = React.memo(function RadioRow<T>(props: RadioRowProps<T>) {
  const { label, value, checked, onChange } = props;

  const onInputChange = React.useCallback(() => onChange(value), [value, onChange]);

  const setRef = usePointerEvents<HTMLDivElement>({ onPointer: onInputChange });

  return (
      <Row ref={setRef}>
        <Input
            type="radio"
            onChange={onInputChange}
            checked={checked}
        />
        {label}
      </Row>
  );
});

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: calc(${baseUnit}px / 2);
`;

const Input = styled.input`
  display: inline-block;
  margin-right: ${baseUnit}px;
  margin-left: 0;
`;
