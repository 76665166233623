import { observer, Observer } from 'mobx-react';
import * as React from 'react';
import Measure, { ContentRect, MeasuredComponentProps } from 'react-measure';
import { FilePathDescription } from "@akst.io/web-resume-dom/services/file_system/types";
import { KeyboardEventDescriptor } from '@akst.io/web-resume-dom/ui/base/keyboard/use_keyboard_events';
import { useMeasureWithElement } from '@akst.io/web-resume-dom/ui/base/responsive/measure';
import { Normal } from '@akst.io/web-resume-dom/ui/base/typography/typography';
import { baseUnit, smallestUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import { CanvasStore } from './ui/canvas/canvas_presenter';
import { controlWidth } from './ui/controls/controls';
import { createTreeMenu } from './ui/tree_menu/tree_menu';
import {
  ContentRectBounds,
  SpriteMakerStore,
  SpriteMakerPresenter,
} from './sprite_maker_presenter';
import {
  CanvasWrapper,
  ColorsContainer,
  LayoutCol,
  LayoutRow,
} from './sprite_maker_styles';

const canvasBorder = smallestUnit * 2;

export type InternallyInjectedProps = {
  canvasWidth: number;
  Panel: React.ComponentType<Pick<PanelProps, 'children'>>;
  TreeMenu: React.ComponentType;
};

export type ExternallyInjectedProps = {
  Canvas: React.ComponentType;
  Controls: React.ComponentType;
  Color: React.ComponentType;
};

export type SpriteMakerProps =
  & ExternallyInjectedProps
  & InternallyInjectedProps;

export const SpriteMaker = React.memo(function SpriteMaker(props: SpriteMakerProps) {
  const {
    canvasWidth,
    Canvas,
    Color,
    Controls,
    Panel,
    TreeMenu,
  } = props;

  /**
   * This enforces a certain bound on the color input.
   */
  const colorStyles = React.useMemo(() => (
      canvasWidth != null
          ? ({ maxWidth: canvasWidth + controlWidth + canvasBorder })
          : undefined
  ), [canvasWidth]);

  return (
      <Panel>
        <TreeMenu/>
        <LayoutCol>
          <LayoutRow>
            <CanvasWrapper>
              <Canvas/>
            </CanvasWrapper>
            <Controls/>
          </LayoutRow>
          <ColorsContainer style={colorStyles}>
            <Color/>
          </ColorsContainer>
        </LayoutCol>
      </Panel>
  );
});

export type CreateOptions = {
  store: SpriteMakerStore;
  presenter: SpriteMakerPresenter;
  Panel: React.ComponentType<PanelProps>;
  filePath: FilePathDescription;
};

export function createSpriteMaker(options: ExternallyInjectedProps & CreateOptions) {
  const { store, presenter } = options;

  const onMinimize = () => {
    presenter.onMinimize(store);
  };

  const onClose = () => {
    presenter.onClose(store);
  };

  const onUndo = () => {
    presenter.onUndo(store);
  };

  const onRedo = () => {
    presenter.onRedo(store);
  };

  const TreeMenu = createTreeMenu({
    onSave: () => presenter.onSave(store),
    onSaveAs: () => presenter.onSaveAs(store),
    onOpen: () => presenter.onOpen(store),
    onClear: () => presenter.onClear(store),
    onUndo,
    onRedo,
  });

  /**
   * Here are the modes supplied to the drawing mode input.
   */
  const keyboardEvents: ReadonlyArray<KeyboardEventDescriptor<HTMLDivElement>> = [
    {
      handler: onUndo,
      type: 'down',
      key: 'z',
      ctrl: true,
    },
    {
      handler: onRedo,
      type: 'down',
      key: 'Z',
      ctrl: true,
    },
  ];

  const PanelImpl = observer((props: Pick<PanelProps, 'children'>) => (
      <options.Panel
          title={store.title}
          pathOfOpenFile={options.filePath}
          onClose={onClose}
          onMinimize={onMinimize}
          keyboardEvents={keyboardEvents}
      >
        {props.children}
      </options.Panel>
  ));

  return observer(() => (
      <SpriteMaker
          Canvas={options.Canvas}
          Color={options.Color}
          Controls={options.Controls}
          Panel={PanelImpl}
          TreeMenu={TreeMenu}
          canvasWidth={options.store.canvasStore.width || 0}
      />
  ));
}
