import classNames from 'classnames';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Rect, renderRects, createRects } from '@akst.io/web-resume-dom/base/rendering/rect';
import { Sprite as SpriteData } from '@akst.io/web-resume-dom/services/sprite/types';
import { spriteRects } from '@akst.io/web-resume-dom/services/sprite/rendering/canvas';
import { hexAsString } from '@akst.io/web-resume-dom/ui/base/color/color';
import { withMeasurement, useMeasure } from '@akst.io/web-resume-dom/ui/base/responsive/measure';
import { Path } from '@akst.io/web-resume-dom/ui/base/svg/path';
import { PathCommand } from '@akst.io/web-resume-dom/ui/base/svg/path';
import { useVisibility } from '@akst.io/web-resume-dom/ui/base/visibility/use_visibility';
import {
  calculateUsableBounds,
  ColorArray,
  Path as DataPath,
  spriteColors,
  spritePaths,
} from './sprite_util';

export type SpriteProps = {
  mode?: 'vector' | 'raster',
  sizing?: 'normal' | 'fill-parent';
  sprite?: SpriteData;
};

export const Sprite = React.memo(function SpriteImpl(props: SpriteProps) {
  const { sizing = 'normal', sprite, mode = 'vector', } = props;

  const fillParent = sizing === 'fill-parent';

  const width = sprite ? sprite.width : 1;
  const height = sprite ? sprite.height : 1;

  const colors = React.useMemo(() => sprite ? spriteColors(sprite) : [0xff0000], [sprite]);

  if (mode === 'vector') {
    return (
        <VectorSprite
            fillParent={fillParent}
            colors={colors}
            width={width}
            height={height}
        />
    );
  } else {
    return (
        <RasterSprite
            fillParent={fillParent}
            colors={colors}
            width={width}
            height={height}
            sizing={sizing}
        />
    );
  }
});

type VectorSpriteProps = {
  fillParent: boolean;
  colors: ReadonlyArray<number | undefined>;
  width: number;
  height: number;
};

const VectorSprite = React.memo(function VectorSprite(props: VectorSpriteProps) {
  const { width, height, colors, fillParent } = props;

  const renderedPaths = React.useMemo(() => {
    const paths = spritePaths(colors, width, height);

    // currently I'm not really sure what would make
    // the best kind of key here, so I guess I'm going
    // with the the array indice.
    return paths.map((path, index) => (
        <Path key={index} {...path}/>
    ));
  }, [width, height, colors]);

  const viewBox = `0 0 ${width} ${height}`;

  return (
      <SvgRoot $fillParent={fillParent} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
        {renderedPaths}
      </SvgRoot>
  );
});

type RasterSpriteProps = {
  fillParent: boolean;
  colors: ReadonlyArray<number | undefined>;
  width: number;
  height: number;
  sizing: 'normal' | 'fill-parent';
};

const RasterSprite = React.memo(function RasterSprite(props: RasterSpriteProps) {
  const { width, height, fillParent, colors, sizing } = props;
  const animationFrameRef = React.useRef<number | undefined>(undefined);
  const [canvas, setCanvas] = React.useState<HTMLCanvasElement | null>(null);

  const [
    { width: clientWidth, height: clientHeight },
    setMeasureRef,
  ] = useMeasure({ width: 0, height: 0 }, []);

  const { width: availableWidth, height: availableHeight } = React.useMemo(() => (
      calculateUsableBounds(
          width,
          height,
          clientWidth,
          clientHeight,
      )
  ), [
    width,
    height,
    clientWidth,
    clientHeight,
  ]);

  const canvasTop = (clientHeight - availableHeight) / 2;
  const canvasLeft = (clientWidth - availableWidth) / 2;
  const pixelWidth = (availableWidth / width);
  const pixelHeight = (availableHeight / height);

  const canDraw = useVisibility({
    element: canvas || undefined,
    updateWhenHidden: false,
    resetOnChange: [clientWidth, clientHeight],
  });

  const rectsMemo = React.useRef<ReadonlyArray<Rect> | undefined>();
  const rects = React.useMemo(() => {
    if (rectsMemo.current) return rectsMemo.current;
    if (!canDraw) return [];

    const rects = createRects(colors, width, height);
    rectsMemo.current = rects;
    return rects;
  }, [
    canDraw,
    colors,
    width,
    height,
  ]);

  React.useEffect(() => {
    if (!canDraw || canvas == null) return;

    const context = canvas.getContext('2d');
    if (context == null) return;

    animationFrameRef.current = requestAnimationFrame(() => {
      context.imageSmoothingEnabled = false;
      context.clearRect(0, 0, pixelWidth, pixelHeight);

      renderRects({
        context,
        rects,
        scaleHeight: pixelWidth,
        scaleWidth: pixelHeight,
      });
    });

    return () => {
      const { current: animationFrame } = animationFrameRef;
      animationFrame != null && cancelAnimationFrame(animationFrame);
    };
  }, [
    canDraw,
    rects,
    canvas,
    pixelWidth,
    pixelHeight,
  ]);

  const baseStyles: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    boxSizing: 'border-box',
    border: 0,
    margin: 0,
    padding: 0,
  } as const;

  const fillParentStyle: React.CSSProperties = {
    paddingTop: canvasTop,
    paddingLeft: canvasLeft,
    paddingRight: canvasLeft,
    paddingBottom: canvasTop,
    ...baseStyles,
  } as const;

  return (
      <div ref={setMeasureRef} style={{ height: '100%' }}>
        <CanvasRoot $fillParent={fillParent}>
          <canvas
              ref={setCanvas}
              height={availableHeight}
              width={availableWidth}
              style={fillParent ? fillParentStyle : baseStyles}
          />
        </CanvasRoot>
      </div>
  );
});

const CanvasRoot = styled.div<{ $fillParent: boolean }>`
  box-sizing: border-box;
  display: block;
  position: relative;
  max-height: 100%;
  max-width: 100%;

  ${({ $fillParent }) => $fillParent && css`
    height: 100%;
    width: 100%;
  `}
`;

const SvgRoot = styled.svg<{ $fillParent: boolean }>`
  box-sizing: border-box;
  display: block;
  position: relative;
  max-height: 100%;
  max-width: 100%;

  ${({ $fillParent }) => $fillParent && css`
    height: 100%;
    width: 100%;
  `}
`;
