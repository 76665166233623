import { ImageAssetId, AudioAssetId } from '@akst.io/web-resume-dom/services/asset/asset_service';
import { Vector } from '@akst.io/web-resume-dom/base/geometry';

export type AssistantFormat = {
  frameSize: Vector,
  layers: number,
  sounds: ReadonlySet<string>,
  animations: ReadonlySet<string>,
};

export type AssistantDefinition = {
  spriteSheet: ImageAssetId,
  frameSize: Vector,
  layers: number,
  sounds: Record<string, AudioAssetId>,
  animations: Partial<Record<AnimationKind, Animation[]>>,
};

export type Animation = {
  frames: AnimationFrame[],
  useExitBranching: boolean,
};

export type Branch = { frame: number, weight: number };

export type AnimationFrame =
  | DeterministicFrame
  | { kind: 'rnd-branching', branches: Branch[] }

export type DeterministicFrame =
  | { kind: 'wait', duration: number }
  | { kind: 'play-sound', sound: string }
  | { kind: 'queue-frame', frame: Frame };

export type RenderFrame =
  | { kind: 'wait', duration: number }
  | { kind: 'queue-frame', frame: Frame };

export function isRenderFrame(frame: DeterministicFrame): frame is RenderFrame {
  return frame.kind !== 'play-sound';
}

export type Frame = {
  duration: number,
  source: Vector[],
  exitFrame?: number,
};

export type AnimationKind =
  | 'acknowledge'
  | 'alert'
  | 'blink'
  | 'congratulate'
  | 'confused'
  | 'explain'
  | 'gesture-down'
  | 'gesture-left'
  | 'gesture-right'
  | 'gesture-up'
  | 'get-attention'
  | 'greet'
  | 'hide'
  | 'idle'
  | 'look-down-start'
  | 'look-down-end'
  | 'look-left-start'
  | 'look-left-end'
  | 'look-right-start'
  | 'look-right-end'
  | 'look-up-start'
  | 'look-up-end'
  | 'move-down-start'
  | 'move-down-end'
  | 'move-left-start'
  | 'move-left-end'
  | 'move-right-start'
  | 'move-right-end'
  | 'move-up-start'
  | 'move-up-end'
  | 'pleased'
  | 'sad'
  | 'search'
  | 'searching'
  | 'show'
  | 'think'
  | 'uncertain'
  | 'wave';
