import styled, { css } from 'styled-components';
import { baseUnit }  from '@akst.io/web-resume-dom/ui/base/units/units';
import {
  bgGrey,
  bgGreyDark,
  borderGrey,
  borderGreyInverted,
}  from '@akst.io/web-resume-dom/ui/base/color/colors';

const borderWidth = baseUnit * 0.25;

export const Root = styled.div`
  width: 100%;
  display: flex;
  background-color: ${bgGrey};
`;

export const TopLevelNode = styled.div`
  padding: ${baseUnit * 0.5}px ${baseUnit * 0.75}px;

  &:hover, &:active {
    padding: ${baseUnit * 0.25}px ${baseUnit * 0.5}px;
    border-width: ${borderWidth}px;
    border-style: solid;
  }

  &:active {
    border-color: ${borderGreyInverted};
  }

  &:hover {
    border-color: ${borderGrey};
  }
`;

export const TopLevelNodeWrapper = styled.div`
  position: relative;
`;

export const FlyoutMenu = styled.div<{ $direction: 'right' | 'down' }>`
  position: absolute;
  min-width: ${baseUnit * 17}px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  border-width: ${borderWidth}px;
  border-style: solid;
  border-color: ${borderGrey};
  background-color: ${bgGrey};

  ${({ $direction }) => $direction === 'right'
    ? css`left: 100%; top: 0;`
    : css`left: 0; top: 100%;`
  }
`;

export const FlyoutMenuItem = styled.div`
  position: relative;
  padding: ${baseUnit / 4}px ${baseUnit / 2}px;
  white-space: nowrap;

  &:hover { background-color: ${bgGreyDark}; }
`;
