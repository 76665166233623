export const getUniqueString: () => string = (() => {
  let i = 1;
  return () => `${i++}`;
})();

function templateStringJoin<A>(
    chunks: TemplateStringsArray,
    args: A[],
    toString: (arg: A) => string,
): string {
  let joined = chunks[0];

  for (let i = 1; i < chunks.length; i++) {
    joined += args[i - 1];
    joined += chunks[i];
  }

  return joined;
}

export function _findIndexOf(text: string, p: (ch: string) => boolean): number | undefined {
  for (let i = 0; i < text.length; i++) {
    if (p(text[i])) {
      return i;
    }
  }
  return undefined;
}


export function _unindent(chunks: TemplateStringsArray, ...args: any): string {
  const joined = templateStringJoin(chunks, args, a => `${a}`);
  const lines = joined.split('\n').slice(1);
  const indentDepth = _findIndexOf(lines[0], ch => ch !== ' ')
  if (indentDepth === undefined) {
    throw new Error('need to fix this');
  }

  const dedented = lines.map(line => line.slice(indentDepth));
  return dedented.join('\n');
}
