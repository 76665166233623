import * as React from 'react';
import resumePdf from 'url:@akst.io/data-resume/resume-public.pdf';
import { About, TextWithLink } from '@akst.io/lib/resume/types';
import { Blink } from '@akst.io/web-resume-dom/ui/base/blink/blink';
import { useMeasureMap } from '@akst.io/web-resume-dom/ui/base/responsive/measure';
import { Spoiler } from '@akst.io/web-resume-dom/ui/base/spoiler/spoiler';
import * as Unit from '@akst.io/web-resume-dom/ui/base/units/units';
import { ImageProps } from '@akst.io/web-resume-dom/ui/system/image/image';
import {
  Heading,
  ListItem,
  Normal,
  RainboxText,
  SubHeading,
  SubSubHeading,
} from '@akst.io/web-resume-dom/ui/application/resume_viewer/ui/type/type';
import {
  CopyContainer,
  Rows,
} from '@akst.io/web-resume-dom/ui/application/resume_viewer/ui/layout/layout';
import { LinkProps } from '@akst.io/web-resume-dom/ui/application/resume_viewer/ui/link/link';
import { ResumeTab } from '../../tabs_presenter';
import {
  LayoutNarrow,
  LayoutWide,
  List,
  ResumeLink,
  Sorry,
} from './welcome_styles';

const EDUCATION: ResumeTab = { kind: 'education' };
const EXPERIENCE: ResumeTab = { kind: 'experience' };
const INTERESTS: ResumeTab = { kind: 'interests' };
const SKILLS: ResumeTab = { kind: 'skills' };
const REFERENCES: ResumeTab = { kind: 'references' };
const VOLUNTEER: ResumeTab = { kind: 'volunteer' };

export const DownloadNotice = () => (
    <Sorry>
      <Normal>
        <strong>
          <ResumeLink
              href={resumePdf}
              download="download"
              target="_blank"
          >
              Click to Download Resume!
          </ResumeLink>
        </strong>
      </Normal>
    </Sorry>
);

type MeasureResult =
  | { kind: 'wide' }
  | { kind: 'narrow', pictureHeight: Unit.Unit };

const LinkListItem = ({
  link,
  label,
  target,
}: {
  link: TextWithLink,
  label: string,
  target?: undefined | '_blank',
}) => (
    <ListItem><strong>{label}:</strong> <ResumeLink>{link.text}</ResumeLink></ListItem>
);

export const Welcome = React.memo(({
  Link,
  Image,
  about,
  downloadSection,
  profilePicture,
}: {
  Link: React.ComponentType<LinkProps>,
  Image: React.ComponentType<ImageProps>,
  about: About,
  downloadSection: 'top' | 'bottom',
  profilePicture: string,
}) => {
  const auto = Unit.useAuto();

  const [mode, setElement] = useMeasureMap<any, MeasureResult>((snapshot) => {
    if (snapshot == null || snapshot.contentRect.width > 500) {
      return { kind: 'wide' };
    }

    return {
      kind: 'narrow',
      pictureHeight: { kind: 'px', value: snapshot.contentRect.width / 2 },
    };
  }, []);

  const Layout = mode.kind === 'wide' ? LayoutWide : LayoutNarrow;

  const {
    contact: { location, phone, email },
    links: { website },
  } = about;

  return (
      <Rows gridTemplateColumns="unset">
        {downloadSection === 'top' && DownloadNotice && <DownloadNotice/>}
        <Heading subHeading="Software Engineer">
          {about.name.long}
        </Heading>
        <Layout ref={setElement}>
          <Rows gridTemplateColumns="unset">
            {mode.kind === 'narrow' && (
                <Image
                    loadingSpeed={1000}
                    width={auto}
                    height={mode.pictureHeight}
                    url={profilePicture}
                />
            )}
            <SubSubHeading>Contact Information</SubSubHeading>
            <Spoiler spoilerMessage={(
                <Normal>Open to see contact info.</Normal>
            )}>
              <ul>
                <ListItem><strong>City:</strong> {location}</ListItem>
                {phone && <LinkListItem label="Phone" link={phone}/>}
                <LinkListItem label="Email" link={email}/>
                <LinkListItem label="Website" link={website} target="_blank"/>
              </ul>
            </Spoiler>
            <SubHeading margin={true} marquee={true}>
              Table of Contents
            </SubHeading>
            <List>
              <Link target={EXPERIENCE} style="chromeless">
                <RainboxText brightness={0.6}>Experience</RainboxText>
              </Link>
              <Link style="chromeless" target={REFERENCES}>⚖️  References</Link>
              <Link style="chromeless" target={EDUCATION}>🏛  Education</Link>
              <Link style="chromeless" target={SKILLS}>🎨 Skills</Link>
              <Link style="chromeless" target={VOLUNTEER}>🤝 Volunteering</Link>
              <Link style="chromeless" target={INTERESTS}>🤔 Interests</Link>
            </List>
          </Rows>
          {mode.kind === 'wide' && (
              <Image
                  loadingSpeed={1000}
                  width={auto}
                  height={auto}
                  url={profilePicture}
              />
          )}
        </Layout>
        <SubHeading margin={true} marquee={true}>
          About Me
        </SubHeading>
        <CopyContainer>
          <Normal>
            Hi 👋  I'm Angus! I'm a Software Engineer based
            in Sydney whose been writing software since 2014.
            Previously I've worked at SafetfyCulture (2014-2015) and
            Canva (2018-2021), as well a Music startup called Jaxsta.
            Most recently I worked at a Health Startup called HealthMatch,
            and during this time I've mostly had a focus on frontend software.
          </Normal>
          <Normal>
            Over the last few months I've been taking some time off
            to enjoy some of my hobbies such as language learning, music
            and a little bit of travel. Nothing too crazy just relaxing.
          </Normal>
          <Normal>
            After all this I'm ready to start my next adventure in software.
            I'm hoping my next role
            will contain an element of leadership and strategic planning.
            I become interested in this after a few experinces in my previous
            role where I saw payoffs from suggestions I made to our team focus
            based on product data.
            It's a very rewarding experince to see these suggestions I made
            result in meaningful jumps to our teams KPIs.
          </Normal>
          <Normal>
            Despite the bulk of my experince being in Frontend, I have
            bits and pieces of experince working in other parts of the stack
            and I have zero quarms with jumping into other parts of the stack
            to unblock my team.
            I actually find it quite fun. Take a look at the section on{" "}
            <Link style="link" target={EXPERIENCE}>experience</Link> to get an
            idea of the exact work I've done previously 🙂
          </Normal>
          <Normal>
            But also feel free to goof around in this windows 95 replica,
            sometimes the start button has useful things to say...
          </Normal>
        </CopyContainer>
        {downloadSection === 'bottom' && DownloadNotice && <DownloadNotice/>}
      </Rows>
  );
});
