import {
  action,
  observable,
  computed,
  IObservableValue,
  IComputedValue,
} from 'mobx';
import { Observer } from 'mobx-react';
import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import Measure, {
  ContentRect as IContentRect,
  MeasureProps,
} from 'react-measure';

type Props = Record<string, any>;
type ReturnType<T> = T extends (...args: any[]) => infer R ? R : any;

export type MeasurementOptions = Pick<MeasureProps, 'bounds' | 'margin' | 'scroll' | 'offset' | 'client'>;

export type ContentRect = IContentRect;

export type SetElement<E> = (element: E | null) => void;
export type ContentRectSnapshot = Pick<DOMRectReadOnly, 'x' | 'y' | 'width' | 'height' | 'left' | 'top'>;
export type MeasureSnapshot<A> = {
  contentRect: ContentRectSnapshot;
  element: A;
};

export function useMeasureMapWithElement<A extends Element, B>(
    element: A | null,
    fn: (node: MeasureSnapshot<A> | undefined) => B,
    changeList: ReadonlyArray<any> = [],
): B {
  const [value, setValue] = React.useState<B>(fn(undefined));

  React.useEffect(() => {
    if (element == null) {
      const frame = requestAnimationFrame(() => setValue(fn(undefined)));
      return () => cancelAnimationFrame(frame);
    };

    let frame: number | undefined;
    let observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target != element) continue;

        const snapshot: MeasureSnapshot<A> = { element, contentRect: entry.contentRect };
        frame = requestAnimationFrame(() => setValue(fn(snapshot)));
      }
    });

    observer.observe(element);

    return () => {
      frame != null && cancelAnimationFrame(frame);
      observer.disconnect();
    };
  }, [element, ...changeList]);

  return value;
}

export function useMeasureMap<A extends Element, B>(
    fn: (node: MeasureSnapshot<A> | undefined) => B,
    changeList: ReadonlyArray<any> = [],
): [B, SetElement<A>] {
  const [element, setElement] = React.useState<A | null>(null);
  const value = useMeasureMapWithElement(element, fn, changeList);
  return [value, setElement];
}

export function useMeasureWithElement<A extends Element, B>(
    element: A | null,
    alt: B,
    changeList: ReadonlyArray<any> = [],
): ContentRectSnapshot | B {
  const value =  useMeasureMapWithElement(
      element,
      maybeSnapshot => maybeSnapshot ? maybeSnapshot.contentRect : alt,
      changeList,
  );
  return value;
}


export function useMeasure<A extends Element, B>(
    alt: B,
    changeList: ReadonlyArray<any> = [],
): [ContentRectSnapshot | B, SetElement<A>] {
  const [element, setElement] = React.useState<A | null>(null);
  const value =  useMeasureMapWithElement(
      element,
      maybeSnapshot => maybeSnapshot ? maybeSnapshot.contentRect : alt,
      changeList,
  );
  return [value, setElement];
}

export function withMeasurement<M extends Props>(fn: (c?: ContentRect) => M, options?: MeasurementOptions) {
  return <O extends Props>(Component: React.ComponentType<M & O>): React.ComponentType<O> => {
    const contentRect: IObservableValue<undefined | ContentRect> =
        observable.box(undefined);

    const responsiveProps: IComputedValue<ReturnType<typeof fn>> =
        computed(() => fn(contentRect.get()));

    const onResize = action((value: ContentRect) => {
      contentRect.set(value);
    });

    return React.memo((props) => (
        <Measure {...options} onResize={onResize}>
          {({ measureRef }) => (
            <div ref={measureRef} style={{ width: '100%', height: '100%' }}>
              <Observer>
                {() => <Component {...responsiveProps.get()} {...props}/>}
              </Observer>
            </div>
          )}
        </Measure>
    ));
  };
}
