import { action, computed, observable, makeObservable } from 'mobx';
import { BoundingRect } from 'react-measure';
import { Clock } from '@akst.io/web-resume-dom/base/time/time';
import {
  LiveDirectory,
  LiveFileSystemNode,
} from '@akst.io/web-resume-dom/services/file_system/live_files_system_node';
import { ApplicationService } from '@akst.io/web-resume-dom/services/application/application_service';
import { ApplicationInstance } from '@akst.io/web-resume-dom/services/application/types';
import { DesktopService } from '@akst.io/web-resume-dom/services/desktop/desktop_service';
import { FileSystemService } from '@akst.io/web-resume-dom/services/file_system/file_system_service';
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import { AudioService } from '@akst.io/web-resume-dom/services/audio/audio_service';
import { AnimationKind } from '@akst.io/web-resume-dom/ui/application/assistant/configs/types';
import { AssistantController } from '@akst.io/web-resume-dom/ui/application/assistant/type';
import { StepProps } from '../types';

type OnStepTransition = StepProps['onStepTransition'];
type AppState = Pick<ApplicationService, 'running' | 'visible'>;

export type Time = { second: number, minute: number, hour: number };

const START_MESSAGE: { animation: AnimationKind, message: string }[] = [
  {
    animation: 'sad',
    message: 'Sorry this doesn\'t do anything',
  },
  {
    animation: 'sad',
    message: 'Are you trying to start something?',
  },
  {
    animation: 'pleased',
    message: 'Contrary to the button name it does not start much',
  },
  {
    animation: 'searching',
    message: 'Try Clicking "My Computer"',
  },
  {
    animation: 'searching',
    message: 'Try opening non text files with Note Pad dot e x e',
  },
  {
    animation: 'searching',
    message: 'Try opening a file in the sprites directory',
  },
];

export class DesktopStepStore {
  public lastStartClick: number = -1;
  public recordeSize: boolean = false;
  public time: Time;

  constructor(
      private readonly appState: AppState,
      public readonly directory: LiveDirectory,
      time: Time,
  ) {
    this.time = time;

    makeObservable(this, {
      time: observable.ref,
      applicationWindows: computed,
      runningApplications: computed
    });
  }

  public get applicationWindows(): ReadonlyArray<[number, ApplicationInstance]> {
    return Array.from(this.appState.visible.entries());
  }

  public get runningApplications(): ReadonlyArray<[number, ApplicationInstance]> {
    return Array.from(this.appState.running.entries());
  }
}

export class DesktopStepPresenter {
  constructor(
      private readonly audioService: AudioService,
      private readonly applicationService: ApplicationService,
      private readonly fileSystemService: FileSystemService,
      private readonly desktopService: DesktopService,
      private readonly loggingService: LoggingService,
      private readonly assistantController: AssistantController,
      private readonly clock: Clock,
      private readonly wait: (ms: number) => Promise<void>,
  ) {
    makeObservable(this, {
      tick: action
    });
  }

  createStore(directory: LiveDirectory) {
    return new DesktopStepStore(
        this.applicationService,
        directory,
        this.getTime(),
    );
  }

  onStartClick(store: DesktopStepStore) {
    if ((this.clock.now() - store.lastStartClick) < 1000) {
      return;
    }

    store.lastStartClick = this.clock.now();

    const index = Math.floor(Math.random() * START_MESSAGE.length);
    const { message, animation } = START_MESSAGE[index];

    const maybeAssistant = this.assistantController.findOrCreateAnAssistant();
    if (!maybeAssistant.ok) return;

    this.loggingService.debug('sending message to assistant', message);
    this.assistantController.queueAssistantExpression({
      id: maybeAssistant.value,
      expression: {
        kind: 'animation',
        animation,
        message,
      },
    });
  }

  tick(store: DesktopStepStore) {
    store.time = this.getTime();
  }

  async start(store: DesktopStepStore) {
    this.audioService.playSound('audio/windows_start');
    await this.wait(3000);
    const angusExe = this.fileSystemService.getLiveFile(['programs', 'Angus.exe']);
    this.applicationService.open(angusExe);
  }

  onFileOpen(store: DesktopStepStore, file: LiveFileSystemNode) {
    this.applicationService.open(file);
  }

  onResize(
      store: DesktopStepStore,
      transitionCallback: OnStepTransition,
      boundingRect: Pick<BoundingRect, 'width' | 'height'>,
  ) {
    this.desktopService.recordDesktopSize(boundingRect.width, boundingRect.height);
    if (!store.recordeSize) {
      store.recordeSize = true
    } else {
      // TODO(Angus): add error bounary to application to go
      // to the 'bluescreen_of_death';
      //
      // transitionCallback('bluescreen_of_death');
    }
  }

  onToggleMinization(
      store: DesktopStepStore,
      procId: number,
  ) {
    this.applicationService.toggleMinization(procId);
  }

  private getTime(): Time {
    const time = this.clock.now();
    const msInSeconds = 1000;
    const msInMinutes = 60 * msInSeconds;
    const msInHours = 60 * msInMinutes;
    return {
      second: Math.floor(time / msInSeconds) % 60,
      minute: Math.floor(time / msInMinutes) % 60,
      hour: Math.floor(time / msInHours) % 24,
    };
  }
}
