import * as React from 'react';
import {
  LocalApplicationController,
  LocalApplicationStarter,
} from '@akst.io/web-resume-dom/services/application/types';
import { ApplicationService } from "@akst.io/web-resume-dom/services/application/application_service";
import { AudioService } from '@akst.io/web-resume-dom/services/audio/audio_service';
import { DeviceService } from '@akst.io/web-resume-dom/services/device/device_service';
import { FileSystemService } from "@akst.io/web-resume-dom/services/file_system/file_system_service";
import { LiveFileSystemNode } from "@akst.io/web-resume-dom/services/file_system/live_files_system_node";
import { SpriteService } from '@akst.io/web-resume-dom/services/sprite/sprite_service';
import { unsafeSpriteCast } from '@akst.io/web-resume-dom/services/sprite/types';
import { AssistantController } from '@akst.io/web-resume-dom/ui/application/assistant/type';
import { ImageProps } from '@akst.io/web-resume-dom/ui/system/image/image';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import sprite from '@akst.io/web-resume-dom/ui/sprites/memes_sprite.json';
import { createTabs, Services } from './tabs/create'

const applicationId = 'resume_viewer';
const FILE_EXTENSION = 'angus';

export function installResumeViewer({
  assistantController,
  services: {
    fileSystemService,
    spriteService,
    ...services
  },
  Image,
  Panel,
}: {
  assistantController: AssistantController,
  services: Services & {
    applicationService: ApplicationService,
    audioService: AudioService,
    deviceService: DeviceService,
    fileSystemService: FileSystemService,
    spriteService: SpriteService,
  },
  Image: React.ComponentType<ImageProps>,
  Panel: React.ComponentType<PanelProps>,
}) {
  const { Component, controller, onStart } = createTabs({
    services,
    assistantController,
    Image,
    Panel,
  });
  const withFile: LocalApplicationStarter = (file: LiveFileSystemNode | undefined, procId: number) => {
    const result = onStart(procId);
    if (result.kind === 'already-running') {
      return {
        ok: false,
        error: { type: 'already-running', pid: result.procId },
      };
    }

    return { ok: true, value: { controller, Component } };
  };

  spriteService.register(
      FILE_EXTENSION,
      'Angus.sprite',
      unsafeSpriteCast(sprite),
  );
  services.applicationService.register(
      FILE_EXTENSION,
      { initializer: { type: 'local', withFile } },
      ['programs', 'Angus.exe'],
  );
}
