import * as React from 'react';
import { Button, ButtonProps } from '@akst.io/web-resume-dom/ui/base/button/button';
import { PanelProps } from '@akst.io/web-resume-dom/ui/system/panel/panel';
import { FileNavigatorProps } from '@akst.io/web-resume-dom/ui/system/file_navigator/file_navigator';

export type FileWindowProps = {
};

export type ExternallyInjectedProps = {
  FileNavigator: React.ComponentType<FileNavigatorProps>;
  Panel: React.ComponentType<PanelProps>;
};

type InterallyInjectedProps = {
  Button: React.ComponentType<ButtonProps>;
};

export type FileWindowImplProps =
  & FileWindowProps
  & ExternallyInjectedProps
  & InterallyInjectedProps;

export const FileWindowImpl = React.memo(function FileWindowImpl(props: FileWindowImplProps) {
  return null;
});

export function createFileWindow(options: ExternallyInjectedProps): React.ComponentType<FileWindowProps> {
  return (props: FileWindowProps) => (
      <FileWindowImpl
          {...options}
          {...props}
          Button={Button}
      />
  );
}
