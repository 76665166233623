import styled from 'styled-components';
import { baseUnit, smallestUnit } from '@akst.io/web-resume-dom/ui/base/units/units';
import { borderGreyInverted } from '@akst.io/web-resume-dom/ui/base/color/colors';

export const buttonWidth = 100;

export const RowItem = styled.div`
  & + & {
    margin-left: ${baseUnit}px;
  }
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

/*
 * SIZING OF THE SLIDER
 */
export const Sliders = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-right: ${baseUnit}px;
`;

export const SliderRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & + & {
    margin-top: ${baseUnit}px;
  }
`;

export const SliderRowName = styled.div`
  width: ${baseUnit * 10}px;
`;

/*
 * RIGHT SIDE CONTENT
 */
export const RightContent = styled(RowItem)`
  max-width: ${buttonWidth}px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Preview = styled.div`
  display: block;
  height: 100%;
  box-sizing: border-box;
  border-width: ${smallestUnit}px;
  border-color: ${borderGreyInverted};
  border-style: solid;
  flex-grow: 2;
`;

export const SaveButton = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${baseUnit}px;
`;

/*
 * THE PALLET BENEATH THE CONTROLS
 */

export const Pallet = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${baseUnit}px;
  flex-wrap: wrap;
`;

export const PalletHint = styled.div`
  margin-top: ${baseUnit}px;
`;

export const PalletEntryContainer = styled.div`
  border-width: ${smallestUnit}px;
  border-color: ${borderGreyInverted};
  border-style: solid;
`;
