import { UnreachableError } from '@akst.io/lib/base/types';
import { LoggingService } from '@akst.io/web-resume-dom/services/logging/logging_service';
import {
  AnimatorStore,
  AnimatorPresenter,
} from './animator/animator_presenter';
import { TranslateCtrl } from './animator/animator_transform_presenter';
import {
  AssistantImageStore,
  AssistantImagePresenter,
} from './assistant_image_presenter';
import { AssistantExpression } from './type';

export type InstanceState =
  | { kind: 'initial' }
  | { kind: 'loading' }
  | { kind: 'available', animatorStore: AnimatorStore }
  | { kind: 'error' };

export class AssistantInstanceStore {
  state: Readonly<InstanceState> = { kind: 'initial' };
  queue: AssistantExpression[] = [];

  constructor(
      readonly procId: number,
      readonly image: AssistantImageStore,
  ) {
  }
}

export class AssistantInstancePresenter {
  constructor(
      private readonly logging: LoggingService,
      private readonly imagePresenter: AssistantImagePresenter,
      private readonly animatorPresenter: AnimatorPresenter,
  ) {
  }

  createInstance(procId: number, image: AssistantImageStore): AssistantInstanceStore {
    return new AssistantInstanceStore(procId, image);
  }

  queueAssistantExpression(
      store: AssistantInstanceStore,
      expression: AssistantExpression,
  ) {
    switch (store.state.kind) {
      case 'available':
        this.animatorPresenter.queueExpression(
            store.state.animatorStore,
            expression,
        );
        return;

      case 'error':
        return;

      case 'loading':
      case 'initial':
        store.queue.push(expression);
        break;

      default:
        throw new UnreachableError(store.state);
    }
  }

  async resume(
      store: AssistantInstanceStore,
      canvas: HTMLCanvasElement,
      translateCtrl: TranslateCtrl,
  ) {
    switch (store.state.kind) {
      case 'available':
        this.animatorPresenter.resume(
            store.state.animatorStore,
            canvas,
            translateCtrl,
        );
        return;

      case 'loading':
      case 'error':
        return;

      case 'initial':
        break;

      default:
        throw new UnreachableError(store.state);
    }

    store.state = { kind: 'loading' };
    const assets = await this.imagePresenter.load(store.image);
    if (!assets.ok) {
      store.state = { kind: 'error' };
      return;
    }

    const result = this.animatorPresenter.createStore(
        canvas,
        assets.value.images,
        assets.value.config,
        translateCtrl,
    );

    if (result.ok) {
      this.logging.debug('animator created');
      const animatorStore = result.value;
      for (const ex of store.queue) {
        this.animatorPresenter.queueExpression(animatorStore, ex);
      }
      store.queue = [];
      store.state = { kind: 'available', animatorStore };
    } else {
      this.logging.error('failed to create animator');
      store.state = { kind: 'error' };
    }
  }

  pause(store: AssistantInstanceStore) {
    switch (store.state.kind) {
      case 'initial':
      case 'error':
      case 'loading':
        return;

      case 'available':
        this.animatorPresenter.pause(store.state.animatorStore);
        return;
      default:
        throw new UnreachableError(store.state);
    }
  }
}
