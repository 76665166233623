export type KeyboardType = 'apple' | 'not-apple';

export function isTouchDevice() {
  return 'ontouchstart' in window;
}

export function isIos() {
  return navigator.userAgent.includes('iPhone')
      || navigator.userAgent.includes('iPad');
}

export function keyboardType(): KeyboardType {
  return navigator.userAgent.indexOf('Mac OS X') !== -1 ? 'apple' : 'not-apple';
}
